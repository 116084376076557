import {
    PAGES_DETAIL,
    PAGES_INSERT,
    PAGES_LIST,
    PAGES_TREE,
    PAGE_ACTION_DELETE,
    PAGE_ACTION_DETAIL,
    PAGES_DELETE,
    PAGES_UPDATE,
    PAGE_ACTION_UPDATE,
    PAGE_ACTION_INSERT,
    PAGE_ACTION_LIST
} from './action-types';
import { PAGE_SIZE } from '../../../../constants/Config';
import * as _ from 'lodash';
import Transformer from '../../../../utils/Transformer';
const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    tree: [],
    action: null,
    item: null,
    actionData: [],
    actionItem: {}
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PAGES_LIST:
            return list(state, payload);
        case PAGES_TREE:
            return tree(state, payload);
        case PAGES_DETAIL:
            return detail(state, payload);
        case PAGES_UPDATE:
            return update(state, payload);
        case PAGES_INSERT:
            return insert(state, payload);
        case PAGES_DELETE:
            return deletes(state, payload);
        case PAGE_ACTION_LIST:
            return actionList(state, payload);
        case PAGE_ACTION_DETAIL:
            return pageActionDetail(state, payload);
        case PAGE_ACTION_DELETE:
            return pageActionDelete(state, payload);
        case PAGE_ACTION_UPDATE:
            return pageActionUpdate(state, payload);
        case PAGE_ACTION_INSERT:
            return pageActionInsert(state, payload);
        default:
            return state;
    }
};

function actionList(state, payload) {
    state = Object.assign({}, state, { actionData: payload });
    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function tree(state, payload) {
    state = Object.assign({}, state, {
        tree: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function insert(state, payload) {
    const insertItem = {
        id: parseInt(payload.id),
        parentId: payload.parentId,
        text: payload.name,
        children: []
    };

    const data = [...state.data, payload];

    if (payload.parentId === null) {
        state.tree = [...state.tree, insertItem];
    } else {
        Transformer.insertTree(state.tree, insertItem);
    }

    return Object.assign({}, state, { data }, { totalRows: state.totalRows + 1 });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    const tree = Transformer.updateTree(state.tree, payload);

    const item = { ...state.item, ...payload };

    return Object.assign({}, state, { data, item, tree });
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);
    const tree = Transformer.deleteTree(state.tree, payload);

    return Object.assign({}, state, { data, tree }, { totalRows: state.totalRows - 1 });
}

function pageActionDetail(state, payload) {
    state = Object.assign({}, state, {
        actionItem: payload
    });
    return state;
}
function pageActionDelete(state, payload) {
    const item = _.find(state.data, (item) => {
        return item.id === payload.pageId;
    });
    const resourcesActions = _.map(item.resourceActions, (item) => {
        if (item.id !== payload.id) {
            return item;
        }
    });
    const items = Object.assign({}, item, { resourcesActions });
    const data = state.data.map((obj) => {
        if (obj.id === payload.pageId) {
            return { ...obj, ...items };
        }
        return obj;
    });
    return Object.assign({}, state, { data });
}

function pageActionUpdate(state, payload) {
    const item = _.find(state.data, (item) => {
        return item.id === payload.pageId;
    });
    const resourcesActions = _.map(item.resourceActions, (item) => {
        if (item.id === payload.id) {
            return { ...item, ...payload };
        }
        return item;
    });
    const items = Object.assign({}, item, { resourcesActions });
    const data = state.data.map((obj) => {
        if (obj.id === payload.pageId) {
            return { ...obj, ...items };
        }
        return obj;
    });
    return Object.assign({}, state, { data });
}

function pageActionInsert(state, payload) {
    const resourceActions = { ...state.item, payload };
    const item = Object.assign({}, state.item, { resourceActions: resourceActions });
    return Object.assign({}, state, { item });
}
export default reducer;
