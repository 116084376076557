export const CUSTOMER_LIST = 'CUSTOMER_LIST';
export const CUSTOMER_DETAIL = 'CUSTOMER_DETAIL';
export const CUSTOMER_UPDATE = 'CUSTOMER_UPDATE';
export const CUSTOMER_INSERT = 'CUSTOMER_INSERT';
export const CUSTOMER_DELETE = 'CUSTOMER_DELETE';
export const CUSTOMER_RESPONSE = 'CUSTOMER_RESPONSE';
export const CUSTOMER_GETALL = 'CUSTOMER_GETALL';
export const CUSTOMER_GETALL_BALANCE = 'CUSTOMER_GETALL_BALANCE';
export const CUSTOMER_GETALL_SERVICECARD = 'CUSTOMER_GETALL_SERVICECARD';
export const CUSTOMER_SELECT = 'CUSTOMER_SELECT';
export const CUSTOMER_GETCODE = 'CUSTOMER_GETCODE';
export const CUSTOMER_FILTER = 'CUSTOMER_FILTER';
export const CUSTOMER_DETAIL_RESET = 'CUSTOMER_DETAIL_RESET';
export const CUSTOMER_HISTORY_ADD = 'CUSTOMER_HISTORY_ADD';
export const CUSTOMER_HISTORY_PAYMENT = 'CUSTOMER_HISTORY_PAYMENT';
export const CUSTOMER_HAVE_CARD = 'CUSTOMER_HAVE_CARD';

export default {
    CUSTOMER_DELETE,
    CUSTOMER_DETAIL,
    CUSTOMER_INSERT,
    CUSTOMER_LIST,
    CUSTOMER_UPDATE,
    CUSTOMER_GETALL,
    CUSTOMER_SELECT,
    CUSTOMER_GETCODE,
    CUSTOMER_FILTER,
    CUSTOMER_DETAIL_RESET,
    CUSTOMER_HISTORY_ADD,
    CUSTOMER_HISTORY_PAYMENT,
    CUSTOMER_GETALL_BALANCE,
    CUSTOMER_GETALL_SERVICECARD,
    CUSTOMER_HAVE_CARD
};
