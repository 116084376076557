export const BOOKING_LIST = 'BOOKING_LIST';
export const BOOKING_DETAIL = 'BOOKING_DETAIL';
export const BOOKING_UPDATE = 'BOOKING_UPDATE';
export const BOOKING_INSERT = 'BOOKING_INSERT';
export const BOOKING_DELETE = 'BOOKING_DELETE';
export const BOOKING_GET_ALL = 'BOOKING_GET_ALL';

export default {
    BOOKING_DELETE,
    BOOKING_DETAIL,
    BOOKING_INSERT,
    BOOKING_LIST,
    BOOKING_UPDATE,
    BOOKING_GET_ALL
};
