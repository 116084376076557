export const USER_LIST = 'NATION_LIST';
export const USER_DETAIL = 'USER_DETAIL';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_INSERT = 'USER_INSERT';
export const USER_DELETE = 'USER_DELETE';
export const USER_RESPONSE = 'USER_RESPONSE';
export const USER_GET_ALL = 'USER_GET_ALL';
export const USERS_GET_ALL = 'USERS_GET_ALL';
export const USER_GETCODE = 'USER_GETCODE';
export const USER_DETAIL_RESET = 'USER_DETAIL_RESET';
export const USER_SELECT = 'USER_SELECT';
export const USER_BRANCH = 'USER_BRANCH';
export const USER_ACTIVE = 'USER_ACTIVE';

export default {
    USER_DELETE,
    USER_DETAIL,
    USER_INSERT,
    USER_LIST,
    USER_UPDATE,
    USER_RESPONSE,
    USER_GETCODE,
    USER_GET_ALL,
    USERS_GET_ALL,
    USER_DETAIL_RESET,
    USER_BRANCH,
    USER_ACTIVE
};
