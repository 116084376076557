import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Navigation from './navigation/index';

const displayName = 'Private Layout';

const propTypes = {
    children: PropTypes.node.isRequired,
    toggleSidebar: PropTypes.bool,
    appSetting: PropTypes.object
};

function PrivateLayout({ children, appSetting }) {
    let renderChildren = false;
    if (appSetting && appSetting.userSettings) {
        const branchItem = _.find(appSetting.userSettings, (item) => {
            return item.key === 'BranchId';
        });
        if (branchItem) {
            renderChildren = true;
        }
    }

    return renderChildren ? <Navigation>{children}</Navigation> : <Navigation></Navigation>;
}

PrivateLayout.dispath = displayName;
PrivateLayout.propTypes = propTypes;

export default PrivateLayout;
