export const GOODS_NOTE_DETAIL_LIST = 'GOODS_NOTE_DETAIL_LIST';
export const GOODS_NOTE_DETAIL_SEARCH = 'GOODS_NOTE_DETAIL_SEARCH';
export const GOODS_NOTE_DETAIL_DETAIL = 'GOODS_NOTE_DETAIL_DETAIL';
export const GOODS_NOTE_DETAIL_UPDATE = 'GOODS_NOTE_DETAIL_UPDATE';
export const GOODS_NOTE_DETAIL_INSERT = 'GOODS_NOTE_DETAIL_INSERT';
export const GOODS_NOTE_DETAIL_DELETE = 'GOODS_NOTE_DETAIL_DELETE';
export const GOODS_NOTE_DETAIL_GET_ALL = 'GOODS_NOTE_DETAIL_GET_ALL';
export const GOODS_NOTE_DETAIL_SELECT = 'GOODS_NOTE_DETAIL_SELECT';
export const GOODS_NOTE_DETAIL_LIST_RESET = 'GOODS_NOTE_DETAIL_LIST_RESET';

export default {
    GOODS_NOTE_DETAIL_DELETE,
    GOODS_NOTE_DETAIL_DETAIL,
    GOODS_NOTE_DETAIL_INSERT,
    GOODS_NOTE_DETAIL_LIST,
    GOODS_NOTE_DETAIL_UPDATE,
    GOODS_NOTE_DETAIL_GET_ALL,
    GOODS_NOTE_DETAIL_SELECT,
    GOODS_NOTE_DETAIL_LIST_RESET,
    GOODS_NOTE_DETAIL_SEARCH
};
