import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { EXCEPT_ROUTER } from '../constants/Config';

const PrivateRoute = ({ component: Component, isAuthenticated, data, currentLanguage, ...rest }) => {
    let isShow = true;

    const isExceptRouter = _.includes(EXCEPT_ROUTER, rest.path);

    if (data && data.pages && !isExceptRouter) {
        const selectPage = _.find(data.pages, { url: rest.path });
        document.title = selectPage ? `GHMSpa - ${JSON.parse(selectPage.name)[currentLanguage]}` : 'GHMSpa';
        isShow = !!selectPage;
    }

    if (isExceptRouter) document.title = 'GHMSpa';

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    isShow ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location }
                            }}
                        />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    data: PropTypes.any,
    currentLanguage: PropTypes.any
};

function mapStateToProps(store) {
    const currentLanguage = store.appSettings.data
        ? _.find(store.appSettings.data.userSettings, (item) => item.key === 'LanguageId')
        : null;

    return {
        isAuthenticated: store.auth.isAuthenticated,
        data: store.appSettings.data,
        currentLanguage: currentLanguage ? currentLanguage.value : 'vi-VN'
    };
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
