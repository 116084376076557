export const GROUPS_LIST = 'GROUPS_LIST';
export const GROUPS_TREE = 'GROUPS_TREE';
export const GROUPS_DETAIL = 'GROUPS_DETAIL';
export const GROUPS_INSERT = 'GROUPS_INSERT';
export const GROUPS_UPDATE = 'GROUPS_UPDATE';
export const GROUPS_DELETE = 'GROUPS_DELETE';
// export const GROUP_ACTION_DETAIL = 'GROUP_ACTION_DETAIL'
// export const GROUP_ACTION_DELETE = 'GROUP_ACTION_DELETE'
// export const GROUP_ACTION_UPDATE = 'GROUP_ACTION_UPDATE'
// export const GROUP_ACTION_INSERT = 'GROUP_ACTION_INSERT'
export default {
    GROUPS_LIST,
    GROUPS_TREE,
    GROUPS_DETAIL,
    GROUPS_INSERT,
    GROUPS_UPDATE,
    GROUPS_DELETE
    // GROUP_ACTION_DELETE,
    // GROUP_ACTION_DETAIL,
    // GROUP_ACTION_INSERT,
    // GROUP_ACTION_UPDATE
};
