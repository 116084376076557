export const PAGES_LIST = 'PAGES_LIST';
export const PAGES_TREE = 'PAGES_TREE';
export const PAGES_DETAIL = 'PAGES_DETAIL';
export const PAGES_INSERT = 'PAGES_INSERT';
export const PAGES_UPDATE = 'PAGES_UPDATE';
export const PAGES_DELETE = 'PAGES_DELETE';
export const PAGE_ACTION_DETAIL = 'PAGE_ACTION_DETAIL';
export const PAGE_ACTION_DELETE = 'PAGE_ACTION_DELETE';
export const PAGE_ACTION_UPDATE = 'PAGE_ACTION_UPDATE';
export const PAGE_ACTION_INSERT = 'PAGE_ACTION_INSERT';
export const PAGE_ACTION_LIST = 'PAGE_ACTION_LIST';

export default {
    PAGES_LIST,
    PAGES_TREE,
    PAGES_DETAIL,
    PAGES_INSERT,
    PAGES_UPDATE,
    PAGES_DELETE,
    PAGE_ACTION_LIST,
    PAGE_ACTION_DELETE,
    PAGE_ACTION_DETAIL,
    PAGE_ACTION_INSERT,
    PAGE_ACTION_UPDATE
};
