import React, { Component } from 'react';
import { TextBox } from 'devextreme-react';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';

class MultiLangNameBox extends Component {
    constructor(props) {
        super(props);

        this.validatorTranslation = {};
        this.props.languages.forEach((item) => {
            this.validatorTranslation[item.languageId] = new ReeValidate({
                name: this.props.validatorValue
            });
            if (this.props.currentLanguage === 'vi-VN') {
                this.validatorTranslation[item.languageId].localize('vi', vi);
            } else {
                this.validatorTranslation[item.languageId].localize('en', en);
            }

            this.validatorTranslation[item.languageId].validate('name', this.props.value[item.languageId]).then(() => {
                this.checkValidate();
            });
        });

        this.valueChangedTranslation = this.valueChangedTranslation.bind(this);
    }

    valueChangedTranslation(e) {
        if (e.event !== undefined && e.event !== null) {
            const { currentLanguageActive } = this.props;
            const value = e.event.target.value.trim();

            this.props.updateData({
                [this.props.field]: { ...this.props.value, [currentLanguageActive]: value }
            });

            this.validatorTranslation[currentLanguageActive].validate(e.event.target.name, value).then(() => {
                this.checkValidate();
            });
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value || nextProps.languages !== this.props.languages) {
            nextProps.languages.forEach((item) => {
                this.validatorTranslation[item.languageId] = new ReeValidate({
                    name: this.props.validatorValue
                });
                if (this.props.currentLanguage === 'vi-VN') {
                    this.validatorTranslation[item.languageId].localize('vi', vi);
                } else {
                    this.validatorTranslation[item.languageId].localize('en', en);
                }

                this.validatorTranslation[item.languageId]
                    .validate('name', nextProps.value[item.languageId])
                    .then(() => {
                        this.checkValidate();
                    });
            });
        }
    }

    checkValidate() {
        const { languages, field } = this.props;

        let formValidate = true;
        languages.forEach((element) => {
            if (this.validatorTranslation[element.languageId].errors.has('name')) {
                formValidate = false;
            }
        });

        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    render() {
        const { currentLanguageActive, value, i18nLabel, validatorValue, readOnly } = this.props;

        return (
            <div
                className={`w-100 ${
                    this.validatorTranslation[currentLanguageActive] &&
                    this.validatorTranslation[currentLanguageActive].errors.has('name')
                        ? ' has-error'
                        : ''
                }`}
            >
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {!readOnly && validatorValue && validatorValue.includes('require') && (
                            <span className="color-red"> *</span>
                        )}
                    </div>

                    <div className={'dx-field-value'}>
                        <TextBox
                            className={'multi-language'}
                            name={'name'}
                            value={value[currentLanguageActive]}
                            onValueChanged={this.valueChangedTranslation}
                            readOnly={readOnly || false}
                        />

                        {this.validatorTranslation[currentLanguageActive] &&
                            this.validatorTranslation[currentLanguageActive].errors.has('name') && (
                            <div className="help-block">
                                {this.validatorTranslation[currentLanguageActive].errors
                                    .first('name')
                                    .replace('name', i18n.t(i18nLabel))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

MultiLangNameBox.propTypes = {
    currentLanguageActive: PropTypes.string,
    value: PropTypes.any,
    i18nLabel: PropTypes.string,
    validatorValue: PropTypes.string,
    readOnly: PropTypes.bool,
    currentLanguage: PropTypes.string,
    updateData: PropTypes.func,
    field: PropTypes.string,
    languages: PropTypes.array
};

const mapStateToProps = (state) => {
    const { userSettings, languages } = state.appSettings.data;
    const currentLanguage = _.find(userSettings, (item) => item.key === 'LanguageId');

    return {
        languages,
        currentLanguage: currentLanguage.value
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiLangNameBox);
