export const SHIFT_LIST = 'SHIFT_LIST';
export const SHIFT_DETAIL = 'SHIFT_DETAIL';
export const SHIFT_UPDATE = 'SHIFT_UPDATE';
export const SHIFT_INSERT = 'SHIFT_INSERT';
export const SHIFT_DELETE = 'SHIFT_DELETE';
export const SHIFT_RESPONSE = 'SHIFT_RESPONSE';
export const SHIFT_GET_ALL = 'SHIFT_GET_ALL';

export default {
    SHIFT_DELETE,
    SHIFT_DETAIL,
    SHIFT_INSERT,
    SHIFT_LIST,
    SHIFT_UPDATE,
    SHIFT_RESPONSE,
    SHIFT_GET_ALL
};
