import {
    INVOICE_HISTORY,
    INVOICE_PAYMENT_BY_CARD,
    INVOICE_LIST,
    INVOICE_LIST_RESET,
    INVOICE_GET_CODE,
    INVOICE_INSERT,
    INVOICE_DETAIL
} from './action-types';

const initialState = {
    history: [],
    payments: [],
    data: [],
    customerId: null,
    totalRows: 0,
    paymentCount: 0,
    historyCount: 0,
    code: '',
    item: {}
};

const invoices = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case INVOICE_HISTORY:
            return history(state, payload);
        case INVOICE_PAYMENT_BY_CARD:
            return paymentbycard(state, payload);
        case INVOICE_LIST:
            return list(state, payload);
        case INVOICE_LIST_RESET:
            return resetList(state);
        case INVOICE_GET_CODE:
            return getInvoiceCode(state, payload);
        case INVOICE_INSERT:
            return insert(state, payload);
        case INVOICE_DETAIL:
            return detail(state, payload);
        default:
            return state;
    }
};

function insert(state, payload) {
    const data = [...state.data, payload];
    return { ...state, item: payload, data };
}

function history(state, payload) {
    state = Object.assign({}, state, { history: payload.data, historyCount: payload.totalRows });
    return state;
}

function paymentbycard(state, payload) {
    return { ...state, payments: payload.data, paymentCount: payload.totalRows };
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detail(state, payload) {
    return { ...state, item: payload };
}

function resetList(state) {
    state = Object.assign({}, state, {
        data: [],
        payments: [],
        history: []
    });
    return state;
}

function getInvoiceCode(state, payload) {
    state = Object.assign({}, state, {
        code: payload
    });
    return state;
}

export const getInvoicePayments = (state) => state.invoices.payments;
export const getInvoicePaymentCount = (state) => state.invoices.paymentCount;

export const getInvoiceHistories = (state) => state.invoices.history;
export const getInvoiceItem = (state) => state.invoices.item;

export default invoices;
