export const PROMOTION_LIST = 'PROMOTION_LIST';
export const PROMOTION_DETAIL = 'PROMOTION_DETAIL';
export const PROMOTION_UPDATE = 'PROMOTION_UPDATE';
export const PROMOTION_INSERT = 'PROMOTION_INSERT';
export const PROMOTION_DELETE = 'PROMOTION_DELETE';
export const PROMOTION_GET_ALL = 'PROMOTION_GET_ALL';
export const PROMOTION_GET_ALL_BY_SERVICE_NOTE = 'PROMOTION_GET_ALL_BY_SERVICE_NOTE';

export default {
    PROMOTION_DELETE,
    PROMOTION_DETAIL,
    PROMOTION_INSERT,
    PROMOTION_LIST,
    PROMOTION_UPDATE,
    PROMOTION_GET_ALL,
    PROMOTION_GET_ALL_BY_SERVICE_NOTE
};
