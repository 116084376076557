import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as signalR from '@aspnet/signalr';
import { LogLevel } from '@aspnet/signalr';
import { insert } from '../store/action';
import {
    searchNotificationRequest,
    updateIsReadNotificationRequest,
    deleteNotificationRequest,
    reSearchNotificationRequest,
    updateAllReadNotificationRequest,
    countUnReadNotificationRequest,
    officeTreeUsersGetAll
} from '../service';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FILE_URL, DEFAULT_AVATAR, NOTIFICATION_URL } from '../../../constants/Config';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ToastsStore } from 'react-toasts';
import i18n from '../../../i18n';
import { logout } from '../../auth/service';
import Transformer from '../../../utils/Transformer';
import PropTypes from 'prop-types';
import { Popover } from 'devextreme-react/popover';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react';
import * as _ from 'lodash';
import viLocale from 'moment/locale/vi';
import gbLocale from 'moment/locale/en-gb';
import NotificationViewModel from '../NotificationViewModel';

var moment = require('moment');
class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultVisible: false,
            defaultVisiblereadAll: false,
            defaultVisibleNotify: false,
            popupDetails: false,
            isShownNotification: false,
            search: {
                isRead: null,
                languageId: this.props.currentLanguage,
                page: 1,
                pageSize: this.props.pageSize
            },
            loading: false,
            selectedId: ''
        };
        this.connection = null;
        this.toggleDefault = this.toggleDefault.bind(this);
        this.toggleDefaultreadAll = this.toggleDefaultreadAll.bind(this);
        this.toggleDefaultNotify = this.toggleDefaultNotify.bind(this);
        this.togglepopupDetails = this.togglepopupDetails.bind(this);
        this.hideWithShadingOptions = this.hideWithShadingOptions.bind(this);
        this.hideWithShadingOptionsreadAll = this.hideWithShadingOptionsreadAll.bind(this);
        this.hideWithShadingOptionsNotify = this.hideWithShadingOptionsNotify.bind(this);
        this.hidepopupDetails = this.hidepopupDetails.bind(this);

        if (this.props.currentLanguage === 'vi-VN') {
            moment.locale('vi', [viLocale]);
        } else {
            moment.locale('es', [gbLocale]);
        }
    }

    componentDidMount() {
        const { currentLanguage } = this.props;
        const payload = { currentLanguage };
        this.props.getAllTreeUsers(payload);
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
        if (nextProps.currentLanguage !== this.props.currentLanguage) {
            if (nextProps.currentLanguage === 'vi-VN') {
                moment.locale('vi', [viLocale]);
            } else {
                moment.locale('es', [gbLocale]);
            }
        }
    }

    toggleDefault(id) {
        this.setState({
            defaultVisible: !this.state.defaultVisible,
            selectedId: id
        });
    }

    hideWithShadingOptions(id) {
        this.setState({
            defaultVisible: false,
            selectedId: id
        });
    }

    togglepopupDetails(id) {
        this.setState({
            popupDetails: !this.state.popupDetails,
            selectedId: id
        });
        if (!id.isRead) {
            const payload = {
                id: id,
                isRead: true
            };

            this.props.updateRead(payload).then(() => {
                this.countUnRead();
            });
        }
        this.props.history.push('/string');
    }

    hidepopupDetails(id) {
        this.setState({
            popupDetails: false,
            selectedId: id
        });
    }

    toggleDefaultreadAll() {
        this.setState({
            defaultVisiblereadAll: !this.state.defaultVisiblereadAll
        });
    }

    hideWithShadingOptionsreadAll() {
        this.setState({
            defaultVisiblereadAll: false
        });
    }

    toggleDefaultNotify() {
        this.setState({
            defaultVisibleNotify: !this.state.defaultVisibleNotify
        });
    }

    hideWithShadingOptionsNotify() {
        this.setState({
            defaultVisibleNotify: false
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        const { search } = this.state;

        const options = {
            accessTokenFactory: () => localStorage.getItem('access_token')
        };

        if (this.props.isAuthenticated) {
            this.props.searchRequest(search).then(() => {});
            this.countUnRead();
        }

        if (this.props.isAuthenticated) {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(`${NOTIFICATION_URL}/notifications`, options)
                .configureLogging(LogLevel.Debug)
                .build();

            this.connection.serverTimeoutInMilliseconds = 100000; // 100 second

            this.connection.start().then(() => {});

            this.connection.on('NotificationReceived', (notification) => {
                const newNotify = new NotificationViewModel(notification, this.props.currentLanguage);
                ToastsStore.info(`${i18n.t('notify.message')}\n${newNotify.title}:${newNotify.subContent}`);

                this.props.insert(newNotify);
            });

            this.connection.on('LoggedOut', () => {
                console.log('logout in other device');
                this.props.logout();
            });
        }
    }

    componentWillUnmount() {
        this.connection.stop();
    }

    readNotify(e, item) {
        e.stopPropagation();
        const payload = {
            id: item.id,
            isRead: true
        };

        this.props.updateRead(payload).then(() => {
            this.countUnRead();
        });
    }

    countUnRead = () => {
        this.props.countUnRead();
    };

    deleteNotify = (e, item) => {
        e.stopPropagation();
        this.props.delete({ id: item.id }).then((mess) => {
            ToastsStore.success(mess);
            this.reSearch();
            this.countUnRead();
        });
    };

    unReadNotifyUpdate(e, item) {
        e.stopPropagation();
        const payload = {
            id: item.id,
            isRead: false
        };

        this.props.updateRead(payload).then(() => {
            this.countUnRead();
        });
    }

    reSearch = () => {
        const { notifications, currentLanguage } = this.props;

        this.props.reSearch({ page: 1, pageSize: notifications.length, languageId: currentLanguage }).then(() => {
            this.countUnRead();
        });
    };

    search = () => {
        const { search } = this.state;

        this.setState({
            loading: true
        });
        this.props.searchRequest(search).then(() => {
            const { notifications } = this.props;

            this.setState({
                notifications,
                loading: false
            });
        });
    };

    handleScroll = (e) => {
        const { notifications, totalRows } = this.props;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        if (bottom && notifications.length < totalRows) {
            this.readMore();
        }
    };

    readMore() {
        const page = this.state.search.page + 1;

        this.setState(
            {
                search: { ...this.state.search, page: page }
            },
            () => {
                this.search();
            }
        );
    }

    readAll() {
        const { unReadCount } = this.props;
        if (unReadCount > 0) {
            this.props.readAll().then(() => {
                this.countUnRead();
            });
        }
    }

    redirectAction = (item) => {
        if (!item.isRead) {
            const payload = {
                id: item.id,
                isRead: true
            };

            this.props.updateRead(payload).then(() => {
                this.countUnRead();
            });
        }
        this.props.history.push('/string');
    };

    render() {
        const { loading, selectedId } = this.state;
        const { notifications, unReadCount } = this.props;

        return (
            <li className="dropdown notification-menu">
                <button
                    className="navbar-toggler dx-theme-accent-as-text-color"
                    id="notification-dropdown"
                    onClick={() => this.toggleDefaultNotify()}
                >
                    <FontAwesomeIcon icon={faBell} size="lg" />
                    {unReadCount > 0 && <span className="badge badge-danger header-badge">{unReadCount}</span>}
                </button>
                <Popover
                    target={'#notification-dropdown'}
                    className="popover-notify"
                    position="bottom"
                    width={300}
                    visible={this.state.defaultVisibleNotify}
                    onHiding={this.hideWithShadingOptionsNotify}
                >
                    <ScrollView>
                        {notifications.length === 0 ? (
                            <div className="text-center pt-4">
                                <b style={{ color: '#b1b1b1' }}>{i18n.t('general.notification.noNotification')}</b>
                                <img src="/assets/images/empty.jpg" alt="" />
                            </div>
                        ) : (
                            <div className="notification-menu dx-theme-background-color">
                                <ul className="navbar-nav">
                                    <li className="not-head dx-theme-text-color dropdown dx-theme-text-color dropdown">
                                        {/* {i18n.t('general.notification.youHave')} <b className="dx-theme-accent-as-text-color">{totalRows}</b> {i18n.t('general.notification.notifications')}. */}
                                        <h4 className="m-0">
                                            <b>{i18n.t('general.notification.notification')}</b>
                                        </h4>
                                        <span
                                            className="tab-noti readAll"
                                            id="link-readAll"
                                            onClick={() => this.toggleDefaultreadAll()}
                                        >
                                            <i className="icon dx-icon-overflow"></i>
                                        </span>
                                        <Popover
                                            target={'#link-readAll'}
                                            position="bottom"
                                            width={250}
                                            visible={this.state.defaultVisiblereadAll}
                                            onHiding={this.hideWithShadingOptionsreadAll}
                                        >
                                            <ul className="action-noti">
                                                <li>
                                                    <span className="dropdown-item py-2" onClick={() => this.readAll()}>
                                                        <i className="icon dx-icon-check mr-2"></i>{' '}
                                                        {i18n.t('general.notification.markAllAsRead')}
                                                    </span>
                                                </li>
                                            </ul>
                                        </Popover>
                                    </li>

                                    <li className="dropdown list-notication" onScroll={this.handleScroll}>
                                        {notifications.map((item, index) => {
                                            return (
                                                <Link
                                                    to=""
                                                    className={`bell-notification  ${item.isRead ? '' : 'is-read'}`}
                                                    key={index}
                                                >
                                                    {item.url === null || item.url === '' || item.url === 'string' ? (
                                                        <div>
                                                            {/* <span className="media dx-theme-text-color" onClick={(e) => this.redirectAction(item)}> */}
                                                            <span
                                                                className="media dx-theme-text-color"
                                                                onClick={() => this.togglepopupDetails(item.id)}
                                                            >
                                                                <span className="media-icon">
                                                                    {item.isSystem ? (
                                                                        <img
                                                                            alt=""
                                                                            className="rounded-circle"
                                                                            src={
                                                                                item.image
                                                                                    ? `${FILE_URL}/${item.image}`
                                                                                    : `${DEFAULT_AVATAR}`
                                                                            }
                                                                            onError={Transformer.ImageOnError}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            alt=""
                                                                            className="rounded-circle"
                                                                            src={
                                                                                item.senderAvatar
                                                                                    ? `${FILE_URL}/${item.senderAvatar}`
                                                                                    : `${DEFAULT_AVATAR}`
                                                                            }
                                                                            onError={Transformer.ImageOnError}
                                                                        />
                                                                    )}
                                                                </span>
                                                                <div className="media-body">
                                                                    {/* <span className="block">{item.senderFullName + ' ' + item.content}</span> */}
                                                                    <span className="block">{item.title}</span>
                                                                    <span className="block-time">
                                                                        {item.subContent}
                                                                    </span>
                                                                    <span className="block-time">
                                                                        {moment(item.createTime).fromNow()}
                                                                    </span>
                                                                </div>
                                                                <span className="dx-theme-accent-as-background-color unread"></span>
                                                            </span>
                                                            <span
                                                                className="tab-noti readAll"
                                                                id={`link-${item.id}`}
                                                                onClick={() => this.toggleDefault(item.id)}
                                                            >
                                                                <i className="icon dx-icon-more dx-theme-text-color"></i>
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <Link to={item.url}>
                                                            <span
                                                                className="media dx-theme-text-color"
                                                                onClick={(e) => this.redirectAction(item)}
                                                            >
                                                                <span className="media-icon">
                                                                    {item.isSystem ? (
                                                                        <img
                                                                            alt=""
                                                                            className="rounded-circle"
                                                                            src={
                                                                                item.image
                                                                                    ? `${FILE_URL}/${item.image}`
                                                                                    : `${DEFAULT_AVATAR}`
                                                                            }
                                                                            onError={Transformer.ImageOnError}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            alt=""
                                                                            className="rounded-circle"
                                                                            src={
                                                                                item.senderAvatar
                                                                                    ? `${FILE_URL}/${item.senderAvatar}`
                                                                                    : `${DEFAULT_AVATAR}`
                                                                            }
                                                                            onError={Transformer.ImageOnError}
                                                                        />
                                                                    )}
                                                                </span>
                                                                <div className="media-body">
                                                                    {/* <span className="block">{item.senderFullName + ' ' + item.content}</span> */}
                                                                    <span className="block">{item.title}</span>
                                                                    <span className="block-time">
                                                                        {item.subContent}
                                                                    </span>
                                                                    <span className="block-time">
                                                                        {moment(item.createTime).fromNow()}
                                                                    </span>
                                                                </div>
                                                                <span className="dx-theme-accent-as-background-color unread"></span>
                                                            </span>
                                                            <span
                                                                className="tab-noti readAll"
                                                                id={`link-${item.id}`}
                                                                onClick={() => this.toggleDefault(item.id)}
                                                            >
                                                                <i className="icon dx-icon-more dx-theme-text-color"></i>
                                                            </span>
                                                        </Link>
                                                    )}

                                                    {item.id === selectedId && (
                                                        <Popover
                                                            target={`#link-${item.id}`}
                                                            position="bottom"
                                                            width={250}
                                                            visible={this.state.defaultVisible}
                                                            onHiding={() => this.hideWithShadingOptions(item.id)}
                                                        >
                                                            <ul
                                                                className="action-noti"
                                                                aria-labelledby="navbarDropdownMenu"
                                                            >
                                                                {item.isRead ? (
                                                                    <li className=" dx-theme-background-color">
                                                                        <span
                                                                            onClick={(e) =>
                                                                                this.unReadNotifyUpdate(e, item)
                                                                            }
                                                                            className="dropdown-item"
                                                                        >
                                                                            <i className="icon dx-icon-check mr-2"></i>
                                                                            {i18n.t(
                                                                                'general.notification.markAsunRead'
                                                                            )}
                                                                        </span>
                                                                    </li>
                                                                ) : (
                                                                    <li className=" dx-theme-background-color">
                                                                        <span
                                                                            onClick={(e) => this.readNotify(e, item)}
                                                                            className="dropdown-item"
                                                                        >
                                                                            <i className="icon dx-icon-check mr-2"></i>
                                                                            {i18n.t('general.notification.markAsRead')}
                                                                        </span>
                                                                    </li>
                                                                )}
                                                                <li className=" dx-theme-background-color">
                                                                    <span
                                                                        onClick={(e) => this.deleteNotify(e, item)}
                                                                        className="dropdown-item"
                                                                    >
                                                                        <i className="icon dx-icon-trash mr-2"></i>{' '}
                                                                        {i18n.t(
                                                                            'general.notification.removeNotifications'
                                                                        )}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </Popover>
                                                    )}
                                                    {item.id === selectedId && (
                                                        <Popup
                                                            dragEnabled={false}
                                                            closeOnOutsideClick={true}
                                                            width={550}
                                                            height={300}
                                                            title={i18n.t('general.notification.notification')}
                                                            visible={this.state.popupDetails}
                                                            onHiding={() => this.hidepopupDetails(item.id)}
                                                        >
                                                            <ScrollView>
                                                                <h4>{item.title}</h4>
                                                                <div className={'pb-3'}>{item.content}</div>
                                                                <p className="time-popup" style={{ color: '#333' }}>
                                                                    {moment(item.createTime)
                                                                        .locale(moment.locale())
                                                                        .fromNow()}
                                                                </p>
                                                            </ScrollView>
                                                        </Popup>
                                                    )}
                                                </Link>
                                            );
                                        })}
                                    </li>
                                    {loading && (
                                        <div className="text-center loading-noti">
                                            <div className="m-loader m-loader--brand"></div>
                                        </div>
                                    )}
                                </ul>
                            </div>
                        )}
                    </ScrollView>
                </Popover>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    const { totalRows, data, unReadCount } = state.notification;
    const { userSettings } = state.appSettings.data;
    const currentLanguage = _.find(userSettings, (item) => item.key === 'LanguageId');
    const pageSize = _.find(userSettings, (item) => item.key === 'PageSize');

    return {
        isAuthenticated: state.auth.isAuthenticated,
        currentPage: state.notification.currentPage,
        notifications: data,
        initNewData: state.auth.initNewApp,
        totalRows,
        pageSize: parseInt(pageSize.value),
        unReadCount: unReadCount || 0,
        currentLanguage: currentLanguage.value
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        insert: (payload) => {
            return dispatch(insert(payload));
        },
        searchRequest: (payload) => {
            return dispatch(searchNotificationRequest(payload));
        },
        reSearch: (payload) => {
            return dispatch(reSearchNotificationRequest(payload));
        },
        updateRead: (payload) => {
            return dispatch(updateIsReadNotificationRequest(payload));
        },
        logout: () => {
            return dispatch(logout());
        },
        delete: (payload) => {
            return dispatch(deleteNotificationRequest(payload));
        },
        readAll: () => {
            return dispatch(updateAllReadNotificationRequest());
        },
        countUnRead: () => {
            return dispatch(countUnReadNotificationRequest());
        },
        getAllTreeUsers: (payload) => {
            return dispatch(officeTreeUsersGetAll(payload));
        }
    };
};
Notification.propTypes = {
    notifications: PropTypes.any,
    isAuthenticated: PropTypes.any,
    insert: PropTypes.any,
    totalRows: PropTypes.any,
    searchRequest: PropTypes.any,
    logout: PropTypes.any,
    updateRead: PropTypes.any,
    delete: PropTypes.any,
    reSearch: PropTypes.any,
    history: PropTypes.any,
    readAll: PropTypes.any,
    unReadCount: PropTypes.any,
    currentLanguage: PropTypes.any,
    countUnRead: PropTypes.any,
    pageSize: PropTypes.any,
    getAllTreeUsers: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));
