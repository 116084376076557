import Loadable from 'react-loadable';
import LoadingComponent from '../../../common/loader/index';

export default [
    {
        path: '/website',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./website/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/website/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./website/detail'),
            loading: LoadingComponent
        })
    },
    {
        path: '/website/:websiteId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./website/detail'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./news/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./news/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news/category',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./news/category'),
            loading: LoadingComponent
        })
    },
    {
        path: '/news/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./news/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/notifications',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./notifications/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/banner',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./banner/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/banner/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./banner/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./faq/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./faq/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq-group',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./faq/group'),
            loading: LoadingComponent
        })
    },
    {
        path: '/faq-group/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./faq/addgroup'),
            loading: LoadingComponent
        })
    },
    {
        path: '/menu',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./menu/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/menu/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./menu/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/menu/:menuId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./menu/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/info-website',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./setting'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-website',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./services'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-website/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./services/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-website/:serviceId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./services/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/album',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./album/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/album/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./album/detail'),
            loading: LoadingComponent
        })
    },
    {
        path: '/album/:albumId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./album/detail'),
            loading: LoadingComponent
        })
    },
    {
        path: '/product-website',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./product'),
            loading: LoadingComponent
        })
    },
    {
        path: '/product-website/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./product/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/product-categories',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./productCategories'),
            loading: LoadingComponent
        })
    },
    {
        path: '/partner',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./partner/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/partner/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./partner/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/partner/:partnerId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./partner/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/feedback',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./feedback/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/feedback/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./feedback/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/feedback/:feedbackId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./feedback/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customerFeedback',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerFeedback/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customerFeedback/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerFeedback/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customerFeedback/:customerFeedbackId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerFeedback/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/subscribe',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./subscribe/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/subscribe/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./subscribe/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/subscribe/:subscribeId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./subscribe/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/booking',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./booking/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/booking/edit/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./booking/edit'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./project/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./project/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./project/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/job',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./job/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/job/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./job/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/job/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./job/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/recruitment',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./recruitment/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/recruitment/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./recruitment/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/recruitment/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./recruitment/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project-category',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./projectCategories/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project-category/add',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./projectCategories/add'),
            loading: LoadingComponent
        })
    },
    {
        path: '/project-category/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./projectCategories/add'),
            loading: LoadingComponent
        })
    }
];
