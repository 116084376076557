import Loadable from 'react-loadable';
import LoadingComponent from '../common/loader/index';

export default [
    {
        path: '/testPage',
        exact: true,
        component: Loadable({
            loader: () => import('./TestPage'),
            loading: LoadingComponent
        })
    }
];
