import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class DatePickerBox extends Component {
    constructor(props) {
        super(props);

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(e) {
        if (e.event !== undefined && e.event !== null) {
            this.props.updateData({
                [this.props.field]: e.value
            });
        }
    }

    render() {
        const { value, i18nLabel, width, zoomLvl, displayFormat, type, showClearButton } = this.props;

        return (
            <div className="form-group cm-mgr-5">
                <div className={'dx-field'}>
                    {zoomLvl === 'year' ? (
                        <DateBox
                            maxZoomLevel={zoomLvl}
                            type={type || 'date'}
                            useMaskBehavior={true}
                            displayFormat={displayFormat}
                            onValueChanged={this.onValueChanged}
                            showClearButton={showClearButton ?? true}
                            placeholder={i18n.t(i18nLabel)}
                            value={value}
                            width={width || 160}
                        />
                    ) : (
                        <DateBox
                            type={type || 'date'}
                            useMaskBehavior={true}
                            onValueChanged={this.onValueChanged}
                            placeholder={i18n.t(i18nLabel)}
                            showClearButton={showClearButton ?? true}
                            value={value}
                            width={width || 160}
                            displayFormat={displayFormat || 'dd/MM/yyyy'}
                        />
                    )}
                </div>
            </div>
        );
    }
}

DatePickerBox.propTypes = {
    value: PropTypes.any,
    i18nLabel: PropTypes.string,
    width: PropTypes.string,
    currentLanguage: PropTypes.string,
    updateData: PropTypes.func,
    field: PropTypes.string,
    zoomLvl: PropTypes.string,
    displayFormat: PropTypes.string,
    type: PropTypes.string
};

export default DatePickerBox;
