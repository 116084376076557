import _ from 'lodash';

const userSettings = JSON.parse(localStorage.getItem('_s'));
const currentBranchId = _.find(userSettings, (item) => item.key === 'BranchId');
const _branchId = currentBranchId ? currentBranchId.value : '';

const currentLanguageId = _.find(userSettings, (item) => item.key === 'LanguageId');
const _languageId = currentLanguageId ? currentLanguageId.value : '';

export default class Transformer {
    static getLanguageId = () => {
        return _languageId;
    };

    static getBranchId = () => {
        return _branchId;
    };

    static checkHttpsHost = () => {
        if (window.location.protocol.includes('https') || window.location.hostname === 'localhost') {
            return true;
        }
        return false;
    };

    static ImageOnError = (e) => {
        e.onError = null;
        e.target.src = 'https://spafile.ghmsoft.vn/uploads/images/no-image.png';
    };

    static fetch(param) {
        if (param && Array.isArray(param)) {
            return Transformer.fetchCollection(param);
        } else if (param && typeof param === 'object') {
            return Transformer.fetchObject(param);
        }
        return param;
    }

    static convertToPramsURI = (obj) => {
        return Object.keys(obj)
            .map((k) => k + '=' + obj[k])
            .join('&');
    };

    static IsJsonString(str) {
        const typeOfStr = typeof str;
        if (typeOfStr !== 'string') {
            return false;
        }
        try {
            const json = JSON.parse(str);
            if (!json || typeof json !== 'object') {
                return false;
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    static convertJSONString(str, languageId) {
        if (!str) {
            return '';
        }
        return {}.hasOwnProperty.call(JSON.parse(str), languageId)
            ? JSON.parse(str)[languageId]
            : JSON.parse(str)['vi-VN'];
    }

    static isEmpty(str) {
        return !str || str.length === 0;
    }

    static fetchCollection(param) {
        return param.map((item) => Transformer.fetch(item));
    }

    static fetchObject(param) {
        const data = {};

        _.forOwn(param, (value, key) => {
            data[_.camelCase(key)] = Transformer.fetch(value);
        });
        return data;
    }

    static resetValidationFields({ errors, replace = false, searchStr = '', replaceStr = '' }) {
        const data = {};
        _.forOwn(errors, (value, key) => {
            let index = '';
            if (replace) {
                index = _.camelCase(key.replace(searchStr, replaceStr));
            } else {
                index = _.camelCase(key);
            }
            data[index] = _.head(value);
        });
        return data;
    }

    static stripVietnameseChars(str) {
        str = str.replace(/\s+/g, ' ');
        str.trim();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
        str = str.replace(/Đ/g, 'D');
        return str.toUpperCase();
    }

    static convertUnsignedChars(str) {
        str = str.replace(/\s+/g, ' ');
        str.trim();
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
        str = str.replace(/đ/g, 'd');
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
        str = str.replace(/Đ/g, 'D');
        str = str.replace(/!|@|#|%|&|=|<|>|`|~|\?|\(|\)|\$|\^|\*|\+|\.|\/|\\/g, '-');
        str = str.split(' ').join('-');
        str = str.replace(/-+/g, '-');
        return str.toLowerCase();
    }

    static bytesToSize(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) {
            return `0 ${sizes[0]}`;
        }
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
        if (i === 0) {
            return `${bytes} ${sizes[i]})`;
        }
        return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    }

    static checkIsImage(extension) {
        return ['png', 'jpg', 'jpeg', 'gif'].indexOf(extension) > -1;
    }

    static checkPermission(permissions, location) {
        if (location.pathname === '/') {
            location.pathname = '/home';
        }
        const selectedPage = _.find(permissions.pages, { url: location.pathname });
        if (!selectedPage) {
            return {};
        }

        const selectedPermission = _.find(permissions.permissions, {
            pageId: selectedPage.id
        });
        if (!selectedPermission) {
            return {};
        }

        const pagePermisions = {};
        selectedPermission.resourceActions.forEach((element) => {
            const hasPermission = (selectedPermission.permissions & element.bitwiseValue) === element.bitwiseValue;

            pagePermisions[element.actionId] = hasPermission;
        });

        return pagePermisions;
    }

    static checkPermissionDetail(permissions, match) {
        const pathname = match.path.split(':')[0].slice(0, -1);
        const selectedPage = _.find(permissions.pages, (x) => x.url === pathname);
        if (!selectedPage) {
            return {};
        }

        const selectedPermission = _.find(permissions.permissions, {
            pageId: selectedPage.id
        });
        if (!selectedPermission) {
            return {};
        }

        const pagePermisions = {};
        selectedPermission.resourceActions.forEach((element) => {
            const hasPermission = (selectedPermission.permissions & element.bitwiseValue) === element.bitwiseValue;

            pagePermisions[element.actionId] = hasPermission;
        });

        return pagePermisions;
    }

    static merge(a, b, props) {
        var reduced = a.filter((itema) => {
            var item = b.find((itemb) => {
                return itemb[props] === itema[props];
            });

            if (item === null || item === undefined) {
                return itema;
            } else {
                itema.permissions = itema.permissions | item.permissions;

                return itema;
            }
        });

        return _.uniqBy([...reduced, ...b], props);
    }

    static convertCurrency(numStr) {
        const x = parseFloat(numStr);

        const currencyString = x.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            minimumFractionDigits: 0
        });

        return currencyString.replace('$', '') + ' ₫';
    }

    static convertTreeToList(treeData) {
        let listData = [];

        treeData.forEach((element) => {
            listData.push({
                id: element.id,
                text: element.text
            });

            listData = [...listData, ...Transformer.convertTreeToList(element.children)];
        });

        return listData;
    }

    static insertTree(treeData, item) {
        const resultData = [];

        treeData.forEach((element) => {
            const addItem = Object.assign({}, element);

            if (element.id === item.parentId) {
                addItem.children.push(item);
            } else {
                if (element.children.length > 0) {
                    addItem.children = [...addItem.children, ...Transformer.insertTree(element.children, item)];
                }
            }

            resultData.push(addItem);
        });

        return resultData;
    }

    static deleteTree(treeData, deleteId) {
        const resultData = [];

        treeData.forEach((element) => {
            const addItem = Object.assign({}, element);

            if (element.id !== deleteId) {
                if (element.children.length > 0) {
                    addItem.children = Transformer.deleteTree(element.children, deleteId);
                }

                resultData.push(addItem);
            }
        });

        return resultData;
    }

    static convertGoodsNoteObjectType(pathname) {
        let objectType = 0;

        switch (pathname) {
            case '/goodNote/receiptNew':
                objectType = 0;
                break;
            case '/goodNote/receiptReturn':
                objectType = 1;
                break;
            // case '/goodNote/receiptTransfer':
            //     objectType = 2;
            //     break;
            case '/goodNote/receiptOther':
                objectType = 5;
                break;
            case '/goodNote/deliverySale':
                objectType = 6;
                break;
            case '/goodNote/deliverySupplier':
                objectType = 7;
                break;
            case '/goodNote/deliveryCancel':
                objectType = 8;
                break;
            case '/goodNote/deliveryUse':
                objectType = 9;
                break;
            case '/goodNote/deliveryTransfer':
                objectType = 10;
                break;
            case '/goodNote/deliverySupply':
                objectType = 11;
                break;
            case '/goodNote/deliveryOther':
                objectType = 12;
                break;
            default:
                break;
        }

        return objectType;
    }

    static updateTree(treeData, updateItem) {
        const resultData = [];

        treeData.forEach((element) => {
            const addItem = Object.assign({}, element);

            if (element.id === updateItem.id) {
                addItem.text = updateItem.name;
            }

            if (element.children.length > 0) {
                addItem.children = Transformer.updateTree(element.children, updateItem);
            }
            resultData.push(addItem);
        });

        return resultData;
    }

    static insertList(listData, insertItem) {
        const resultData = [];

        listData.forEach((element) => {
            resultData.push(element);
        });

        if (insertItem.isActive) {
            resultData.push(insertItem);
        }

        return resultData;
    }

    static deleteList(listData, deleteItem) {
        const resultData = [];

        listData.forEach((element) => {
            if (element.id !== deleteItem.id) {
                resultData.push(element);
            }
        });

        return resultData;
    }

    static compareTree(tree1, tree2) {
        if (tree1 !== tree2) {
            return false;
        }

        const list1 = Transformer.convertTreeToList(tree1);
        const list2 = Transformer.convertTreeToList(tree2);

        return list1.length === list2.length;
    }

    static updateList(listData, updateItem) {
        const resultData = [];

        if (_.findIndex(listData, (x) => x.id === updateItem.id) === -1 && updateItem.isActive) {
            return Transformer.insertList(listData, updateItem);
        }

        listData.forEach((element) => {
            if (element.id === updateItem.id) {
                if (updateItem.isActive) {
                    resultData.push(updateItem);
                }
            } else {
                resultData.push(element);
            }
        });

        return resultData;
    }

    static scrollToTreeNode(id, treeView) {
        const treeNode = document.getElementById('treeView').querySelector(`[data-item-id="${id}"]`);
        treeView._scrollableContainer.scrollToElement(treeNode);
        return this.highlightTreeItem(id);
    }

    static highlightTreeItem(id) {
        const treeItems = document.getElementsByClassName('dx-treeview-item');
        for (const item of treeItems) {
            item.classList.remove('dx-theme-accent-as-background-color');
        }
        const treeNode = document.getElementById('treeView').querySelector(`[data-item-id="${id}"]`);
        const treeItem = treeNode.querySelector('.dx-treeview-item');
        treeItem.classList.add('dx-theme-accent-as-background-color');
    }

    static compareString(string1, string2) {
        const string11 = string1.toUpperCase();
        const string22 = string2.toUpperCase();

        if (string11 === string22) {
            return true;
        } else {
            return false;
        }
    }

    static convertNumToWord = (amount) => {
        const digit = ['không ', 'một ', 'hai ', 'ba ', 'bốn ', 'năm ', 'sáu ', 'bảy ', 'tám ', 'chín '];
        const block = ['', 'nghìn ', 'triệu ', 'tỷ ', 'nghìn tỷ ', 'triệu tỷ '];
        let lan = 0;
        let num = 0;
        let result = '';
        let tmp = '';
        const position = [];

        if (amount < 0) {
            return 'Số tiền âm !';
        }

        if (amount === 0) {
            return 'Không đồng !';
        }

        if (amount > 0) {
            num = amount;
        } else {
            num = -amount;
        }

        if (amount > 8999999999999999) {
            // amount = 0;
            return 'Số quá lớn!';
        }

        position[5] = Math.floor(num / 1000000000000000);

        if (isNaN(position[5])) {
            position[5] = '0';
        }
        num = num - parseFloat(position[5].toString()) * 1000000000000000;

        position[4] = Math.floor(num / 1000000000000);

        if (isNaN(position[4])) {
            position[4] = '0';
        }
        num = num - parseFloat(position[4].toString()) * 1000000000000;

        position[3] = Math.floor(num / 1000000000);

        if (isNaN(position[3])) {
            position[3] = '0';
        }
        num = num - parseFloat(position[3].toString()) * 1000000000;

        position[2] = parseInt(num / 1000000);

        if (isNaN(position[2])) {
            position[2] = '0';
        }

        position[1] = parseInt((num % 1000000) / 1000);

        if (isNaN(position[1])) {
            position[1] = '0';
        }

        position[0] = parseInt(num % 1000);

        if (isNaN(position[0])) {
            position[0] = '0';
        }

        if (position[5] > 0) {
            lan = 5;
        } else if (position[4] > 0) {
            lan = 4;
        } else if (position[3] > 0) {
            lan = 3;
        } else if (position[2] > 0) {
            lan = 2;
        } else if (position[1] > 0) {
            lan = 1;
        } else {
            lan = 0;
        }

        for (let i = lan; i >= 0; i--) {
            tmp = Transformer.convert3DigitsNum(position[i], digit);
            result += tmp;

            if (position[i] > 0) {
                result += block[i];
            }
        }

        result = result.substring(0, 1).toUpperCase() + result.substring(1) + 'đồng';

        return result;
    };

    static convert3DigitsNum = (_3DigitsNum, digit) => {
        const huns = parseInt(_3DigitsNum / 100);
        const tens = parseInt((_3DigitsNum % 100) / 10);
        const ones = _3DigitsNum % 10;
        let result = '';

        if (huns === 0 && tens === 0 && ones === 0) {
            return '';
        }

        if (huns !== 0) {
            result += digit[huns] + 'trăm ';

            if (tens === 0 && ones !== 0) {
                result += 'linh ';
            }
        }

        if (tens !== 0 && tens !== 1) {
            result += digit[tens] + 'mươi ';

            if (tens === 0 && ones !== 0) {
                result = result + 'linh ';
            }
        }

        if (tens === 1) result += 'mười ';

        switch (ones) {
            case 1:
                if (tens !== 0 && tens !== 1) {
                    result += 'mốt ';
                } else {
                    result += digit[ones];
                }
                break;
            case 5:
                if (tens === 0) {
                    result += digit[ones];
                } else {
                    result += 'lăm ';
                }
                break;
            default:
                if (ones !== 0) {
                    result += digit[ones];
                }
                break;
        }
        return result;
    };

    static validateCustomerName(name) {
        const regex = /^[a-zA-Z][a-zA-Z\s]*$/;
        return regex.test(this.stripVietnameseChars(name));
    }

    static validatePhoneNumber(phoneNumber) {
        const validatePhone = /((84|0[3|5|7|8|9])+([0-9]{8,12})\b)/g;
        const isPhoneNumber = validatePhone.test(phoneNumber);

        return isPhoneNumber;
    }
}
