import React, { Component } from 'react';
import { Button } from 'devextreme-react';
import { CheckBox } from 'devextreme-react/check-box';
import { Popover } from 'devextreme-react/popover';

class GridSettingButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowPopup: false
        };

        this.showPopup = this.showPopup.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
        this.updateListSetting = this.updateListSetting.bind(this);
    }

    showPopup(e) {
        this.setState({
            isShowPopup: !this.state.isShowPopup
        });
        setTimeout(() => {
            const clientX = e.event.clientX - 100;
            const clientY = e.event.clientY + 30;
            const settingPopovers = document.getElementsByClassName(
                'dx-overlay-content dx-popup-normal dx-resizable dx-popup-flex-height'
            );
            if (settingPopovers.length) {
                const content = settingPopovers[settingPopovers.length - 1];
                const { x, y } = content.getBoundingClientRect();
                if (Math.abs(x) > 1000 || Math.abs(y) > 1000) {
                    content.style.transform = `translate(${clientX}px, ${clientY}px)`;
                }
            }
        }, 200);
    }

    hidePopup() {
        this.setState({
            isShowPopup: false
        });
    }

    updateListSetting(e, field) {
        this.props.updateListSetting({
            [field]: e.value
        });
    }

    render() {
        const { isShowPopup } = this.state;
        const { listSetting } = this.props;

        return (
            <div className={'ml-2'}>
                <div className={'dx-field'}>
                    <Button icon="preferences" id="link1" onClick={this.showPopup} />
                </div>
                <Popover target="#link1" position="bottom" onHiding={this.hidePopup} width={200} visible={isShowPopup}>
                    <CheckBox
                        value={listSetting.filterRow}
                        onValueChanged={(e) => this.updateListSetting(e, 'filterRow')}
                        width={'100%'}
                        text="Filter Row"
                    />
                    <hr />

                    <CheckBox
                        value={listSetting.filterHeader}
                        onValueChanged={(e) => this.updateListSetting(e, 'filterHeader')}
                        width={'100%'}
                        text="Filter Header"
                    />
                    <hr />

                    <CheckBox
                        value={listSetting.groupColumn}
                        onValueChanged={(e) => this.updateListSetting(e, 'groupColumn')}
                        width={'100%'}
                        text="Group Column"
                    />
                    <hr />

                    <CheckBox
                        value={listSetting.chooseColumn}
                        onValueChanged={(e) => this.updateListSetting(e, 'chooseColumn')}
                        width={'100%'}
                        text="Choose Column"
                    />
                    <hr />

                    <CheckBox
                        value={listSetting.exportData}
                        onValueChanged={(e) => this.updateListSetting(e, 'exportData')}
                        width={'100%'}
                        text="Export Data"
                    />
                    <hr />
                </Popover>
            </div>
        );
    }
}

export default GridSettingButton;
