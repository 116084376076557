export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NOTIFICATION_UPDATE_READ = 'NOTIFICATION_UPDATE_READ';
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE';
export const NOTIFICATION_RELIST = 'NOTIFICATION_RELIST';
export const NOTIFICATION_UPDATE_ALL_READ = 'NOTIFICATION_UPDATE_ALL_READ';
export const NOTIFICATION_COUNT_UNREAD = 'NOTIFICATION_COUNT_UNREAD';
export const NOTIFICATION_INSERT = 'NOTIFICATION_INSERT';
export const OFFICE_TREES_USERS_GETALL = 'OFFICE_TREES_USERS_GETALL';

export default {
    NOTIFICATION_INSERT,
    NOTIFICATION_LIST,
    NOTIFICATION_UPDATE_READ,
    NOTIFICATION_DELETE,
    NOTIFICATION_RELIST,
    NOTIFICATION_COUNT_UNREAD,
    NOTIFICATION_UPDATE_ALL_READ,
    OFFICE_TREES_USERS_GETALL
};
