import React, { Component } from 'react';
import { TextBox } from 'devextreme-react';
import i18n from '../../../i18n';
import PropTypes from 'prop-types';

var moment = require('moment');

class ReadOnlyTimeBox extends Component {
    render() {
        const { value, i18nLabel, timeFormat } = this.props;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}</div>

                <div className={'dx-field-value'}>
                    <TextBox
                        className={'multi-language'}
                        name={'name'}
                        value={moment(value).isValid() ? moment(value).format(timeFormat) : ''}
                        readOnly={true}
                    />
                </div>
            </div>
        );
    }
}

ReadOnlyTimeBox.propTypes = {
    i18nLabel: PropTypes.string,
    value: PropTypes.any,
    timeFormat: PropTypes.string
};

export default ReadOnlyTimeBox;
