export const viW = {
    // W
    website: {
        album: {
            listTitle: 'Danh sách Album Ảnh và Video',
            pageUnit: 'albums',
            name: 'Tên',
            description: 'Mô tả',
            seoLink: 'SEOLink',
            metaTitle: 'Meta Title',
            metaDescription: 'Meta Description',
            metaKeyword: 'Meta Keyword',
            type: 'Loại',
            thumbnail: 'Thumbnail',
            alt: 'Alt',
            order: 'Thứ tự',
            isActive: 'Kích hoạt',
            isHomePage: 'Trang chủ',
            isHot: 'Hot',
            isDefault: 'Mặc định',
            chooseAlbum: 'Chọn Album',
            photo: {
                listTitle: 'Danh sách Ảnh',
                pageUnit: 'ảnh',
                title: 'Tiêu đề',
                description: 'Mô tả',
                alt: 'Alt',
                order: 'Order',
                url: 'Url',
                insertTitle: 'Thêm mới Ảnh',
                updateTitle: 'Chỉnh sửa Ảnh'
            },
            video: {
                listTitle: 'Danh sách Video',
                pageUnit: 'video',
                title: 'Tiêu đề',
                description: 'Mô tả',
                alt: 'Alt',
                type: 'Loại video',
                url: 'Url',
                lastUpdateHomePage: 'Cập nhật mới nhất',
                thumbnail: 'Thumbnail',
                videoLinkId: 'Video Link Id',
                order: 'Thứ tự',
                isActive: 'Kích hoạt',
                isDefault: 'Mặc định',
                isHomePage: 'Trang chủ',
                insertTitle: 'Thêm mới Video',
                updateTitle: 'Chỉnh sửa Video',
                videoLinkRequired: 'Hãy nhập video link',
                imageRequired: 'Hãy chọn 1 ảnh',
                videoTypeRequired: 'Hãy chọn loại video',
                typeRequired: 'Hãy chọn loại album item'
            }
        },
        webProduct: {
            insertTitle: 'Thêm mới sản phẩm',
            updateTitle: 'Chỉnh sửa sản phẩm',
            title: 'Quản lý sản phẩm website',
            isHomePage: 'Trang chủ',
            isHot: 'Hot',
            isActive: 'Kích hoạt',
            detail: 'Chi tiết',
            prodName: 'Tên sản phẩm',
            productTranslation: 'Product translations',
            productImages: 'Ảnh sản phẩm',
            element: 'Element',
            label: 'Label',
            uses: 'Uses',
            userManual: 'User manual',
            prodCode: 'Code',
            unit: 'Sản phẩm',
            updateMeta: 'Cập nhật meta',
            insertMeta: 'Thêm mới meta',
            content: 'Nội dung',
            capacity: 'Dung tích',
            category: {
                title: 'Danh sách Chuyên mục Sản phẩm',
                pageUnit: 'chuyên mục',
                insertTitle: 'Thêm mới Chuyên mục Sản phẩm',
                updateTitle: 'Chỉnh sửa Chuyên mục Sản phẩm',
                name: 'Tên',
                seoLink: 'Seo Link',
                description: 'Mô tả',
                metaTitle: 'Meta Title',
                metaDescription: 'Meta Description',
                altImages: 'Alt Image',
                bannerImages: 'Ảnh Banner',
                bannerImagesMobile: 'Ảnh Banner Mobile',
                isActive: 'Kích hoạt',
                isHot: 'Hot',
                isHomepage: 'Trang chủ'
            },
            translation: {
                pageUnit: 'translations',
                insertTitle: 'Thêm mới Translation Sản phẩm',
                updateTitle: 'Chỉnh sửa Translation Sản phẩm',
                title: 'Danh sách Translation Sản phẩm'
            },
            likeCount: 'Số lượt xem',
            viewCount: 'Số lượt thích',
            commentCount: 'Số lượt bình luận',
            metaDescription: 'Meta Description',
            metaTitle: 'Meta Title',
            salePrice: 'Sale Price'
        },
        webService: {
            title: 'Quản lý dịch vụ website',
            infoTitle: 'Thông tin dịch vụ website',
            name: 'Tên',
            seoLink: 'Seo Link',
            serviceTitle: 'Tiêu đề chi tiết dịch vụ',
            videoUrl: 'VideoUrl',
            description: 'Mô tả',
            alt: 'Alt',
            metaTitle: 'Meta title',
            metaDescription: 'Meta description',
            metaKeyword: 'Meta keyword',
            icon: 'Icon',
            image: 'Ảnh',
            thumbnail: 'Thumbnail',
            order: 'Thứ tự',
            addNew: 'Thêm mới',
            edit: 'Chỉnh sửa',
            insertTitle: 'Thêm mới dịch vụ website',
            updateTitle: 'Chỉnh sửa dịch vụ website',
            insertServiceTitle: 'Thêm mới Tiêu đề website',
            updateServiceTitle: 'Chỉnh sửa Tiêu đề website',
            insertServiceItemMetaTitle: 'Thêm mới Tiêu đề Chi tiết Dịch vụ',
            updateServiceItemMetaTitle: 'Chỉnh sửa Tiêu đề Chi tiết Dịch vụ',
            webServiceTitle: 'Tiêu đề',
            tags: 'Tags',
            isActive: 'Kích Hoạt',
            pageUnit: 'dịch vụ',
            serviceTitleMeta: {
                title: 'Danh sách Tiêu đề Chi tiết Dịch vụ',
                pageUnit: 'tiêu đề',
                insertTitle: 'Thêm mới Tiêu đề Chi tiết Dịch vụ',
                updateTitle: 'Chỉnh sửa Tiêu đề Chi tiết Dịch vụ',
                isActive: 'Kích hoạt',
                item: {
                    title: 'Danh sách Mục Tiêu đề',
                    pageUnit: 'mục'
                }
            },
            serviceFaqMeta: {
                title: 'Danh sách Câu hỏi Thường gặp Chi tiết Dịch vụ',
                pageUnit: 'câu hỏi',
                image: 'Ảnh'
            }
        },
        productCategories: {
            title: 'Chuyên mục sản phẩm',
            insertTitle: 'Thêm mới sản phẩm',
            updateTitle: 'Chỉnh sửa sản phẩm',
            name: 'Tên',
            banner: 'Banner',
            mobileBanner: 'Mobile banner'
        },
        list: {
            title: 'Danh sách Website',
            unit: 'websites',
            name: 'Tên Website',
            phone: 'Số điện thoại',
            address: 'Địa chỉ',
            note: 'Ghi chú',
            isDefault: 'Mặc định',
            isActive: 'Kích hoạt',
            languagesList: 'Ngôn ngữ'
        },
        form: {
            insertTitle: 'Thêm mới Website',
            updateTitle: 'Chỉnh sửa Website',
            name: 'Tên Website',
            phone: 'Số điện thoại',
            address: 'Địa chỉ',
            note: 'Ghi chú',
            isDefault: 'Mặc định',
            isActive: 'Kích hoạt',
            title: 'Tiêu đề',
            subTitle: 'Tiêu đề phụ'
        },
        faq: {
            list: {
                title: 'Danh sách Câu hỏi thường gặp',
                unit: 'câu hỏi',
                question: 'Câu hỏi',
                answer: 'Câu trả lời',
                website: 'Website',
                faqGroup: 'Nhóm Faq',
                photo: 'Photo',
                alt: 'Alt',
                isActive: 'Kích hoạt',
                order: 'Thứ tự',
                group: 'Nhóm FAQ'
            },
            form: {
                insertTitle: 'Thêm mới Câu hỏi',
                updateTitle: 'Chỉnh sửa Câu hỏi',
                question: 'Câu hỏi',
                answer: 'Câu trả lời',
                website: 'Website',
                faqGroup: 'Nhóm Faq',
                photo: 'Photo',
                alt: 'Alt',
                isActive: 'Kích hoạt'
            }
        },
        faqGroup: {
            list: {
                title: 'Danh sách Nhóm Câu hỏi thường gặp',
                unit: 'nhóm',
                name: 'Tên Nhóm FAQ',
                description: 'Mô tả',
                thumbnail: 'Thumbnail',
                alt: 'Alt',
                seoLink: 'SEO Link',
                order: 'Thứ tự',
                metaTitle: 'meta Title',
                metaKeyword: 'meta Keyword',
                metaDescription: 'meta Description',
                isActive: 'Kích hoạt'
            },
            form: {
                name: 'Tên Nhóm FAQ',
                description: 'Mô tả',
                thumbnail: 'Thumbnail',
                isDefault: 'Mặc định',
                alt: 'Alt',
                seoLink: 'SEO Link',
                order: 'Thứ tự',
                metaTitle: 'meta Title',
                metaKeyword: 'meta Keyword',
                metaDescription: 'meta Description',
                isActive: 'Kích hoạt'
            }
        },
        banner: {
            list: {
                title: 'Quản lý banner',
                unit: 'banners',
                bannerItemsTitle: 'Danh sách Banner-item',
                bannerItemsUnit: 'Banner-items',
                name: 'Tên',
                image: 'Ảnh',
                imageMobile: 'Ảnh trên di động',
                url: 'Đường dẫn',
                alt: 'Tên thay thế',
                note: 'Mô tả',
                status: 'Trạng thái',
                position: 'Vị trí',
                type: 'Loại banner',
                isActive: 'Kích hoạt'
            },
            form: {
                insertTitle: 'Thêm mới banner',
                updateTitle: 'Chỉnh sửa banner',
                bannerPosition: 'Vị trí banner',
                bannerType: 'Kiểu banner',
                insertBannerItem: 'Thêm mới Banner-item',
                updateBannerItem: 'Chỉnh sửa Banner-item'
            },
            error: {
                bannerItemsRequired: 'Hãy thêm banner item',
                imageRequired: 'Hãy chọn 1 ảnh',
                urlPattern: 'URL không hợp lệ. Hãy nhập lại đường dẫn url',
                urlRequired: 'Hãy nhập đường dẫn',
                invalidName: 'đã tồn tại',
                positionRequired: 'Hãy chọn vị trí banner',
                typeRequired: 'Hãy chọn kiểu banner'
            }
        },
        news: {
            list: {
                title: 'Quản lý tin tức',
                unit: 'tin tức',
                newsTitle: 'Tiêu đề',
                category: 'Chuyên mục',
                createDate: 'Ngày tạo',
                viewCount: 'Lượt xem',
                status: 'Trạng thái',
                selectType: 'Chọn loại',
                isHot: 'Tin nóng',
                isHomepage: 'Ở trang chủ',
                userCreate: 'Người tạo',
                seoLink: 'SEO Link',
                editNews: 'Chỉnh sửa tin tức',
                isActive: 'Kích hoạt',
                description: 'Mô tả',
                type: 'Phân loại',
                note: 'Ghi chú',
                startTime: 'Thời gian bắt đầu',
                declineReasons: 'Lý do từ chối'
            },
            form: {
                insertTitle: 'Thêm mới tin tức',
                updateTitle: 'Chỉnh sửa tin tức',
                description: 'Mô tả',
                source: 'Nguồn',
                content: 'Nội dung',
                altImage: 'Tên thay thế',
                seoLink: 'SEO Link',
                metaKeyword: 'Meta keyword',
                metaTitle: 'Meta title',
                metaDescription: 'Meta description'
            },
            error: {
                categoryRequired: 'Hãy chọn chuyên mục tin tức',
                seoLinkRequired: 'Hãy nhập SEOLink',
                typeRequired: 'Hãy chọn phân loại tin',
                startTimeRequired: 'Hãy chọn thời gian bắt đầu'
            }
        },
        info: {
            logo: 'Logo',
            logoFooter: 'Logo Footer',
            favicon: 'Favicon',
            zaloId: 'Zalo Id',
            brand: 'Brand',
            instruction: 'Instruction',
            metaTitle: 'Meta Title',
            metaDescription: 'Meta Description',
            ipBlocking: 'Ip Blocking',
            metaKeyword: 'MetaKeyword',
            fanpage: 'Fanpage',
            googleMap: 'GoogleMap',
            hotline: 'Hotline',
            logoMobile: 'Logo Mobile',
            trackingHeader: 'Tracking Header',
            trackingBody: 'Tracking Body',
            googleAnalytics: 'Google Analytics',
            commentNotAllow: 'Comment Not Allow',
            fanPageFacebookId: 'Fanpage Facebook Id'
        },
        socialNetwork: {
            list: {
                title: 'Quản lý Mạng xã hội',
                unit: 'Mạng xã hội',
                image: 'Ảnh',
                socialNetwork: 'Mạng xã hội',
                url: 'Đường dẫn',
                icon: 'Icon',
                order: 'Thứ tự',
                description: 'Mô tả',
                isActive: 'Kích hoạt'
            },
            form: {
                insertTitle: 'Insert Social Network',
                updateTitle: 'Update Social Network'
            }
        },
        languages: {
            list: {
                title: 'Quản lý ngôn ngữ website',
                unit: 'Ngôn ngữ',
                languageId: 'ID Ngôn ngữ',
                name: 'Tên',
                default: 'Mặc định',
                active: 'Kích hoạt'
            }
        },
        menus: {
            list: {
                title: 'Quản lý menu',
                unit: 'menu',
                name: 'Tên',
                position: 'Vị trí',
                description: 'Mô tả',
                isActive: 'Kích hoạt'
            },
            form: {
                insertMenuItem: 'Thêm mới menu-item',
                updateMenuItem: 'Chỉnh sửa menu-item',
                objectType: 'Chọn loại đối tượng',
                objectId: 'Chọn đối tượng',
                parentId: 'Chọn menu cha',
                icon: 'Icon',
                news: 'Chọn tin tức'
            },
            error: {
                objectTypeRequired: 'Hãy chọn loại đối tượng',
                urlRequired: 'Hãy nhập đường dẫn',
                newsRequired: 'Hãy chọn 1 tin tức',
                categoryRequired: 'Hãy chọn 1 chuyên mục tin tức',
                seoLinkRequired: 'Hãy nhập SEOLink',
                productRequired: 'Hãy chọn 1 sản phẩm',
                productCategoryRequired: 'Hãy chọn 1 chuyên mục sản phẩm',
                serviceRequired: 'Hãy chọn 1 dịch vụ',
                faqRequired: 'Hãy chọn 1 mục faq',
                feedbackRequired: 'Hãy chọn 1 phản hồi',
                albumRequired: 'Hãy chọn 1 album'
            }
        },
        hardContent: {
            name: 'Tên',
            code: 'Mã',
            description: 'Mô tả',
            url: 'Url',
            image: 'Ảnh',
            alt: 'Alt',
            order: 'Thứ tự',
            isActive: 'Kích hoạt',
            listTitle: 'Danh sách Hard Contents',
            pageUnit: 'contents',
            insertTitle: 'Thêm mới Hard Content',
            updateTitle: 'Chỉnh sửa Hard Content',
            detail: {
                insertTitle: 'Thêm mới Hard Content Detail',
                updateTitle: 'Chỉnh sửa Hard Content Detail',
                listTitle: 'Danh sách Hard Content Details',
                pageUnit: 'hard content details'
            }
        },
        branchContact: {
            name: 'Tên',
            workTime: 'Thời gian làm việc',
            logo: 'Logo',
            link: 'Link',
            googleMap: 'Google Map',
            address: 'Địa chỉ',
            description: 'Mô tả',
            order: 'Thứ tự',
            emailList: 'Email',
            phoneList: 'Số điện thoại',
            isActive: 'Kích hoạt',
            isDefault: 'Mặc định',
            isOffice: 'Văn phòng',
            website: 'Website',
            listTitle: 'Danh sách Branch Contacts',
            pageUnit: 'contacts',
            insertTitle: 'Thêm mới Branch Contact',
            updateTitle: 'Chỉnh sửa Branch Contact',
            images: 'Danh sách ảnh slide',
            detail: {
                insertTitle: 'Thêm mới Branch Contact Detail',
                updateTitle: 'Chỉnh sửa Branch Contact Detail',
                listTitle: 'Danh sách Branch Contact Details',
                pageUnit: 'contact details',
                contactType: 'Contact Type',
                contactValue: 'Contact Value'
            }
        },
        newsCategory: {
            list: {
                title: 'Quản lý chuyên mục tin tức',
                unit: 'Chuyên mục',
                metaTitle: 'Meta title',
                metaKeyword: 'Meta keyword',
                metaDescription: 'Meta description',
                order: 'Thứ tự'
            },
            form: {
                insertTitle: 'Thêm mới chuyên mục tin tức',
                updateTitle: 'Chỉnh sửa chuyên mục tin tức',
                parentCategory: 'Chuyên mục cha',
                images: 'Ảnh',
                imagesMobile: 'Ảnh trên di động'
            }
        },
        partner: {
            listTitle: 'Danh sách Đối tác',
            pageUnit: 'đối tác',
            name: 'Tên',
            description: 'Mô tả',
            thumbnail: 'Thumbnail',
            alt: 'Alt',
            url: 'Url',
            order: 'Thứ tự',
            isActive: 'Kích hoạt'
        },
        customerFeedback: {
            listTitle: 'Danh sách Phản hồi Khách hàng',
            pageUnit: 'phản hồi',
            name: 'Tên',
            avata: 'Avatar',
            description: 'Mô tả',
            position: 'Vị trí',
            thumbnail: 'Thumbnail',
            alt: 'Alt',
            rate: 'Rate',
            url: 'Url',
            order: 'Thứ tự',
            isActive: 'Kích hoạt',
            seoLink: 'SEO Link'
        },
        feedback: {
            listTitle: 'Danh sách Phản hồi',
            pageUnit: 'phản hồi',
            email: 'Email',
            fullName: 'Họ và Tên',
            phoneNumber: 'Số điện thoại',
            title: 'Tiêu đề',
            content: 'Nội dung',
            isView: 'Xem',
            description: 'Mô tả'
        },
        subscribe: {
            listTitle: 'Danh sách Đăng ký Theo dõi',
            pageUnit: 'đăng ký',
            email: 'Email'
        },
        booking: {
            title: 'Quản lý đặt lịch hẹn',
            name: 'Tên khách hàng',
            email: 'Email',
            address: 'Địa chỉ',
            phone: 'Số điện thoại',
            description: 'Nội dung',
            appointmentTime: 'Thời gian hẹn',
            appointmentDate: 'Ngày hẹn',
            editTitle: 'Chỉnh sửa đặt lịch hẹn',
            approvedStatus: 'Trạng thái',
            appointmentDateError: 'Thời gian hẹn phải là thời gian trong tương lai'
        },
        project: {
            title: 'Quản lý dự án',
            infoTitle: 'Thông tin dự án',
            project: 'Dự án',
            projectCategory: 'Chuyên mục dự án',
            projectTitle: 'Tiêu đề',
            description: 'Mô tả',
            image: 'Hình Ảnh',
            logo: 'Logo',
            classify: 'Lĩnh vực',
            isHomePage: 'Hiển thị trang home',
            isActive: 'Kích hoạt',
            year: 'Năm',
            client: 'Khách hàng',
            url: 'url',
            albumType: 'Loại',
            services: 'Dịch vụ',
            videoLinkId: 'Video Id',
            titleColor: 'Màu tiêu đề',
            updateTitle: 'Chỉnh sửa chi tiết dự án',
            insertTitle: 'Thêm mới chi tiết dự án',
            albumUpdateTitle: 'Chỉnh sửa album item',
            albumInsertTitle: 'Thêm mới album item',
            detailList: 'Danh sách Chi tiết dự án',
            albumList: 'Danh sách ảnh dự án',
            pageUnit: 'Chi tiết',
            projectCategoryInfoTitle: 'Thông tin Chuyên mục Dự án',
            projectCategoryTitle: 'Quản lý Chuyên mục Dự án',
            categoryRequired: 'Hãy chọn 1 chuyên mục dự án'
        },
        job: {
            name: 'Tên',
            description: 'Mô tả',
            type: 'Loại công việc',
            order: 'Thứ tự',
            isActive: 'Kích hoạt',
            title: 'Quản lý Vị trí tuyển dụng',
            infoTitle: 'Thông tin Vị trí tuyển dụng',
            job: 'Vị trí tuyển dụng',
            titlee: 'Tiêu đề',
            image: 'Hình Ảnh',
            salaryRange: 'Mức lương',
            pageUnit: 'vị trí tuyển dụng',
            seoLink: 'SEO Link',
            requirement: 'Yêu cầu',
            location: 'Khu vực',
            position: 'Vị trí',
            quantity: 'Số lượng',
            benefit: 'Quyền lợi',
            workingTime: 'Thời gian làm việc'
        },
        recruitment: {
            title: 'Quản lý đăng kí Tuyển dụng',
            fullName: 'Họ và tên',
            jobsRequired: 'Hãy chọn vị trí ứng tuyển',
            cvRequired: 'Hãy chọn cv của ứng viên',
            recruitment: 'Tuyển dụng',
            editRecruitment: 'Chỉnh sửa đăng kí tuyển dụng',
            email: 'Email',
            jobsDetail: 'Vị trí công việc',
            phoneNumber: 'Số điện thoại',
            isView: 'Đã xem',
            description: 'Mô tả',
            nameInvalid: 'Tên không được chứa số và ký tự đặc biệt',
            phoneInvalid: 'Sai định dạng số điện thoại',
            isViewStatus: 'Trạng thái',
            job: 'Chọn công việc'
        }
    },
    warehouse: {
        list: {
            title: 'Danh sách kho ',
            warehouseName: 'Tên kho',
            manageconfig: 'Người quản lý',
            warehouse: 'Kho',
            receiptWarehouse: 'Kho nhập',
            deliveryWarehouse: 'Kho xuất'
        },
        form: {
            insertTitle: 'Thêm mới kho',
            info: 'Thông tin kho',
            supplier: 'Nhà cung cấp',
            address: 'Địa chỉ',
            staff: 'Nhân viên',
            receiptNumber: 'Số phiếu',
            date: 'Ngày',
            invoice: 'Chứng từ',
            invoiceDate: 'Ngày CT',
            note: 'Ghi chú',
            deliever: 'Người giao'
        },
        menu: {
            manageConfig: {
                title: 'Danh sách quản lý kho',
                insertTitle: 'Thêm mới quản lý kho',
                updateTitle: 'Chỉnh sửa quản lý kho',
                warehouseManageConfig: 'Cấu hình quản lý kho',
                selectTitle: 'Chọn phân quyền'
            },
            productConfig: {
                title: 'Danh sách sản phẩm kho',
                insertTitle: 'Thêm mới sản phẩm kho',
                updateTitle: 'Chỉnh sửa sản phẩm kho',
                warehouseProductConfig: 'Cấu hình sản phẩm kho',
                selectTitle: 'Chọn sản phẩm'
            }
        },
        error: {
            title: 'Bạn chưa được phân quyền kho'
        },
        title: {
            listNewReceipt: 'Danh sách Nhập kho mua mới',
            receiptDetail: 'Thông tin chi tiết',
            updateReceipt: 'Chỉnh sửa nhập kho mua mới'
        },
        print: {
            noteCreator: 'Người lập phiếu',
            deliver: 'Người giao hàng',
            stockKeeper: 'Thủ kho',
            chiefAccountant: 'Kế toán trưởng',
            receiver: 'Người nhận',
            exporter: 'Người xuất',
            importer: 'Người nhập',
            departmentOfGoods: 'Thành viên tổ vật tư'
        }
    }
};
