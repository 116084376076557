import { PAGE_SIZE } from '../../../constants/Config';
import {
    SERVICE_NOTE_DETAIL_DETAIL,
    SERVICE_NOTE_DETAIL_INSERT,
    SERVICE_NOTE_DETAIL_LIST,
    SERVICE_NOTE_DETAIL_SELECT,
    SERVICE_NOTE_DETAIL_RESET,
    SERVICE_NOTE_DETAIL_UPDATE,
    SERVICE_NOTE_DETAIL_BY_CUSTOMER_LIST,
    SERVICE_NOTE_DETAIL_LIST_RESET,
    SERVICE_NOTE_DETAIL_INSERT_LIST,
    SERVICE_NOTE_STATUS
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {
        id: null,
        code: null
    },
    suggestion: [],
    selectedCustomerId: null,
    dataByCustomer: [],
    serviceNoteStatus: {}
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SERVICE_NOTE_DETAIL_DETAIL:
            return detail(state, payload);
        case SERVICE_NOTE_DETAIL_INSERT:
            return insert(state, payload);
        case SERVICE_NOTE_DETAIL_LIST:
            return list(state, payload);
        case SERVICE_NOTE_DETAIL_INSERT_LIST:
            return insertList(state, payload);
        case SERVICE_NOTE_DETAIL_BY_CUSTOMER_LIST:
            return listByCustomer(state, payload);
        case SERVICE_NOTE_DETAIL_SELECT:
            return select(state, payload);
        case SERVICE_NOTE_DETAIL_UPDATE:
            return update(state, payload);
        case SERVICE_NOTE_DETAIL_RESET:
            return reset(state);
        case SERVICE_NOTE_DETAIL_LIST_RESET:
            return resetList(state);
        case SERVICE_NOTE_STATUS:
            return storeStatus(state, payload);
        default:
            return state;
    }
};

function resetList(state) {
    state = Object.assign({}, state, {
        data: []
    });
    return state;
}

function reset(state) {
    return initialState;
}

function select(state, payload) {
    state = Object.assign({}, state, {
        item: {
            id: payload
        }
    });
    return state;
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insertList(state, payload) {
    const data = state.data ? [...state.data, ...payload] : [...payload];

    return Object.assign({}, state, {
        data
    });
}

function insert(state, payload) {
    // const data = state.data ? [...state.data, payload] : [payload];
    // const item = Object.assign({}, state.item, payload);

    return { ...state, data: [...state.data, payload] };
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function list(state, payload) {
    // state = Object.assign({}, state, { data: payload });
    return { ...state, data: payload };
}

function listByCustomer(state, payload) {
    state = Object.assign({}, state, {
        dataByCustomer: payload.data,
        selectedCustomerId: payload.customerId,
        totalRows: payload.totalRows
    });
    return state;
}

function storeStatus(state, payload) {
    state = Object.assign({}, state, {
        serviceNoteStatus: payload
    });
    return state;
}

export const getServiceNoteDetails = (state) => state.serviceNoteDetail.data;

export default reducer;
