export const viA = {
    // A
    account: {
        form: {
            account: 'Tài khoản',
            newPassword: 'Mật khẩu mới',
            resetTitle: 'Đặt lại Mật khẩu'
        }
    },
    area: {
        list: { title: 'Danh sách khu vực', unit: 'khu vực' },
        form: {
            name: 'Tên khu vực',
            select: 'Chọn khu vực',
            active: 'Hoạt động'
        }
    },
    about: {
        title: 'Thông tin spa',
        basicinformation: 'Thông tin cơ bản',
        Workshiftinformation: 'Thông tin ca làm việc',
        systemconfiguration: 'Cấu hình hệ thống',
        spaName: 'Tên Spa',
        branchinformation: 'Thông tin chi nhánh'
    }
};
