import {
    BRANCH_CONTACT_LIST,
    BRANCH_CONTACT_INSERT,
    BRANCH_CONTACT_DETAIL,
    BRANCH_CONTACT_DELETE,
    BRANCH_CONTACT_UPDATE,
    BRANCH_CONTACT_DETAIL_LIST,
    BRANCH_CONTACT_DETAIL_INSERT,
    BRANCH_CONTACT_DETAIL_DETAIL
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    detail: {
        data: [],
        item: {},
        totalRows: 0
    },
    item: {}
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case BRANCH_CONTACT_LIST:
            return list(state, payload);
        case BRANCH_CONTACT_INSERT:
            return insert(state, payload);
        case BRANCH_CONTACT_DETAIL:
            return detail(state, payload);
        case BRANCH_CONTACT_UPDATE:
            return update(state, payload);
        case BRANCH_CONTACT_DELETE:
            return deletes(state, payload);

        case BRANCH_CONTACT_DETAIL_LIST:
            return detailList(state, payload);
        case BRANCH_CONTACT_DETAIL_INSERT:
            return detailInsert(state, payload);
        case BRANCH_CONTACT_DETAIL_DETAIL:
            return detailDetail(state, payload);
        default:
            return state;
    }
};

function detailDetail(state, payload) {
    const detail = {
        ...state.detail,
        item: payload
    };
    state = Object.assign({}, state, {
        detail
    });
    return state;
}

function detailInsert(state, payload) {
    const data = [...state.detail.data, payload];
    return Object.assign({}, state, {
        detail: { ...state.detail, data }
    });
}

function detailList(state, payload) {
    state = Object.assign({}, state, {
        detail: { data: payload, totalRows: payload.length }
    });
    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, {
        data: payload.data,
        totalRows: payload.totalRows
    });
    return state;
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    console.log(payload, data);

    return Object.assign({}, state, { data });
}

export default reducer;
