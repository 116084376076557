import Loadable from 'react-loadable';
import LoadingComponent from '../common/loader';

export default [
    {
        path: '/service/:id',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./ServiceDetailContainer'),
            loading: LoadingComponent
        })
    }
];
