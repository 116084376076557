import {
    NEWS_LIST,
    NEWS_GETALL,
    NEWS_DETAIL,
    NEWS_INSERT,
    NEWS_UPDATE,
    NEWS_DELETE,
    CATEGORY_GETALL,
    CURRENT_PAGE
} from './action-types';
import { PAGE_SIZE } from '../../../../constants/Config';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    item: null,
    suggestion: [],
    categories: [],
    edit: false
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case NEWS_LIST:
            return list(state, payload);
        case NEWS_GETALL:
            return suggest(state, payload);
        case NEWS_DETAIL:
            return detail(state, payload);
        case NEWS_INSERT:
            return insert(state, payload);
        case NEWS_UPDATE:
            return update(state, payload);
        case NEWS_DELETE:
            return deletes(state, payload);
        case CATEGORY_GETALL:
            return category(state, payload);
        case CURRENT_PAGE:
            return page(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function suggest(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function category(state, payload) {
    state = Object.assign({}, state, {
        categories: [...payload]
    });
    return state;
}

function page(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

export default reducer;
