import BaseViewModel from '../../base/BaseViewModel';
import { STATUS, MARRIED_STATUS, USER_TYPE, GENDER } from '../../constants/Config';
import * as _ from 'lodash';

var moment = require('moment');

export default class UserViewModel extends BaseViewModel {
    initialize(props) {
        super.initialize(props);

        this.branchIds = _.map(this.branchUsers, (x) => x.branchId);
    }

    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);

        this.branchName = _.map(this.branchUsers, (x) => JSON.parse(x.branchName)[languageId]).join(', ');

        const selectedStatus = _.find(STATUS, (x) => x.id === this.status);
        this.statusName = JSON.parse(selectedStatus.name)[languageId];

        const selectedMarriedStatus = _.find(MARRIED_STATUS, (x) => x.id === this.marriedStatus);
        this.marriedStatusName = JSON.parse(selectedMarriedStatus.name)[languageId];

        const selectedUserType = _.find(USER_TYPE, (x) => x.id === this.userType);
        if (selectedUserType) {
            this.userTypeName = JSON.parse(selectedUserType.name)[languageId];
        } else {
            this.userTypeName = '';
        }

        this.gender = Number.isInteger(props.gender) ? JSON.parse(GENDER[props.gender].name)[languageId] : props.gender;

        this.birthday = moment(props.birthday).isValid() ? moment(props.birthday).format('DD/MM/YYYY') : '';
        this.joinedDate = moment(props.joinedDate).isValid() ? moment(props.joinedDate).format('DD/MM/YYYY') : '';
    }
}
