export const enA = {
    account: {
        form: {
            account: 'Account Name',
            newPassword: 'New Password',
            resetTitle: 'Reset Password'
        }
    },
    area: {
        list: { title: 'List of Areas', unit: 'area' },
        form: {
            name: 'Area name',
            select: 'Select Area',
            active: 'Active'
        }
    }
};
