import { NOTIFICATION_URL, NAILSPA_URL, FIREBASE_URL } from '../../constants/Config';
import Http from '../../utils/Http';
import Transformer from '../../utils/Transformer';
import NotificationViewModel from './NotificationViewModel';
import OfficeTreeUsersViewModel from './OfficeTreeUsersViewModel';
import * as notificationAction from './store/action';

export function searchNotificationRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let url = `${NOTIFICATION_URL}/api/v1/notifications`;
            url += payload.page ? `?page=${payload.page}` : '&page=1';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '&pageSize=5';

            Http.get(url)
                .then((res) => {
                    dispatch(
                        notificationAction.list({
                            ...res.data,
                            data:
                                res.data?.data?.map(
                                    (notify) => new NotificationViewModel(notify, payload.languageId)
                                ) || [],
                            rawData: res.data.data
                        })
                    );
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function reSearchNotificationRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let url = `${NOTIFICATION_URL}/api/v1/notifications`;
            url += payload.page ? `?page=${payload.page}` : '&page=1';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '&pageSize=5';

            Http.get(url)
                .then((res) => {
                    dispatch(
                        notificationAction.reList({
                            ...res.data,
                            data: res.data.data.map((notify) => new NotificationViewModel(notify, payload.languageId))
                        })
                    );
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function updateIsReadNotificationRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NOTIFICATION_URL}/api/v1/notifications/${payload.id}`;

            Http.post(url, payload.isRead, {
                headers: { 'Content-Type': 'application/json-patch+json' }
            })
                .then(() => {
                    dispatch(notificationAction.updateRead(payload.id));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function countUnReadNotificationRequest() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NOTIFICATION_URL}/api/v1/notifications/count-read`;

            Http.get(url)
                .then((res) => {
                    dispatch(notificationAction.countUnRead(res.data.unRead));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function updateAllReadNotificationRequest() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NOTIFICATION_URL}/api/v1/notifications`;

            Http.put(url)
                .then(() => {
                    dispatch(notificationAction.updateAllRead());
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function deleteNotificationRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NOTIFICATION_URL}/api/v1/notifications/delete/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    // dispatch(notificationAction.updateRead(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}
export function notificationDisconnectRequest() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NOTIFICATION_URL}/api/v1/notifications/disconnect`;

            Http.get(url)
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function notifyInsertRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NOTIFICATION_URL}/api/v1/notifications/sends`;
            const notify = [...payload];
            payload.forEach((item) => {
                item.title = typeof item.title === 'object' ? JSON.stringify(item.title) : item.title;
                item.content = typeof item.content === 'object' ? JSON.stringify(item.content) : item.content;
                item.subContent =
                    typeof item.subContent === 'object' ? JSON.stringify(item.subContent) : item.subContent;
            });

            Http.post(url, notify)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function notifySendFirebaseRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${FIREBASE_URL}PushNotifications/send-automatic`;
            const notify = [...payload];

            payload.forEach((item) => {
                item.title = Transformer.IsJsonString(item.title)
                    ? Transformer.convertJSONString(item.title, item.languageId)
                    : item.title;
                item.body = Transformer.IsJsonString(item.content)
                    ? Transformer.convertJSONString(item.content, item.languageId)
                    : item.content;
                item.userId = item.receiverId;
            });

            Http.post(url, notify)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function officeTreeUsersGetAll(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}Offices/trees-user`;

            Http.get(url)
                .then((res) => {
                    dispatch(
                        notificationAction.officeTreeUser(
                            res.data.map((item) => new OfficeTreeUsersViewModel(item, payload.currentLanguage))
                        )
                    );
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
