import { RadioGroup } from 'devextreme-react';
import React, { Component } from 'react';
import { IS_ALL } from '../../../constants/Config';
import PropTypes from 'prop-types';

class IsAllRadioGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: []
        };

        this.changeActive = this.changeActive.bind(this);
    }

    componentDidMount() {
        this.handleLanguage();
    }

    handleLanguage = () => {
        const { languages } = this.props;

        let datas = [];

        languages.forEach((element) => {
            const newArray = [];

            for (const item of IS_ALL) {
                const i = Object.assign({}, item);
                const nameParse = i.name ? JSON.parse(i.name) : '';

                i.name = nameParse[element.languageId];
                newArray.push(i);
            }

            datas = { ...datas, [element.languageId]: newArray };
        });

        this.setState({
            datas
        });
    };

    changeActive() {
        const { value, field } = this.props;

        this.props.updateData({
            [field]: !value
        });
    }

    render() {
        const { value, currentLanguageActive, readOnly } = this.props;
        const { datas } = this.state;

        return (
            <div>
                <div className={'dx-field-label'}></div>
                <div className={'dx-field-value'}>
                    <RadioGroup
                        readOnly={!!readOnly}
                        items={datas[currentLanguageActive]}
                        value={value ? 1 : 0}
                        layout={'horizontal'}
                        onValueChanged={this.changeActive}
                        displayExpr={'name'}
                        valueExpr={'id'}
                    />
                </div>
            </div>
        );
    }
}

IsAllRadioGroup.propTypes = {
    currentLanguageActive: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    field: PropTypes.string,
    updateData: PropTypes.func,
    languages: PropTypes.array
};

export default IsAllRadioGroup;
