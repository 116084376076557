import React, { Component } from 'react';
import { DateBox } from 'devextreme-react';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class TimePickerBox extends Component {
    constructor(props) {
        super(props);

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(e) {
        this.props.updateData({
            [this.props.field]: e.value
        });
    }

    render() {
        const { value, i18nLabel, width, max, min, readOnly, interval, displayFormat } = this.props;

        return (
            <div className="form-group cm-mgr-5">
                <div className={'dx-field'}>
                    <DateBox
                        type={'time'}
                        onValueChanged={this.onValueChanged}
                        placeholder={i18n.t(i18nLabel)}
                        interval={interval || 30}
                        displayFormat={displayFormat || 'HH:mm'}
                        showClearButton={true}
                        value={value}
                        width={width || 120}
                        min={min || null}
                        max={max || null}
                        readOnly={readOnly || false}
                    />
                </div>
            </div>
        );
    }
}

TimePickerBox.propTypes = {
    value: PropTypes.string,
    i18nLabel: PropTypes.string,
    width: PropTypes.number,
    currentLanguage: PropTypes.string,
    updateData: PropTypes.func,
    field: PropTypes.string
};

export default TimePickerBox;
