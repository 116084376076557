export const PROMOTION_SERVICE_CARD_SEARCH = 'PROMOTION_SERVICE_CARD_SEARCH';
export const PROMOTION_DETAIL = 'PROMOTION_DETAIL';
export const PROMOTION_SERVICE_CARD_GETALL = 'PROMOTION_SERVICE_CARD_GETALL';
export const PROMOTION_SERVICE_CARD_DETAIL = 'PROMOTION_SERVICE_CARD_DETAIL';
export const PROMOTION_SERVICE_CARD_RESET = 'PROMOTION_SERVICE_CARD_RESET';
export const PROMOTION_SERVICE_CARD_DETAIL_GETALL = 'PROMOTION_SERVICE_CARD_DETAIL_GETALL';
export const PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETALL = 'PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETALL';
export const PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETDETAIL = 'PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETDETAIL';
export const PROMOTION_SERVICE_CARD_DETAIL_RESET = 'PROMOTION_SERVICE_CARD_DETAIL_RESET';
export const PROMOTION_SERVICE_CARD_DETAIL_DETAIL_RESET = 'PROMOTION_SERVICE_CARD_DETAIL_DETAIL_RESET';
export const PROMOTION_SERVICE_CARD_GETALL_BY_TIME = 'PROMOTION_SERVICE_CARD_GETALL_BY_TIME';
// export const PROMOTION_SERVICE_CARD_SEARCH = 'PROMOTION_SERVICE_CARD_SEARCH';
// export const PROMOTION_SERVICE_CARD_SEARCH = 'PROMOTION_SERVICE_CARD_SEARCH';

export default {
    PROMOTION_DETAIL,
    PROMOTION_SERVICE_CARD_GETALL,
    PROMOTION_SERVICE_CARD_SEARCH,
    PROMOTION_SERVICE_CARD_DETAIL,
    PROMOTION_SERVICE_CARD_DETAIL_GETALL,
    PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETALL,
    PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETDETAIL,
    PROMOTION_SERVICE_CARD_DETAIL_RESET,
    PROMOTION_SERVICE_CARD_DETAIL_DETAIL_RESET,
    PROMOTION_SERVICE_CARD_RESET
};
