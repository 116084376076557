export const CUSTOMER_SERVICE_CARD_LIST = 'CUSTOMER_SERVICE_CARD_LIST';
export const CUSTOMER_SERVICE_CARD_SELECT = 'CUSTOMER_SERVICE_CARD_SELECT';
export const CUSTOMER_SERVICE_CARD_UPDATE = 'CUSTOMER_SERVICE_CARD_UPDATE';
export const CUSTOMER_SERVICE_CARD_INSERT = 'CUSTOMER_SERVICE_CARD_INSERT';
export const CUSTOMER_SERVICE_CARD_GET_ALL = 'CUSTOMER_SERVICE_CARD_GET_ALL';
export const CUSTOMER_SERVICE_CARD_DELETE = 'CUSTOMER_SERVICE_CARD_DELETE';
export const CUSTOMER_SERVICE_CARD_DETAIL = 'CUSTOMER_SERVICE_CARD_DETAIL';
export const CUSTOMER_SERVICE_CARD_LIST_RESET = 'CUSTOMER_SERVICE_CARD_LIST_RESET';
export const CUSTOMER_SERVICE_CARD_HISTORY = 'CUSTOMER_SERVICE_CARD_HISTORY';
export const CUSTOMER_SERVICE_CARD_HISTORY_LOADED = 'CUSTOMER_SERVICE_CARD_HISTORY_LOADED';
export const CUSTOMER_SERVICE_CARD_BY_CUSTOMER_GET_ALL = 'CUSTOMER_SERVICE_CARD_BY_CUSTOMER_GET_ALL';
export const CUSTOMER_SERVICE_CARD_BY_SERVICE_NOTE_LIST = 'CUSTOMER_SERVICE_CARD_BY_SERVICE_NOTE_LIST';
export const CUSTOMER_SERVICE_CARD_TDVOTHER_PAYMENT = 'CUSTOMER_SERVICE_CARD_TDVOTHER_PAYMENT';
export const CUSTOMER_SERVICE_CARD_RESET_TDVOTHER_PAYMENT = 'CUSTOMER_SERVICE_CARD_RESET_TDVOTHER_PAYMENT';

export default {
    CUSTOMER_SERVICE_CARD_INSERT,
    CUSTOMER_SERVICE_CARD_LIST,
    CUSTOMER_SERVICE_CARD_SELECT,
    CUSTOMER_SERVICE_CARD_UPDATE,
    CUSTOMER_SERVICE_CARD_GET_ALL,
    CUSTOMER_SERVICE_CARD_DELETE,
    CUSTOMER_SERVICE_CARD_DETAIL,
    CUSTOMER_SERVICE_CARD_LIST_RESET,
    CUSTOMER_SERVICE_CARD_HISTORY,
    CUSTOMER_SERVICE_CARD_HISTORY_LOADED,
    CUSTOMER_SERVICE_CARD_BY_CUSTOMER_GET_ALL,
    CUSTOMER_SERVICE_CARD_BY_SERVICE_NOTE_LIST,
    CUSTOMER_SERVICE_CARD_TDVOTHER_PAYMENT,
    CUSTOMER_SERVICE_CARD_RESET_TDVOTHER_PAYMENT
};
