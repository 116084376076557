export const REPORT_LIST = 'REPORT_LIST';
export const REPORT_DETAIL = 'REPORT_DETAIL';
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_INSERT = 'REPORT_INSERT';
export const REPORT_DELETE = 'REPORT_DELETE';
export const REPORT_GET_ALL = 'REPORT_GET_ALL';
export const REPORT_TOTAL_REVENUE = 'REPORT_TOTAL_REVENUE';
export const REPORT_FIRST_TIME = 'REPORT_FIRST_TIME';
export const REPORT_CUSTOMER_POINTS = 'REPORT_CUSTOMER_POINTS';
export const REPORT_SERVICE_APPOIMENT = 'REPORT_SERVICE_APPOIMENT';
export const REPORT_STAFF_APPOIMENT = 'REPORT_STAFF_APPOIMENT';
export const REPORT_COUNT_SERVICES_PRODUCTS = 'REPORT_COUNT_SERVICES_PRODUCTS';
export const REPORT_CUSTOMER_SERVICE_DETAIL = 'REPORT_CUSTOMER_SERVICE_DETAIL';
export const REPORT_CUSTOMER = 'REPORT_CUSTOMER';
export const REPORT_WAREHOUSE_CARD = 'REPORT_WAREHOUSE_CARD';
export const REPORT_EXPORT_IMPORT = 'REPORT_EXPORT_IMPORT';
export const REPORT_PRODUCT_INVENTORY = 'REPORT_PRODUCT_INVENTORY';
export const REPORT_PRODUCT_INVENTORY_DETAIL = 'REPORT_PRODUCT_INVENTORY_DETAIL';
export const REPORT_USER = 'REPORT_USER';
export const REPORT_USER_DETAIL = 'REPORT_USER_DETAIL';
export const REPORT_USER_DETAIL_RESET = 'REPORT_USER_DETAIL_RESET';
export const GET_DETAIL_PAYMENT_REPORT = 'GET_DETAIL_PAYMENT_REPORT';
export const REPORT_SERVICE_PRODUCT = 'REPORT_SERVICE_PRODUCT';
export const REPORT_CUSTOMER_CARD = 'REPORT_CUSTOMER_CARD';
export const REPORT_PRODUCT_GROUPS = 'REPORT_PRODUCT_GROUPS';
export const REPORT_PRODUCT_GROUPS_DETAIL = 'REPORT_PRODUCT_GROUPS_DETAIL';
export const REPORT_BOOKING_ALL_BRANCH = 'REPORT_BOOKING_ALL_BRANCH';
export const REPORT_USER_ALL_BRANCH = 'REPORT_USER_ALL_BRANCH';
export const REPORT_CUSTOMER_NO_COME = 'REPORT_CUSTOMER_NO_COME';
export const REPORT_CALENDAR_REMINDER = 'REPORT_CALENDAR_REMINDER';
export const REPORT_CUSTOMER_CARD_IS_ABOUT_TO_EXPIRE = 'REPORT_CUSTOMER_CARD_IS_ABOUT_TO_EXPIRE';
export const REPORT_CUSTOMER_SERVICE_CARD_IS_ABOUT_TO_EXPIRE = 'REPORT_CUSTOMER_SERVICE_CARD_IS_ABOUT_TO_EXPIRE';
export const FEEDBACK_INSERT = 'FEEDBACK_INSERT';
export const REPORT_CUSTOMER_CARE = 'REPORT_CUSTOMER_CARE';

export default {
    REPORT_DELETE,
    REPORT_DETAIL,
    REPORT_INSERT,
    REPORT_LIST,
    REPORT_UPDATE,
    REPORT_GET_ALL,
    REPORT_TOTAL_REVENUE,
    REPORT_FIRST_TIME,
    REPORT_CUSTOMER_POINTS,
    REPORT_SERVICE_APPOIMENT,
    REPORT_STAFF_APPOIMENT,
    REPORT_COUNT_SERVICES_PRODUCTS,
    REPORT_CUSTOMER_SERVICE_DETAIL,
    REPORT_CUSTOMER,
    REPORT_WAREHOUSE_CARD,
    REPORT_EXPORT_IMPORT,
    REPORT_PRODUCT_INVENTORY,
    REPORT_PRODUCT_INVENTORY_DETAIL,
    REPORT_USER,
    REPORT_USER_DETAIL,
    REPORT_USER_DETAIL_RESET,
    GET_DETAIL_PAYMENT_REPORT,
    REPORT_SERVICE_PRODUCT,
    REPORT_CUSTOMER_CARD,
    REPORT_PRODUCT_GROUPS,
    REPORT_PRODUCT_GROUPS_DETAIL,
    REPORT_BOOKING_ALL_BRANCH,
    REPORT_USER_ALL_BRANCH,
    REPORT_CUSTOMER_NO_COME,
    REPORT_CALENDAR_REMINDER,
    REPORT_CUSTOMER_CARD_IS_ABOUT_TO_EXPIRE,
    REPORT_CUSTOMER_SERVICE_CARD_IS_ABOUT_TO_EXPIRE,
    FEEDBACK_INSERT,
    REPORT_CUSTOMER_CARE
};
