import {
    PROVINCE_DELETE,
    PROVINCE_DETAIL,
    PROVINCE_INSERT,
    PROVINCE_LIST,
    PROVINCE_RESPONSE,
    PROVINCE_UPDATE,
    PROVINCE_GETALL,
    PROVINCE_BY_COUNTRY
} from './action-types';

export function list(payload) {
    return {
        type: PROVINCE_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: PROVINCE_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: PROVINCE_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: PROVINCE_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: PROVINCE_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: PROVINCE_RESPONSE,
        payload
    };
}
export function getAll(payload) {
    return {
        type: PROVINCE_GETALL,
        payload
    };
}

export function listByCountry(payload) {
    return {
        type: PROVINCE_BY_COUNTRY,
        payload
    };
}
