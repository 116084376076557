import { WEBSITE_SOCIAL_SEARCH, WEBSITE_SOCIAL_DETAIL } from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    item: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        // case WEBSITE_SOCIAL_INSERT:
        //     return insert(state, payload)
        case WEBSITE_SOCIAL_SEARCH:
            return search(state, payload);
        case WEBSITE_SOCIAL_DETAIL:
            return detail(state, payload);
        default:
            return state;
    }
};

function search(state, payload) {
    state = Object.assign({}, state, { data: payload.data, totalRows: payload.totalRows });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, { item: payload });
    return state;
}

export default reducer;
