import {
    CUSTOMER_SERVICE_CARD_INSERT,
    CUSTOMER_SERVICE_CARD_LIST,
    CUSTOMER_SERVICE_CARD_UPDATE,
    CUSTOMER_SERVICE_CARD_GET_ALL,
    CUSTOMER_SERVICE_CARD_DELETE,
    CUSTOMER_SERVICE_CARD_DETAIL,
    CUSTOMER_SERVICE_CARD_LIST_RESET,
    CUSTOMER_SERVICE_CARD_HISTORY,
    CUSTOMER_SERVICE_CARD_BY_CUSTOMER_GET_ALL,
    CUSTOMER_SERVICE_CARD_BY_SERVICE_NOTE_LIST,
    CUSTOMER_SERVICE_CARD_HISTORY_LOADED,
    CUSTOMER_SERVICE_CARD_TDVOTHER_PAYMENT,
    CUSTOMER_SERVICE_CARD_SELECT,
    CUSTOMER_SERVICE_CARD_RESET_TDVOTHER_PAYMENT
} from './action-types';
import * as _ from 'lodash';

const initialState = {
    data: [],
    history: [],
    historyLoaded: [],
    totalRowsHistory: 0,
    totalRowsPaymentHistory: 0,
    totalRows: 0,
    currentPage: 0,
    pageSize: 0,
    item: {},
    suggestion: null,
    byCustomer: {
        suggestion: null
    },
    byServiceNote: {
        suggestion: []
    },
    payment: {
        suggestion: []
    }
};

const customerServiceCard = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case CUSTOMER_SERVICE_CARD_SELECT:
            return select(state, payload);
        case CUSTOMER_SERVICE_CARD_LIST:
            return list(state, payload);
        case CUSTOMER_SERVICE_CARD_BY_CUSTOMER_GET_ALL:
            return getAllByCustomer(state, payload);
        case CUSTOMER_SERVICE_CARD_BY_SERVICE_NOTE_LIST:
            return getAllByServiceNote(state, payload);
        case CUSTOMER_SERVICE_CARD_TDVOTHER_PAYMENT:
            return getAllTDVOtherPayment(state, payload);
        case CUSTOMER_SERVICE_CARD_UPDATE:
            return update(state, payload);
        case CUSTOMER_SERVICE_CARD_INSERT:
            return insert(state, payload);
        case CUSTOMER_SERVICE_CARD_DELETE:
            return deletes(state, payload);
        case CUSTOMER_SERVICE_CARD_GET_ALL:
            return suggestion(state, payload);
        case CUSTOMER_SERVICE_CARD_DETAIL:
            return detail(state, payload);
        case CUSTOMER_SERVICE_CARD_LIST_RESET:
            return resetList(state);
        case CUSTOMER_SERVICE_CARD_HISTORY:
            return history(state, payload);
        case CUSTOMER_SERVICE_CARD_HISTORY_LOADED:
            return historyLoaded(state, payload);
        case CUSTOMER_SERVICE_CARD_RESET_TDVOTHER_PAYMENT:
            return resetTDVOther(state, payload);
        default:
            return state;
    }
};

function getAllTDVOtherPayment(state, payload) {
    const payment = {
        suggestion: payload
    };
    state = Object.assign({}, state, {
        payment
    });
    return state;
}

function resetTDVOther(state) {
    const payment = {
        suggestion: []
    };
    state = Object.assign({}, state, {
        payment
    });
    return state;
}

function getAllByServiceNote(state, payload) {
    const byServiceNote = {
        suggestion: payload
    };
    state = Object.assign({}, state, {
        byServiceNote
    });
    return state;
}

function getAllByCustomer(state, payload) {
    const byCustomer = {
        suggestion: payload
    };
    state = Object.assign({}, state, {
        byCustomer
    });
    return state;
}

function resetList(state) {
    state = Object.assign({}, state, {
        data: []
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, {
        data: payload.data.data,
        totalRows: payload.totalRows,
        pageSize: payload.pageSize
    });
    return state;
}

function select(state, payload) {
    return { ...state, item: _.find(state.data, (x) => x.id === payload) };
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    return { ...state, item: payload };
}

function history(state, payload) {
    state = Object.assign({}, state, { history: payload.data, totalRowsHistory: payload.totalRows });
    return state;
}

function historyLoaded(state, payload) {
    state = Object.assign({}, state, { historyLoaded: payload.data, totalRowsPaymentHistory: payload.totalRows });
    return state;
}

export const getCustomerServiceCards = (state) => state.customerServiceCard.data;
export const getCustomerServiceCardItem = (state) => state.customerServiceCard.item;

export default customerServiceCard;
