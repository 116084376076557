import { GOODS_NOTE_DETAIL_STAFF_SALE_LIST, GOODS_NOTE_DETAIL_STAFF_ADVISORY_LIST } from './action-types';

const initialState = {
    userSale: [],
    userAdvisory: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GOODS_NOTE_DETAIL_STAFF_SALE_LIST:
            return listSale(state, payload);
        case GOODS_NOTE_DETAIL_STAFF_ADVISORY_LIST:
            return listAdvisory(state, payload);
        default:
            return state;
    }
};

function listSale(state, payload) {
    state = Object.assign({}, state, { userSale: payload });
    return state;
}

function listAdvisory(state, payload) {
    state = Object.assign({}, state, { userAdvisory: payload });
    return state;
}

export default reducer;
