import Transformer from '../utils/Transformer';

const LANGUAGES = ['vi-VN', 'en-US'];

function useJSON(data, jsonField = []) {
    const convertData = {};

    Object.keys(data).forEach((element) => {
        if (jsonField.includes(element) || (data[element] && Transformer.IsJsonString(data[element]))) {
            LANGUAGES.forEach((language) => {
                convertData[`${element}-${language}`] = Transformer.convertJSONString(data[element], language);
            });
        } else {
            convertData[element] = data[element];
        }
    });

    return convertData;
}

export default useJSON;
