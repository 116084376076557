import {
    NATION_DELETE,
    NATION_DETAIL,
    NATION_INSERT,
    NATION_LIST,
    NATION_RESPONSE,
    NATION_UPDATE,
    NATION_GETALL,
    NATION_BY_COUNTRY
} from './action-types';

export function list(payload) {
    return {
        type: NATION_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: NATION_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: NATION_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: NATION_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: NATION_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: NATION_RESPONSE,
        payload
    };
}
export function getAll(payload) {
    return {
        type: NATION_GETALL,
        payload
    };
}

export function listByCountry(payload) {
    return {
        type: NATION_BY_COUNTRY,
        payload
    };
}
