import BaseViewModel from '../../base/BaseViewModel';
import * as _ from 'lodash';
import Transformer from '../../utils/Transformer';
import { TYPE_SERVICE, FORMAT_TIME_BYLANG } from '../../constants/Config';
const moment = require('moment');
export default class GoodsNoteDetailViewModel extends BaseViewModel {
    initialize(props) {
        super.initialize(props);
        if (!props.totalAmount) {
            this.totalAmount = props.discountByPercent
                ? (props.price * (100 - props.discountPrice)) / 100
                : props.price - props.discountPrice;
        } else {
            this.totalAmount = props.totalAmount;
        }

        this.typeCashBack = _.has(props, 'type') ? JSON.parse(TYPE_SERVICE[props.type].name) : '';
        this.productType = props.discountByPercent
            ? props.discountPrice / 100
            : Transformer.convertCurrency(props.price - this.totalAmount);

        // this.discountPrice = props.discountByPercent
        //     ? props.discountPrice / 100
        //     : props.discountPrice;
    }

    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);
        if (!props.totalAmount) {
            this.totalAmount = props.discountByPercent
                ? (props.price * props.quantity * (100 - props.discountPrice)) / 100
                : props.price * props.quantity - props.discountPrice;
        } else {
            this.totalAmount = props.totalAmount;
        }

        this.listNameUserSale = _.isArray(props.userSales)
            ? _.map(props.userSales, (x) => x.userFullName).join(', ')
            : '';
        this.listNameUserAdvisory = _.isArray(props.userAdvisorys)
            ? _.map(props.userAdvisorys, (x) => x.userFullName).join(', ')
            : '';
        this.discountPriceValue = props.price * props.quantity - this.totalAmount;
        this.entryDate = props.entryDate ? moment(props.entryDate).format('DD/MM/YYYY') : '';
        if (props.unitName && Transformer.IsJsonString(props.unitName)) {
            this.unitName =
                JSON.parse(props.unitName)[languageId] !== undefined
                    ? JSON.parse(props.unitName)[languageId]
                    : JSON.parse(props.unitName)['vi-VN'];
        } else this.unitName = props.unitName;
        // this.unitName = Transformer.IsJsonString(props.unitName)
        //     ? JSON.parse(props.unitName)[languageId]
        //     : props.unitName;
        this.discountPriceConvert = props.discountByPercent
            ? props.totalPrice * (props.discountPrice / 100)
            : props.discountPrice;
        this.priceTxt = Transformer.convertCurrency(props.price);
        this.productName = Transformer.IsJsonString(props.productName)
            ? Transformer.convertJSONString(props.productName, languageId)
            : props.productName;

        if (props.expiryDate) {
            this.expiryDate = props.expiryDate ? moment(props.expiryDate).format(FORMAT_TIME_BYLANG[languageId]) : '';
        }
    }
}

export class ProductPurchasedViewModel extends BaseViewModel {
    initialize(props) {
        super.initialize(props);
        if (!props.totalAmount) {
            this.totalAmount = props.discountByPercent
                ? (props.price * (100 - props.discountPrice)) / 100
                : props.price - props.discountPrice;
        } else {
            this.totalAmount = props.totalAmount;
        }

        this.typeCashBack = _.has(props, 'type') ? JSON.parse(TYPE_SERVICE[props.type].name) : '';
        this.productType = props.discountByPercent
            ? props.discountPrice / 100
            : Transformer.convertCurrency(props.price - this.totalAmount);

        // this.discountPrice = props.discountByPercent
        //     ? props.discountPrice / 100
        //     : props.discountPrice;
    }

    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);
        if (!props.totalAmount) {
            this.totalAmount = props.discountByPercent
                ? (props.price * props.quantity * (100 - props.discountPrice)) / 100
                : props.price * props.quantity - props.discountPrice;
        } else {
            this.totalAmount = props.totalAmount;
        }

        this.listNameUserSale = _.isArray(props.userSales)
            ? _.map(props.userSales, (x) => x.userFullName).join(', ')
            : '';
        this.listNameUserAdvisory = _.isArray(props.userAdvisorys)
            ? _.map(props.userAdvisorys, (x) => x.userFullName).join(', ')
            : '';
        this.discountPriceValue = props.price * props.quantity - this.totalAmount;

        if (props.unitName && Transformer.IsJsonString(props.unitName)) {
            this.unitName =
                JSON.parse(props.unitName)[languageId] !== undefined
                    ? JSON.parse(props.unitName)[languageId]
                    : JSON.parse(props.unitName)['vi-VN'];
        } else this.unitName = props.unitName;
        // this.unitName = Transformer.IsJsonString(props.unitName)
        //     ? JSON.parse(props.unitName)[languageId]
        //     : props.unitName;
        this.discountPriceConvert = props.discountByPercent
            ? props.totalPrice * (props.discountPrice / 100)
            : props.discountPrice;
        this.priceTxt = Transformer.convertCurrency(props.price);
        this.productName = Transformer.IsJsonString(props.productName)
            ? Transformer.convertJSONString(props.productName, languageId)
            : props.productName;

        if (props.expiryDate) {
            this.expiryDate = props.expiryDate ? moment(props.expiryDate).format(FORMAT_TIME_BYLANG[languageId]) : '';
        }
    }
}
