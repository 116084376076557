import {
    ALBUM_LIST,
    ALBUM_UPDATE,
    ALBUM_DELETE,
    ALBUM_GETALL,
    ALBUM_DETAIL,
    ALBUM_INSERT,
    ALBUM_SELECT,
    ALBUM_PHOTO_GETALL,
    ALBUM_PHOTO_DETAIL,
    ALBUM_VIDEO_GETALL,
    ALBUM_VIDEO_DETAIL
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    items: [],
    item: null,
    selected: null,
    languages: [],
    userIds: [],
    suggestion: [],
    byUser: [],
    photo: {
        data: [],
        totalRows: 0,
        item: {}
    },
    video: {
        data: [],
        totalRows: 0,
        item: {}
    }
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ALBUM_LIST:
            return list(state, payload);
        case ALBUM_DETAIL:
            return detail(state, payload);
        case ALBUM_UPDATE:
            return update(state, payload);
        case ALBUM_INSERT:
            return insert(state, payload);
        case ALBUM_DELETE:
            return deletes(state, payload);
        case ALBUM_GETALL:
            return suggestion(state, payload);
        case ALBUM_SELECT:
            return select(state, payload);

        case ALBUM_PHOTO_GETALL:
            return photoGetAll(state, payload);
        case ALBUM_PHOTO_DETAIL:
            return photoDetail(state, payload);

        case ALBUM_VIDEO_GETALL:
            return videoGetAll(state, payload);
        case ALBUM_VIDEO_DETAIL:
            return videoDetail(state, payload);
        default:
            return state;
    }
};

function photoDetail(state, payload) {
    state = Object.assign({}, state, {
        photo: {
            ...state.photo,
            item: payload
        }
    });
    return state;
}

function videoDetail(state, payload) {
    state = Object.assign({}, state, {
        video: {
            ...state.video,
            item: payload
        }
    });
    return state;
}

function videoGetAll(state, payload) {
    state = Object.assign({}, state, {
        video: {
            data: payload,
            totalRows: payload.length
        }
    });
    return state;
}

function photoGetAll(state, payload) {
    state = Object.assign({}, state, {
        photo: {
            data: payload,
            totalRows: payload.length
        }
    });
    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function suggestion(state, payload) {
    let selected = null;
    if (payload.length > 0) {
        selected = payload[0];
    }

    state = Object.assign({}, state, {
        suggestion: payload,
        selected
    });
    return state;
}

function select(state, payload) {
    console.log(state, payload);
    state = Object.assign({}, state, {
        selected: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

export default reducer;
