import React, { useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { FolderVM } from '../../model/File';
import { FILE_URL_API } from '../../constants/Config';
import useFetchGetAll from '../../data/useFetchGetAll';
import notify from 'devextreme/ui/notify';

const accessToken = localStorage.getItem('access_token');

export default function FolderTreeView() {
    const [selectedIds, setSelectedIds] = useState([]);
    const folder = useFetchGetAll('Folders/trees', FolderVM, [], FILE_URL_API);

    const syncTreeViewSelection = () => {
        const _ids = folder.data.filter((x) => x.selected).map((x) => x.id);

        setSelectedIds(_ids);
    };

    const treeViewSelectionChanged = (e) => {
        const _selectedNodesKeys = e.component.getSelectedNodesKeys();

        _selectedNodesKeys.forEach((element) => {
            if (!selectedIds.includes(element)) {
                setPublicFolder(element, true);
            }
        });

        selectedIds.forEach((element) => {
            if (!_selectedNodesKeys.includes(element)) {
                setPublicFolder(element, false);
            }
        });

        setSelectedIds(_selectedNodesKeys);
    };

    const setPublicFolder = (folderId, value) => {
        const _selectFolder = folder.data.find((x) => x.id === folderId);
        if (_selectFolder) {
            fetch(
                `${FILE_URL_API}Folders/${folderId}/${value}?concurrencyStamp=${_selectFolder.data.concurrencyStamp}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                        'Accept-Language': 'vi-VN'
                    }
                }
            ).then((result) => {
                result.json().then((json) => {
                    if (result.ok) {
                        notify(
                            {
                                message: json.message || 'Added successfully',
                                position: {
                                    my: 'center top',
                                    at: 'center top'
                                }
                            },
                            'success',
                            3000
                        );
                    }
                });
            });
        }
    };

    return (
        <TreeView
            id="folder-treeview"
            items={folder.data}
            keyExpr={'id'}
            width={350}
            selectionMode="multiple"
            showCheckBoxesMode="normal"
            selectNodesRecursive={true}
            selectByClick={true}
            onContentReady={syncTreeViewSelection}
            onItemSelectionChanged={treeViewSelectionChanged}
        />
    );
}
