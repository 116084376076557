export const FAQGROUP_LIST = 'FAQGROUP_LIST';
export const FAQGROUP_DETAIL = 'FAQGROUP_DETAIL';
export const FAQGROUP_UPDATE = 'FAQGROUP_UPDATE';
export const FAQGROUP_INSERT = 'FAQGROUP_INSERT';
export const FAQGROUP_DELETE = 'FAQGROUP_DELETE';
export const FAQGROUP_RESPONSE = 'FAQGROUP_RESPONSE';
export const FAQGROUP_GETALL = 'FAQGROUP_GETALL';

export default {
    FAQGROUP_DELETE,
    FAQGROUP_DETAIL,
    FAQGROUP_INSERT,
    FAQGROUP_LIST,
    FAQGROUP_UPDATE,
    FAQGROUP_GETALL
};
