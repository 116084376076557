export const RELIGION_LIST = 'RELIGION_LIST';
export const RELIGION_DETAIL = 'RELIGION_DETAIL';
export const RELIGION_UPDATE = 'RELIGION_UPDATE';
export const RELIGION_INSERT = 'RELIGION_INSERT';
export const RELIGION_DELETE = 'RELIGION_DELETE';
export const RELIGION_RESPONSE = 'RELIGION_RESPONSE';
export const RELIGION_GETALL = 'RELIGION_GETALL';
export const RELIGION_BY_COUNTRY = 'RELIGION_BY_COUNTRY';
export default {
    RELIGION_DELETE,
    RELIGION_DETAIL,
    RELIGION_INSERT,
    RELIGION_LIST,
    RELIGION_UPDATE,
    RELIGION_RESPONSE,
    RELIGION_GETALL,
    RELIGION_BY_COUNTRY
};
