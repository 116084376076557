export const REQUEST_LIST = 'REQUEST_LIST';
export const REQUEST_DETAIL = 'REQUEST_DETAIL';
export const REQUEST_UPDATE = 'REQUEST_UPDATE';
export const REQUEST_INSERT = 'REQUEST_INSERT';
export const REQUEST_DELETE = 'REQUEST_DELETE';
export const REQUEST_GET_ALL = 'REQUEST_GET_ALL';
export const REQUEST_OLDNEW = 'REQUEST_OLDNEW';
export const REQUEST_PRODUCT_OLD_NEW = 'REQUEST_PRODUCT_OLD_NEW';

export default {
    REQUEST_DELETE,
    REQUEST_DETAIL,
    REQUEST_INSERT,
    REQUEST_LIST,
    REQUEST_UPDATE,
    REQUEST_GET_ALL,
    REQUEST_OLDNEW,
    REQUEST_PRODUCT_OLD_NEW
};
