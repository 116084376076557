import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/customer',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./list/test'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer/info',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./info/CustomerInfo'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer/point',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerPoint/index'),
            loading: LoadingComponent
        })
    }
];
