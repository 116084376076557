import React from 'react';
import { TextBox } from 'devextreme-react';

export default function Field(data) {
    return (
        <div className={'custom-item'}>
            <TextBox className={'branch-name'} defaultValue={data && data.name} readOnly={true} />
        </div>
    );
}
