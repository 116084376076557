import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/service',
        exact: true,
        auth: true,
        component: Loadable({
            // loader: () => import("../../pages/ServiceContainer"),
            loader: () => import('./list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/service-group',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./groupService/list/index'),
            loading: LoadingComponent
        })
    }
];
