import {
    ROLE_GROUP_LIST,
    ROLE_GROUP_DELETE,
    ROLE_GROUP_INSERT,
    ROLE_GROUP_UPDATE,
    ROLE_GROUP_DETAIL
} from './actions-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    item: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ROLE_GROUP_LIST:
            return listRoleGroup(state, payload);
        case ROLE_GROUP_DELETE:
            return deleteRoleGroup(state, payload);
        case ROLE_GROUP_INSERT:
            return insertRoleGroup(state, payload);
        case ROLE_GROUP_UPDATE:
            return updateRoleGroup(state, payload);
        case ROLE_GROUP_DETAIL:
            return detailRoleGroup(state, payload);
        default:
            return state;
    }
};

function listRoleGroup(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detailRoleGroup(state, payload) {
    state = Object.assign({}, state, { item: payload });
    return state;
}

function deleteRoleGroup(state, payload) {
    const data = state.data.filter((item) => {
        return item.id !== payload;
    });

    state = Object.assign({}, state, { data });
    return state;
}

function updateRoleGroup(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insertRoleGroup(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

export default reducer;
