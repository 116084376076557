import { CORE_LANGUAGE_INSERT, CORE_LANGUAGE_LIST, CORE_LANGUAGE_SUGGESTION } from './action-type';

const initialState = {
    items: [],
    item: null,
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case CORE_LANGUAGE_LIST:
            return list(state, payload);
        case CORE_LANGUAGE_INSERT:
            return insert(state, payload);
        case CORE_LANGUAGE_SUGGESTION:
            return suggestion(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function insert(state, payload) {
    const item = [...state.items, payload];
    return Object.assign({}, state, { item });
}

function suggestion(state, payload) {
    const suggestion = payload.map((item) => {
        const items = {
            languageId: item.id,
            name: item.name,
            description: item.description,
            isActive: item.isActive,
            isDefault: false
        };
        return items;
    });
    return Object.assign({}, state, { suggestion: suggestion });
}

export default reducer;
