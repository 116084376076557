import React, { Component } from 'react';
import { Button } from 'devextreme-react';
import PropTypes from 'prop-types';
class SearchButton extends Component {
    render() {
        const { searchBtn } = this.props;

        return (
            <div className="form-group">
                <div className={'dx-field'}>
                    <div className={'dx-field-value w100pc'}>
                        <Button onClick={searchBtn} icon={'search'} />
                    </div>
                </div>
            </div>
        );
    }
}

SearchButton.propTypes = {
    searchBtn: PropTypes.func
};
export default SearchButton;
