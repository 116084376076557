export const INVOICE_HISTORY = 'INVOICE_HISTORY';
export const INVOICE_PAYMENT_BY_CARD = 'INVOICE_PAYMENT_BY_CARD';
export const INVOICE_LIST = 'INVOICE_LIST';
export const INVOICE_LIST_RESET = 'INVOICE_LIST_RESET';
export const INVOICE_GET_CODE = 'INVOICE_GET_CODE';
export const INVOICE_INSERT = 'INVOICE_INSERT';
export const INVOICE_DETAIL = 'INVOICE_DETAIL';

export default {
    INVOICE_HISTORY,
    INVOICE_PAYMENT_BY_CARD,
    INVOICE_LIST,
    INVOICE_LIST_RESET,
    INVOICE_GET_CODE,
    INVOICE_INSERT,
    INVOICE_DETAIL
};
