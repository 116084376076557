import {
    WEBSITE_PRODUCT_CATEGORIES_SEARCH,
    WEBSITE_PRODUCT_CATEGORIES_DETAIL,
    WEBSITE_PRODUCT_CATEGORIES_TREE
} from './action-types';

const initialState = {
    data: [],
    item: null,
    suggestion: [],
    totalRows: 0,
    tree: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case WEBSITE_PRODUCT_CATEGORIES_SEARCH:
            return search(state, payload);
        case WEBSITE_PRODUCT_CATEGORIES_DETAIL:
            return detail(state, payload);
        case WEBSITE_PRODUCT_CATEGORIES_TREE:
            return tree(state, payload);

        default:
            return state;
    }
};

const search = (state, payload) => {
    state = Object.assign({}, state, payload);

    return state;
};

const detail = (state, payload) => {
    state = { ...state, item: payload };

    return state;
};

const tree = (state, payload) => {
    state = { ...state, tree: payload };

    return state;
};

export default reducer;
