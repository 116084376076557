import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrivateLayout from './Private';
import PublicLayout from './Public';

import './layout.scss';
class Layout extends Component {
    static displayName = 'Layout';
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        children: PropTypes.node.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    render() {
        const { children, ...props } = this.props;
        if (this.props.isAuthenticated) {
            return <PrivateLayout {...props}>{children}</PrivateLayout>;
        }
        return <PublicLayout {...props}>{children}</PublicLayout>;
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        listBranch: state.branch.suggestion,
        appSetting: state.appSettings.data
    };
};

export default withRouter(connect(mapStateToProps)(Layout));
