export const viB = {
    // B
    branch: {
        list: {
            title: 'Danh sách chi nhánh',
            email: 'Email',
            hotline: 'Hotline',
            note: 'Ghi chú'
        },
        userList: { title: 'Danh sách Nhân viên thuộc Chi Nhánh' },
        form: {
            insertTitle: 'Thêm mới Chi Nhánh',
            updateTitle: 'Chỉnh sửa Chi Nhánh'
        }
    },
    base: {
        selectImg: 'Chọn Ảnh',
        removeImg: 'Xóa Ảnh',
        order: 'Order',
        selectCV: 'Chọn CV',
        selectJD: 'Chọn JD',
        remove: 'Xóa'
    },
    brand: {
        list: { title: 'Danh sách nhãn hiệu' }
    },
    booking: {
        Bookinginfo: 'Thông tin đặt hẹn',
        AppointmentTimeRequire: 'Thời gian hẹn không hợp lệ',
        BookingServiceRequire: 'Dịch vụ đặt hẹn không hợp lệ',
        createNote: 'Lập phiếu để sử dụng dịch vụ',
        EditBooking: 'Chỉnh sửa lịch hẹn',
        Joins: 'Nhân viên tham gia',
        SaveBooking: 'Lưu'
    }
};
