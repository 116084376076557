import React, { Component } from 'react';
import i18n from '../../i18n';
import { TextBox } from 'devextreme-react';
import PropTypes from 'prop-types';

class BaseCodeBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            renderDone: false,
            value: this.props.value
        };
    }

    componentDidMount() {
        this.getCode();
    }

    getCode() {
        this.setState({
            renderDone: false
        });

        this.props.getCode().then(() => {
            const { field, code } = this.props;

            this.props.updateData({
                [field]: code
            });
            this.setState({
                renderDone: true
            });
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value === '' && this.state.renderDone) {
            this.getCode();
        }
    }

    render() {
        const { i18nLabel, code } = this.props;
        const { renderDone } = this.state;

        if (!renderDone) {
            return <div>Loading...</div>;
        }

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}</div>
                <div className={'dx-field-value'}>
                    <TextBox value={code} disabled />
                </div>
            </div>
        );
    }
}

BaseCodeBox.propTypes = {
    field: PropTypes.string,
    getCode: PropTypes.func,
    value: PropTypes.string,
    i18nLabel: PropTypes.string,
    code: PropTypes.any,
    updateData: PropTypes.func
};

export default BaseCodeBox;
