import { SERVICE_CARD_DETAIL_DELETE, SERVICE_CARD_DETAIL_INSERT } from './action-types';
import { PAGE_SIZE } from '../../../constants/Config';
const initialState = {
    data: [],
    totalRows: 0,
    currentPAGE: 0,
    pageSize: PAGE_SIZE,
    action: null,
    item: null,
    getAll: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case SERVICE_CARD_DETAIL_INSERT:
            return insert(state, payload);
        case SERVICE_CARD_DETAIL_DELETE:
            return deletes(state, payload);
        default:
            return state;
    }
};

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

export default reducer;
