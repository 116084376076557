import {
    PARTNER_LIST,
    PARTNER_UPDATE,
    PARTNER_DELETE,
    PARTNER_GETALL,
    PARTNER_DETAIL,
    PARTNER_INSERT
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    items: [],
    item: null,
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PARTNER_LIST:
            return list(state, payload);
        case PARTNER_DETAIL:
            return detail(state, payload);
        case PARTNER_UPDATE:
            return update(state, payload);
        case PARTNER_INSERT:
            return insert(state, payload);
        case PARTNER_DELETE:
            return deletes(state, payload);
        case PARTNER_GETALL:
            return suggestion(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}
function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

export default reducer;
