import configRoutes from '../modules/config/routes';
import userRoutes from '../modules/user/routes';
import customerRoutes from '../modules/customer/routes';
import customerServiceCardRoutes from '../modules/customerServiceCard/routes';
import bookingRoutes from '../modules/booking/routes';
import serviceRoutes from '../modules/service/routes';
import goodsNoteRoutes from '../modules/goodsNote/routes';
import fileRoutes from '../modules/filemanagement/routes';
import productRoutes from '../modules/product/routes';
import requestRoutes from '../modules/request/routes';
import promotionRoutes from '../modules/promotion/routes';
import serviceCardRoutes from '../modules/serviceCard/routes';
import reportRoutes from '../modules/report/routes';
import customerCareRoutes from '../modules/customerCare/routes';
import warehouseRoutes from '../modules/warehouse/routes';
import promotionCardRoutes from '../modules/promotionCard/routes';
import promotionServiceCardRoutes from '../modules/promotionServiceCard/routes';
import websiteRoutes from '../features/nailspa/website/routes';
import systemRoutes from '../features/nailspa/system/routes';
import authRoutes from '../features/nailspa/auth/routes';
import coreRoutes from '../modules/core/routes';
import testRoutes from '../test/routes';
import inventoryRoutes from '../modules/inventory/routes';
import notifyRoutes from '../modules/notification/routes';
import newRoutes from '../pages/routes';

export default [
    ...authRoutes,
    ...bookingRoutes,
    ...configRoutes,
    ...userRoutes,
    ...customerRoutes,
    ...notifyRoutes,
    ...serviceRoutes,
    ...productRoutes,
    ...fileRoutes,
    ...customerServiceCardRoutes,
    ...requestRoutes,
    ...promotionRoutes,
    ...serviceCardRoutes,
    ...reportRoutes,
    ...warehouseRoutes,
    ...websiteRoutes,
    ...coreRoutes,
    ...systemRoutes,
    ...promotionCardRoutes,
    ...goodsNoteRoutes,
    ...customerCareRoutes,
    ...promotionServiceCardRoutes,
    ...testRoutes,
    ...inventoryRoutes,
    ...newRoutes
];
