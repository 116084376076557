export const BRANCHS_LIST = 'BRANCHS_LIST';
export const BRANCHS_DETAIL = 'BRANCHS_DETAIL';
export const BRANCHS_INSERT = 'BRANCHS_INSERT';
export const BRANCHS_UPDATE = 'BRANCHS_UPDATE';
export const BRANCHS_DELETE = 'BRANCHS_DELETE';
export const BRANCHS_GETALL = 'BRANCHS_GETALL';
export const BRANCHS_USER_LIST = 'BRANCHS_USER_LIST';

export default {
    BRANCHS_LIST,
    BRANCHS_DETAIL,
    BRANCHS_INSERT,
    BRANCHS_UPDATE,
    BRANCHS_DELETE,
    BRANCHS_GETALL,
    BRANCHS_USER_LIST
};
