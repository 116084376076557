import { connect } from 'react-redux';
import { websiteGetAllRequest, websiteGetAllByUserRequest, websiteSelectedItemUpdate } from '../service';
import BaseSelectBox from '../../../../base/components/BaseSelectBox';
import React from 'react';
import { SelectBox } from 'devextreme-react';
import i18n from '../../../../i18n';
import * as _ from 'lodash';
import Transformer from '../../../../utils/Transformer';

class WebsiteSelectHeader extends BaseSelectBox {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            value: null
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.listData !== this.props.listData) {
            this.handleLanguageUpdate(nextProps.listData);
        }
    }

    handleLanguageUpdate = (listData) => {
        const { languages, displayExpr } = this.props;

        const fieldName = displayExpr || 'name';
        let datas = {};

        languages.forEach((element) => {
            const newArray = [];

            for (const item of listData) {
                const i = Object.assign({}, item);
                const nameParse = Transformer.IsJsonString(i[fieldName])
                    ? JSON.parse(i[fieldName])[element.languageId]
                    : i[fieldName];

                i[fieldName] = nameParse;
                newArray.push(i);
            }

            datas = { ...datas, [element.languageId]: newArray };
        });

        this.setState(
            {
                datas
            },
            () => {
                this.setState({ renderDone: true });
            }
        );
    };

    getAllData() {
        const { currentUser } = this.props;
        const { payload } = this.state;

        if (currentUser.isAdmin) {
            this.props.getAllData(payload).then(() => {
                this.handleLanguage();
            });
        } else {
            this.props.getAllbyUser(payload).then(() => {
                this.handleLanguage();
            });
        }
    }

    onDataChanged(e) {
        const { listData } = this.props;

        const selectedItem = _.find(listData, (x) => x.id === e.value);

        if (selectedItem !== null) {
            this.props.changeWebsite(selectedItem);
        }
    }

    render() {
        const { currentLanguage, i18nLabel, valueExpr, displayExpr, readOnly, disabled, selectedWebsite } = this.props;
        const { datas, renderDone } = this.state;

        if (!renderDone) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                {datas[currentLanguage] && datas[currentLanguage].length > 0 ? (
                    <SelectBox
                        searchEnabled={true}
                        items={datas[currentLanguage]}
                        value={selectedWebsite ? selectedWebsite.id : ''}
                        valueExpr={valueExpr || 'id'}
                        displayExpr={displayExpr || 'name'}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={false}
                        onValueChanged={this.onDataChanged}
                        readOnly={readOnly || false}
                        disabled={disabled}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentUser, userSettings } = state.appSettings.data;
    const currentLanguage = _.find(userSettings, (item) => item.key === 'LanguageId');
    const { suggestion, byUser, selected } = state.website;

    return {
        languages: state.appSettings.data.languages,
        listData: currentUser.isAdmin ? suggestion : byUser,
        currentUser,
        selectedWebsite: selected,
        currentLanguage: currentLanguage.value
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: () => {
            return dispatch(websiteGetAllRequest());
        },
        getAllbyUser: () => {
            return dispatch(websiteGetAllByUserRequest());
        },
        changeWebsite: (payload) => {
            return dispatch(websiteSelectedItemUpdate(payload));
        }
    };
};

WebsiteSelectHeader.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteSelectHeader);
