import { NAILSPA_URL } from '../../constants/Config';
import Http from '../../utils/Http';
import * as userActions from './store/actions';
import { spinnerLoading } from '../../common/spinner/store/action';
import UserViewModel from './UserViewModel';
import Transformer from '../../utils/Transformer';
var moment = require('moment');

export function resetUserDetail() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(userActions.resetDetail());
            resolve();
        });
}

export function selectUser(userId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(userActions.select(userId));
            resolve();
        });
}

export function userSearchRequire(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}/users/search-istech/${payload.branchId}?keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(userActions.list(res.data));
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function userSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}users?keyword=${payload.keyword}&page=${payload.page}&pageSize=${payload.pageSize}`;
            url += payload.isActive !== null ? `&isActive=${payload.isActive}` : '';
            url += payload.officeId ? `&officeId=${payload.officeId}` : '';
            url += payload.branchId ? `&branchId=${payload.branchId}` : '';

            Http.get(url)
                .then((res) => {
                    setTimeout(() => {
                        dispatch(spinnerLoading(false));
                    }, 1000);

                    dispatch(
                        userActions.list({
                            ...res.data,
                            data: res.data.data.map((user) => new UserViewModel(user, payload.languageId))
                        })
                    );
                    resolve();
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function getAllUserDataRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const data = {
                page: payload.page,
                pageSize: payload.pageSize
            };

            const url = `${NAILSPA_URL}Users?${Transformer.convertToPramsURI(data)}`;

            Http.get(url)
                .then((res) => {
                    resolve(
                        res.data.data.map((user) => {
                            user.birthday = moment(user.birthday).isValid()
                                ? moment(user.birthday).format('DD/MM/YYYY')
                                : '';
                            user.joinedDate = moment(user.joinedDate).isValid()
                                ? moment(user.joinedDate).format('DD/MM/YYYY')
                                : '';
                            user.idCardDateOfIssue = moment(user.idCardDateOfIssue).isValid()
                                ? moment(user.idCardDateOfIssue).format('DD/MM/YYYY')
                                : '';
                            user.outDate = moment(user.outDate).isValid()
                                ? moment(user.outDate).format('DD/MM/YYYY')
                                : '';
                            if (user) {
                                return new UserViewModel(user, payload.languageId);
                            }
                        })
                    );
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function userInsertRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}users`;
            Http.post(url, payload)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    payload.id = res.data.data;
                    payload.concurrencyStamp = res.data.data;

                    // dispatch(userActions.insert(payload));
                    dispatch(userActions.detail(payload));

                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    const errMes = `Code: ${err.response.data.code} - ${err.response.data.message}`;
                    reject(errMes);
                });
        });
}

export function userUpdateRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}users/${payload.id}`;

            Http.put(url, payload)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    payload.concurrencyStamp = res.data.data;

                    // dispatch(userActions.update(payload))
                    dispatch(userActions.detail(payload));

                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        });
}

export function userGetDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}users/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(userActions.detail(res.data.data));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        });
}

export function userDeleteRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}users/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(userActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        });
}

export function userGetAllRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}users/get-all`;
            url += payload ? `/${payload.branchId}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(userActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function userGetBranchUserRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}BranchUsers/${payload.branchId}?page=1&pageSize=500`;

            Http.get(url)
                .then((res) => {
                    dispatch(userActions.getBranchUsers(res.data.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function userGetCode() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}users/code`;

            Http.get(url)
                .then((res) => {
                    dispatch(userActions.getCode(res.data.data));
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function timeShiftUserIsTech(branchId) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}Users/get-istech/${branchId}`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(userActions.getAll(res.data));
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                    dispatch(spinnerLoading(false));
                });
        });
    };
}
