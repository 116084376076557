import React, { Component } from 'react';
import { NumberBox } from 'devextreme-react';
import i18n from '../../i18n';

class OrderBox extends Component {
    constructor(props) {
        super(props);

        this.orderChanged = this.orderChanged.bind(this);
    }

    orderChanged(e) {
        const { field } = this.props;

        this.props.updateData({
            [field]: e.value === null ? 0 : e.value
        });
    }

    render() {
        const { i18nLabel, value, readOnly } = this.props;

        return (
            <div className={'dx-field orderbox'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}</div>
                <div className={'dx-field-value'}>
                    <NumberBox
                        name={'order'}
                        value={value}
                        max={1000000}
                        min={0}
                        readOnly={readOnly || false}
                        onValueChanged={this.orderChanged}
                    />
                </div>
            </div>
        );
    }
}

export default OrderBox;
