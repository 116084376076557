import { PAGE_SIZE } from '../../../../constants/Config';
import {
    WEBSITE_PROJECT_CATEGORIES_SEARCH,
    WEBSITE_PROJECT_CATEGORIES_DETAIL,
    WEBSITE_PROJECT_CATEGORIES_GETALL
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    item: null,
    suggestion: [],
    currentPage: 1,
    pageSize: PAGE_SIZE
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case WEBSITE_PROJECT_CATEGORIES_SEARCH:
            return list(state, payload);
        case WEBSITE_PROJECT_CATEGORIES_DETAIL:
            return detail(state, payload);
        case WEBSITE_PROJECT_CATEGORIES_GETALL:
            return getAll(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

const detail = (state, payload) => {
    state = { ...state, item: payload };
    return state;
};

const getAll = (state, payload) => {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
};

export default reducer;
