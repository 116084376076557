import React, { Component } from 'react';
import i18n from '../../i18n';
import { Button } from 'devextreme-react';
import PropTypes from 'prop-types';

class CloseButton extends Component {
    render() {
        const { hideForm } = this.props;

        return (
            <Button
                className={'button-info'}
                text={i18n.t('general.button.cancel')}
                icon="close"
                width={'126'}
                onClick={hideForm}
            />
        );
    }
}

CloseButton.propTypes = {
    hideForm: PropTypes.func.isRequired
};

CloseButton.defaultProps = {
    hideForm: () => console.log('hide Form')
};

export default CloseButton;
