import { CATEGORIES } from '../../../constants/Config';
import BaseReadOnlyConstants from '../../components/BaseReadOnlyConstants';

class ReadOnlyCategory extends BaseReadOnlyConstants {
    constructor(props) {
        super(props);

        this.CONSTANTS = CATEGORIES;
    }
}

export default ReadOnlyCategory;
