import { PAGE_SIZE } from '../../../constants/Config';
import {
    PROMOTION_SERVICE_CARD_SEARCH,
    PROMOTION_SERVICE_CARD_GETALL,
    PROMOTION_SERVICE_CARD_DETAIL,
    PROMOTION_DETAIL,
    PROMOTION_SERVICE_CARD_DETAIL_GETALL,
    PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETALL,
    PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETDETAIL,
    PROMOTION_SERVICE_CARD_DETAIL_RESET,
    PROMOTION_SERVICE_CARD_DETAIL_DETAIL_RESET,
    PROMOTION_SERVICE_CARD_GETALL_BY_TIME,
    PROMOTION_SERVICE_CARD_RESET
} from './action-types';
import * as _ from 'lodash';
const initialState = {
    data: [],
    dataSearch: [],
    totalRows: 0,
    totalRowsSearch: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {},
    itemServiceCard: {},
    itemServiceCardDetail: {},
    suggestion: [],
    serviceCardSuggestion: [],
    serviceCardDetailSuggestion: [],
    getAllByTime: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PROMOTION_SERVICE_CARD_SEARCH:
            return list(state, payload);
        case PROMOTION_SERVICE_CARD_GETALL:
            return suggestion(state, payload);
        case PROMOTION_DETAIL:
            return detail(state, payload);
        case PROMOTION_SERVICE_CARD_DETAIL:
            return detailServiceCard(state, payload);
        case PROMOTION_SERVICE_CARD_DETAIL_GETALL:
            return serviceCardGetAll(state, payload);
        case PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETDETAIL:
            return detailServiceCardDetail(state, payload);
        case PROMOTION_SERVICE_CARD_DETAIL_DETAIL_GETALL:
            return serviceCardDetailGetAll(state, payload);
        case PROMOTION_SERVICE_CARD_DETAIL_RESET:
            return resetPromotionServiceCardDetail(state, payload);
        case PROMOTION_SERVICE_CARD_DETAIL_DETAIL_RESET:
            return resetPromotionServiceCardDetailDetail(state, payload);
        case PROMOTION_SERVICE_CARD_GETALL_BY_TIME:
            return getAllByTime(state, payload);
        case PROMOTION_SERVICE_CARD_RESET:
            return resetGetAllByTime(state, payload);
        default:
            return state;
    }
};

// function resetList(state) {
//     state = Object.assign({}, state, {
//         data: [],
//         dataSearch: []
//     });
//     return state;
// }

// function select(state, payload) {
//     state = Object.assign({}, state, {
//         item: {
//             id: payload
//         }
//     });
//     return state;
// }

function list(state, payload) {
    _.isArray(payload)
        ? (state = Object.assign({}, state, { data: payload }))
        : (state = Object.assign({}, state, { data: payload.data, totalRows: payload.totalRows }));

    return state;
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function detailServiceCard(state, payload) {
    state = Object.assign({}, state, {
        itemServiceCard: payload
    });
    return state;
}
function serviceCardGetAll(state, payload) {
    state = Object.assign({}, state, {
        serviceCardSuggestion: payload
    });
    return state;
}

function serviceCardDetailGetAll(state, payload) {
    state = Object.assign({}, state, {
        serviceCardDetailSuggestion: payload
    });
    return state;
}

function detailServiceCardDetail(state, payload) {
    state = Object.assign({}, state, {
        itemServiceCardDetail: payload
    });
    return state;
}

function resetPromotionServiceCardDetail(state) {
    state = Object.assign({}, state, {
        serviceCardSuggestion: []
    });
    return state;
}

function resetPromotionServiceCardDetailDetail(state) {
    state = Object.assign({}, state, {
        serviceCardDetailSuggestion: []
    });
    return state;
}
function getAllByTime(state, payload) {
    state = Object.assign({}, state, {
        getAllByTime: [...payload]
    });
    return state;
}

function resetGetAllByTime(state) {
    state = Object.assign({}, state, {
        getAllByTime: []
    });
    return state;
}
// function deletes(state, payload) {
//     const data = state.data.filter((obj) => obj.id !== payload);
//     return Object.assign({}, state, { data });
// }

// function update(state, payload) {
//     const data = state.data.map((obj) => {
//         if (obj.id === payload.id) {
//             return { ...obj, ...payload };
//         }
//         return obj;
//     });

//     return Object.assign({}, state, { data });
// }

// function insert(state, payload) {
//     const data = state.data ? [...state.data, payload] : [payload];
//     const item = Object.assign({}, state.item, payload);

//     return Object.assign({}, state, { data, item });
// }

// function search(state, payload) {
//     return Object.assign({ ...state, dataSearch: payload.data, totalRowsSearch: payload.totalRows });
// }

export default reducer;
