import { JOB_DELETE, JOB_DETAIL, JOB_INSERT, JOB_LIST, JOB_RESPONSE, JOB_UPDATE, JOB_GETALL } from './action-types';

export function list(payload) {
    return {
        type: JOB_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: JOB_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: JOB_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: JOB_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: JOB_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: JOB_RESPONSE,
        payload
    };
}
export function suggestion(payload) {
    return {
        type: JOB_GETALL,
        payload
    };
}
