import { PAGE_SIZE } from '../../../constants/Config';
import {
    REQUEST_DELETE,
    REQUEST_DETAIL,
    REQUEST_INSERT,
    REQUEST_LIST,
    REQUEST_UPDATE,
    REQUEST_GET_ALL,
    REQUEST_OLDNEW,
    REQUEST_PRODUCT_OLD_NEW
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {},
    listOlds: [],
    listNews: [],
    listProdOld: [],
    listProdNew: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case REQUEST_LIST:
            return list(state, payload);
        case REQUEST_DETAIL:
            return detail(state, payload);
        case REQUEST_UPDATE:
            return update(state, payload);
        case REQUEST_INSERT:
            return insert(state, payload);
        case REQUEST_DELETE:
            return deletes(state, payload);
        case REQUEST_GET_ALL:
            return getAll(state, payload);
        case REQUEST_OLDNEW:
            return oldnew(state, payload);
        case REQUEST_PRODUCT_OLD_NEW:
            return getProductOldNew(state, payload);
        default:
            return state;
    }
};

const getProductOldNew = (state, payload) => {
    return { ...state, listProdNew: payload.listNews, listProdOld: payload.listOlds };
};

function oldnew(state, payload) {
    state = Object.assign({}, state, payload);
    return { ...state, ...payload };
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return { ...state, ...payload };
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.UserId !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function getAll(state, payload) {
    state = Object.assign({}, state, { data: payload });
    return state;
}

export const getRequestsList = (state) => state.request.data;

export default reducer;
