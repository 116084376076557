import {
    WEBSITE_LIST,
    WEBSITE_UPDATE,
    WEBSITE_DELETE,
    WEBSITE_GETALL,
    WEBSITE_DETAIL,
    WEBSITE_INSERT,
    WEBSITE_GETALL_BYUSER,
    WEBSITE_SELECT,
    WEBSITE_GET_LANGUAGES,
    WEBSITE_GET_USERS,
    WEBSITE_INSERT_USER,
    WEBSITE_DELETE_USER,
    WEBSITE_INSERT_LANGUAGES,
    WEBSITE_UPDATE_LANGUAGES,
    WEBSITE_DELETE_LANGUAGES,
    WEBSITE_UPDATE_ACTIVE
} from './action-types';
import Transformer from '../../../../utils/Transformer';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    items: [],
    item: null,
    selected: null,
    languages: [],
    userIds: [],
    suggestion: [],
    byUser: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case WEBSITE_LIST:
            return list(state, payload);
        case WEBSITE_GET_LANGUAGES:
            return getLanguage(state, payload);
        case WEBSITE_INSERT_LANGUAGES:
            return insertLanguage(state, payload);
        case WEBSITE_UPDATE_LANGUAGES:
            return updateLanguage(state, payload);
        case WEBSITE_DELETE_LANGUAGES:
            return deleteLanguage(state, payload);
        case WEBSITE_GET_USERS:
            return getUser(state, payload);
        case WEBSITE_DELETE_USER:
            return deleteUser(state, payload);
        case WEBSITE_INSERT_USER:
            return insertUser(state, payload);
        case WEBSITE_DETAIL:
            return detail(state, payload);
        case WEBSITE_UPDATE:
            return update(state, payload);
        case WEBSITE_INSERT:
            return insert(state, payload);
        case WEBSITE_DELETE:
            return deletes(state, payload);
        case WEBSITE_GETALL:
            return suggestion(state, payload);
        case WEBSITE_GETALL_BYUSER:
            return getAllbyUser(state, payload);
        case WEBSITE_SELECT:
            return select(state, payload);
        case WEBSITE_UPDATE_ACTIVE:
            return updateActive(state, payload);
        default:
            return state;
    }
};

function deleteUser(state, payload) {
    const userIds = state.userIds.filter((obj) => obj !== payload);

    return Object.assign({}, state, { userIds });
}

function insertUser(state, payload) {
    const userIds = [...state.userIds, payload];
    return Object.assign({}, state, { userIds });
}

function getUser(state, payload) {
    state = Object.assign({}, state, { userIds: payload });
    return state;
}

function getLanguage(state, payload) {
    state = Object.assign({}, state, { languages: payload });
    return state;
}

function deleteLanguage(state, payload) {
    const languages = state.languages.filter((obj) => obj !== payload);
    return Object.assign({}, state, { languages });
}

function insertLanguage(state, payload) {
    const languages = [...state.languages, payload];
    return Object.assign({}, state, { languages });
}

function updateLanguage(state, payload) {
    const languages = state.languages.map((obj) => {
        if (obj.languageId === payload.languageId) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { languages });
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function getAllbyUser(state, payload) {
    let selected = null;
    if (payload.length > 0) {
        selected = payload[0];
    }

    state = Object.assign({}, state, {
        byUser: payload,
        selected
    });
    return state;
}

function suggestion(state, payload) {
    let selected = null;
    if (payload.length > 0) {
        selected = payload[0];
    }

    state = Object.assign({}, state, {
        suggestion: payload,
        selected
    });
    return state;
}

function select(state, payload) {
    state = Object.assign({}, state, {
        selected: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    const item = { ...state.item, ...payload };
    const suggestion = Transformer.updateList(state.suggestion, payload);

    return Object.assign({}, state, { data, item, suggestion });
}

function updateActive(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    const item = { ...state.item, ...payload };
    const suggestion = Transformer.updateList(state.suggestion, payload);

    return Object.assign({}, state, { data, item, suggestion });
}

function insert(state, payload) {
    const data = [...state.data, payload];

    const suggestion = Transformer.insertList(state.suggestion, payload);

    return Object.assign({}, state, { data, suggestion });
}

export default reducer;
