import {
    HARD_CONTENT_LIST,
    HARD_CONTENT_UPDATE,
    HARD_CONTENT_DELETE,
    HARD_CONTENT_GETALL,
    HARD_CONTENT_DETAIL,
    HARD_CONTENT_INSERT,
    HARD_CONTENT_DETAIL_GETALL,
    HARD_CONTENT_DETAIL_INSERT,
    HARD_CONTENT_DETAIL_DETAIL
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    item: {},
    suggestion: [],
    detail: {
        data: [],
        item: {},
        totalRows: 0
    }
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case HARD_CONTENT_LIST:
            return list(state, payload);
        case HARD_CONTENT_DETAIL:
            return detail(state, payload);
        case HARD_CONTENT_UPDATE:
            return update(state, payload);
        case HARD_CONTENT_INSERT:
            return insert(state, payload);
        case HARD_CONTENT_DELETE:
            return deletes(state, payload);
        case HARD_CONTENT_GETALL:
            return suggestion(state, payload);
        case HARD_CONTENT_DETAIL_GETALL:
            return detailList(state, payload);
        case HARD_CONTENT_DETAIL_DETAIL:
            return detailDetail(state, payload);
        case HARD_CONTENT_DETAIL_INSERT:
            return detailInsert(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}
function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function detailList(state, payload) {
    state = Object.assign({}, state, {
        detail: { data: payload, totalRows: payload.length }
    });
    return state;
}

function detailInsert(state, payload) {
    const data = [...state.detail.data, payload];
    state = Object.assign({}, state, {
        detail: { ...state.detail, data }
    });
    return state;
}

function detailDetail(state, payload) {
    const detail = {
        ...state.detail,
        item: payload
    };
    state = Object.assign({}, state, {
        detail
    });
    return state;
}

export default reducer;
