import CountrySelectBox from '../modules/category/country/components/CountrySelectBox';
import ResourceSelectBox from '../modules/category/resource/components/ResourceSelectBox';
import JobSelectBox from '../modules/category/job/components/JobSelectBox';
import ProvinceSelectBox from '../modules/category/province/components/ProvinceSelectBox';
import ReligionSelectBox from '../modules/category/religion/components/ReligionSelectBox';
import NationSelectBox from '../modules/category/nation/components/NationSelectBox';
import DistrictSelectBox from '../modules/category/district/components/DistrictSelectBox';
import CustomerCodeBox from '../modules/customer/components/CustomerCodeBox';
import CustomerSelectBox from '../modules/customer/components/CustomerSelectBox';
import UserSelectBox from '../modules/user/components/UserSelectBox';
import UserCodeBox from '../modules/user/components/UserCodeBox';
import ReadOnlyCategory from '../base/form/readonly/ReadOnlyCategory';
import NameBox from '../base/form/NameBox';
import PasswordBox from '../base/form/PasswordBox';
import CurrencyBox from '../base/form/CurrencyBox';
import DescriptionBox from '../base/form/DescriptionBox';
import MutilLangNameBox from '../base/form/MutilLangNameBox';
import NewMutilLangNameBox from '../base/form/new/NewMutilLangNameBox';
import HTMLEditorBox from '../base/form/HTMLEditorBox';
import MultiLangDescriptionBox from '../base/form/MultiLangDescriptionBox';
import NewMultiLangDescriptionBox from '../base/form/new/NewMultiLangDescriptionBox';
import BirthdayBox from '../base/form/BirthdayBox';
import TimeBox from '../base/form/TimeBox';
import PhoneNumberBox from '../base/form/PhoneNumberBox';
import ReadOnlyTextBox from '../base/form/readonly/ReadOnlyTextBox';
import ReadOnlyGender from '../base/form/readonly/ReadOnlyGender';
import ReadOnlyTimeBox from '../base/form/readonly/ReadOnlyTimeBox';
import ReadOnlyTextArea from '../base/form/readonly/ReadOnlyTextArea';
import DiscountBox from '../base/form/DiscountBox';
import ReadOnlyMultiLangTextBox from '../base/form/readonly/ReadOnlyMultiLangTextBox';
import ReadOnlyMultiLangAreaBox from '../base/form/readonly/ReadOnlyMultiLangAreaBox';
import EmailBox from '../base/form/EmailBox';
import GenderSelectBox from '../base/form/GenderSelectBox';
import ActiveCheckBox from '../base/form/ActiveCheckBox';
import ContinueCheckBox from '../base/form/ContinueCheckBox';
import ReadOnlyCurrencyBox from '../base/form/readonly/ReadOnlyCurrencyBox';
import ReadOnlyCheckBox from '../base/form/readonly/ReadOnlyCheckBox';
import ReadOnlyTypePromotion from '../base/form/readonly/ReadOnlyTypePromotion';
import AvatarBox from '../base/form/AvatarBox';
import ImageBox from '../base/form/ImageBox';
import OrderBox from '../base/form/OrderBox';
import LangTabs from '../base/form/LangTabs';
import ReadOnlyStatus from '../base/form/readonly/ReadOnlyStatus';
import FormPopup from '../base/form/FormPopup';
import ReadOnlyMarriedStatus from '../base/form/readonly/ReadOnlyMarriedStatus';
import SearchButton from '../base/toolbox/SearchButton';
import AddNewButton from '../base/toolbox/AddNewButton';
import GridSettingButton from '../base/toolbox/GridSettingButton';
import SearchTextBox from '../base/toolbox/SearchTextBox';
import CloseButton from '../base/actionbox/CloseButton';
import SubmitButton from '../base/actionbox/SubmitButton';
import RequestStatusSelectBox from '../base/toolbox/RequestStatusSelectBox';
import ServiceNoteStatusSelectBox from '../base/toolbox/ServiceNoteStatusSelectBox';
import ServiceNoteTypeSelectBox from '../base/toolbox/ServiceNoteTypeSelectBox';
import ActiveSelectBox from '../base/toolbox/ActiveSelectBox';
import DatePickerBox from '../base/toolbox/DatePickerBox';
import TimePickerBox from '../base/toolbox/TimePickerBox';
import PercentageBox from '../base/form/PercentageBox';
import MarriedStatusSelectBox from '../base/form/constants/MarriedStatusSelectBox';
import StatusSelectBox from '../base/form/constants/StatusSelectBox';
import UserTypeSelectBox from '../base/form/constants/UserTypeSelectBox';
import FeedBackSelectBox from '../base/form/constants/FeedBackSelectBox';
import IsTechRadioGroup from '../base/form/constants/IsTechRadioGroup';
import IsAllRadioGroup from '../base/form/constants/IsAllRadioGroup';
import IsDiscountRadioGroup from '../base/form/constants/IsDiscountRadioGroup';
import PageSizeSelectBox from '../base/form/constants/PageSizeSelectBox';
import TypeReport from '../base/toolbox/TypeReport';
import TypeProductReport from '../base/toolbox/TypeProductReport';
import ManageConfigPermissionSelectBox from '../base/toolbox/ManageConfigPermissionSelectBox';
import PromotionStatusSelectBox from '../base/toolbox/PromotionStatusSelectBox';
import WarehouseSelectBox from '../modules/warehouseManagerConfig/component/WarehouseSelectBox';
import MultiLangHTMLEditor from '../base/form/MultiLangHTMLEditor';
import URLBox from '../base/form/URLBox';
import CustomTagBox from '../base/form/CustomTagBox';
import NewsCategoryDropDownToolBox from '../base/toolbox/NewsCategoryDropDownToolBox';
import SEOLinkBox from '../base/form/SEOLinkBox';
import DetailBox from '../base/form/DetailBox';
import NewsTypeSelectBox from '../base/toolbox/NewsTypeSelectBox';
import AppointmentTimeBox from '../base/form/AppointmentTimeBox';
import JobTypesSelectBox from '../base/form/JobTypeSelectBox';
import CVBox from '../base/form/CVBox';
import ColorPickerBox from '../base/form/ColorPickerBox';
import WorkmanshipSelectBox from '../base/form/constants/WorkmanshipSelectBox';
import AttitudeSelectBox from '../base/form/constants/AttitudeSelectBox';
import ProductReviewSelectBox from '../base/form/constants/ProductReviewSelectBox';
import LotIdSelectbox from '../base/form/LotIdSelectBox';

const COMPONENT_TYPE = {
    NAMEBOX: 0,
    DESCRIPTIONBOX: 1,
    LANGNAMEBOX: 2,
    LANGDESCRIPTIONBOX: 3,
    NUMBERBOX: 4,
    CHECKBOX: 5
};

export {
    COMPONENT_TYPE,
    AddNewButton,
    GridSettingButton,
    WarehouseSelectBox,
    CountrySelectBox,
    ResourceSelectBox,
    JobSelectBox,
    ProvinceSelectBox,
    ReligionSelectBox,
    NationSelectBox,
    DistrictSelectBox,
    CustomerCodeBox,
    UserCodeBox,
    CustomerSelectBox,
    UserSelectBox,
    ReadOnlyMarriedStatus,
    NameBox,
    PasswordBox,
    DescriptionBox,
    BirthdayBox,
    DatePickerBox,
    PhoneNumberBox,
    EmailBox,
    GenderSelectBox,
    MultiLangDescriptionBox,
    MutilLangNameBox,
    ActiveCheckBox,
    OrderBox,
    LangTabs,
    ContinueCheckBox,
    ReadOnlyTextBox,
    ReadOnlyTextArea,
    ActiveSelectBox,
    AvatarBox,
    ServiceNoteStatusSelectBox,
    ServiceNoteTypeSelectBox,
    ReadOnlyTimeBox,
    ReadOnlyMultiLangTextBox,
    DiscountBox,
    ReadOnlyCurrencyBox,
    ReadOnlyGender,
    ReadOnlyMultiLangAreaBox,
    ReadOnlyCheckBox,
    ReadOnlyStatus,
    ReadOnlyTypePromotion,
    SearchTextBox,
    SearchButton,
    CloseButton,
    RequestStatusSelectBox,
    CurrencyBox,
    FormPopup,
    SubmitButton,
    ReadOnlyCategory,
    PercentageBox,
    MarriedStatusSelectBox,
    StatusSelectBox,
    UserTypeSelectBox,
    FeedBackSelectBox,
    IsTechRadioGroup,
    IsAllRadioGroup,
    IsDiscountRadioGroup,
    PageSizeSelectBox,
    TypeReport,
    TypeProductReport,
    TimeBox,
    ManageConfigPermissionSelectBox,
    PromotionStatusSelectBox,
    ImageBox,
    MultiLangHTMLEditor,
    HTMLEditorBox,
    URLBox,
    CustomTagBox,
    NewsCategoryDropDownToolBox,
    SEOLinkBox,
    DetailBox,
    NewsTypeSelectBox,
    TimePickerBox,
    AppointmentTimeBox,
    JobTypesSelectBox,
    CVBox,
    ColorPickerBox,
    WorkmanshipSelectBox,
    AttitudeSelectBox,
    ProductReviewSelectBox,
    LotIdSelectbox,
    NewMutilLangNameBox,
    NewMultiLangDescriptionBox
};
