import { NAILSPA_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import * as resourceActions from './store/actions';
import { spinnerLoading } from '../../../common/spinner/store/action';

export function resourceSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            let url = `${NAILSPA_URL}categorys/resource?keyword=${payload.keyword}`;
            url += payload.isActive !== null ? `&isActive=${payload.isActive}` : '';
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(resourceActions.list(res.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function resourceSearchValidNameRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}categorys/resource?keyword=${payload.keyword}`;
            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
}

export function resourceInsertRequest(resource) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/resource`;
            const resourceUpdate = resource;
            resourceUpdate.name = JSON.stringify(resource.name);
            resourceUpdate.description = JSON.stringify(resource.description);

            Http.post(url, resourceUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resource.id = res.data.data;
                    dispatch(resourceActions.insert(resource));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function resourceUpdateRequest(resource) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}categorys/resource/${resource.id}`;

            const resourceUpdate = resource;
            resourceUpdate.name = JSON.stringify(resource.name);
            resourceUpdate.description = JSON.stringify(resource.description);

            Http.put(url, resourceUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resource.concurrencyStamp = res.data.data;
                    dispatch(resourceActions.update(resource));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function resourceDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            // dispatch(spinnerLoading(true))
            const url = `${NAILSPA_URL}categorys/resource/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    // dispatch(spinnerLoading(false))
                    dispatch(resourceActions.detail(res.data.data));
                    resolve();
                })
                .catch(() => {
                    // dispatch(spinnerLoading(false))
                    reject();
                });
        });
}

export function resourceDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}categorys/resource/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(resourceActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function resourceGetAll() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}categorys/get-all/resource`;

            Http.get(url)
                .then((res) => {
                    dispatch(resourceActions.suggestion(res.data));
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
