import { WEBSITE_LANGUAGE_GETALL } from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    item: null,
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case WEBSITE_LANGUAGE_GETALL:
            return getAll(state, payload);
        default:
            return state;
    }
};

function getAll(state, payload) {
    state = Object.assign({}, state, { suggestion: [...payload] });
    return state;
}

export default reducer;
