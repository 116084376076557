export const WEBSITE_PROJECT_CATEGORIES_SEARCH = 'WEBSITE_PROJECT_CATEGORIES_SEARCH';
export const WEBSITE_PROJECT_CATEGORIES_DETAIL = 'WEBSITE_PROJECT_CATEGORIES_DETAIL';
export const WEBSITE_PROJECT_CATEGORIES_TREE = 'WEBSITE_PROJECT_CATEGORIES_TREE';
export const WEBSITE_PROJECT_CATEGORIES_GETALL = 'WEBSITE_PROJECT_CATEGORIES_GETALL';

export default {
    WEBSITE_PROJECT_CATEGORIES_SEARCH,
    WEBSITE_PROJECT_CATEGORIES_DETAIL,
    WEBSITE_PROJECT_CATEGORIES_TREE,
    WEBSITE_PROJECT_CATEGORIES_GETALL
};
