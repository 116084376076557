import React, { Component } from 'react';
import { TagBox } from 'devextreme-react';
import PropTypes from 'prop-types';

class CustomTagBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: []
        };
    }

    onCustomItemCreating = (e) => {
        let { value, field } = this.props;
        const newValue = e.text;

        e.customItem = newValue;
        value = [{ name: newValue }, ...value];

        this.props.updateData({ [field]: [...value] });
    };

    onValueChanged = (e) => {
        const { field } = this.props;
        if (e.value.length > e.previousValue.length) {
            this.props.updateData({ [field]: [...e.previousValue] });
        } else {
            this.props.updateData({ [field]: [...e.value] });
        }
    };

    render() {
        const { value, readOnly } = this.props;
        return (
            <div className="form-group mb-3 cm-mgr-5">
                <div className={'dx-field'}>
                    <div className="dx-field-label">Tags:</div>
                    <div className={'dx-field-value'}>
                        <TagBox
                            placeholder={''}
                            value={value}
                            acceptCustomValue={true}
                            multiline={false}
                            displayExpr={'name'}
                            openOnFieldClick={false}
                            disabled={readOnly || false}
                            onValueChanged={this.onValueChanged}
                            onCustomItemCreating={this.onCustomItemCreating}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

CustomTagBox.propTypes = {
    field: PropTypes.string,
    updateData: PropTypes.func,
    value: PropTypes.array,
    readOnly: PropTypes.bool
};

export default CustomTagBox;
