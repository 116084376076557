import React from 'react';
import { FILE_URL } from '../../../constants/Config';

export default function Item(data) {
    return (
        data && (
            <div className="custom-item">
                {data && <img src={FILE_URL + '/' + data.image} alt="Copyright Of GHMSoft" />}
                <div className="product-name">{data.name}</div>
            </div>
        )
    );
}
