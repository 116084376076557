export const enP = {
    program: {
        info: 'Program information'
    },
    page: {
        list: { title: 'List of Pages' },
        form: {
            code: 'Page code',
            icon: 'Icon',
            titleUpdate: 'Edit page',
            titleInsert: 'Add New Page',
            used: 'Page used',
            lotId: 'LotId',
            printAmout: 'Print Amount'
        },
        action: {
            listTitle: 'List of Page Actions',
            code: 'Action Code',
            bitwise: 'Bitwise value',
            insertTitle: 'Add New Action',
            updateTitle: 'Edit Action',
            actionCode: 'Action Code',
            actionName: 'Action Name',
            edit: 'Edit'
        }
    },
    province: {
        list: { title: 'List of Provinces/Cities' },
        form: { active: 'Activate' }
    },
    paymentMethod: {
        list: { title: 'List of Payment Methods' },
        form: { methods: 'Payment Methods' }
    },
    product: {
        form: {
            insertTitle: 'Add New Product',
            updateTitle: 'Edit Product',
            selectImg: 'Select Image'
        },
        list: {
            price: 'Sale Price',
            order: 'Order',
            description: 'Description',
            capacity: 'Capacity',
            license: 'License',
            source: 'Source',
            directions: 'Directions',
            element: 'Elements',
            label: 'Label',
            uses: 'Uses'
        }
    },
    position: {
        list: {
            multiple: 'Allows Mutiple',
            department: 'Used for Departments'
        }
    },
    promotion: {
        list: {
            title: 'List of promotion',
            promotion: 'promotion'
        },
        form: {
            promotionCard: 'Promotion card',
            promotionCardDetail: 'Promotion of card',
            promoTitle: 'Promotion',
            note: 'Note',
            insertTitle: 'Add new promotion',
            info: 'Promotion info',
            updateTitle: 'Edit promotion',
            servicePromotion: 'Services under the preferential program',
            productPromotion: 'Products under the preferential program',
            listSelect: 'List selected',
            selectServices: 'Select services',
            selectProducts: 'Select products',
            days: 'Duration (days)'
        }
    },
    promotionServiceCard: {
        list: {
            title: 'Service card promotion list',
            promotionCard: 'Promotion',
            promotionCardName: 'Promotion name',
            titleDetail: 'Promotion program details',
            info: 'Infomation of promotion',
            promotionCardDetailName: 'Name of service card promotion',
            promotionServiceCardDetailDetail: 'Promotions list of service card',
            promotionServiceCardDetail: 'Service card list',
            note: 'Create the promotion first then add service cards to the promotion',
            serviceCardDetailNote: 'Add service card first then add promotions of the service card'
        },
        form: {
            insertTitle: 'Add new promotion',
            updateTitle: 'Edit promotion',
            serviceCardInsertTitle: 'Add new service card of promotion',
            serviceCardUpdateTitle: 'Edit service card of promotion'
        },
        error: {
            endTime: 'End time must be after start time. Please check again.'
        }
    }
};
