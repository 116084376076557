import React, { Component } from 'react';
import { CheckBox } from 'devextreme-react';
import i18n from '../../i18n';
import PropTypes from 'prop-types';
class ContinueCheckBox extends Component {
    constructor(props) {
        super(props);

        this.changeActive = this.changeActive.bind(this);
    }

    changeActive() {
        const { value, field } = this.props;

        this.props.updateData({
            [field]: !value
        });
    }

    render() {
        const { value } = this.props;

        return (
            <CheckBox
                value={value}
                onValueChanged={this.changeActive}
                text={i18n.t('general.button.keepadding')}
                icon="chevrondoubleright"
            />
        );
    }
}

ContinueCheckBox.propTypes = {
    value: PropTypes.bool,
    field: PropTypes.string,
    updateData: PropTypes.any
};

export default ContinueCheckBox;
