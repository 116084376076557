export const PROJECT_INSERT = 'PROJECT_INSERT';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECT_DETAIL = 'PROJECT_DETAIL';
export const PROJECT_GETALL = 'PROJECT_GETALL';
export const PROJECT_DETAIL_GETALL = 'PROJECT_DETAIL_GETALL';
export const PROJECT_ALBUM_GETALL = 'PROJECT_ALBUM_GETALL';
export const PROJECT_LIST = 'PROJECT_LIST';
export const CURRENT_PAGE = 'CURRENT_PAGE';

export default {
    PROJECT_DETAIL,
    PROJECT_GETALL,
    PROJECT_INSERT,
    PROJECT_UPDATE,
    PROJECT_LIST,
    PROJECT_DETAIL_GETALL,
    PROJECT_ALBUM_GETALL
};
