export const NATION_LIST = 'NATION_LIST';
export const NATION_DETAIL = 'NATION_DETAIL';
export const NATION_UPDATE = 'NATION_UPDATE';
export const NATION_INSERT = 'NATION_INSERT';
export const NATION_DELETE = 'NATION_DELETE';
export const NATION_RESPONSE = 'NATION_RESPONSE';
export const NATION_GETALL = 'NATION_GETALL';
export const NATION_BY_COUNTRY = 'NATION_BY_COUNTRY';
export default {
    NATION_DELETE,
    NATION_DETAIL,
    NATION_INSERT,
    NATION_LIST,
    NATION_UPDATE,
    NATION_RESPONSE,
    NATION_GETALL,
    NATION_BY_COUNTRY
};
