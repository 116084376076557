import FileManager, { Permissions } from 'devextreme-react/file-manager';
import CustomFileProvider from 'devextreme/ui/file_manager/file_provider/custom';
import { FILE_URL_API } from '../../constants/Config';
import React from 'react';
import PropsType from 'prop-types';
import notify from 'devextreme/ui/notify';
import { useHistory } from 'react-router-dom';

const accessToken = localStorage.getItem('access_token');

const CurrentFolderMV = (data) => {
    const folders = data.folders.map((element) => ({
        name: element.name,
        isDirectory: true,
        hasSubDirectories: false,
        id: element.id,
        key: element.id,
        concurrencyStamp: element.concurrencyStamp,
        parentId: element.parentId,
        hasDirectories: true,
        items: [
            {
                name: 'Projects',
                isDirectory: true,
                items: [
                    {
                        name: 'About.rtf',
                        isDirectory: false,
                        size: 1024
                    },
                    {
                        name: 'Passwords.rtf',
                        isDirectory: false,
                        size: 2048
                    }
                ]
            },
            {
                name: 'About.xml',
                isDirectory: false,
                size: 1024
            },
            {
                name: 'Managers.rtf',
                isDirectory: false,
                size: 2048
            },
            {
                name: 'ToDo.txt',
                isDirectory: false,
                size: 3072
            }
        ]
    }));

    const files = data.files.map((element) => ({
        name: element.name,
        isDirectory: false,
        size: element.size,
        id: element.id,
        folderId: element.folderId,
        concurrencyStamp: element.concurrencyStamp,
        url: element.url
    }));

    return [].concat(folders).concat(files);
};

const FileMgr = React.forwardRef((props, ref) => {
    const history = useHistory();
    const { selectionMode, allowedFileExtensions } = props;

    const customFileProvider = new CustomFileProvider({
        uploadChunkSize: 1000000,
        hasSubDirectoriesExpr: 'hasDirectories',
        getItems: function (pathInfo) {
            const folderId = pathInfo.length > 0 ? pathInfo[0].key : 0;
            return fetch(`${FILE_URL_API}folders/current-directory/${folderId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Access-Control-Allow-Origin': '*'
                }
            }).then((result) =>
                result
                    .json()
                    .then((json) => {
                        if (result.ok) {
                            return CurrentFolderMV(json);
                        }
                        throw json.Message;
                    })
                    .catch(() => {
                        if (result.status === 401) {
                            history.push('/login');
                        }
                    })
            );
        },
        deleteItem: function (item) {
            if (!item.isDirectory) {
                const fileId = item.dataItem.id;
                return fetch(`${FILE_URL_API}Files/${fileId}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((result) => {
                    if (result.ok) {
                        return result.text().then((text) => text && JSON.parse(text));
                    } else {
                        return result.json().then((json) => {
                            throw json.Message;
                        });
                    }
                });
            } else {
                const folderId = item.dataItem.id;
                return fetch(`${FILE_URL_API}Folders/${folderId}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((result) => {
                    if (result.ok) {
                        return result.text().then((text) => text && JSON.parse(text));
                    } else {
                        return result.json().then((json) => {
                            throw json.Message;
                        });
                    }
                });
            }
        },
        renameItem: function (item, name) {
            if (!item.isDirectory) {
                const fileId = item.dataItem.id;
                return fetch(`${FILE_URL_API}Files/${fileId}`, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: name,
                        folderId: item.dataItem.folderId,
                        concurrencyStamp: item.dataItem.concurrencyStamp
                    })
                }).then((result) => {
                    if (result.ok) {
                        return result.text().then((text) => text && JSON.parse(text));
                    } else {
                        return result.json().then((json) => {
                            throw json.Message;
                        });
                    }
                });
            } else {
                const folderId = item.dataItem.id;
                return fetch(`${FILE_URL_API}Folders/${folderId}`, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: name,
                        order: 0,
                        parentId: item.dataItem.parentId,
                        description: '',
                        concurrencyStamp: item.dataItem.concurrencyStamp
                    })
                }).then((result) => {
                    if (result.ok) {
                        return result.text().then((text) => text && JSON.parse(text));
                    } else {
                        return result.json().then((json) => {
                            throw json.Message;
                        });
                    }
                });
            }
        },
        downloadItems: function (items) {
            items.forEach((element) => {
                const fileId = element.dataItem.id;
                return fetch(`${FILE_URL_API}Files/downloads/${fileId}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((result) => {
                    if (result.ok) {
                        return result.blob().then((blob) => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', element.name);

                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        });
                    } else {
                        return result.json().then((json) => {
                            notify(json.message, 'error', 500);
                            throw json.message;
                        });
                    }
                });
            });
        },
        uploadFileChunk: function (fileData, chunksInfo, destinationDir) {
            const _folderId = destinationDir.dataItem ? destinationDir.dataItem.id : null;
            const _formData = new FormData();
            _folderId && _formData.append('folderId', _folderId);
            _formData.append('formFileCollection', fileData);

            return fetch(`${FILE_URL_API}Files/uploads`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                body: _formData
            }).then((result) => {
                if (result.ok) {
                    // return result.text().then(text => text && JSON.parse(text));
                } else {
                    return result.json().then((json) => {
                        throw json.Message;
                    });
                }
            });
        },
        moveItem: function (item, destinationDirectory) {
            if (!item.isDirectory) {
                const fileId = item.dataItem.id;
                return fetch(`${FILE_URL_API}Files/${fileId}`, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: item.dataItem.name,
                        folderId: destinationDirectory.dataItem.id,
                        concurrencyStamp: item.dataItem.concurrencyStamp
                    })
                }).then((result) => {
                    if (result.ok) {
                        return result.text().then((text) => text && JSON.parse(text));
                    } else {
                        return result.json().then((json) => {
                            throw json.Message;
                        });
                    }
                });
            } else {
                const folderId = item.dataItem.id;
                return fetch(`${FILE_URL_API}Folders/${folderId}`, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: item.dataItem.name,
                        order: 0,
                        parentId: destinationDirectory.dataItem.id,
                        description: '',
                        concurrencyStamp: item.dataItem.concurrencyStamp
                    })
                }).then((result) => {
                    if (result.ok) {
                        return result.text().then((text) => text && JSON.parse(text));
                    } else {
                        return result.json().then((json) => {
                            throw json.Message;
                        });
                    }
                });
            }
        },
        createDirectory: function (parentDirectory, name) {
            const _parentId = parentDirectory.dataItem ? parentDirectory.dataItem.id : null;

            return fetch(`${FILE_URL_API}Folders`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    order: 0,
                    parentId: _parentId,
                    description: '',
                    concurrencyStamp: ''
                })
            }).then((result) => {
                if (result.ok) {
                    return result.text().then((text) => text && JSON.parse(text));
                } else {
                    return result.json().then((json) => {
                        throw json.Message;
                    });
                }
            });
        }
    });

    return (
        <React.Fragment>
            <FileManager
                ref={ref}
                selectionMode={selectionMode || 'multiple'}
                allowedFileExtensions={allowedFileExtensions || []}
                fileProvider={customFileProvider}
                upload={{ maxFileSize: 1000000 }}
            >
                <Permissions
                    create={true}
                    copy={true}
                    move={true}
                    remove={true}
                    rename={true}
                    upload={true}
                    download={true}
                ></Permissions>
            </FileManager>
        </React.Fragment>
    );
});

FileMgr.displayName = 'FileMgr';

FileMgr.propTypes = {
    accessToken: PropsType.string,
    selectionMode: PropsType.string,
    allowedFileExtensions: PropsType.array
};

export default FileMgr;
