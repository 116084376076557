import { combineReducers } from 'redux';
import auth from '../modules/auth/store/reducers';
import notification from '../modules/notification/store/reducer';
// import themes from '../modules/core/theme/store/reducer'
import spinner from '../common/spinner/store/reducer';
import { AUTH_LOGOUT } from '../modules/auth/store/action-types';
import coreGroups from '../modules/category/group/store/reducer';
import coreShift from '../modules/system/store/reducer';
import coreTimeShift from '../modules/home/store/reducer';
import coreResources from '../modules/category/resource/store/reducer';
import groupServices from '../modules/service/groupService/store/reducer';
import groupProducts from '../modules/product/groupProduct/store/reducer';
import groupAreas from '../modules/area/store/reducer';
import productConvertUnits from '../modules/product/productConvertUnit/store/reducer';
import coreUnits from '../modules/product/unit/store/reducer';
import product from '../modules/product/store/reducer';
import customer from '../modules/customer/store/reducer';
import filemanagement from '../modules/filemanagement/store/reducer';
import user from '../modules/user/store/reducer';
import userService from '../modules/userService/store/reducer';
import coreBrands from '../modules/category/brand/store/reducer';
import supplier from '../modules/category/supplier/store/reducer';
import title from '../modules/user/title/store/reducer';
import position from '../modules/user/position/store/reducer';
import office from '../modules/user/department/store/reducer';
import userInfo from '../modules/config/usercustom/store/reducer';
import booking from '../modules/booking/store/reducer';
import goodsNoteDetail from '../modules/goodsNoteDetail/store/reducer';
import goodsNoteDetailStaff from '../modules/goodsNoteDetailStaff/store/reducer';
import customerServiceCard from '../modules/customerServiceCard/store/reducer';
import corePromotion from '../modules/promotion/store/reducer';
import invoices from '../modules/invoices/store/reducer';
import request from '../modules/request/store/reducer';
import service from '../modules/service/store/reducer';
import serviceNote from '../modules/serviceNote/store/reducer';
import serviceNoteDetail from '../modules/serviceNoteDetail/store/reducer';
import serviceCard from '../modules/serviceCard/store/reducer';
import serviceCardDetail from '../modules/serviceCardDetail/store/reducer';
import serviceProduct from '../modules/serviceProduct/store/reducer';
import report from '../modules/report/store/reducer';
import warehouse from '../modules/warehouse/store/reducer';
import warehouseManagerConfig from '../modules/warehouseManagerConfig/store/reducer';
import warehouseProductConfig from '../modules/warehouseProductConfig/store/reducer';
import promotionCard from '../modules/promotionCard/store/reducer';
import promotionCardDetail from '../modules/promotionCardDetail/store/reducer';
import customerCard from '../modules/customerServiceCard/customerCards/store/reducer';
import goodsNote from '../modules/goodsNote/store/reducer';
import website from '../modules/website/reducer';
import category from '../modules/category/reducer';
import core from '../modules/core/reducer';
import customerCare from '../modules/customerCare/store/reducer';
import promotionServiceCard from '../modules/promotionServiceCard/store/reducer';
// import customerCareCard from '../modules/customerCare/store/reducer';
import inventory from '../modules/inventory/store/reducer';

const combinedReducer = combineReducers({
    auth,
    notification,
    spinner,
    coreGroups,
    groupServices,
    groupProducts,
    coreUnits,
    product,
    coreShift,
    coreTimeShift,
    coreResources,
    customer,
    service,
    filemanagement,
    user,
    coreBrands,
    productConvertUnits,
    position,
    office,
    title,
    userInfo,
    booking,
    serviceCard,
    serviceNoteDetail,
    serviceCardDetail,
    serviceNote,
    groupAreas,
    serviceProduct,
    customerServiceCard,
    userService,
    request,
    goodsNoteDetailStaff,
    corePromotion,
    invoices,
    goodsNoteDetail,
    report,
    warehouse,
    warehouseManagerConfig,
    warehouseProductConfig,
    promotionCard,
    promotionCardDetail,
    ...website,
    ...core,
    ...category,
    customerCard,
    goodsNote,
    supplier,
    customerCare,
    promotionServiceCard,
    inventory
});

const initialState = combinedReducer({}, {}, {}, {});

const rootReducer = (state, action) => {
    if (action.type === AUTH_LOGOUT) {
        state = initialState;
    }
    return combinedReducer(state, action);
};

export default rootReducer;
