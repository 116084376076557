import React, { Component } from 'react';
import { CheckBox } from 'devextreme-react';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class ActiveCheckBox extends Component {
    constructor(props) {
        super(props);

        this.changeActive = this.changeActive.bind(this);
    }

    changeActive() {
        const { value, field } = this.props;

        this.props.updateData({
            [field]: !value
        });
    }

    render() {
        const { value, i18nLabel, readOnly } = this.props;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}</div>
                <div className={'dx-field-value active-left'}>
                    <CheckBox
                        className={'active-left'}
                        value={value}
                        onValueChanged={this.changeActive}
                        width={80}
                        readOnly={readOnly || false}
                    />
                </div>
            </div>
        );
    }
}

ActiveCheckBox.propTypes = {
    value: PropTypes.bool,
    field: PropTypes.string,
    i18nLabel: PropTypes.string,
    updateData: PropTypes.func,
    readOnly: PropTypes.bool
};

export default ActiveCheckBox;
