export const enU = {
    userCustom: {
        member: {
            fullname: 'Fullname',
            identify: 'Identify No',
            passport: 'Passport No',
            email: 'Email',
            address: 'Address',
            identifyDate: 'Date of Issuance'
        }
    },
    user: {
        list: {
            title: 'List of Staffs',
            selectTitle: 'List of Selected Staffs',
            deleteAll: 'Delete All',
            unit: 'staffs',
            code: 'User Code'
        },
        form: {
            insertTitle: 'Add New Staff',
            info: 'Staff Information',
            enrollDate: 'Date of Enroll',
            email: 'Email',
            facebook: 'Facebook',
            infoTitle: 'User Information',
            joinedDate: 'Joined Date',
            fullname: 'Fullname',
            gender: 'Gender',
            account: 'Account',
            identify: 'Identify No',
            identifyDate: 'Date of Issuance',
            passport: 'Passport No',
            passportDate: 'Date of Issuance',
            passportPlace: 'Place of Issuance',
            permanentResidence: 'Permanent Residence',
            taxIdentificationNumber: 'Tax Identification Number',
            nationality: 'Nationality',
            bankAccountNumber: 'Bank Account Number',
            serviceTitle: 'Staff service'
        }
    },
    userService: {
        list: { title: 'List of Service Staffs', unit: 'staffs' },
        serviceList: {
            insertTitle: 'Add New Service',
            updateTitle: 'Edit Service',
            title: 'List of Staff Services',
            unit: 'services',
            commissionRate: 'Commission Rate',
            executeTime: 'Execute Time',
            price: 'Price',
            code: 'Service Code',
            name: 'Service Name'
        },
        form: { selectTitle: 'Select Staff' }
    },
    usercustom: {
        custom: 'Custom',
        personalinformation: 'Personal information',
        usercustom: 'User custom',
        Defaultcolor: 'Default color',
        Displaylanguage: 'Display language',
        usedlanguage: 'Used language',
        language: 'Language',
        Defaultbranch: 'Default branch',
        numberpage: 'Number of page display records',
        historyService: 'Services completed',
        ProductBought: 'Products Bought',
        accumulatePoints: 'Accumulate Points',
        bill: 'Bill'
    }
};
