import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/report-commissionne',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCommissionne/commissionne'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-commissionne-detail',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCommissionneDetail/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customerServiceCard',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCustomerServiceCard/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customerServiceCard-detail',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCustomerServiceCardDetailSummary/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-total-revenue',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportTotalRevenue/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportRevenue/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/service',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/ServiceReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/customer-birthday/servicenote',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/CustomerBirthdayServiceNoteReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/service/detail',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/DetailServiceReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/product',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/ProductReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/product/detail',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/DetailProductReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/customer-service',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/CustomerServiceReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/customer-service/detail',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/DetailCustomerServiceReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/customer-product',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/CustomerProductReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/customer-product/detail',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/DetailCustomerProductReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/first-time-customer',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportFirstTimeCustomer/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customer-points',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCustomerPoints/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-service-appointment',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportServiceAppointment/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-appointment-staff',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportStaffAppointment/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-revenue-services-products',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportServicesAndProductsPurchase/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customer-service-detail',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCustomerServiceDetail/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customer',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCustomerService/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer-care-report',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCustomerCare/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customer-data',
        // exact: true,
        // auth: true,
        component: Loadable({
            loader: () => import('./reportCustomerData/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-warehouse-card',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportWarehouseCard/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-import-export',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportImportExport/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-product-inventory',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportProductInventory/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-user',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportUser/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-user-detail',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportUserDetail/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/detail-payment',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./detailPayment'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-service-product',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportServiceAndProduct/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customer-card',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportCustomerCard/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/product-group',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./productGroup/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/customer-detail-all-branch',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerDetailAllBranch/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/customerServiceCard',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/CustomerServiceCardReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/totalBalanceCustomerServiceCard',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/TotalBalanceCustomerServiceCardReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report/totalBalanceCustomerCard',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/report/TotalBalanceCustomerCardReportContainer'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customer-no-come',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportRemind/ReportCustomerNoCome'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-calendar-reminder',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportRemind/ReportCalendarReminder'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customer-card-is-about-to-expire',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportRemind/ReportCustomerCardIsAboutToExpire'),
            loading: LoadingComponent
        })
    },
    {
        path: '/report-customer-service-card-is-about-to-expire',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./reportRemind/ReportCustomerServiceCardIsAboutToExpire'),
            loading: LoadingComponent
        })
    }
];
