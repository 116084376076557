import {
    COUNTRY_DELETE,
    COUNTRY_DETAIL,
    COUNTRY_INSERT,
    COUNTRY_LIST,
    COUNTRY_RESPONSE,
    COUNTRY_UPDATE,
    COUNTRY_GETALL
} from './action-types';

export function list(payload) {
    return {
        type: COUNTRY_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: COUNTRY_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: COUNTRY_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: COUNTRY_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: COUNTRY_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: COUNTRY_RESPONSE,
        payload
    };
}
export function suggestion(payload) {
    return {
        type: COUNTRY_GETALL,
        payload
    };
}
