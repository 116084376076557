import { connect } from 'react-redux';
import { customerGetCodeRequest } from '../service';
import BaseCodeBox from '../../../base/components/BaseCodeBox';

class CustomerCodeBox extends BaseCodeBox {}

const mapStateToProps = (state) => {
    return {
        languages: state.appSettings.data.languages,
        code: state.customer.code
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCode: () => {
            return dispatch(customerGetCodeRequest());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCodeBox);
