import { NAILSPA_URL } from '../../constants/Config';
import Http from '../../utils/Http';
import { spinnerLoading } from '../../common/spinner/store/action';
import Transformer from '../../utils/Transformer';
import * as goodsNoteDetailActions from './store/actions';
import GoodsNoteDetailViewModel from './GoodsNoteDetailViewModel';

export function selectGoodsNoteDetail(goodsNoteDetailId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(goodsNoteDetailActions.select(goodsNoteDetailId));
            resolve();
        });
}

export function resetGoodsNoteDetailList() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(goodsNoteDetailActions.resetList());
            resolve();
        });
}

export function getAllGoodNoteDetail(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}goodsNoteDetails/${payload.goodsNotesId}`;

            Http.get(url)
                .then((res) => {
                    dispatch(
                        goodsNoteDetailActions.list(
                            res.data.map((detail) => new GoodsNoteDetailViewModel(detail, payload.languageId))
                        )
                    );
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        });
}

export function goodsNoteDetailInsertRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}goodsNoteDetails/${payload.goodsNotesId}`;
            const goodsNoteDetailInfo = Object.assign({}, payload);

            goodsNoteDetailInfo.note = JSON.stringify(payload.note);
            goodsNoteDetailInfo.unitName = JSON.stringify(payload.unitName);
            goodsNoteDetailInfo.discountNote = JSON.stringify(payload.discountNote);

            Http.post(url, goodsNoteDetailInfo)
                .then((res) => {
                    // dispatch(goodsNoteDetailActions.insert(new GoodsNoteDetailViewModel({ ...goodsNoteDetailInfo, id: res.data.data }, payload.languageId)));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error.response.data.message);
                });
        });
}

export function goodsNoteDetailUpdateRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));

            const url = `${NAILSPA_URL}goodsNoteDetails/${payload.goodsNotesId}/${payload.id}`;
            const goodsNoteDetailInfo = Object.assign({}, payload);
            goodsNoteDetailInfo.note = JSON.stringify(payload.note);
            goodsNoteDetailInfo.discountNote = JSON.stringify(payload.discountNote);

            // if (goodsNoteDetailInfo.discountByPercent) {
            //     goodsNoteDetailInfo.discountPrice =
            //         goodsNoteDetailInfo.discountPrice * 100;
            // }

            Http.put(url, goodsNoteDetailInfo)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        });
}

export function goodsNoteDetailDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}goodsNoteDetails/${payload.goodsNotesId}/${payload.id}`;

            Http.get(url, payload)
                .then((res) => {
                    dispatch(goodsNoteDetailActions.detail(res.data.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function goodsNoteDetailsDeleteRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}goodsNoteDetails/${payload.goodsNotesId}/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        });
}

export function goodsNoteDetailSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const data = {
                isPayment: payload.isPayment,
                keyword: payload.keyword,
                page: payload.page,
                pageSize: payload.pageSize,
                customerId: payload.customerId,
                warehouseId: payload.warehouseId,
                type: payload.type,
                objectType: payload.objectType
            };
            // if(payload.warehouseId) data.warehouseId = payload.warehouseId
            if (payload.startDate) data.startDate = payload.startDate;
            if (payload.endDate) data.endDate = payload.endDate;
            const url = `${NAILSPA_URL}goodsNoteDetails?${Transformer.convertToPramsURI(data)}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    // let goodsNote = Object.assign({}, res.data, { customerId: payload.customerId })
                    dispatch(goodsNoteDetailActions.search(res.data));
                    resolve();
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function updateGoodNoteDetailRemind(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}GoodsNoteDetails/update-remind/${payload.warehouseId}/${payload.goodNotesId}/${payload.id}/${payload.isRemind}?remindedNote=${payload.remindedNote}`;

            Http.put(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error.response.data.message);
                });
        });
}

export function getProductQuantityInStock(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}InventoryDailyReports/inventory-product/${payload.warehouseId}/${payload.productId}/${payload.unitId}?entryDate=${payload.entryDate}`;
            Http.get(url, payload)
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function getProductLotInStock(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}GoodsNoteDetails/lot/${payload.warehouseId}/productId?productId=${payload.productId}&entryDate=${payload.entryDate}`;

            Http.get(url, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function getProductCommission(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}GoodsNoteDetails/product-commission/${payload.goodsNotesId}/${payload.customerId}/${payload.productId}`;
            Http.get(url, payload)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}
