import React, { Component } from 'react';
import { TextArea } from 'devextreme-react';
import i18n from '../../../i18n';
import PropTypes from 'prop-types';

class ReadOnlyMultiLangAreaBox extends Component {
    render() {
        const { value, i18nLabel, currentLanguageActive } = this.props;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}</div>

                <div className={'dx-field-value'}>
                    <TextArea
                        className={'multi-language'}
                        name={'name'}
                        value={value ? value[currentLanguageActive] : ''}
                        readOnly={true}
                    />
                </div>
            </div>
        );
    }
}

ReadOnlyMultiLangAreaBox.propTypes = {
    i18nLabel: PropTypes.string,
    value: PropTypes.any,
    currentLanguageActive: PropTypes.string
};

export default ReadOnlyMultiLangAreaBox;
