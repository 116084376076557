import React, { Component } from 'react';
import DateBox from 'devextreme-react/date-box';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import PropTypes from 'prop-types';

class BirthdayBox extends Component {
    constructor(props) {
        super(props);

        this.validator = new ReeValidate({
            birthday: this.props.validatorValue ? this.props.validatorValue : null
        });

        this.state = {
            errors: this.validator.errors
        };

        if (this.props.value) {
            this.validator.validate('birthday', this.props.value).then(() => {
                this.checkValidate();
            });
        }

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    checkValidate() {
        const { field } = this.props;

        const formValidate = !this.validator.errors.has('birthday');
        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    onValueChanged(e) {
        const { errors } = this.validator;

        errors.remove('birthday');
        this.props.updateData({
            [this.props.field]: e.value
        });

        this.validator
            .validate('birthday', e.value)
            .then(() => {
                this.checkValidate();
            })
            .catch(() => {});
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.validator.validate('birthday', nextProps.value).then(() => {
                this.checkValidate();
            });
        }
    }

    render() {
        const {
            value,
            i18nLabel,
            type,
            displayFormat,
            maxZoomLevel,
            validatorValue,
            hiddenError,
            readOnly,
            interval,
            min,
            max
        } = this.props;

        return (
            <div className={'' + (this.validator.errors.has('birthday') ? ' has-error' : '')}>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {!readOnly && validatorValue && validatorValue.includes('require') && (
                            <span style={{ color: 'red' }}> *</span>
                        )}
                    </div>
                    <div className={'dx-field-value'}>
                        <DateBox
                            type={type || 'date'}
                            // pickerType="calendar"
                            useMaskBehavior={true}
                            placeholder={i18n.t(i18nLabel)}
                            onValueChanged={this.onValueChanged}
                            showClearButton={true}
                            displayFormat={displayFormat || 'dd/MM/yyyy'}
                            value={value}
                            min={min || null}
                            max={max || null}
                            interval={interval || null}
                            maxZoomLevel={maxZoomLevel || null}
                            readOnly={readOnly || false}
                        />

                        {this.validator.errors.has('birthday') && !hiddenError && (
                            <div className="help-block">
                                {this.validator.errors.first('birthday').replace('birthday', i18n.t(i18nLabel))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

BirthdayBox.propTypes = {
    value: PropTypes.any,
    field: PropTypes.string,
    updateData: PropTypes.func,
    editable: PropTypes.bool,
    i18nLabel: PropTypes.string,
    displayFormat: PropTypes.string,
    validatorValue: PropTypes.string,
    readOnly: PropTypes.bool,
    hiddenError: PropTypes.bool,
    min: PropTypes.any,
    max: PropTypes.any,
    type: PropTypes.string,
    maxZoomLevel: PropTypes.string,
    interval: PropTypes.number
};

export default BirthdayBox;
