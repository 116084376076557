export const COUNTRY_LIST = 'COUNTRY_LIST';
export const COUNTRY_DETAIL = 'COUNTRY_DETAIL';
export const COUNTRY_UPDATE = 'COUNTRY_UPDATE';
export const COUNTRY_INSERT = 'COUNTRY_INSERT';
export const COUNTRY_DELETE = 'COUNTRY_DELETE';
export const COUNTRY_RESPONSE = 'COUNTRY_RESPONSE';
export const COUNTRY_GETALL = 'COUNTRY_GETALL';
export default {
    COUNTRY_DELETE,
    COUNTRY_DETAIL,
    COUNTRY_INSERT,
    COUNTRY_LIST,
    COUNTRY_UPDATE,
    COUNTRY_GETALL
};
