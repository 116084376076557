import React, { Component } from 'react';
import Tabs from 'devextreme-react/tabs';

class LangTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabs: this.props.languages.map((item, index) => ({
                id: index,
                text: item.name,
                languageId: item.languageId
            }))
        };

        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
        this.setFocusTab = this.setFocusTab.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.languages !== this.props.languages) {
            const tabs = nextProps.languages.map((item, index) => ({
                id: index,
                text: item.name,
                languageId: item.languageId
            }));

            this.setState({ tabs });
        }
    }

    onTabsSelectionChanged(args) {
        const { languages } = this.props;
        if (args.name === 'selectedIndex' && args.value !== -1) {
            this.props.updateLanguage({
                selectedIndex: args.value,
                currentLanguageActive: languages[args.value].languageId
            });
        }
    }

    setFocusTab() {
        const tabs = document.getElementsByClassName('dx-tab');
        for (const tab of tabs) {
            tab.classList.remove('set-focus-tab', 'dx-theme-accent-as-border-color');
        }
        const selectedTab = document.getElementsByClassName('dx-tab-selected');
        selectedTab[0].classList.add('set-focus-tab', 'dx-theme-accent-as-border-color');
    }

    render() {
        const { selectedIndex } = this.props;
        const { tabs } = this.state;

        return (
            <div className="p-tab-lang">
                <Tabs
                    className={'tab-lang-style mb-3 dx-theme-background-color'}
                    dataSource={tabs}
                    selectedIndex={selectedIndex}
                    onOptionChanged={this.onTabsSelectionChanged}
                    onItemClick={this.setFocusTab}
                />
            </div>
        );
    }
}

export default LangTabs;
