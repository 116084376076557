import React, { Component } from 'react';
import { NumberBox, RadioGroup } from 'devextreme-react';
import i18n from '../../i18n';
import { DISCOUNT } from '../../constants/Config';
import PropTypes from 'prop-types';

class DiscountBox extends Component {
    constructor(props) {
        super(props);

        this.onHandleValueChange = this.onHandleValueChange.bind(this);
    }

    onHandleValueChange(e, field) {
        const { discountByPercent, price } = this.props;

        this.props.updateData({
            [field]: e.value
        });

        if (field === 'discountPrice') {
            this.props.updateData({
                formValidate: { [field]: !(!discountByPercent && price < e.value) }
            });
        }
    }

    render() {
        const { discountByPercent, price, readOnly, usedPromotion, discountPrice } = this.props;
        return (
            <div
                className={
                    'dx-field' + (!discountByPercent && price < discountPrice && !usedPromotion ? ' has-error' : '')
                }
            >
                <div className={'dx-field-label'}>{i18n.t('general.form.discounts')}:</div>
                <div className={'dx-field-value'}>
                    <div className={'row'}>
                        <div className="col-7">
                            <RadioGroup
                                items={DISCOUNT}
                                value={discountByPercent ? 1 : 0}
                                readOnly={readOnly}
                                layout={'horizontal'}
                                className="col pl-2"
                                onValueChanged={(e) => this.onHandleValueChange(e, 'discountByPercent')}
                                displayExpr={'name'}
                                valueExpr={'id'}
                            />
                        </div>
                        <div className="col-5">
                            {discountByPercent ? (
                                <NumberBox
                                    readOnly={readOnly}
                                    max={100}
                                    min={0}
                                    className="mr-2"
                                    value={discountPrice}
                                    onValueChanged={(e) => this.onHandleValueChange(e, 'discountPrice')}
                                />
                            ) : (
                                <NumberBox
                                    format="#,##0.## ₫"
                                    className="mr-2"
                                    min={0}
                                    max={price}
                                    value={discountPrice}
                                    readOnly={readOnly}
                                    onValueChanged={(e) => this.onHandleValueChange(e, 'discountPrice')}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {!discountByPercent && price < discountPrice && !usedPromotion && (
                    <div className="help-block">{'Giảm giá không được lớn hơn thành tiền. Vui lòng kiểm tra lại'}</div>
                )}
            </div>
        );
    }
}

DiscountBox.propTypes = {
    usedPromotion: PropTypes.bool,
    readOnly: PropTypes.bool,
    updateData: PropTypes.func,
    discountByPercent: PropTypes.bool,
    price: PropTypes.number,
    discountPrice: PropTypes.number
};

export default DiscountBox;
