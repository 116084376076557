export const enB = {
    branch: {
        list: {
            title: 'List of branches',
            email: 'Email',
            hotline: 'Hotline',
            Note: 'Notes'
        },
        userList: { title: 'List of Branch employees' },
        form: {
            insertTitle: 'Add new Branch',
            updateTitle: 'Edit Branches'
        }
    },
    base: {
        selectImg: 'Select Photo',
        removeImg: 'Delete Photo',
        order: 'Order',
        selectCV: 'Select CV',
        selectJD: 'Select JD',
        remove: 'Delete'
    },
    brand: {
        list: { title: 'Trademark list' }
    },
    booking: {
        Bookinginfo: 'Appointment information',
        AppointmentTimeRequire: 'Invalid appointment time',
        BookingServiceRequire: 'Invalid appointment service',
        createNote: 'Create a vote to use the service',
        EditBooking: 'Edit appointment',
        Joins: 'Participating staff',
        SaveBooking: 'Save'
    }
};
