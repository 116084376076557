import { PAGE_SIZE } from '../../../../constants/Config';
import {
    ACCOUNT_DELETE,
    ACCOUNT_DETAIL,
    ACCOUNT_INSERT,
    ACCOUNT_LIST,
    ACCOUNT_UPDATE,
    ACCOUNT_CHANGE_PASSWORD,
    ACCOUNT_RESET_PASSWORD,
    ACCOUNT_GETALL_EMPLOYEE
} from './action-type';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: null,
    employee: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ACCOUNT_LIST:
            return list(state, payload);
        case ACCOUNT_DETAIL:
            return detail(state, payload);
        case ACCOUNT_UPDATE:
            return update(state, payload);
        case ACCOUNT_INSERT:
            return insert(state, payload);
        case ACCOUNT_DELETE:
            return deletes(state, payload);
        case ACCOUNT_CHANGE_PASSWORD:
            return changePassword(state, payload);
        case ACCOUNT_RESET_PASSWORD:
            return resetPassword(state, payload);
        case ACCOUNT_GETALL_EMPLOYEE:
            return getAllEmployee(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function changePassword(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function resetPassword(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function getAllEmployee(state, payload) {
    return Object.assign({}, state, { employee: payload });
}

export default reducer;
