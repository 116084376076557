export const INVENTORY_LIST = 'INVENTORY_LIST';
export const INVENTORY_LIST_PRODUCT = 'INVENTORY LIST PRODUCT';
export const LIST_COUNTER = 'LIST COUNTER';
export const INVENTORY_DELETE = 'INVENTORY_DELETE';
export const INVENTORY_DETAIL = 'INVENTORY_DETAIL';
export const UPDATE_ITEM_PRODUCT = 'UPDATE_ITEM_PRODUCT';
export const INVENTORY_LIST_NEW = 'INVENTORY_LIST_NEW';
export default {
    INVENTORY_LIST,
    INVENTORY_LIST_PRODUCT,
    LIST_COUNTER,
    INVENTORY_DELETE,
    INVENTORY_DETAIL,
    UPDATE_ITEM_PRODUCT,
    INVENTORY_LIST_NEW
};
