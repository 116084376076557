import { GOODS_NOTE_LIST, GOODS_NOTE_DETAIL, GOODS_NOTE_UPDATE } from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    item: {}
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GOODS_NOTE_LIST:
            return list(state, payload);
        case GOODS_NOTE_DETAIL:
            return detail(state, payload);
        case GOODS_NOTE_UPDATE:
            return update(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

export const getGoodNotesList = (state) => state.goodsNote.data;

export default reducer;
