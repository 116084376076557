export const PROVINCE_LIST = 'PROVINCE_LIST';
export const PROVINCE_DETAIL = 'PROVINCE_DETAIL';
export const PROVINCE_UPDATE = 'PROVINCE_UPDATE';
export const PROVINCE_INSERT = 'PROVINCE_INSERT';
export const PROVINCE_DELETE = 'PROVINCE_DELETE';
export const PROVINCE_RESPONSE = 'PROVINCE_RESPONSE';
export const PROVINCE_GETALL = 'PROVINCE_GETALL';
export const PROVINCE_BY_COUNTRY = 'PROVINCE_BY_COUNTRY';
export default {
    PROVINCE_DELETE,
    PROVINCE_DETAIL,
    PROVINCE_INSERT,
    PROVINCE_LIST,
    PROVINCE_UPDATE,
    PROVINCE_RESPONSE,
    PROVINCE_GETALL,
    PROVINCE_BY_COUNTRY
};
