import React, { Component } from 'react';
import i18n from '../../i18n';
import { SelectBox } from 'devextreme-react';
import { GENDER } from '../../constants/Config';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';
import PropTypes from 'prop-types';

class GenderSelectBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: {},
            payload: {}
        };

        this.validator = new ReeValidate({
            gender: this.props.validatorValue
        });
        if (this.props.currentLanguage === 'vi-VN') {
            this.validator.localize('vi', vi);
        } else {
            this.validator.localize('en', en);
        }
        this.onDataChanged = this.onDataChanged.bind(this);
    }

    componentDidMount() {
        this.handleLanguage();
    }

    handleLanguage = () => {
        const { languages } = this.props;

        let datas = [];

        languages.forEach((element) => {
            const newArray = [];

            for (const item of GENDER) {
                const i = Object.assign({}, item);
                const nameParse = i.name ? JSON.parse(i.name) : '';

                i.name = nameParse[element.languageId];
                newArray.push(i);
            }

            datas = { ...datas, [element.languageId]: newArray };
        });

        this.setState({
            datas
        });
    };

    /**
     * Handle Data Change.
     *
     * @inheritdoc
     */
    onDataChanged(e) {
        const { field } = this.props;
        this.props.updateData({
            [field]: e.value
        });

        this.validator
            .validate('gender', e.value)
            .then(() => {
                this.checkValidate();
            })
            .catch(() => {});
        // if (e.value !== null) {
        //     const { field } = this.props;

        //     this.props.updateData({
        //         [field]: e.value
        //     });
        // }
        if (e.value === null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: ''
            });
        }
    }

    checkValidate() {
        const { field } = this.props;
        const formValidate = !this.validator.errors.has('gender');
        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    render() {
        const { currentLanguageActive, value, i18nLabel, validatorValue } = this.props;
        const { datas } = this.state;

        return (
            <div className={'' + (this.validator.errors.has('gender') ? ' has-error' : '')}>
                {/* <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}<span className="color-red"> *</span></div> */}
                <div className={'dx-field-label'}>
                    {i18n.t(i18nLabel) + ':'}
                    {validatorValue && validatorValue.includes('require') && <span className="color-red"> *</span>}
                </div>
                <div className={'dx-field-value'}>
                    <SelectBox
                        searchEnabled={true}
                        items={datas[currentLanguageActive]}
                        value={value}
                        valueExpr={'id'}
                        displayExpr={'name'}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={true}
                        onValueChanged={this.onDataChanged}
                    />

                    {this.validator.errors.has('gender') && (
                        <div className="help-block">
                            {this.validator.errors.first('gender').replace('gender', i18n.t(i18nLabel))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

GenderSelectBox.propTypes = {
    currentLanguageActive: PropTypes.any,
    value: PropTypes.any,
    i18nLabel: PropTypes.any,
    validatorValue: PropTypes.any,
    updateData: PropTypes.any,
    field: PropTypes.any,
    currentLanguage: PropTypes.any,
    languages: PropTypes.any
};

export default GenderSelectBox;
