import { PAGE_SIZE } from '../../../constants/Config';
import {
    REPORT_DELETE,
    REPORT_DETAIL,
    REPORT_INSERT,
    REPORT_LIST,
    REPORT_UPDATE,
    REPORT_GET_ALL,
    REPORT_TOTAL_REVENUE,
    REPORT_FIRST_TIME,
    REPORT_CUSTOMER_POINTS,
    REPORT_SERVICE_APPOIMENT,
    REPORT_STAFF_APPOIMENT,
    REPORT_COUNT_SERVICES_PRODUCTS,
    REPORT_CUSTOMER_SERVICE_DETAIL,
    REPORT_CUSTOMER,
    REPORT_WAREHOUSE_CARD,
    REPORT_EXPORT_IMPORT,
    REPORT_PRODUCT_INVENTORY,
    REPORT_PRODUCT_INVENTORY_DETAIL,
    REPORT_USER,
    REPORT_USER_DETAIL,
    REPORT_USER_DETAIL_RESET,
    GET_DETAIL_PAYMENT_REPORT,
    REPORT_SERVICE_PRODUCT,
    REPORT_CUSTOMER_CARD,
    REPORT_PRODUCT_GROUPS,
    REPORT_BOOKING_ALL_BRANCH,
    REPORT_USER_ALL_BRANCH,
    REPORT_CUSTOMER_NO_COME,
    REPORT_CALENDAR_REMINDER,
    REPORT_CUSTOMER_CARD_IS_ABOUT_TO_EXPIRE,
    REPORT_CUSTOMER_SERVICE_CARD_IS_ABOUT_TO_EXPIRE,
    FEEDBACK_INSERT,
    REPORT_CUSTOMER_CARE
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {
        data: [],
        totlaRows: 0
    },
    suggestion: {
        data: [],
        totalRows: 0
    },
    totalRevenue: [],
    firstTime: [],
    customerPoints: [],
    serviceAppointment: [],
    staffAppointment: [],
    totalRowsCP: 0,
    totalRowsSA: 0,
    totalRowsStA: 0,
    countServicesProducts: [],
    totalRowsCountSnP: 0,
    customerServiceDetail: [],
    totalRowsCSD: 0,
    customer: [],
    totalRowsC: 0,
    warehouseCard: [],
    totalRowsWC: 0,
    importExport: [],
    totalRowsIE: 0,
    productInventory: [],
    totalRowsPI: 0,
    productInventoryDetail: [],
    totalRowsPID: 0,
    user: [],
    totalRowsUser: 0,
    userDetail: {
        data: [],
        totalRows: 0
    },
    totalRowsUD: 0,
    detailPayment: {
        data: [],
        totalRows: 0
    },
    productGroups: {
        data: [],
        totalRows: 0
    },
    bookingAllBranch: {
        data: [],
        totalRows: 0
    },
    userAllBranch: {
        data: [],
        totalRows: 0
    },
    customerNoCome: {
        data: [],
        totalRows: 0
    },
    customerCare: {
        data: [],
        totalRows: 0,
        totalData: 0
    },
    calendarReminder: {
        data: [],
        totalRows: 0
    },
    customerCardIsAboutToExpire: {
        data: [],
        totalRows: 0
    },
    customerServiceCardIsAboutToExpire: {
        data: [],
        totalRows: 0
    },
    productGroupDetail: [],
    serviceProduct: [],
    customerCard: [],
    totalRowsCC: 0,
    totalData: {}
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_DETAIL_PAYMENT_REPORT:
            return getDetailPaymentReport(state, payload);
        case REPORT_LIST:
            return list(state, payload);
        case REPORT_DETAIL:
            return detail(state, payload);
        case REPORT_UPDATE:
            return update(state, payload);
        case REPORT_INSERT:
            return insert(state, payload);
        case REPORT_DELETE:
            return deletes(state, payload);
        case REPORT_GET_ALL:
            return suggestion(state, payload);
        case REPORT_TOTAL_REVENUE:
            return totalRevenue(state, payload);
        case REPORT_FIRST_TIME:
            return firstTime(state, payload);
        case REPORT_CUSTOMER_POINTS:
            return customerPoints(state, payload);
        case REPORT_SERVICE_APPOIMENT:
            return serviceAppointment(state, payload);
        case REPORT_STAFF_APPOIMENT:
            return staffAppointment(state, payload);
        case REPORT_COUNT_SERVICES_PRODUCTS:
            return {
                ...state,
                countServicesProducts: payload.data,
                totalRowsCountSnP: payload.totalRows,
                totalData: payload.totalData
            };
        case REPORT_CUSTOMER_SERVICE_DETAIL:
            return customerServiceDetail(state, payload);
        case REPORT_CUSTOMER:
            return customer(state, payload);
        case REPORT_WAREHOUSE_CARD:
            return warehouseCard(state, payload);
        case REPORT_EXPORT_IMPORT:
            return exportImport(state, payload);
        case REPORT_PRODUCT_INVENTORY:
            return productInventory(state, payload);
        case REPORT_PRODUCT_INVENTORY_DETAIL:
            return productInventoryDetail(state, payload);
        case REPORT_USER:
            return user(state, payload);
        case REPORT_USER_DETAIL:
            return userDetail(state, payload);
        case REPORT_USER_DETAIL_RESET:
            return userDetailReset(state, payload);
        case REPORT_SERVICE_PRODUCT:
            return totalRevenueSeriveAndProduct(state, payload);
        case REPORT_CUSTOMER_CARD:
            return customerCard(state, payload);
        case REPORT_PRODUCT_GROUPS:
            return productGroups(state, payload);
        case REPORT_BOOKING_ALL_BRANCH:
            return bookingAllBranch(state, payload);
        case REPORT_USER_ALL_BRANCH:
            return userAllBranch(state, payload);
        case REPORT_CUSTOMER_NO_COME:
            return customerNoCome(state, payload);
        case REPORT_CALENDAR_REMINDER:
            return calendarReminder(state, payload);
        case REPORT_CUSTOMER_CARD_IS_ABOUT_TO_EXPIRE:
            return customerCardIsAboutToExpire(state, payload);
        case REPORT_CUSTOMER_SERVICE_CARD_IS_ABOUT_TO_EXPIRE:
            return customerServiceCardIsAboutToExpire(state, payload);
        case FEEDBACK_INSERT:
            return feedbackInsert(state, payload);
        case REPORT_CUSTOMER_CARE:
            return customerCare(state, payload);
        default:
            return state;
    }
};

function customerCare(state, payload) {
    state = Object.assign({}, state, { customerCare: payload });
    return state;
}

function getDetailPaymentReport(state, payload) {
    state = Object.assign({}, state, { detailPayment: payload });
    return state;
}

function productGroups(state, payload) {
    state = Object.assign({}, state, { productGroups: payload });
    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: { ...payload },
        totalData: payload.totalData
    });
    return state;
}

function totalRevenue(state, payload) {
    state = Object.assign({}, state, {
        totalRevenue: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);
    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function firstTime(state, payload) {
    state = Object.assign({}, state, {
        firstTime: payload
    });
    return state;
}

function customerPoints(state, payload) {
    state = Object.assign({}, state, {
        customerPoints: payload.data,
        totalRowsCP: payload.totalRows,
        totalData: payload.totalData
    });
    return state;
}
function serviceAppointment(state, payload) {
    state = Object.assign({}, state, {
        serviceAppointment: payload.data,
        totalRowsSA: payload.totalRows
    });
    return state;
}
function staffAppointment(state, payload) {
    state = Object.assign({}, state, {
        staffAppointment: payload.data,
        totalRowsStA: payload.totalRows
    });
    return state;
}

function customerServiceDetail(state, payload) {
    state = Object.assign({}, state, {
        customerServiceDetail: payload.data,
        totalRowsCSD: payload.totalRows
    });
    return state;
}

function customer(state, payload) {
    state = Object.assign({}, state, {
        customer: payload.data,
        totalRowsC: payload.totalRows
    });
    return state;
}

function warehouseCard(state, payload) {
    state = Object.assign({}, state, {
        warehouseCard: payload.data,
        totalRowsWC: payload.totalRows
    });
    return state;
}

function exportImport(state, payload) {
    state = Object.assign({}, state, {
        importExport: payload.data,
        totalRowsIE: payload.totalRows
    });
    return state;
}

function productInventory(state, payload) {
    state = Object.assign({}, state, {
        productInventory: payload.data,
        totalRowsPI: payload.totalRows
    });
    return state;
}

function productInventoryDetail(state, payload) {
    state = Object.assign({}, state, {
        productInventoryDetail: payload.data,
        totalRowsPID: payload.totalRows
    });
    return state;
}
function user(state, payload) {
    state = Object.assign({}, state, {
        user: payload.data,
        totalRowsUser: payload.totalRows,
        totalData: payload.totalData
    });
    return state;
}
function userDetail(state, payload) {
    state = Object.assign({}, state, {
        userDetail: payload,
        totalData: payload.totalData
    });
    return state;
}

function userDetailReset(state, payload) {
    state = Object.assign({}, state, {
        userDetail: {
            data: [],
            totalRows: 0
        },
        totalRowsUD: 0
    });
    return state;
}

function totalRevenueSeriveAndProduct(state, payload) {
    state = Object.assign({}, state, {
        serviceProduct: payload
    });
    return state;
}

function customerCard(state, payload) {
    state = Object.assign({}, state, {
        customerCard: payload.data,
        totalRowsCC: payload.totalRows
    });
    return state;
}

function bookingAllBranch(state, payload) {
    state = Object.assign({}, state, { bookingAllBranch: payload });
    return state;
}

function userAllBranch(state, payload) {
    state = Object.assign({}, state, { userAllBranch: payload });
    return state;
}

function customerNoCome(state, payload) {
    state = Object.assign({}, state, { customerNoCome: payload });
    return state;
}

function calendarReminder(state, payload) {
    state = Object.assign({}, state, { calendarReminder: payload });
    return state;
}

function customerCardIsAboutToExpire(state, payload) {
    state = Object.assign({}, state, { customerCardIsAboutToExpire: payload });
    return state;
}

function customerServiceCardIsAboutToExpire(state, payload) {
    state = Object.assign({}, state, { customerServiceCardIsAboutToExpire: payload });
    return state;
}

function feedbackInsert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}
export default reducer;
