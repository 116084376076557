export const BRAND_LIST = 'BRAND_LIST';
export const BRAND_DETAIL = 'BRAND_DETAIL';
export const BRAND_UPDATE = 'BRAND_UPDATE';
export const BRAND_INSERT = 'BRAND_INSERT';
export const BRAND_DELETE = 'BRAND_DELETE';
export const BRAND_RESPONSE = 'BRAND_RESPONSE';
export const BRAND_GETALL = 'BRAND_GETALL';
export default {
    BRAND_DELETE,
    BRAND_DETAIL,
    BRAND_INSERT,
    BRAND_LIST,
    BRAND_UPDATE,
    BRAND_GETALL
};
