import { viW } from './vi_w';
import { viU } from './vi_u';
import { viA } from './vi_a';
import { viB } from './vi_b';
import { viC } from './vi_c';
import { viD } from './vi_d';
import { viP } from './vi_p';
import { viS } from './vi_s';
import { viGeneral } from './vi_general';
import { viF } from './vi_f';
import { viR } from './vi_r';
import { viG } from './vi_g';
import { viN } from './vi_n';
import { viI } from './vi_i';

export const i18nVi = {
    translation: {
        ...viA,
        ...viB,
        ...viC,
        ...viD,

        // F
        ...viF,
        // G
        ...viG,

        // I
        ...viI,

        // J
        job: {
            list: { title: 'Danh sách Nghề Nghiệp' }
        },

        // M
        message: {
            deleteForm: 'Sau khi xóa danh mục sẽ không sử dụng ở các mục liên quan',
            deleteConfirm: 'Bạn có chắc chắn muốn xóa mục này?',
            accept: 'Chấp nhận',
            decline: 'Hủy bỏ',
            loading: 'Đang tải dữ liệu. Vui lòng đợi...',
            emptyPanelText: 'Kéo cột muốn group vào đây',
            findCustomer: 'Khách hàng được tìm thấy',
            has: 'Có',
            alert: 'Thông báo',
            changeStatusConfirmTrue: 'Thẻ này đã ngừng hoạt động. Bạn có chắc chắn đổi thẻ này về trạng thái hoạt động',
            changeStatusConfirmFalse:
                'Thẻ này đang được sử dụng. Bạn có chắc chắn đổi thẻ này về trạng thái không hoạt động',
            wrongAccOrPass: 'Sai tài khoản hoặc mật khẩu',
            lockAcc: 'Tài khoản bị khoá',
            notExistAcc: 'Tài khoản không tồn tại'
        },

        ...viN,
        tenant: {
            list: {
                domain: 'Domain',
                note: 'Ghi chú',
                title: 'Danh sách Khách Hàng',
                info: 'Thông tin khách hàng'
            }
        },

        ...viP,

        // R
        ...viR,

        // S
        ...viS,

        // T
        title: {
            list: { title: 'Danh sách Chức danh' }
        },

        ...viU,

        ...viW,

        ...viGeneral,

        titlePage: {
            Calendar: 'Lịch làm việc',
            Home: 'Trang chủ',
            Spainformation: 'Thông tin spa',
            Listregions: 'Danh sách khu vực',
            Countrylists: 'Danh sách quốc gia',
            Listsprovincescities: 'Danh sách tỉnh/thành',
            DistrictList: 'Danh sách quận/huyện',
            EthnicList: 'Danh sách dân tộc',
            ReligiousList: 'Danh sách tôn giáo',
            Listpaymentmethods: 'Danh sách phương thức thanh toán',
            Guestsourcelist: 'Danh sách nguồn khách',
            Careerlist: 'Danh sách nghề nghiệp',
            pagelist: 'Danh sách trang',
            configuration: 'Cấu hình',
            OccupationList: 'Danh sách nghề nghiệp',
            customerlists: 'Danh sách khách hàng',
            permissionlists: 'Danh sách nhóm quyền',
            permissions: 'Phân quyền',
            CustomerManagement: 'Quản lý khách hàng',
            CustomerAppointment: 'Lịch hẹn khách hàng',
            Makeappointment: 'Đặt lịch hẹn',
            Listcustomercards: 'Danh sách thẻ khách hàng',
            Listcustomercoupons: 'Danh sách phiếu khách hàng',
            CustomerVoucher: 'Phiếu khách hàng',
            RequestList: 'Danh sách phiếu yêu cầu',
            AccountList: 'Danh sách tài khoản',
            StaffList: 'Danh sách nhân viên',
            StaffManagement: 'Quản lý nhân viên',
            Listtitles: 'Danh sách chức danh',
            Jobtitlemanagement: 'Quản lý chức danh',
            Joblist: 'Danh sách chức vụ',
            Jobmanager: 'Quản lý chức vụ',
            DepartmentList: 'Danh sách phòng ban',
            ServiceList: 'Danh sách dịch vụ',
            Servicegrouplist: 'Danh sách nhóm dịch vụ',
            Servicecardlist: 'Danh sách dịch vụ trong thẻ',
            Servicemanagement: 'Quản lý dịch vụ',
            FileManagement: 'Quản lý tài liệu',
            ProductList: 'Danh sách sản phẩm',
            ProductManagement: 'Quản lý sản phẩm',
            Productgrouplist: 'Danh sách nhóm sản phẩm',
            Unitlist: 'Danh sách đơn vị',
            Promotionlist: 'Danh sách khuyến mại',
            Promotionmanagement: 'Quản lý khuyến mại',
            pointCustomer: 'Danh sách khách hàng điểm',
            CustomerBill: 'Lịch sử hóa đơn khách hàng'
        },
        titlePopup: {
            Addnewguestsource: 'Thêm mới nguồn khách',
            Editguestsource: 'Chỉnh sửa nguồn khách',
            Addnewpayments: 'Thêm mới phương thức thanh toán',
            Editpayments: 'Chỉnh sửa phương thức thanh toán',
            Addnewtitle: 'Thêm mới chức danh',
            Edittitle: 'Chỉnh sửa chức danh',
            Addnewposition: 'Thêm mới chức vụ',
            Editposition: 'Chỉnh sửa chức vụ',
            Addnewdistrict: 'Thêm mới quận / huyện',
            Editdistrict: 'Chỉnh sửa quận / huyện',
            Addnewcountry: 'Thêm mới quốc gia',
            Editcountry: 'Chỉnh sửa quốc gia',
            Addnewoccupation: 'Thêm mới nghề nghiệp',
            Editoccupation: 'Chỉnh sửa nghề nghiệp',
            Addnewethnicity: 'Thêm mới dân tộc',
            Editethnicity: 'Chỉnh sửa dân tộc',
            Addnewprovince: 'Thêm mới Tỉnh/ Thành phố',
            Editprovince: 'Chỉnh sửa Tỉnh/ Thành phố',
            Addnewreligion: 'Thêm mới tôn giáo',
            Editreligion: 'Chỉnh sửa tôn giáo',
            Addnewbrand: 'Thêm mới nhãn hiệu',
            Editbrand: 'Chỉnh sửa nhãn hiệu',
            PaidDetail: 'Thông tin phiếu',
            Addnewbooking: 'Thêm mới lịch đặt hẹn',
            Editbooking: 'Chỉnh sửa lịch đặt hẹn',
            AddNewPermissionGroup: 'Thêm mới nhóm quyền',
            paymentDetail: 'Chi tiết thanh toán',
            serviceDetail: 'Chi tiết dịch vụ',
            AddnewpromotionCard: 'Thêm mới chương trình ưu đãi của thẻ',
            EditpromotionCard: 'Chỉnh sửa khuyến mại thẻ',
            AddnewpromotionCardDetail: 'Thêm mới chi tiết khuyến mại thẻ',
            EditpromotionCardDetail: 'Chỉnh sửa chi tiết khuyến mại thẻ',
            AddNewProduct: 'Thêm mới sản phẩm',
            UpdateProduct: 'Cập nhật sản phẩm',
            prepaidCard: 'Nhập số dư cũ thẻ trả trước',
            serviceCardOld: 'Nhập số dư cũ thẻ dịch vụ',
            serviceCard: 'Nhập số dư cũ thẻ dịch vụ'
        }
    }
};
