export const SUPPLIER_LIST = 'SUPPLIER_LIST';
export const SUPPLIER_DETAIL = 'SUPPLIER_DETAIL';
export const SUPPLIER_UPDATE = 'SUPPLIER_UPDATE';
export const SUPPLIER_INSERT = 'SUPPLIER_INSERT';
export const SUPPLIER_DELETE = 'SUPPLIER_DELETE';
export const SUPPLIER_RESPONSE = 'SUPPLIER_RESPONSE';
export const SUPPLIER_GETALL = 'SUPPLIER_GETALL';
export default {
    SUPPLIER_DELETE,
    SUPPLIER_DETAIL,
    SUPPLIER_INSERT,
    SUPPLIER_LIST,
    SUPPLIER_UPDATE,
    SUPPLIER_GETALL
};
