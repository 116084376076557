import React, { Component } from 'react';
import i18n from '../../i18n';
import { Button } from 'devextreme-react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class SubmitButton extends Component {
    render() {
        const { formValidate, handleSubmit, isLoading } = this.props;

        return (
            <Button
                className={'button-info mx-1 dx-theme-accent-as-background-color'}
                text={i18n.t('general.button.save')}
                icon="save"
                width={120}
                disabled={_.includes(Object.values(formValidate), false)}
                onClick={!isLoading ? handleSubmit : () => console.log('on click')}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { isLoading } = state.spinner;
    const { userSettings, languages } = state.appSettings.data;

    const currentLanguage = _.find(userSettings, (item) => item.key === 'LanguageId');

    return {
        isLoading,
        languages,
        currentLanguage: currentLanguage.value,
        customerDetail: state.customer.item
    };
};

SubmitButton.propTypes = {
    formValidate: PropTypes.object,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool
};

export default connect(mapStateToProps, null)(SubmitButton);
