import { PAGE_SIZE } from '../../../constants/Config';
import {
    INVENTORY_LIST,
    INVENTORY_LIST_PRODUCT,
    LIST_COUNTER,
    INVENTORY_DELETE,
    INVENTORY_DETAIL,
    UPDATE_ITEM_PRODUCT,
    INVENTORY_LIST_NEW
} from './action-types';

const initialState = {
    data: [],
    productList: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {},
    suggestion: [],
    totalRevenue: [],
    firstTime: [],
    customerPoints: [],
    serviceAppointment: [],
    staffAppointment: [],
    totalRowsCP: 0,
    totalRowsSA: 0,
    totalRowsStA: 0,
    countServicesProducts: [],
    totalRowsCountSnP: 0,
    customerServiceDetail: [],
    totalRowsCSD: 0,
    customer: [],
    totalRowsC: 0,
    totalRowsPL: 0,
    listCounter: [],
    listProductNew: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case INVENTORY_LIST:
            return list(state, payload);
        case INVENTORY_DETAIL:
            return detail(state, payload);
        case INVENTORY_DELETE:
            return deletes(state, payload);
        case INVENTORY_LIST_PRODUCT:
            return listProduct(state, payload);
        case LIST_COUNTER:
            return listCounter(state, payload);
        case UPDATE_ITEM_PRODUCT:
            return updateItemProduct(state, payload);
        case INVENTORY_LIST_NEW:
            return listProductNew(state, payload);
        default:
            return state;
    }
};

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload,
        productList: payload.inventoryProduct
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function listProduct(state, payload) {
    state = Object.assign({}, state, {
        productList: payload
    });
    return state;
}

function listCounter(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function updateItemProduct(state, payload) {
    state = Object.assign({}, state, {
        productList: payload
    });
    return state;
}

function listProductNew(state, payload) {
    state = Object.assign({}, state, {
        listProductNew: payload
    });
    return state;
}

export default reducer;
