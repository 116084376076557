import { MARRIED_STATUS } from '../../../constants/Config';
import BaseReadOnlyConstants from '../../components/BaseReadOnlyConstants';

class ReadOnlyMarriedStatus extends BaseReadOnlyConstants {
    constructor(props) {
        super(props);

        this.CONSTANTS = MARRIED_STATUS;
    }
}

export default ReadOnlyMarriedStatus;
