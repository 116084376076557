export const CUSTOMER_CARD_LIST = 'CUSTOMER_CARD_LIST';
export const CUSTOMER_CARD_UPDATE = 'CUSTOMER_CARD_UPDATE';
export const CUSTOMER_CARD_INSERT = 'CUSTOMER_CARD_INSERT';
export const CUSTOMER_CARD_GET_ALL = 'CUSTOMER_CARD_GET_ALL';
export const CUSTOMER_CARD_DELETE = 'CUSTOMER_CARD_DELETE';
export const CUSTOMER_CARD_DETAIL = 'CUSTOMER_CARD_DETAIL';
export const CUSTOMER_CARD_SELECT_ID = 'CUSTOMER_CARD_SELECT_ID';
export const CUSTOMER_CARD_LIST_BY_CUSTOMER = 'CUSTOMER_CARD_LIST_BY_CUSTOMER';
export const CUSTOMER_CARD_HISTORY = 'CUSTOMER_CARD_HISTORY';
export const CUSTOMER_CARD_GIFT = 'CUSTOMER_CARD_GIFT';
export default {
    CUSTOMER_CARD_INSERT,
    CUSTOMER_CARD_LIST,
    CUSTOMER_CARD_UPDATE,
    CUSTOMER_CARD_GET_ALL,
    CUSTOMER_CARD_DELETE,
    CUSTOMER_CARD_DETAIL,
    CUSTOMER_CARD_SELECT_ID,
    CUSTOMER_CARD_LIST_BY_CUSTOMER,
    CUSTOMER_CARD_HISTORY,
    CUSTOMER_CARD_GIFT
};
