export const viD = {
    // D
    district: {
        list: { title: 'Danh sách Quận/Huyện' }
    },
    department: {
        form: {
            infoTitle: 'Thông tin Phòng Ban',
            updateTitle: 'Chỉnh sửa Phòng Ban',
            insertTitle: 'Thêm mới Phòng Ban'
        },
        position: { title: 'Danh sách Chức vụ thuộc Phòng Ban' }
    }
};
