import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/customer-care',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer-care/card',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerRequestStatus/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer-report',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/new-customer-report',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./newCustomerReport/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer-birthday-report',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerBirthdayReport/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/appointment-reminder',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./AppoitmentReminder/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer-support-categories',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./customerSupportCategories/list'),
            loading: LoadingComponent
        })
    }
];
