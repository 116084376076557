export const WAREHOUSE_LIST = 'WAREHOUSE_LIST';
export const WAREHOUSE_DETAIL = 'WAREHOUSE_DETAIL';
export const WAREHOUSE_UPDATE = 'WAREHOUSE_UPDATE';
export const WAREHOUSE_INSERT = 'WAREHOUSE_INSERT';
export const WAREHOUSE_DELETE = 'WAREHOUSE_DELETE';
export const WAREHOUSE_RESPONSE = 'WAREHOUSE_RESPONSE';
export const WAREHOUSE_GETALL = 'WAREHOUSE_GETALL';
export default {
    WAREHOUSE_DELETE,
    WAREHOUSE_DETAIL,
    WAREHOUSE_INSERT,
    WAREHOUSE_LIST,
    WAREHOUSE_UPDATE,
    WAREHOUSE_GETALL
};
