export const SERVICE_NOTE_DETAIL_DETAIL = 'SERVICE_NOTE_DETAIL_DETAIL';
export const SERVICE_NOTE_DETAIL_INSERT = 'SERVICE_NOTE_DETAIL_INSERT';
export const SERVICE_NOTE_DETAIL_LIST = 'SERVICE_NOTE_DETAIL_LIST';
export const SERVICE_NOTE_DETAIL_INSERT_LIST = 'SERVICE_NOTE_DETAIL_INSERT_LIST';
export const SERVICE_NOTE_DETAIL_SELECT = 'SERVICE_NOTE_DETAIL_SELECT';
export const SERVICE_NOTE_DETAIL_RESET = 'SERVICE_NOTE_DETAIL_RESET';
export const SERVICE_NOTE_DETAIL_LIST_RESET = 'SERVICE_NOTE_DETAIL_LIST_RESET';
export const SERVICE_NOTE_DETAIL_UPDATE = 'SERVICE_NOTE_DETAIL_UPDATE';
export const SERVICE_NOTE_DETAIL_BY_CUSTOMER_LIST = 'SERVICE_NOTE_DETAIL_BY_CUSTOMER_LIST';
export const SERVICE_NOTE_STATUS = 'SERVICE_NOTE_STATUS';

export default {
    SERVICE_NOTE_DETAIL_DETAIL,
    SERVICE_NOTE_DETAIL_INSERT,
    SERVICE_NOTE_DETAIL_LIST,
    SERVICE_NOTE_DETAIL_SELECT,
    SERVICE_NOTE_DETAIL_RESET,
    SERVICE_NOTE_DETAIL_UPDATE,
    SERVICE_NOTE_DETAIL_BY_CUSTOMER_LIST,
    SERVICE_NOTE_DETAIL_LIST_RESET,
    SERVICE_NOTE_DETAIL_INSERT_LIST,
    SERVICE_NOTE_STATUS
};
