import {
    GOODS_NOTE_DETAIL_DELETE,
    GOODS_NOTE_DETAIL_DETAIL,
    GOODS_NOTE_DETAIL_INSERT,
    GOODS_NOTE_DETAIL_LIST,
    GOODS_NOTE_DETAIL_UPDATE,
    GOODS_NOTE_DETAIL_GET_ALL,
    GOODS_NOTE_DETAIL_SELECT,
    GOODS_NOTE_DETAIL_LIST_RESET,
    GOODS_NOTE_DETAIL_SEARCH
} from './action-types';

export function resetList() {
    return {
        type: GOODS_NOTE_DETAIL_LIST_RESET
    };
}

export function select(payload) {
    return {
        type: GOODS_NOTE_DETAIL_SELECT,
        payload
    };
}

export function list(payload) {
    return {
        type: GOODS_NOTE_DETAIL_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: GOODS_NOTE_DETAIL_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: GOODS_NOTE_DETAIL_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: GOODS_NOTE_DETAIL_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: GOODS_NOTE_DETAIL_INSERT,
        payload
    };
}

export function suggestion(payload) {
    return {
        type: GOODS_NOTE_DETAIL_GET_ALL,
        payload
    };
}
export function search(payload) {
    return {
        type: GOODS_NOTE_DETAIL_SEARCH,
        payload: payload || []
    };
}
