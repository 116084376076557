// import lib

import Loadable from 'react-loadable';
// import component

import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/service-card-promotion',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./list/index'),
            loading: LoadingComponent
        })
    }
];
