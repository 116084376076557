export const WEBSITE_SOCIAL_SEARCH = 'WEBSITE_SOCIAL_SEARCH';
export const WEBSITE_SOCIAL_INSERT = 'WEBSITE_SOCIAL_INSERT';
export const WEBSITE_SOCIAL_UPDATE = 'WEBSITE_SOCIAL_UPDATE';
export const WEBSITE_SOCIAL_DETAIL = 'WEBSITE_SOCIAL_DETAIL';

export default {
    WEBSITE_SOCIAL_SEARCH,
    WEBSITE_SOCIAL_INSERT,
    WEBSITE_SOCIAL_DETAIL,
    WEBSITE_SOCIAL_UPDATE
};
