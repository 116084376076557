import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.node.isRequired
};
const displayName = 'Public Layout';

function PublicLayout({ children }) {
    return (
        <div>
            <main>{children}</main>
        </div>
    );
}

PublicLayout.dispatch = displayName;
PublicLayout.propTypes = propTypes;

export default PublicLayout;
