import { NAILSPA_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import * as officeAction from './store/actions';
import { spinnerLoading } from '../../../common/spinner/store/action';

export function officeSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}offices?keyword=${payload.keyword}`;
            url += payload.isActive !== null ? `&isActive=${payload.isActive}` : '';
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(officeAction.list(res.data));
                    resolve();
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject();
                });
        });
}

export function officeSearchNameValid(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}offices?keyword=${payload.keyword}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function officeInsertRequest(office) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}offices`;
            const officeUpdate = Object.assign({}, office);
            officeUpdate.name = JSON.stringify(office.name);
            officeUpdate.description = JSON.stringify(office.description);

            Http.post(url, officeUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    officeUpdate.id = res.data.data;
                    dispatch(officeAction.insert(officeUpdate));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function officeUpdateRequest(office) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}offices/${office.id}`;
            const officeUpdate = Object.assign({}, office);
            officeUpdate.name = JSON.stringify(office.name);
            officeUpdate.description = JSON.stringify(office.description);

            Http.put(url, officeUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    officeUpdate.concurrencyStamp = res.data.data;
                    dispatch(officeAction.update(officeUpdate));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function officeDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}offices/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(officeAction.detail(res.data.data));
                    resolve();
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err);
                });
        });
}

export function officeDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}offices/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(officeAction.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
    };
}

export function officeTreeRequest() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}offices/trees`;

            Http.get(url)
                .then((res) => {
                    dispatch(officeAction.tree(res.data));
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function officeGetAll() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${NAILSPA_URL}offices/trees`;

            Http.get(url)
                .then((res) => {
                    dispatch(officeAction.tree(res.data));
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}
