import { PAGE_SIZE } from '../../../../constants/Config';
import {
    BANNER_LIST,
    BANNER_GETALL,
    BANNER_DETAIL,
    BANNER_INSERT,
    BANNER_UPDATE,
    BANNER_DELETE,
    BANNER_ITEM_GETALL,
    BANNER_ITEM_DETAIL
} from './action-types';

const initialState = {
    // banner
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    items: [],
    item: null,
    suggestion: [],

    // banner item
    banner_item: null,
    items_suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case BANNER_LIST:
            return list(state, payload);
        case BANNER_GETALL:
            return suggestion(state, payload);
        case BANNER_DETAIL:
            return detail(state, payload);
        case BANNER_INSERT:
            return insert(state, payload);
        case BANNER_UPDATE:
            return update(state, payload);
        case BANNER_DELETE:
            return deletes(state, payload);
        case BANNER_ITEM_GETALL:
            return item_suggestion(state, payload);
        case BANNER_ITEM_DETAIL:
            return item_detail(state, payload);
        // case BANNER_ITEM_INSERT:
        //     return item_insert(state, payload)
        // case BANNER_ITEM_UPDATE:
        //     return item_update(state, payload)
        // case BANNER_ITEM_DELETE:
        //     return item_deletes(state, payload)
        default:
            return state;
    }
};

// banner
function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

// banner item
function item_suggestion(state, payload) {
    state = Object.assign({}, state, {
        items_suggestion: payload
    });
    return state;
}

function item_detail(state, payload) {
    state = Object.assign({}, state, {
        banner_item: payload
    });
    return state;
}

export default reducer;
