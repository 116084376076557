export const DISTRICT_LIST = 'DISTRICT_LIST';
export const DISTRICT_DETAIL = 'DISTRICT_DETAIL';
export const DISTRICT_UPDATE = 'DISTRICT_UPDATE';
export const DISTRICT_INSERT = 'DISTRICT_INSERT';
export const DISTRICT_DELETE = 'DISTRICT_DELETE';
export const DISTRICT_RESPONSE = 'DISTRICT_RESPONSE';
export const DISTRICT_GETALL = 'DISTRICT_GETALL';
export default {
    DISTRICT_DELETE,
    DISTRICT_DETAIL,
    DISTRICT_INSERT,
    DISTRICT_LIST,
    DISTRICT_UPDATE,
    DISTRICT_RESPONSE,
    DISTRICT_GETALL
};
