import { PRODUCTCONVERTUNIT_INSERT, PRODUCTCONVERTUNIT_LIST, PRODUCTCONVERTUNIT_DELETE } from './action-types';
import { PAGE_SIZE } from '../../../../constants/Config';
const initialState = {
    data: [],
    totalRows: 0,
    currentPAGE: 0,
    pageSize: PAGE_SIZE,
    tree: [],
    action: null,
    item: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PRODUCTCONVERTUNIT_LIST:
            return list(state, payload);
        case PRODUCTCONVERTUNIT_INSERT:
            return insert(state, payload);
        case PRODUCTCONVERTUNIT_DELETE:
            return deletes(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, { data: payload });
    return state;
}

function insert(state, payload) {
    const data = [...state.data, payload];

    return Object.assign({}, state, { data });
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}
export default reducer;
