import React, { Component } from 'react';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import FileManagement from './';
import PropTypes from 'prop-types';

class FileChoosePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            isShowPopup: this.props.isShowPopup
        };
    }

    render() {
        const { selectionMode, allowedExt, onFileSelected, hidePopup } = this.props;

        return (
            <Popup
                position={'center'}
                visible={this.state.isShowPopup}
                onHiding={() =>
                    this.setState({
                        isShowPopup: false
                    })
                }
                onHidden={hidePopup}
                shading={true}
                title={'Chọn file'}
            >
                <ScrollView>
                    <div>
                        <FileManagement
                            allowedExt={allowedExt}
                            insidePopup={true}
                            onSelectFile={this.props.onFileSelected}
                            selectionMode={selectionMode}
                        />
                    </div>
                </ScrollView>
            </Popup>
        );
    }
}

FileChoosePopup.propTypes = {
    isShowPopup: PropTypes.bool,
    selectionMode: PropTypes.string,
    allowedExt: PropTypes.array,
    onFileSelected: PropTypes.func,
    hidePopup: PropTypes.func
};

export default FileChoosePopup;
