export const RESOURCE_LIST = 'RESOURCE_LIST';
export const RESOURCE_DETAIL = 'RESOURCE_DETAIL';
export const RESOURCE_UPDATE = 'RESOURCE_UPDATE';
export const RESOURCE_INSERT = 'RESOURCE_INSERT';
export const RESOURCE_DELETE = 'RESOURCE_DELETE';
export const RESOURCE_RESPONSE = 'RESOURCE_RESPONSE';
export const RESOURCE_GETALL = 'RESOURCE_GETALL';
export default {
    RESOURCE_DELETE,
    RESOURCE_DETAIL,
    RESOURCE_INSERT,
    RESOURCE_LIST,
    RESOURCE_UPDATE,
    RESOURCE_GETALL
};
