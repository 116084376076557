import { RECRUITMENT_DETAIL, RECRUITMENT_LIST } from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    item: null,
    suggestion: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case RECRUITMENT_DETAIL:
            return detail(state, payload);
        case RECRUITMENT_LIST:
            return list(state, payload);

        default:
            return state;
    }
};

function detail(state, payload) {
    state = { ...state, item: payload };

    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

export default reducer;
