import React, { Component } from 'react';
import i18n from '../../i18n';
import { SelectBox } from 'devextreme-react';
import * as _ from 'lodash';
import Transformer from '../../utils/Transformer';
import PropTypes from 'prop-types';

class BaseSelectBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: [],
            payload: {},
            renderDone: false
        };

        this.onDataChanged = this.onDataChanged.bind(this);
    }

    componentDidMount() {
        this.getAllData();
    }

    getAllData() {
        const { payload } = this.state;

        if (!this.props.listData || this.props.listData.length === 0) {
            this.props.getAllData(payload).then(() => {
                this.handleLanguage();
            });
        } else {
            this.handleLanguage();
        }
    }

    handleLanguage = () => {
        const { languages, listData, displayExpr } = this.props;
        const fieldName = displayExpr || 'name';
        let datas = {};

        languages.forEach((element) => {
            const newArray = [];

            for (const item of listData) {
                const i = Object.assign({}, item);
                const nameParse = Transformer.IsJsonString(i[fieldName])
                    ? Transformer.convertJSONString(i[fieldName], element.languageId)
                    : i[fieldName];

                i[fieldName] = nameParse;
                newArray.push(i);
            }

            datas = { ...datas, [element.languageId]: newArray };
        });

        this.setState({ datas }, () => {
            this.setState({ renderDone: true });
        });
    };

    /**
     * Handle Data Change.
     *
     * @inheritdoc
     */
    onDataChanged(e) {
        const { field } = this.props;
        this.props.updateData({
            [field]: e.value
        });
    }

    render() {
        const {
            currentLanguageActive,
            value,
            i18nLabel,
            validatorValue,
            valueExpr,
            displayExpr,
            readOnly,
            isShowClearButton,
            disabled,
            isShowLabel,
            languages
        } = this.props;
        const { datas, renderDone } = this.state;
        const selectedLanguage = _.find(languages, ['isDefault', true]);
        const defaultLanguageId =
            selectedLanguage !== undefined ? selectedLanguage.languageId : languages[0].languageId;
        return (
            <div className={'dx-field'}>
                {isShowLabel && (
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {validatorValue && validatorValue.includes('require') && (
                            <span style={{ color: 'red' }}> *</span>
                        )}
                    </div>
                )}
                <div className={'dx-field-value'}>
                    {!renderDone ? (
                        <SelectBox items={[]} value={''} placeholder={i18n.t(i18nLabel)} />
                    ) : (
                        <SelectBox
                            searchEnabled={true}
                            items={
                                _.findIndex(languages, ['languageId', currentLanguageActive]) === -1
                                    ? datas[defaultLanguageId]
                                    : datas[currentLanguageActive]
                            }
                            value={value || ''}
                            valueExpr={valueExpr || 'id'}
                            displayExpr={displayExpr || 'name'}
                            placeholder={i18n.t(i18nLabel)}
                            showClearButton={isShowClearButton}
                            onValueChanged={this.onDataChanged}
                            readOnly={readOnly || false}
                            disabled={disabled}
                        />
                    )}
                </div>
            </div>
        );
    }
}

BaseSelectBox.propTypes = {
    updateData: PropTypes.func,
    i18nLabel: PropTypes.string,
    currentLanguageActive: PropTypes.string,
    isShowLabel: PropTypes.bool,
    isShowClearButton: PropTypes.bool,
    value: PropTypes.string,
    languages: PropTypes.array,
    disabled: PropTypes.bool,
    validatorValue: PropTypes.string,
    valueExpr: PropTypes.string,
    displayExpr: PropTypes.string,
    readOnly: PropTypes.bool,
    listData: PropTypes.any,
    getAllData: PropTypes.func,
    field: PropTypes.string
};

BaseSelectBox.defaultProps = {
    isShowClearButton: false,
    isShowLabel: true
};

export default BaseSelectBox;
