import React, { Component } from 'react';
import i18n from '../../i18n';
import { SelectBox } from 'devextreme-react';
import { PROMOTION_NOTE } from '../../constants/Config';
import PropTypes from 'prop-types';

class PromotionStatusSelectBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datas: {}
        };

        this.onDataChanged = this.onDataChanged.bind(this);
    }

    componentDidMount() {
        this.handleLanguage();
    }

    handleLanguage = () => {
        const { languages } = this.props;

        let datas = [];

        languages.forEach((element) => {
            const newArray = [];

            for (const item of PROMOTION_NOTE) {
                const i = Object.assign({}, item);
                const nameParse = i.name ? JSON.parse(i.name) : '';

                i.name = nameParse[element.languageId];
                newArray.push(i);
            }

            datas = { ...datas, [element.languageId]: newArray };
        });

        this.setState({
            datas
        });
    };

    /**
     * Handle Data Change.
     *
     * @inheritdoc
     */
    onDataChanged(e) {
        const { field } = this.props;
        this.props.updateData({
            [field]: e.value
        });
        if (e.value === null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: ''
            });
        }
    }

    render() {
        const { currentLanguage, value, i18nLabel } = this.props;
        const { datas } = this.state;

        return (
            <div className="form-group cm-mgr-5">
                <div className={'dx-field'}>
                    <SelectBox
                        searchEnabled={true}
                        items={datas[currentLanguage]}
                        value={value}
                        valueExpr={'id'}
                        displayExpr={'name'}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={true}
                        onValueChanged={this.onDataChanged}
                    />
                </div>
            </div>
        );
    }
}

PromotionStatusSelectBox.propTypes = {
    languages: PropTypes.array,
    field: PropTypes.string,
    currentLanguage: PropTypes.string,
    value: PropTypes.string,
    i18nLabel: PropTypes.string,
    updateData: PropTypes.func
};

export default PromotionStatusSelectBox;
