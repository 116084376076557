import FileMgr from '../../components/file/FileMgr';
import React, { useState, useRef } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import FolderTreeView from '../../components/file/FolderTreeView';
import { Popup, Position } from 'devextreme-react/popup';

export default function FileManagement(props) {
    const { selectionMode, onSelectFile, allowedExt } = props;
    const [showPopup, setShowPopup] = useState(false);
    const fileRef = useRef(null);

    const selectFiles = () => {
        const selectFiles = fileRef.current.instance.getSelectedItems();
        if (selectFiles && selectFiles.length > 0) {
            onSelectFile(selectFiles[0]);
        }
    };

    const renderPopup = () => {
        return <FolderTreeView />;
    };
    return (
        <>
            <Toolbar>
                <Item location="center" text={'Quản lý tài liệu'} />
                <Item
                    location="after"
                    widget={'dxButton'}
                    options={{
                        icon: 'share',
                        text: 'Share Folder',
                        onClick: () => setShowPopup(true)
                    }}
                />
                <Item
                    location="after"
                    widget={'dxButton'}
                    options={{
                        icon: 'save',
                        text: 'Lưu',
                        onClick: selectFiles
                    }}
                />
            </Toolbar>
            <FileMgr selectionMode={selectionMode} ref={fileRef} allowedFileExtensions={allowedExt} />

            <Popup
                visible={showPopup}
                onHiding={() => setShowPopup(false)}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title={'Chọn folder để chia sẻ'}
                container=".dx-viewport"
                width={400}
                height={600}
                contentRender={renderPopup}
            >
                <Position at="center" my="center" />
            </Popup>
        </>
    );
}
