import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/service-card',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./list/index'),
            loading: LoadingComponent
        })
    }
];
