import { PAGE_SIZE } from '../../../../constants/Config';
import {
    PROJECT_DETAIL,
    PROJECT_DETAIL_GETALL,
    PROJECT_GETALL,
    PROJECT_ALBUM_GETALL,
    PROJECT_LIST
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    item: null,
    suggestion: [],
    detailSuggestion: [],
    albumSuggestion: [],
    currentPage: 1,
    pageSize: PAGE_SIZE,
    categories: [],
    edit: false
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PROJECT_LIST:
            return list(state, payload);
        case PROJECT_DETAIL:
            return detail(state, payload);
        case PROJECT_GETALL:
            return getAll(state, payload);
        case PROJECT_DETAIL_GETALL:
            return detailGetAll(state, payload);
        case PROJECT_ALBUM_GETALL:
            return albumGetAll(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

const detail = (state, payload) => {
    state = { ...state, item: payload };

    return state;
};

const getAll = (state, payload) => {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
};

const detailGetAll = (state, payload) => {
    state = Object.assign({}, state, {
        detailSuggestion: payload
    });
    return state;
};

const albumGetAll = (state, payload) => {
    state = Object.assign({}, state, {
        albumSuggestion: payload
    });
    return state;
};

export default reducer;
