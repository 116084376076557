import { RECORDS_SELECT } from '../../../constants/Config';
import i18n from '../../../i18n';
import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react';
import PropTypes from 'prop-types';

class PageSizeSelectBox extends Component {
    constructor(props) {
        super(props);

        this.onDataChanged = this.onDataChanged.bind(this);
    }

    /**
     * Handle Data Change.
     *
     * @inheritdoc
     */
    onDataChanged(e) {
        if (e.value !== null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: e.value
            });
        }
    }

    render() {
        const { value, i18nLabel } = this.props;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel) + ':'}</div>
                <div className={'dx-field-value'}>
                    <SelectBox
                        searchEnabled={true}
                        items={RECORDS_SELECT}
                        value={value}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={true}
                        onValueChanged={this.onDataChanged}
                    />
                </div>
            </div>
        );
    }
}

PageSizeSelectBox.propTypes = {
    value: PropTypes.any,
    i18nLabel: PropTypes.string,
    field: PropTypes.string,
    updateData: PropTypes.func
};

export default PageSizeSelectBox;
