export const EMPLOYEE_OFFICE_LIST = 'EMPLOYEE_OFFICE_LIST';
export const EMPLOYEE_OFFICE_DETAIL = 'EMPLOYEE_OFFICE_DETAIL';
export const EMPLOYEE_OFFICE_UPDATE = 'EMPLOYEE_OFFICE_UPDATE';
export const EMPLOYEE_OFFICE_INSERT = 'EMPLOYEE_OFFICE_INSERT';
export const EMPLOYEE_OFFICE_DELETE = 'EMPLOYEE_OFFICE_DELETE';
export const EMPLOYEE_OFFICE_TREE = 'EMPLOYEE_OFFICE_TREE';
export default {
    EMPLOYEE_OFFICE_DELETE,
    EMPLOYEE_OFFICE_DETAIL,
    EMPLOYEE_OFFICE_INSERT,
    EMPLOYEE_OFFICE_LIST,
    EMPLOYEE_OFFICE_UPDATE,
    EMPLOYEE_OFFICE_TREE
};
