import { TYPE_PROMOTION_READONLY } from '../../../constants/Config';
import BaseReadOnlyConstants from '../../components/BaseReadOnlyConstants';

class ReadOnlyTypePromotion extends BaseReadOnlyConstants {
    constructor(props) {
        super(props);

        this.CONSTANTS = TYPE_PROMOTION_READONLY;
    }
}

export default ReadOnlyTypePromotion;
