import {
    CUSTOMER_CARD_INSERT,
    CUSTOMER_CARD_LIST,
    CUSTOMER_CARD_UPDATE,
    CUSTOMER_CARD_GET_ALL,
    CUSTOMER_CARD_DELETE,
    CUSTOMER_CARD_DETAIL,
    CUSTOMER_CARD_SELECT_ID,
    CUSTOMER_CARD_LIST_BY_CUSTOMER,
    CUSTOMER_CARD_HISTORY,
    CUSTOMER_CARD_GIFT
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: 0,
    item: {},
    suggestion: [],
    customerCardId: null,
    listCardByCustomer: [],
    history: [],
    gift: [],
    totalRowGifts: 0
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case CUSTOMER_CARD_LIST:
            return list(state, payload);
        case CUSTOMER_CARD_UPDATE:
            return update(state, payload);
        case CUSTOMER_CARD_INSERT:
            return insert(state, payload);
        case CUSTOMER_CARD_DELETE:
            return deletes(state, payload);
        case CUSTOMER_CARD_GET_ALL:
            return suggestion(state, payload);
        case CUSTOMER_CARD_DETAIL:
            return detail(state, payload);
        case CUSTOMER_CARD_SELECT_ID:
            return selectId(state, payload);
        case CUSTOMER_CARD_LIST_BY_CUSTOMER:
            return customerCardListByCustomerId(state, payload);
        case CUSTOMER_CARD_HISTORY:
            return cardHistory(state, payload);
        case CUSTOMER_CARD_GIFT:
            return cardGift(state, payload);
        default:
            return state;
    }
};

const customerCardListByCustomerId = (state, payload) => {
    state = { ...state, listCardByCustomer: payload };

    return state;
};

const detail = (state, payload) => {
    state = { ...state, item: payload };

    return state;
};

const selectId = (state, payload) => {
    state = { ...state, customerCardId: payload };

    return state;
};

const list = (state, payload) => {
    return { ...state, ...payload };
};

const suggestion = (state, payload) => {
    state = { ...state, suggestion: payload };

    return state;
};

const deletes = (state, payload) => {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
};

const update = (state, payload) => {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });

    return Object.assign({}, state, { data });
};

const insert = (state, payload) => {
    state = Object.assign({}, state, {
        data: payload
    });
    return state;
};
const cardHistory = (state, payload) => {
    state = Object.assign({}, state, {
        history: payload
    });
    return state;
};

const cardGift = (state, payload) => {
    state = Object.assign({}, state, {
        gift: payload.data,
        totalRowGifts: payload.totalRows
    });
    return state;
};

export const getCustomerCards = (state) => state.customerCard.data;
export default reducer;
