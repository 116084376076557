import {
    DISTRICT_DELETE,
    DISTRICT_DETAIL,
    DISTRICT_INSERT,
    DISTRICT_LIST,
    DISTRICT_RESPONSE,
    DISTRICT_UPDATE,
    DISTRICT_GETALL
} from './action-types';

export function list(payload) {
    return {
        type: DISTRICT_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: DISTRICT_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: DISTRICT_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: DISTRICT_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: DISTRICT_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: DISTRICT_RESPONSE,
        payload
    };
}

export function suggestion(payload) {
    return {
        type: DISTRICT_GETALL,
        payload
    };
}
