export const viR = {
    religion: {
        list: { title: 'Danh sách Tôn Giáo' },
        form: {
            updateTitle: 'Chỉnh sửa Tôn Giáo',
            insertTitle: 'Thêm mới Tôn Giáo'
        }
    },
    resource: {
        list: { title: 'Danh sách Nguồn Khách' }
    },
    role: {
        list: { title: 'Danh sách Nhóm Quyền' },
        form: {
            groupname: 'Tên Nhóm Quyền',
            titleInfo: 'Thông tin Nhóm Quyền',
            edit: 'Chỉnh sửa nhóm quyền'
        }
    },
    request: {
        form: { details: 'Chi tiết phiếu' },
        list: { edit: 'Chỉnh sửa/ Tạo phiếu yêu cầu' }
    },
    report: {
        reportCommissione: 'Báo cáo về hoa hồng của nhân viên',
        reportCommissioneDetail: 'Báo cáo về chi tiết hoa hồng của nhân viên',
        listCustomerServiceCard: 'Báo cáo doanh thu của thẻ dịch vụ khách hàng',
        listCustomerServiceCardDetail: 'Báo cáo tổng hợp chi tiết thẻ dịch vụ khách hàng',
        customerPoints: 'Báo cáo tích điểm',
        serviceAppointmentReport: 'Báo cáo Đặt hẹn dịch vụ',
        staffAppointment: 'Báo cáo Đặt lịch hẹn nhân viên',
        listTotal: 'Báo cáo tổng doanh thu',
        listTotalDetailService: 'Doanh thu chi tiết dịch vụ',
        listTotalDetailProduct: 'Doanh thu chi tiết sản phẩm',
        listTotalDetailCustomer: 'Doanh thu chi tiết khách hàng',
        listReport: 'Báo cáo doanh thu',
        countServicesProducts: 'Báo cáo doanh thu số lần dịch vụ/ sản phẩm',
        customerServiceDetail: 'Báo cáo chi tiết dịch vụ khách hàng theo ngày giờ',
        listWarehouseCard: 'Báo cáo thẻ kho',
        firstTimeCustomer: 'Báo cáo khách hàng sử dụng dịch vụ lần đầu',
        listImportExport: 'Báo cáo nhập xuất tồn',
        listProductInventory: 'Báo cáo sản phẩm tồn kho',
        listUser: 'Báo cáo nhân viên',
        listUserDetail: 'Báo cáo nhân viên chi tiết',
        listServiceProduct: 'Báo cáo dịch vụ/ sản phẩm',
        listCustomerCard: 'Báo cáo mua thẻ trả trước',
        listProductGroup: 'Báo cáo doanh thu theo nhóm sản phẩm',
        customerDetailAllBranch: 'Báo cáo chi tiết KH chi nhánh',
        userTimeSheet: 'Báo cáo lịch làm việc nhân viên',
        customerNoCome: 'Báo cáo khách hàng không quay lại',
        calendarReminder: 'Báo cáo nhắc lịch dịch vụ sản phẩm',
        customerCardIsAboutToExpire: 'Báo cáo thẻ trả trước sắp hết hạn',
        customerServiceCardIsAboutToExpire: 'Báo cáo thẻ dịch vụ sắp hết hạn',
        detail: 'Chi tiết',
        Service: 'dịch vụ',
        Product: 'sản phẩm',
        Price: 'Tổng doanh thu',
        Discount: 'Giảm giá',
        Amount: 'Doanh thu thuần',
        CustomerServiceCard: 'Thu tiền bán thẻ trả trước',
        Total: 'Tổng tiền thu được từ',
        TotalUse: 'Số tiền đã sử dụng',
        TotalBalance: 'Số tiền còn lại',
        TotalPaid: 'Số tiền đã TT',
        TotalPaidProduct: 'Số tiền đã TT SP',
        TotalPaidService: 'Số tiền đã TT DV',
        percent: 'Phần trăm',
        commissioneMoney: 'Tiền hoa hồng',
        joinedDate: 'Ngày ',
        serviceCount: 'Số lần DV',
        productCount: 'Số lần SP',
        totalAmountService: 'Tổng tiền DV',
        totalAmountProduct: 'Tổng tiền SP',
        exportExcel: 'Xuất excel',
        exportAll: 'Xuất tất cả',
        totalPrice: 'Tổng doanh thu',
        totalAmount: 'Thành tiền',
        employee: 'Nhân viên thực hiện',
        customersReport: 'Báo cáo khách hàng',
        explain: 'Diễn giải',
        error: {
            selectUser: 'Chưa chọn nhân viên'
        },
        detailPayment: {
            listTitle: 'Báo cáo chi tiết thanh toán',
            customerCode: 'Mã KH',
            fullName: 'Khách hàng',
            birthday: 'Ngày sinh',
            phoneNumber: 'Số điện thoại',
            type: 'Loại',
            creatorFullName: 'Người thu',
            pageUnit: 'bản ghi',
            cardType: 'Loại thẻ',
            paidCustomerCode: 'Mã KH TT',
            paidCustomerName: 'Tên KH TT',
            totalValue: 'Tổng tiền',
            invoiceDate: 'Ngày thu'
        },
        popup: {
            insertCustomerFeedback: 'Thêm lịch sử cuộc gọi',
            feedback: 'Lịch sử cuộc gọi',
            listFeedback: 'Danh sách lịch sử cuộc gọi',
            reviewsFeedback: 'Phản hồi',
            consultationInfo: 'Thông tin tư vấn',
            evaluate: 'Đánh giá',
            note: 'Ghi chú'
        }
    }
};
