import { NAILSPA_URL } from '../../constants/Config';
import Http from '../../utils/Http';
import * as warehouseManagerConfigActions from './store/actions';
import { spinnerLoading } from '../../common/spinner/store/action';

export function warehouseManagerConfigSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${NAILSPA_URL}warehouseManagerConfigs/${payload.warehouseId}?keyword=${payload.keyword}`;
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(warehouseManagerConfigActions.list(res.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function warehouseManagerConfigInsertRequest(warehouseManagerConfig) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}warehouseManagerConfigs/${warehouseManagerConfig.warehouseId}`;
            const warehouseManagerConfigUpdate = warehouseManagerConfig;

            Http.post(url, warehouseManagerConfigUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    warehouseManagerConfig.id = res.data.data;
                    dispatch(warehouseManagerConfigActions.insert(warehouseManagerConfig));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function warehouseManagerConfigUpdateRequest(warehouseManagerConfig) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}warehouseManagerConfigs/${warehouseManagerConfig.warehouseId}/${warehouseManagerConfig.userId}`;
            const warehouseManagerConfigUpdate = warehouseManagerConfig;
            Http.put(url, warehouseManagerConfigUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    warehouseManagerConfig.concurrencyStamp = res.data.data;
                    // dispatch(warehouseManagerConfigActions.update(warehouseManagerConfig));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function warehouseManagerConfigDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}warehouseManagerConfigs/${payload.warehouseId}/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(warehouseManagerConfigActions.detail(res.data.data));
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
}

export function warehouseManagerConfigDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}warehouseManagerConfigs/${payload.warehouseId}/${payload.userId}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
    };
}

export function warehouseManagerConfigGetAllByUserId(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}warehouseManagerConfigs/get-all-by-warehouseId/${payload.userId}`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(warehouseManagerConfigActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function warehouseManagerConfigGetAllUserId(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}warehouseManagerConfigs/get-all-by-userId/${payload.userId}`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(warehouseManagerConfigActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function warehouseManagerConfigGetAllByWarehouseId(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${NAILSPA_URL}warehouseManagerConfigs/get-all-by-userId/${payload.warehouseId}`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(warehouseManagerConfigActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
