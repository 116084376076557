export const WEBSITE_SERVICE_SEARCH = 'WEBSITE_SERVICE_SEARCH';
export const WEBSITE_SERVICE_INSERT = 'WEBSITE_SERVICE_INSERT';
export const WEBSITE_SERVICE_UPDATE = 'WEBSITE_SERVICE_UPDATE';
export const WEBSITE_SERVICE_DETAIL = 'WEBSITE_SERVICE_DETAIL';
export const WEBSITE_SERVICE_DELETE = 'WEBSITE_SERVICE_DELETE';
export const WEBSITE_SERVICE_META = 'WEBSITE_SERVICE_META';
export const WEBSITE_SERVICE_RESET = 'WEBSITE_SERVICE_RESET';
export const WEBSITE_SERVICE_GETALL = 'WEBSITE_SERVICE_GETALL';

export const WEBSITE_SERVICE_TITLE_GETALL = 'WEBSITE_SERVICE_TITLE_GETALL';
export const WEBSITE_SERVICE_TITLE_INSERT = 'WEBSITE_SERVICE_TITLE_INSERT';
export const WEBSITE_SERVICE_TITLE_DETAIL = 'WEBSITE_SERVICE_TITLE_DETAIL';

export const WEBSITE_SERVICE_TITLE_ITEM_GETALL = 'WEBSITE_SERVICE_TITLE_ITEM_GETALL';

export const WEBSITE_SERVICE_FAQ_GETALL = 'WEBSITE_SERVICE_FAQ_GETALL';
export const WEBSITE_SERVICE_FAQ_INSERT = 'WEBSITE_SERVICE_FAQ_INSERT';
export const WEBSITE_SERVICE_FAQ_UPDATE = 'WEBSITE_SERVICE_FAQ_UPDATE';
export const WEBSITE_SERVICE_FAQ_DETAIL = 'WEBSITE_SERVICE_FAQ_DETAIL';

export default {
    WEBSITE_SERVICE_SEARCH,
    WEBSITE_SERVICE_INSERT,
    WEBSITE_SERVICE_DETAIL,
    WEBSITE_SERVICE_UPDATE,
    WEBSITE_SERVICE_META,
    WEBSITE_SERVICE_RESET,
    WEBSITE_SERVICE_GETALL,
    WEBSITE_SERVICE_DELETE,

    WEBSITE_SERVICE_TITLE_GETALL,
    WEBSITE_SERVICE_TITLE_INSERT,

    WEBSITE_SERVICE_TITLE_ITEM_GETALL,

    WEBSITE_SERVICE_FAQ_GETALL,
    WEBSITE_SERVICE_FAQ_INSERT,
    WEBSITE_SERVICE_FAQ_UPDATE,
    WEBSITE_SERVICE_FAQ_DETAIL
};
