export const TIME_SHIFT_LIST = 'TIME_SHIFT_LIST';
export const TIME_SHIFT_DETAIL = 'TIME_SHIFT_DETAIL';
export const TIME_SHIFT_UPDATE = 'TIME_SHIFT_UPDATE';
export const TIME_SHIFT_INSERT = 'TIME_SHIFT_INSERT';
export const TIME_SHIFT_DELETE = 'TIME_SHIFT_DELETE';
export const TIME_SHIFT_RESPONSE = 'TIME_SHIFT_RESPONSE';
export const TIME_SHIFT_GET_ALL = 'TIME_SHIFT_GET_ALL';

export default {
    TIME_SHIFT_DELETE,
    TIME_SHIFT_DETAIL,
    TIME_SHIFT_INSERT,
    TIME_SHIFT_LIST,
    TIME_SHIFT_UPDATE,
    TIME_SHIFT_RESPONSE,
    TIME_SHIFT_GET_ALL
};
