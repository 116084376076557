import website from './website/store/reducer';
import faq from './faq/store/reducer';
import faqgroup from './faqgroup/store/reducer';
import banner from './banner/store/reducer';
import news from './news/store/reducer';
import websiteSetting from './setting/store/reducer';
import socialNetwork from './socialNetwork/store/reducer';
import languages from './languages/store/reducer';
import menu from './menu/store/reducer';
import webService from './web_service/store/reducer';
import branchContact from './branchContacts/store/reducer';
import hardContent from './hardContent/store/reducer';
import newsCategory from './category/store/reducer';
import album from './album/store/reducer';
import productWebsite from './product/store/reducer';
import partner from './partner/store/reducer';
import customerFeedback from './customerFeedback/store/reducer';
import feedback from './feedback/store/reducer';
import subscribe from './subscribe/store/reducer';
import productCategories from './productCategories/store/reducer';
import bookingSchedule from './booking/store/reducer';
import project from './project/store/reducer';
import job from './job/store/reducer';
import recruitment from './recruitment/store/reducer';
import projectCategories from './projectCategories/store/reducer';

export default {
    website,
    faq,
    faqgroup,
    banner,
    news,
    websiteSetting,
    socialNetwork,
    languages,
    menu,
    webService,
    branchContact,
    hardContent,
    newsCategory,
    album,
    productWebsite,
    partner,
    customerFeedback,
    feedback,
    subscribe,
    productCategories,
    bookingSchedule,
    project,
    job,
    recruitment,
    projectCategories
};
