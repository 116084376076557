export const GROUPS_PRODUCT_LIST = 'GROUPS_PRODUCT_LIST';
export const GROUPS_PRODUCT_TREE = 'GROUPS_PRODUCT_TREE';
export const GROUPS_PRODUCT_DETAIL = 'GROUPS_PRODUCT_DETAIL';
export const GROUPS_PRODUCT_INSERT = 'GROUPS_PRODUCT_INSERT';
export const GROUPS_PRODUCT_UPDATE = 'GROUPS_PRODUCT_UPDATE';
export const GROUPS_PRODUCT_DELETE = 'GROUPS_PRODUCT_DELETE';
export default {
    GROUPS_PRODUCT_LIST,
    GROUPS_PRODUCT_TREE,
    GROUPS_PRODUCT_DETAIL,
    GROUPS_PRODUCT_INSERT,
    GROUPS_PRODUCT_UPDATE,
    GROUPS_PRODUCT_DELETE
};
