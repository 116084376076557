import { STATUS } from '../../../constants/Config';
import BaseReadOnlyConstants from '../../components/BaseReadOnlyConstants';

class ReadOnlyStatus extends BaseReadOnlyConstants {
    constructor(props) {
        super(props);

        this.CONSTANTS = STATUS;
    }
}

export default ReadOnlyStatus;
