import { enW } from './en_w';
import { enGeneral } from './en_general';
import { enC } from './en_c';
import { enP } from './en_p';
import { enS } from './en_s';
import { enA } from './en_a';
import { enB } from './en_b';
import { enU } from './en_u';
import { enG } from './en_g';
import { enR } from './en_r';
import { enN } from './en_n';
import { enI } from './en_i';

export const i18nEn = {
    translation: {
        message: {
            deleteForm: 'After deleting the list, it will not be used in related items',
            deleteConfirm: 'Are you sure you want to delete this item?',
            accept: 'Accept',
            setback: 'Cancel',
            decline: 'Cancel',
            loading: 'Loading data. Please wait...',
            emptyPanelText: 'Drag a column header here to group by that column',
            findCustomer: 'find customers',
            has: 'Have',
            alert: 'Alert',
            changeStatusConfirmTrue: 'This card is not active. Are you sure to change this card status to active',
            changeStatusConfirmFalse: 'This card is in use. Are you sure to change this card status to inactive',
            wrongAccOrPass: 'Wrong Account or Password',
            lockAcc: 'This Account has been locked',
            notExistAcc: 'This Account is not exist'
        },

        // a
        ...enA,

        // b
        ...enB,
        // c
        ...enC,

        // D
        district: {
            list: { title: 'List of Districts' }
        },
        department: {
            form: {
                infoTitle: 'Department Info',
                updateTitle: 'Edit Department',
                insertTitle: 'Add New Department'
            },
            position: { title: 'List of Positions in Department' }
        },
        // F
        fileManagement: {
            list: {
                view: 'View',
                edit: 'Edit',
                delete: 'Delete',
                rename: 'Rename',
                refresh: 'Refresh'
            },
            toolbar: {
                addFolder: 'Add Folder',
                chooseFile: 'Choose File',
                searchPlaceholder: 'Enter file name'
            },
            popup: {
                addNewFolderTitle: 'ADD NEW FOLDER',
                folderLabel: 'Folder',
                renameTitle: 'RENAME',
                editTitle: 'EDIT FOLDER/ FILE',
                deleteTitle: 'Are you sure to delete this file/ folder ?',
                deleteNotice: 'Deleted file/folder will not be used in related items'
            },
            error: {
                selectFileFolder: 'Please select file or folder',
                folderContainFiles: "You can't delete a folder which contains one or more folders/ files"
            }
        },
        // G
        ...enG,

        // I
        ...enI,

        // R
        ...enR,
        religion: {
            list: { title: 'List of Religions' },
            form: {
                updateTitle: 'Edit Religion',
                insertTitle: 'Add New Religion'
            }
        },
        resource: {
            list: { title: 'List of Guest Resources' }
        },
        job: {
            list: { title: 'List of Jobs' }
        },

        // P
        ...enP,

        ...enN,
        tenant: {
            list: {
                domain: 'Domain',
                note: 'Note',
                title: 'List of Tenants',
                info: 'Info of Tenants'
            }
        },
        role: {
            list: { title: 'List of Permission Groups' },
            form: {
                groupname: 'Permission Group Name',
                titleInfo: 'Permission Group Info',
                edit: 'Edit Permission Group'
            }
        },
        request: {
            form: { details: 'Customer voucher details' },
            list: { edit: 'Edit / Create order form' }
        },

        // S

        // U
        ...enU,

        // S
        ...enS,

        title: {
            list: { title: 'List of Titles' }
        },

        ...enW,

        ...enGeneral,

        about: {
            title: 'SPA INFORMATION',
            basicinformation: 'Basic information',
            Workshiftinformation: 'Shift information',
            systemconfiguration: 'System configuration',
            spaName: 'Spa name',
            branchinformation: 'Branch information'
        },
        setting: {
            title: { label: 'Configuration' },
            account: { title: 'Account list', role: 'User rights' },
            page: { title: 'Page list' },
            name: { label: 'Name' },
            role: { title: 'List of rights', info: 'Info of rights' },
            area: { title: 'List of regions' },
            customer: { title: 'Customer list' },
            branch: { title: 'Branch list' }
        },

        titlePage: {
            Calendar: 'Calendar',
            Home: 'Home',
            Spainformation: 'Spa information',
            Listregions: 'Area list',
            Countrylists: 'Country list',
            Listsprovincescities: 'List of provinces / cities',
            DistrictList: 'District List',
            EthnicList: 'Lists of ethnicity',
            ReligiousList: 'Lists of Religion',
            Listpaymentmethods: 'Lists of payment methods',
            Guestsourcelist: 'List of guest sources',
            Careerlist: 'Lists of occupation',
            pagelist: 'Page list',
            configuration: 'Configuration',
            OccupationList: 'Lists of occupation',
            customerlists: 'Customer list',
            permissionlists: 'List of rights groups',
            permissions: 'Decentralization',
            CustomerManagement: 'Customer management',
            CustomerAppointment: 'Customer appointment',
            Makeappointment: 'Book an appointment',
            Listcustomercards: 'List of customer cards',
            Listcustomercoupons: 'Customer list',
            CustomerVoucher: 'Customer voucher',
            RequestList: 'Request list',
            AccountList: 'Account list',
            StaffList: 'Staff list',
            StaffManagement: 'Staff manager',
            Listtitles: 'Staff list',
            Jobtitlemanagement: 'Job title management',
            Joblist: 'Lists of occupation',
            Jobmanager: 'Job management',
            DepartmentList: 'Department List',
            ServiceList: 'Service List',
            Servicegrouplist: 'Service group list',
            Servicecardlist: 'Service card list',
            Servicemanagement: 'Service management',
            FileManagement: 'File Management',
            ProductList: 'Product List',
            ProductManagement: 'Product Management',
            Productgrouplist: 'List of product groups',
            Unitlist: 'List of units',
            Promotionlist: 'Promotion list',
            Promotionmanagement: 'Promotion management',
            pointCustomer: 'Customer points list',
            CustomerBill: 'Customer bill history list'
        },
        titlePopup: {
            Addnewguestsource: 'Add new guest source',
            Editguestsource: 'Edit guest source',
            Addnewpayments: 'Add new payment method',
            Editpayments: 'Edit payment method',
            Addnewtitle: 'Add a new title',
            Edittitle: 'Edit title',
            Addnewposition: 'Add new position',
            Editposition: 'Edit position',
            Addnewdistrict: 'Add new district',
            Editdistrict: 'Edit district',
            Addnewcountry: 'Add new country',
            Editcountry: 'Edit country',
            Addnewoccupation: 'Add new occupation',
            Editoccupation: 'Edit occupation',
            Addnewethnicity: 'Add new ethnicity',
            Editethnicity: 'Edit ethnicity',
            Addnewprovince: 'Add new Province / City',
            Editprovince: 'Edit Province / City',
            Addnewreligion: 'Add new religion',
            Editreligion: 'Edit religion',
            Addnewbrand: 'Add new brand',
            Editbrand: 'Edit brand',
            PaidDetail: 'Voucher information',
            Addnewbooking: 'Add booking',
            Editbooking: 'Edit booking',
            AddNewPermissionGroup: 'Add New Permission Group',
            paymentDetail: 'Payment Detail',
            serviceDetail: 'Service Detail',
            AddnewpromotionCard: 'Add promotion card',
            EditpromotionCard: 'Edit promotion card',
            AddnewpromotionCardDetail: 'Add promotion card detail',
            EditpromotionCardDetail: 'Edit promotion card detail',
            AddNewProduct: 'Add new product',
            UpdateProduct: 'Update product',
            prepaidCard: 'Enter the balance of the prepaid card',
            serviceCardOld: 'Enter the balance of the service card',
            serviceCard: 'Enter balance of service card'
        },

        booking: {
            Bookinginfo: 'Booking Infomation',
            AppointmentTimeRequire: 'Appointment time is required',
            BookingServiceRequire: 'Booking service is require',
            createNote: 'Create Note',
            EditBooking: 'Edit booking',
            Joins: 'Participating staff',
            SaveBooking: 'Save'
        },
        promotionCard: {
            list: {
                title: 'List of promotion card ',
                promotionCard: 'Promotion card',
                promotionCardName: 'Promotion card name',
                titleDetail: 'List of promotion card detail',
                info: 'Promotion card info',
                promotionCardDetailName: 'Promotion card detail name '
            }
        }
    }
};
