import HTTP from '../../utils/Http';
import { CLIENT_ID, CORE_URL, SCOPES, AUTH_URL, DOMAIN, DOMAIN_TYPE, CLIENT_SECRET } from '../../constants/Config';
import Transformer from '../../utils/Transformer';
import * as authActions from './store/actions';
import qs from 'qs';
import { spinnerLoading } from '../../common/spinner/store/action';

export function login(credentials) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));

            const axiosConfig = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*'
                }
            };

            const input = qs.stringify({
                grant_type: 'password',
                client_id: CLIENT_ID,
                scope: SCOPES,
                username: credentials.username,
                password: credentials.password,
                type: DOMAIN_TYPE,
                client_secret: CLIENT_SECRET,
                domain: DOMAIN
            });

            HTTP.post(`${AUTH_URL}/connect/token`, input, axiosConfig)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    const data = Transformer.fetch(res.data);
                    dispatch(authActions.authLogin(data.accessToken));
                    if (credentials.remember) {
                        localStorage.setItem('refresh_token', data.refreshToken);
                    }

                    resolve();
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    const statusCode = err.response.status;
                    const data = {
                        error: null,
                        statusCode
                    };
                    if (statusCode === 400) {
                        data.error = err.response.data;
                        return reject(data);
                    }
                });
        });
}

export function getRefreshToken() {}

export function logout() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${AUTH_URL}/connect/revocation`;

            const input = qs.stringify({
                client_id: CLIENT_ID,
                client_secret: CLIENT_SECRET,
                token: localStorage.getItem('access_token'),
                token_type_hint: 'access_token'
            });

            HTTP.post(url, input)
                .then((res) => {
                    dispatch(spinnerLoading(false));

                    dispatch(authActions.authLogout());
                    resolve();
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err);
                });
        });
}

export function CheckExitUserName({ domain = DOMAIN, username, type = 0 }) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}forgots/check-user/${domain}/${username}/${type}`;

            HTTP.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(authActions.checkUserName(res.data));
                    resolve(res);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}
export function sentCodeRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}forgots/sent-mail/${payload.domain}/${payload.username}/${payload.type}`;

            HTTP.post(url, payload)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(authActions.sentCode(res.data));
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}
export function confirmCodeRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}forgots/confirm-code/${payload.domain}/${payload.username}/${payload.code}/${payload.pwd}/${payload.type}`;

            HTTP.post(url, payload)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(authActions.confirmCode(payload));
                    resolve(res.data);
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}

export function initNewAppRequest() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));

            const url = `${CORE_URL}AppSettings/InitNewApp/${DOMAIN}`;

            HTTP.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(authActions.authInitNewApp(res.data.data));
                    resolve();
                })
                .catch((error) => {
                    dispatch(spinnerLoading(false));
                    reject(error);
                });
        });
}
