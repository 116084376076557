export const CUSTOMERCARE_LIST = 'CUSTOMERCARE_LIST';
export const CUSTOMERCARE_DETAIL = 'CUSTOMERCARE_DETAIL';
export const CUSTOMERCARE_UPDATE = 'CUSTOMERCARE_UPDATE';
export const CUSTOMERCARE_APPOINTMENT = 'CUSTOMERCARE_APPOINTMENT';
export const CUSTOMERCARE_CARD = 'CUSTOMERCARE_CARD';
export const CUSTOMERCARE_PROFILE = 'CUSTOMERCARE_PROFILE';
export const CUSTOMERCARE_NEW_CUSTOMER = 'CUSTOMERCARE_NEW_CUSTOMER';
export const CUSTOMERCARE_CUSTOMER_BIRTHDAY = 'CUSTOMERCARE_CUSTOMER_BIRTHDAY';
export const CATEGORIES_CHECK_LIST = 'CATEGORIES_CHECK_LIST';
export const CATEGORIES_RESULT_LIST = 'CATEGORIES_RESULT_LIST';
export const CATEGORIES_CLASSIFY_LIST = 'CATEGORIES_CLASSIFY_LIST';

export default {
    CUSTOMERCARE_LIST,
    CUSTOMERCARE_DETAIL,
    CUSTOMERCARE_UPDATE,
    CUSTOMERCARE_APPOINTMENT,
    CUSTOMERCARE_CARD,
    CUSTOMERCARE_PROFILE,
    CUSTOMERCARE_NEW_CUSTOMER,
    CUSTOMERCARE_CUSTOMER_BIRTHDAY,
    CATEGORIES_CHECK_LIST,
    CATEGORIES_RESULT_LIST,
    CATEGORIES_CLASSIFY_LIST
};
