export const EMPLOYEE_TITLE_LIST = 'EMPLOYEE_TITLE_LIST';
export const EMPLOYEE_TITLE_DETAIL = 'EMPLOYEE_TITLE_DETAIL';
export const EMPLOYEE_TITLE_UPDATE = 'EMPLOYEE_TITLE_UPDATE';
export const EMPLOYEE_TITLE_INSERT = 'EMPLOYEE_TITLE_INSERT';
export const EMPLOYEE_TITLE_DELETE = 'EMPLOYEE_TITLE_DELETE';
export const EMPLOYEE_TITLE_GET_ALL = 'EMPLOYEE_TITLE_GET_ALL';
export default {
    EMPLOYEE_TITLE_DELETE,
    EMPLOYEE_TITLE_DETAIL,
    EMPLOYEE_TITLE_INSERT,
    EMPLOYEE_TITLE_LIST,
    EMPLOYEE_TITLE_UPDATE,
    EMPLOYEE_TITLE_GET_ALL
};
