import {
    USER_DELETE,
    USER_DETAIL,
    USER_INSERT,
    USER_LIST,
    USER_RESPONSE,
    USER_UPDATE,
    USER_GET_ALL,
    USER_GETCODE,
    USERS_GET_ALL,
    USER_DETAIL_RESET,
    USER_SELECT,
    USER_BRANCH,
    USER_ACTIVE
} from './action-types';

export function select(payload) {
    return {
        type: USER_SELECT,
        payload
    };
}

export function getCode(payload) {
    return {
        type: USER_GETCODE,
        payload
    };
}

export function resetDetail() {
    return {
        type: USER_DETAIL_RESET
    };
}

export function list(payload) {
    return {
        type: USER_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: USER_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: USER_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: USER_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: USER_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: USER_RESPONSE,
        payload
    };
}
export function getAll(payload) {
    return {
        type: USER_GET_ALL,
        payload
    };
}

export function getBranchUsers(payload) {
    return {
        type: USER_BRANCH,
        payload
    };
}

export function suggestion(payload) {
    return {
        type: USERS_GET_ALL,
        payload
    };
}

export function getUserActive(payload) {
    return {
        type: USER_ACTIVE,
        payload
    };
}
