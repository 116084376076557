import { connect } from 'react-redux';
import { districtGetAll } from '../service';
import BaseSelectBox from '../../../../base/components/BaseSelectBox';

class DistrictSelectBox extends BaseSelectBox {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            payload: {
                provinceId: this.props.provinceId
            }
        };
    }

    componentDidMount() {
        if (this.props.provinceId) {
            this.getAllData();
        } else {
            this.setState({
                datas: {},
                renderDone: true
            });
        }
    }

    getAllData() {
        const { listData } = this.props;
        const { payload } = this.state;

        if (this.props.provinceId === null) {
            this.setState({
                datas: {}
            });
        } else {
            if (listData && listData.length > 0 && listData[0].provinceId === this.props.provinceId) {
                this.handleLanguage();
            } else {
                this.props.getAllData(payload).then(() => {
                    this.handleLanguage();
                });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.provinceId !== this.state.payload.provinceId) {
            const payload = { ...this.state.payload, provinceId: nextProps.provinceId };

            this.setState(
                {
                    payload
                },
                () => {
                    this.getAllData();
                }
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.appSettings.data.languages,
        listData: state.district.suggestion
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: (payload) => {
            return dispatch(districtGetAll(payload));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DistrictSelectBox);
