import useJSON from '../data/useJSON';

const FolderVM = (data) => {
    const returnData = useJSON(data);

    returnData['items'] = data.children.map((item) => FolderVM(item));
    returnData['selected'] = data.data.isPublic;

    return returnData;
};

export { FolderVM };
