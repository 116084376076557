import { WEBSITE_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import * as websiteActions from './store/actions';
import { spinnerLoading } from '../../../common/spinner/store/action';
import * as _ from 'lodash';

export function websiteSearchRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            let url = `${WEBSITE_URL}Websites?keyword=${payload.keyword}`;
            url += payload.isActive !== null ? `&isActive=${payload.isActive}` : '';
            url += payload.page ? `&page=${payload.page}` : '';
            url += payload.pageSize ? `&pageSize=${payload.pageSize}` : '';

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(websiteActions.list(res.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function websiteSearchNameValid(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites?keyword=${payload.keyword}`;

            Http.get(url)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function websiteInsertRequest(website) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${WEBSITE_URL}Websites`;
            const websiteUpdate = Object.assign({}, website);
            websiteUpdate.name = JSON.stringify(website.name);
            websiteUpdate.address = JSON.stringify(website.address);
            websiteUpdate.note = JSON.stringify(website.note);

            Http.post(url, websiteUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    websiteUpdate.id = res.data.data;
                    dispatch(websiteActions.insert(websiteUpdate));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function websiteUpdateRequest(website) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${WEBSITE_URL}Websites/${website.id}`;
            const websiteUpdate = Object.assign({}, website);
            websiteUpdate.name = JSON.stringify(website.name);
            websiteUpdate.address = JSON.stringify(website.address);
            websiteUpdate.note = JSON.stringify(website.note);

            Http.put(url, websiteUpdate)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    websiteUpdate.concurrencyStamp = res.data.data;
                    dispatch(websiteActions.update(websiteUpdate));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function websiteUpdateActiveRequest(website) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${WEBSITE_URL}Websites/active/${website.id}/${website.isActive}`;

            Http.put(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(websiteActions.updateActive(website));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err.response.data.message);
                });
        });
}

export function websiteDetailRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${WEBSITE_URL}Websites/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(websiteActions.detail(res.data.data));
                    resolve();
                })
                .catch((err) => {
                    // console.log('err', err)
                    reject(err.response.data.message);
                });
        });
}

export function websiteDeleteRequest(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${WEBSITE_URL}Websites/${payload.id}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(websiteActions.deletes(payload.id));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
    };
}

export function websiteGetAllRequest() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/get-all`;

            Http.get(url)
                .then((res) => {
                    dispatch(websiteActions.suggestion(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function websiteGetAllByUserRequest() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/get-all-by-user`;

            Http.get(url)
                .then((res) => {
                    dispatch(websiteActions.getAllbyUser(res.data));
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function websiteSelectedItemUpdate(payload) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(websiteActions.select(payload));
            resolve();
        });
    };
}

export function websiteLanguagesGetRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/${payload.id}/languages`;

            Http.get(url)
                .then((res) => {
                    dispatch(websiteActions.getLanguage(res.data));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function websiteLanguagesInsertRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/${payload.websiteId}/languages`;

            Http.post(url, payload.websiteLanguageMeta)
                .then((res) => {
                    dispatch(websiteActions.insertLanguage(payload.websiteLanguageMeta));
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
}

export function websiteLanguagesActiveUpdateRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/${payload.websiteId}/language/${payload.languageId}/active/${payload.isActive}`;

            Http.put(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
}

export function websiteLanguagesDefaultUpdateRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/${payload.websiteId}/language/${payload.languageId}/default/${payload.isDefault}`;

            Http.put(url)
                .then((res) => {
                    resolve(res.data.message);
                })
                .catch((err) => {
                    reject(err.response.data.message);
                });
        });
}

export function websiteLanguagesDeleteRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/${payload.websiteId}/languages/${payload.languageId}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(websiteActions.deleteLanguage(res.data));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function websiteUsersGetRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/user/get-all/${payload.id}`;

            Http.get(url)
                .then((res) => {
                    dispatch(websiteActions.getUser(_.map(res.data, 'userId')));
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function websiteUsersInsertRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/user/${payload.id}/${payload.userId}`;

            Http.post(url)
                .then((res) => {
                    dispatch(websiteActions.insertUser(res.data.data));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function websiteUsersDeleteRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${WEBSITE_URL}Websites/user/${payload.id}/${payload.userId}`;

            Http.delete(url)
                .then((res) => {
                    dispatch(websiteActions.deleteUser(res.data.data));
                    resolve(res.data.message);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}
