import { GROUPS_DETAIL, GROUPS_INSERT, GROUPS_LIST, GROUPS_TREE, GROUPS_DELETE, GROUPS_UPDATE } from './action-types';
import { PAGE_SIZE } from '../../../../constants/Config';

const initialState = {
    data: [],
    totalRows: 0,
    currentPAGE: 0,
    pageSize: PAGE_SIZE,
    tree: [],
    action: null,
    item: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GROUPS_LIST:
            return list(state, payload);
        case GROUPS_TREE:
            return tree(state, payload);
        case GROUPS_DETAIL:
            return detail(state, payload);
        case GROUPS_UPDATE:
            return update(state, payload);
        case GROUPS_INSERT:
            return insert(state, payload);
        case GROUPS_DELETE:
            return deletes(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function tree(state, payload) {
    state = Object.assign({}, state, {
        tree: payload
    });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    const item = { ...state.item, ...payload };
    return Object.assign({}, state, { data, item });
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);

    return Object.assign({}, state, { data });
}
// function pageActionDetail(state, payload) {
//     return state
// }
// function pageActionDelete(state, payload) {
//         const item = _.find(state.data, (item) => {
//             return item.id === payload.pageId
//         })
//     const resourcesActions = _.map(item.resourceActions, (item) => {
//          if(item.id !== payload.id) {
//             return item;
//          }
//     })
//     const items = Object.assign({}, item, {resourcesActions})
//         const data = state.data.map(obj => {
//             if(obj.id === payload.pageId) {
//                 return {...obj, ...items}
//             }
//             return obj
//         })
//     return  Object.assign({}, state, {data})
// }

// function pageActionUpdate(state, payload) {
//     const item = _.find(state.data, (item) => {
//         return item.id === payload.pageId
//     })
//     const resourcesActions = _.map(item.resourceActions, (item) => {
//         if(item.id === payload.id) {
//             return {...item, ...payload};
//         }
//         return item
//     })
//     const items = Object.assign({}, item, {resourcesActions})
//     const data = state.data.map(obj => {
//         if(obj.id === payload.pageId) {
//             return {...obj, ...items}
//         }
//         return obj
//     })
//     return  Object.assign({}, state, {data})
// }

// function pageActionInsert(state, payload) {
//     const resourceActions = [...state.item.resourceActions, payload]
//     const item = Object.assign({}, state.item, {resourceActions: resourceActions})
//     return Object.assign({}, state, { item })

// }

export default reducer;
