import { PAGE_SIZE } from '../../../../constants/Config';
import {
    EMPLOYEE_OFFICE_DELETE,
    EMPLOYEE_OFFICE_DETAIL,
    EMPLOYEE_OFFICE_INSERT,
    EMPLOYEE_OFFICE_LIST,
    EMPLOYEE_OFFICE_UPDATE,
    EMPLOYEE_OFFICE_TREE
} from './action-types';
import Transformer from '../../../../utils/Transformer';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    items: [],
    item: null,
    suggestion: [],
    tree: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case EMPLOYEE_OFFICE_LIST:
            return list(state, payload);
        case EMPLOYEE_OFFICE_DETAIL:
            return detail(state, payload);
        case EMPLOYEE_OFFICE_UPDATE:
            return update(state, payload);
        case EMPLOYEE_OFFICE_INSERT:
            return insert(state, payload);
        case EMPLOYEE_OFFICE_DELETE:
            return deletes(state, payload);
        case EMPLOYEE_OFFICE_TREE:
            return tree(state, payload);
        default:
            return state;
    }
};

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);
    const tree = Transformer.deleteTree(state.tree, payload);

    return Object.assign({}, state, { data, tree }, { totalRows: state.totalRows - 1 });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    const tree = Transformer.updateTree(state.tree, payload);

    const item = { ...state.item, ...payload };
    return Object.assign({}, state, { data, tree, item });
}

function insert(state, payload) {
    const insertItem = {
        id: parseInt(payload.id),
        parentId: payload.parentId,
        text: payload.name,
        children: []
    };

    const data = [...state.data, payload];

    if (payload.parentId === null) {
        state.tree = [...state.tree, insertItem];
    } else {
        Transformer.insertTree(state.tree, insertItem);
    }

    return Object.assign({}, state, { data }, { totalRows: state.totalRows + 1 });
}

function tree(state, payload) {
    state = Object.assign({}, state, { tree: payload });
    return state;
}
export default reducer;
