export const viS = {
    service: {
        list: {
            title: 'Danh sách Dịch vụ',
            unit: 'dịch vụ',
            commissioneRate: '% Hoa Hồng'
        },
        form: {
            info: 'Thông tin dịch vụ',
            material: 'Vật tư tiêu hao',
            staff: 'Nhân viên thực hiện',
            titleUpdate: 'Chỉnh sửa dịch vụ',
            titleInsert: 'Thêm mới dịch vụ',
            active: 'Hoạt động',
            time: 'Thời gian thực hiện',
            selectImg: 'Chọn ảnh',
            price: 'Giá'
        }
    },
    serviceCard: {
        list: { commissioneRate: 'Hoa Hồng' },
        form: {
            infoTitle: 'Thông tin Thẻ dịch vụ',
            insertTitle: 'Thêm mới Thẻ dịch vụ',
            updateTitle: 'Chỉnh sửa Thẻ dịch vụ'
        }
    },
    systemInfo: {
        basicInfo: {
            title: 'Thông tin cơ bản',
            hotline: 'Hotline',
            name: 'Tên Spa',
            address: 'Địa chỉ',
            slogan: 'Slogan',
            logo: 'Logo'
        },
        shiftInfo: {
            title: 'Thông tin ca làm việc',
            list: 'Danh sách Ca làm việc'
        },
        systemConfig: {
            title: 'Cấu hình hệ thống',
            selectImg: 'Chọn ảnh',
            job: 'Nghề nghiệp'
        }
    },
    system: {
        shift: {
            unit: 'ca',
            shiftName: 'Tên ca làm việc',
            insertTitle: 'Thêm mới Ca làm việc',
            updateTitle: 'Chỉnh sửa Ca làm việc',
            hoursOfWork: 'Số giờ làm việc'
        }
    },
    serviceProduct: {
        error: { required: 'Sản phẩm không được bỏ trống' },
        list: {
            title: 'Danh sách Vật tư tiêu hao',
            code: 'Mã Vật tư',
            name: 'Tên Vật tư',
            amount: 'Số lượng',
            unit: 'Đơn vị'
        },
        form: {
            insertTitle: 'Thêm mới Vật tư tiêu hao',
            updateTitle: 'Chỉnh sửa Vật tư tiêu hao',
            product: 'Vật tư',
            quantity: 'Số lượng',
            code: 'Mã vật tư',
            unit: 'Đơn vị'
        }
    },
    serviceNote: {
        info: { code: 'Mã phiếu' },
        list: { title: 'Danh sách phiếu khách hàng' },
        error: {
            required: 'Lý do giảm giá không được bỏ trống',
            serviceRequired: 'Dịch vụ không được bỏ trống',
            placeRequired: 'Khu vực không được bỏ trống',
            serviceStaffRequire: 'Nhân viên đặt hẹn không được để trống',
            discountNoteRequired: 'Lí do giảm giá không được để trống'
        },
        customerCode: 'Mã KH',
        phoneNumber: 'Số ĐT'
    },
    serviceNoteDetail: {
        form: {
            insertTitle: 'Thêm mới dịch vụ',
            updateTitle: 'Chỉnh sửa dịch vụ',
            serviceName: 'Tên dịch vụ',
            serviceCode: 'Mã dịch vụ',
            placeName: 'Khu Vực',
            staff: 'Nhân Viên làm'
        }
    },
    setting: {
        title: { label: 'Cấu hình' },
        account: {
            title: 'Danh sách tài khoản',
            role: 'Quyền người dùng'
        },
        page: { title: 'Danh sách trang' },
        name: { label: 'Tên' },
        role: { title: 'Danh sách quyền', info: 'Thông tin quyền' },
        area: { title: 'Danh sách khu vực' },
        customer: { title: 'Danh sách khách hàng' },
        branch: { title: 'Danh sách chi nhánh' }
    },
    supplier: {
        titlePopup: {
            Addnewsupplier: 'Thêm mới nhà cung cấp',
            Editsupplier: 'Chỉnh sửa nhà cung cấp'
        }
    }
};
