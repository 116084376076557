export const BOOKING_LIST = 'BOOKING_LIST';
export const BOOKING_GETALL = 'BOOKING_GETALL';
export const BOOKING_DETAIL = 'BOOKING_DETAIL';
export const BOOKING_INSERT = 'BOOKING_INSERT';
export const BOOKING_UPDATE = 'BOOKING_UPDATE';
export const BOOKING_DELETE = 'BOOKING_DELETE';
export const CURRENT_PAGE = 'CURRENT_PAGE';

export const BOOKING_APPROVE_STATUS = 'BOOKING_APPROVE_STATUS';
export const BOOKING_UPDATE_HOT = 'BOOKING_UPDATE_HOT';
export const BOOKING_UPDATE_HOMEPAGE = 'BOOKING_UPDATE_HOMEPAGE';
export const BOOKING_UPDATE_VIEW_COUNT = 'BOOKING_UPDATE_VIEW_COUNT';
export const BOOKING_GETALL_TRANSLATION = 'BOOKING_GETALL_TRANSLATION';
export const BOOKING_INSERT_TRANSLATION = 'BOOKING_INSERT_TRANSLATION';
export const BOOKING_DETAIL_TRANSLATION = 'BOOKING_DETAIL_TRANSLATION';
export const BOOKING_UPDATE_TRANSLATION = 'BOOKING_UPDATE_TRANSLATION';
export const BOOKING_DELETE_TRANSLATION = 'BOOKING_DELETE_TRANSLATION';

export const CATEGORY_GETALL = 'CATEGORY_GETALL';
