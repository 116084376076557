import React, { useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import * as events from 'devextreme/events';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
function SideNavigationMenu({ openMenu, pages, currentLanguage }) {
    const treeViewRef = useRef();
    const wrapperRef = useRef();
    const getWrapperRef = useCallback(
        (element) => {
            const prevElement = wrapperRef.current;
            if (prevElement) {
                events.off(prevElement, 'dxclick');
            }

            wrapperRef.current = element;
            events.on(element, 'dxclick', (e) => {
                openMenu(e);
            });
        },
        [openMenu]
    );

    const renderSidebarItem = (pages, parentId) => {
        const sidebarItems = [];

        const childrenPages = _.filter(pages, (page) => {
            return page.parentId === parentId;
        });

        if (childrenPages) {
            childrenPages.forEach((page) => {
                page.items = renderSidebarItem(pages, page.id);
                sidebarItems.push({
                    ...page,
                    text: JSON.parse(page.name)[currentLanguage],
                    icon: page.icon,
                    url: page.url
                });
            });
        }

        return sidebarItems;
    };

    const menu = renderSidebarItem(pages, null);

    const items = useMemo(
        () =>
            menu.map((item) => ({
                text: JSON.parse(item.name)[currentLanguage],
                icon: item.icon,
                items: item.items,
                url: item.url
            })),
        [currentLanguage]
    );
    function renderTreeViewItem(item) {
        if (item.url) {
            return (
                <Link to={item.url ? item.url : '#'}>
                    <i className={`dx-icon dx-icon-${item.icon ? item.icon : 'bulletlist'}`}></i>
                    <span>{item.text}</span>
                </Link>
            );
        } else {
            return (
                <Link to={'#'}>
                    <i className={`dx-icon dx-icon-${item.icon ? item.icon : 'bulletlist'}`}></i>
                    <span>{item.text}</span>
                </Link>
            );
        }
    }
    return (
        <div
            className={'dx-swatch-additional side-navigation-menu dx-theme-accent-as-background-color'}
            ref={getWrapperRef}
        >
            <div className={'menu-container dx-theme-accent-as-background-color'}>
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr={'name'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    searchEnabled={true}
                    searchMode={'contains'}
                    expandEvent={'click'}
                    width={'250px'}
                    itemRender={(e) => renderTreeViewItem(e)}
                />
            </div>
        </div>
    );
}
SideNavigationMenu.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    pages: PropTypes.array,
    openMenu: PropTypes.any
};
export default SideNavigationMenu;
