import React, { Component } from 'react';
import { FILE_URL, DEFAULT_IMG } from '../../constants/Config';
import i18n from '../../i18n';
import { Button } from 'devextreme-react';
import FileChoosePopup from '../../modules/filemanagement/FileChoosePopup';
import PropTypes from 'prop-types';
import Transformer from '../../utils/Transformer';

class AvatarBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowImgPicker: false
        };

        this.handleUploadImg = this.handleUploadImg.bind(this);
        this.onSetAvatar = this.onSetAvatar.bind(this);
        this.handleRemoveImg = this.handleRemoveImg.bind(this);
    }

    handleUploadImg() {
        this.setState({
            isShowImgPicker: true
        });
    }

    handleRemoveImg() {
        this.props.updateData({
            avatar: ''
        });
    }

    hideFileChoosePopup = () => {
        this.setState({
            isShowImgPicker: false
        });
    };

    onSetAvatar(e) {
        this.setState({
            currentUrl: e.dataItem.url,
            isShowImgPicker: false
        });

        this.props.updateData({
            [this.props.field]: e.dataItem.url
        });
    }

    render() {
        const { value, editable } = this.props;
        const { isShowImgPicker } = this.state;

        return (
            <div className="wrapper-button-center">
                <div className={'mb-3 text-center'}>
                    {value !== '' ? (
                        <img
                            onError={Transformer.ImageOnError}
                            src={`${FILE_URL}/${value}`}
                            alt="Copyright Of GHMSoft"
                            height="200"
                        />
                    ) : (
                        <img
                            onError={Transformer.ImageOnError}
                            src={`${DEFAULT_IMG}`}
                            alt="Copyright Of GHMSoft"
                            height="200"
                        />
                    )}
                </div>
                {editable && (
                    <div className="d-flex justify-content-center mb-2">
                        <Button text={i18n.t('base.selectImg')} icon="photo" onClick={this.handleUploadImg} />

                        <Button
                            className="ml-3"
                            icon="close"
                            text={i18n.t('base.removeImg')}
                            onClick={this.handleRemoveImg}
                        />
                    </div>
                )}

                {isShowImgPicker && (
                    <FileChoosePopup
                        hidePopup={this.hideFileChoosePopup}
                        isShowPopup={this.state.isShowImgPicker}
                        allowedExt={['.jpg', '.png']}
                        onFileSelected={this.onSetAvatar}
                    />
                )}
            </div>
        );
    }
}

AvatarBox.propTypes = {
    value: PropTypes.string,
    field: PropTypes.string,
    updateData: PropTypes.func,
    editable: PropTypes.bool
};

export default AvatarBox;
