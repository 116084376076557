import React, { Component } from 'react';
import { DateBox } from 'devextreme-react';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';

class TimeBox extends Component {
    constructor(props) {
        super(props);

        this.validator = new ReeValidate({
            time: 'required'
        });

        if (this.props.currentLanguage === 'vi-VN') {
            this.validator.localize('vi', vi);
        } else {
            this.validator.localize('en', en);
        }

        this.validator.validate('time', this.props.value).then(() => {
            this.checkValidate();
        });

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(e) {
        if (e.event !== undefined && e.event !== null) {
            const { errors } = this.validator;

            errors.remove('time');

            this.props.updateData({
                [this.props.field]: e.value
            });

            this.validator
                .validate('time', e.value)
                .then(() => {
                    this.checkValidate();
                })
                .catch(() => {});
        }
    }

    checkValidate() {
        const { field } = this.props;

        const formValidate = !this.validator.errors.has('time');
        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    render() {
        const { i18nLabel, value } = this.props;

        return (
            <div className={'' + (this.validator.errors.has('time') ? ' has-error' : '')}>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        <span className="color-red"> *</span>
                    </div>

                    <div className={'dx-field-value'}>
                        <DateBox
                            type={'time'}
                            onValueChanged={this.onValueChanged}
                            value={value}
                            showClearButton={true}
                            pickerType={'native'}
                        />

                        {this.validator.errors.has('time') && (
                            <div className="help-block">{this.validator.errors.first('time')}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default TimeBox;
