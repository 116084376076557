import i18next from 'i18next';
import { i18nVi } from './vi';
import { i18nEn } from './en';

i18next.init({
    interpolation: {
        // React already does escaping
        escapeValue: false
    },
    lng: 'vi-VN', // 'en' | 'es'
    // Using simple hardcoded resources for simple example
    resources: {
        'vi-VN': i18nVi,
        'en-US': i18nEn
    }
});

export default i18next;
