import { spinnerLoading } from '../../../common/spinner/store/action';
import { CORE_URL, FIREBASE_URL } from '../../../constants/Config';
import Http from '../../../utils/Http';
import Transformer from '../../../utils/Transformer';
import { coreInitApp, getApp, settingConfig, updateUserSettings } from './store/actions';

export function initAppService() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(`${CORE_URL}appsettings`)
                .then((res) => {
                    const data = Transformer.fetch(res.data);
                    dispatch(coreInitApp(data));
                    // console.log(res);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function insertUserTokenPostRequest(payload) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            const url = `${FIREBASE_URL}UserTokens`;

            Http.post(url, payload)
                .then((res) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

export function getAppSettings() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(getApp());
            resolve();
        });
}

export function configSearchRequest() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}AppSettings/settings`;
            Http.get(url)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    dispatch(settingConfig(res.data.data));
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
}

export function configInsertRequest(configSystem) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(spinnerLoading(true));
            const url = `${CORE_URL}AppSettings/settings`;
            Http.post(url, configSystem)
                .then((res) => {
                    dispatch(spinnerLoading(false));
                    resolve(res.data);
                })
                .catch((err) => {
                    dispatch(spinnerLoading(false));
                    reject(err);
                });
        });
}

export function updateLanguageRequest(languageId) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(
                updateUserSettings({
                    key: 'LanguageId',
                    value: languageId
                })
            );
            resolve();
        });
}
