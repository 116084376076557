export const JOB_LIST = 'JOB_LIST';
export const CURRENT_PAGE = 'CURRENT_PAGE';
export const JOB_INSERT = 'JOB_INSERT';
export const JOB_DETAIL = 'JOB_DETAIL';
export const JOB_UPDATE = 'JOB_UPDATE';
export const JOB_GETALL = 'JOB_GETALL';

export default {
    JOB_LIST,
    CURRENT_PAGE,
    JOB_INSERT,
    JOB_DETAIL,
    JOB_UPDATE,
    JOB_GETALL
};
