import React, { Component } from 'react';
import { NumberBox } from 'devextreme-react';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class PercentageBox extends Component {
    constructor(props) {
        super(props);

        this.onChangeValue = this.onChangeValue.bind(this);
    }

    onChangeValue(e) {
        if (e.event !== undefined && e.event !== null) {
            if (e.event.type !== 'dxclick') {
                this.props.updateData({
                    [this.props.field]: e.value
                });
            }
        }
    }

    render() {
        const { value, i18nLabel, readOnly } = this.props;

        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>{i18n.t(i18nLabel)} % :</div>
                <div className={'dx-field-value'}>
                    <NumberBox
                        readOnly={readOnly || false}
                        value={value}
                        format="#0.00"
                        max={100}
                        min={0}
                        onValueChanged={this.onChangeValue}
                    />
                </div>
            </div>
        );
    }
}

PercentageBox.propTypes = {
    updateData: PropTypes.func,
    i18nLabel: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.any,
    field: PropTypes.string
};

export default PercentageBox;
