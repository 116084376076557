import React, { Component } from 'react';
import { TextBox, Button } from 'devextreme-react/text-box';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';

class PasswordBox extends Component {
    constructor(props) {
        super(props);

        this.validator = new ReeValidate({
            [i18n.t(props.i18nLabel)]: this.props.validatorValue
        });

        this.state = {
            errors: this.validator.errors,
            mode: 'password'
        };

        this.valueChanged = this.valueChanged.bind(this);

        this.updateMode = {
            icon: '/assets/images/eye.png',
            type: 'default',
            onClick: () => {
                this.setState({
                    mode: this.state.mode === 'text' ? 'password' : 'text'
                });
            }
        };

        if (this.props.currentLanguage === 'vi-VN') {
            this.validator.localize('vi', vi);
        } else {
            this.validator.localize('en', en);
        }
    }

    valueChanged = (e) => {
        if (e.event !== undefined && e.event !== null) {
            if (e.event.type !== 'dxclick') {
                const { errors } = this.validator;

                errors.remove(e.event.target.name);
                this.props.updateData({
                    [this.props.field]: e.event.target.value
                });

                this.validator
                    .validate(e.event.target.name, e.event.target.value)
                    .then(() => {
                        this.setState({ errors });

                        const formValidate = !errors.has(this.props.field);
                        this.props.updateData({
                            formValidate
                        });
                    })
                    .catch(() => {});
            }
        }
    };

    UNSAFE_componentWillReceiveProps(nextProp) {
        if (this.props.currentLanguage === 'vi-VN') {
            this.validator.localize('vi', vi);
        } else {
            this.validator.localize('en', en);
        }
    }

    render() {
        const { value, i18nLabel, showEye } = this.props;
        const { errors, mode } = this.state;
        return (
            <div className={'' + (errors.has(i18n.t(i18nLabel)) ? ' has-error' : '')}>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel)} :<span className="color-red"> *</span>
                    </div>

                    <div className={'dx-field-value'}>
                        <TextBox
                            mode={mode}
                            className={'multi-language'}
                            name={i18n.t(i18nLabel)}
                            value={value}
                            onValueChanged={this.valueChanged}
                        >
                            {showEye && (
                                <Button name={i18n.t(i18nLabel)} location={'after'} options={this.updateMode} />
                            )}
                        </TextBox>

                        {errors.has(i18n.t(i18nLabel)) && (
                            <div className="help-block">{errors.first(i18n.t(i18nLabel))}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordBox;
