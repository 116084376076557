import branch from './branch/store/reducer';
import brand from './brand/store/reducer';
import supplier from './supplier/store/reducer';
import country from './country/store/reducer';
import district from './district/store/reducer';
import job from './job/store/reducer';
import methodOfPayment from './methodOfPayment/store/reducer';
import nation from './nation/store/reducer';
import province from './province/store/reducer';
import religion from './religion/store/reducer';
import resource from './resource/store/reducer';

export default {
    branch,
    brand,
    country,
    district,
    job,
    methodOfPayment,
    nation,
    province,
    religion,
    resource,
    supplier
};
