export const GOODS_NOTE_LIST = 'GOODS_NOTE_LIST';
export const GOODS_NOTE_DETAIL = 'GOODS_NOTE_DETAIL';
export const GOODS_NOTE_INSERT = 'GOODS_NOTE_INSERT';
export const GOODS_NOTE_DELETE = 'GOODS_NOTE_DELETE';
export const GOODS_NOTE_UPDATE = 'GOODS_NOTE_UPDATE';

export default {
    GOODS_NOTE_LIST,
    GOODS_NOTE_DETAIL,
    GOODS_NOTE_INSERT,
    GOODS_NOTE_DELETE,
    GOODS_NOTE_UPDATE
};
