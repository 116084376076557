export const SERVICE_PRODUCT_LIST = 'SERVICE_PRODUCT_LIST';
export const SERVICE_PRODUCT_DETAIL = 'SERVICE_PRODUCT_DETAIL';
export const SERVICE_PRODUCT_UPDATE = 'SERVICE_PRODUCT_UPDATE';
export const SERVICE_PRODUCT_INSERT = 'SERVICE_PRODUCT_INSERT';
export const SERVICE_PRODUCT_DELETE = 'SERVICE_PRODUCT_DELETE';
export const SERVICE_PRODUCT_SELECT = 'SERVICE_PRODUCT_SELECT';

export default {
    SERVICE_PRODUCT_DELETE,
    SERVICE_PRODUCT_DETAIL,
    SERVICE_PRODUCT_INSERT,
    SERVICE_PRODUCT_LIST,
    SERVICE_PRODUCT_UPDATE,
    SERVICE_PRODUCT_SELECT
};
