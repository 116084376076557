import Loadable from 'react-loadable';
import LoadingComponent from '../../../common/loader/index';

export default [
    {
        path: '/login',
        exact: true,
        component: Loadable({
            loader: () => import('./login'),
            loading: LoadingComponent
        })
    },
    {
        path: '/change-password',
        exact: true,
        component: Loadable({
            loader: () => import('./changepass'),
            loading: LoadingComponent
        })
    },
    {
        path: '/forgot-password',
        exact: true,
        component: Loadable({
            loader: () => import('./forgotpass'),
            loading: LoadingComponent
        })
    },
    {
        path: '/forgot-password/email',
        exact: true,
        component: Loadable({
            loader: () => import('./forgotemail'),
            loading: LoadingComponent
        })
    }
];
