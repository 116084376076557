import { REVIEW_RATE } from '../../../constants/Config';
import BaseConstantSelectBox from '../../components/BaseConstantSelectBox';

class WorkmanshipSelectBox extends BaseConstantSelectBox {
    constructor(props) {
        super(props);

        this.initData = REVIEW_RATE;
    }

    onDataChanged(e) {
        if (e.value !== null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: e.value
            });
        }
        if (e.value === null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: ''
            });
        }
    }
}

export default WorkmanshipSelectBox;
