import Loadable from 'react-loadable';

import LoadingComponent from '../../common/loader/index';
export default [
    {
        path: '/product',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/product/unit',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./unit/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/product/groupproduct',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./groupProduct/list/index'),
            loading: LoadingComponent
        })
    }
];
