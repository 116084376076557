import {
    CUSTOMERCARE_DETAIL,
    CUSTOMERCARE_LIST,
    CUSTOMERCARE_APPOINTMENT,
    CUSTOMERCARE_CARD,
    CUSTOMERCARE_PROFILE,
    CUSTOMERCARE_NEW_CUSTOMER,
    CUSTOMERCARE_CUSTOMER_BIRTHDAY,
    // CUSTOMERCARE_UPDATE,
    CATEGORIES_CHECK_LIST,
    CATEGORIES_RESULT_LIST,
    CATEGORIES_CLASSIFY_LIST
} from './action-types';
import { PAGE_SIZE } from '../../../constants/Config';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: {},
    items: [],
    suggestion: [],
    appointment: [],
    totalRowsAppointment: 0,
    totalRevenue: [],
    customerCard: [],
    totalRowsCustomerCard: 0,
    profile: [],
    totalRowsProfile: 0,
    newCustomers: [],
    totalRowsNewCus: 0,
    customerBirthday: [],
    totalRowsBirthday: 0,
    checkList: [],
    resultList: [],
    classifyList: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case CUSTOMERCARE_LIST:
            return list(state, payload);
        case CUSTOMERCARE_DETAIL:
            return detail(state, payload);
        case CUSTOMERCARE_APPOINTMENT:
            return appointment(state, payload);
        case CUSTOMERCARE_CARD:
            return card(state, payload);
        case CUSTOMERCARE_PROFILE:
            return profile(state, payload);
        case CUSTOMERCARE_NEW_CUSTOMER:
            return newCustomersRpt(state, payload);
        case CUSTOMERCARE_CUSTOMER_BIRTHDAY:
            return customerBirthdayRpt(state, payload);
        case CATEGORIES_CHECK_LIST:
            return getCheckList(state, payload);
        case CATEGORIES_RESULT_LIST:
            return getResultList(state, payload);
        case CATEGORIES_CLASSIFY_LIST:
            return getClassifyList(state, payload);
        default:
            return state;
    }
};

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return state;
}

function appointment(state, payload) {
    state = Object.assign({}, state, {
        appointment: payload.data,
        totalRowsAppointment: payload.totalRows
    });
    return state;
}

function card(state, payload) {
    state = Object.assign({}, state, {
        customerCard: payload.data,
        totalRowsCustomerCard: payload.totalRows
    });
    return state;
}

function profile(state, payload) {
    state = Object.assign({}, state, {
        profile: payload
    });
    return state;
}

function newCustomersRpt(state, payload) {
    state = Object.assign({}, state, {
        newCustomers: payload.data,
        totalRowsNewCus: payload.totalRows
    });
    return state;
}

function customerBirthdayRpt(state, payload) {
    state = Object.assign({}, state, {
        customerBirthday: payload.data,
        totalRowsBirthday: payload.totalRows
    });
    return state;
}

function getResultList(state, payload) {
    state = Object.assign({}, state, { resultList: payload.data });
    return state;
}

function getCheckList(state, payload) {
    state = Object.assign({}, state, { checkList: payload.data });
    return state;
}

function getClassifyList(state, payload) {
    state = Object.assign({}, state, { classifyList: payload.data });
    return state;
}

export default reducer;
