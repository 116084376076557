export const WAREHOUSE_MANAGER_CONFIG_LIST = 'WAREHOUSE_MANAGER_CONFIG_LIST';
export const WAREHOUSE_MANAGER_CONFIG_DETAIL = 'WAREHOUSE_MANAGER_CONFIG_DETAIL';
export const WAREHOUSE_MANAGER_CONFIG_UPDATE = 'WAREHOUSE_MANAGER_CONFIG_UPDATE';
export const WAREHOUSE_MANAGER_CONFIG_INSERT = 'WAREHOUSE_MANAGER_CONFIG_INSERT';
export const WAREHOUSE_MANAGER_CONFIG_DELETE = 'WAREHOUSE_MANAGER_CONFIG_DELETE';
export const WAREHOUSE_MANAGER_CONFIG_RESPONSE = 'WAREHOUSE_MANAGER_CONFIG_RESPONSE';
export const WAREHOUSE_MANAGER_CONFIG_GETALL = 'WAREHOUSE_MANAGER_CONFIG_GETALL';
export default {
    WAREHOUSE_MANAGER_CONFIG_DELETE,
    WAREHOUSE_MANAGER_CONFIG_DETAIL,
    WAREHOUSE_MANAGER_CONFIG_INSERT,
    WAREHOUSE_MANAGER_CONFIG_LIST,
    WAREHOUSE_MANAGER_CONFIG_UPDATE,
    WAREHOUSE_MANAGER_CONFIG_GETALL
};
