export const BRANCH_CONTACT_LIST = 'BRANCH_CONTACT_LIST';
export const BRANCH_CONTACT_INSERT = 'BRANCH_CONTACT_INSERT';
export const BRANCH_CONTACT_DETAIL = 'BRANCH_CONTACT_DETAIL';
export const BRANCH_CONTACT_DELETE = 'BRANCH_CONTACT_DELETE';
export const BRANCH_CONTACT_UPDATE = 'BRANCH_CONTACT_UPDATE';
export const BRANCH_CONTACT_DETAIL_LIST = 'BRANCH_CONTACT_DETAIL_LIST';
export const BRANCH_CONTACT_DETAIL_INSERT = 'BRANCH_CONTACT_DETAIL_INSERT';
export const BRANCH_CONTACT_DETAIL_DELETE = 'BRANCH_CONTACT_DETAIL_DELETE';
export const BRANCH_CONTACT_DETAIL_UPDATE = 'BRANCH_CONTACT_DETAIL_UPDATE';
export const BRANCH_CONTACT_DETAIL_DETAIL = 'BRANCH_CONTACT_DETAIL_DETAIL';

export default {
    BRANCH_CONTACT_LIST,
    BRANCH_CONTACT_INSERT,
    BRANCH_CONTACT_DETAIL,
    BRANCH_CONTACT_DELETE,
    BRANCH_CONTACT_UPDATE,
    BRANCH_CONTACT_DETAIL_LIST,
    BRANCH_CONTACT_DETAIL_INSERT,
    BRANCH_CONTACT_DETAIL_DELETE,
    BRANCH_CONTACT_DETAIL_UPDATE,
    BRANCH_CONTACT_DETAIL_DETAIL
};
