import { USER_DETAIL, USER_SETTING } from './action-types';

const initialState = {
    data: {},
    setting: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case USER_DETAIL:
            return detail(state, payload);
        case USER_SETTING:
            return setting(state, payload);
        default:
            return state;
    }
};

function detail(state, payload) {
    state = Object.assign({}, state, {
        data: payload
    });
    return state;
}

function setting(state, payload) {
    state = Object.assign({}, state, {
        setting: payload
    });
    return state;
}

export default reducer;
