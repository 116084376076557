export const PARTNER_LIST = 'PARTNER_LIST';
export const PARTNER_DETAIL = 'PARTNER_DETAIL';
export const PARTNER_UPDATE = 'PARTNER_UPDATE';
export const PARTNER_INSERT = 'PARTNER_INSERT';
export const PARTNER_DELETE = 'PARTNER_DELETE';
export const PARTNER_RESPONSE = 'PARTNER_RESPONSE';
export const PARTNER_GETALL = 'PARTNER_GETALL';

export default {
    PARTNER_DELETE,
    PARTNER_DETAIL,
    PARTNER_INSERT,
    PARTNER_LIST,
    PARTNER_UPDATE,
    PARTNER_GETALL
};
