import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/personnel',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/personnel/list',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/personnel/department',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./department/list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/personnel/position',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./position/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/personnel/title',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./title/list/index'),
            loading: LoadingComponent
        })
    }
];
