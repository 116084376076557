import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/goodNote/receiptNew',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./importReceiptNew/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/receiptReturn',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./receiptReturn/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/receiptTransfer',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./list/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/receiptOther',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./receiptOther/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/deliverySale',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./deliverySale/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/deliverySupplier',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./deliverySupplier/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/deliveryCancel',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./deliveryCancel/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/deliveryUse',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./deliveryUse/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/deliveryTransfer',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./deliveryTransfer/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/deliveryOther',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./deliveryOther/list'),
            loading: LoadingComponent
        })
    },
    {
        path: '/goodNote/deliverySupply',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./deliverySupply/list'),
            loading: LoadingComponent
        })
    }
];
