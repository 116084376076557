import $ from 'jquery';
$(document).ready(function () {
    $('.sidebar-toggle').on('click', function () {
        var $window = $(window);
        if ($window.width() < 767) {
            setMenu();
        } else {
            if ($('body').hasClass('sidebar-collapse') === true) {
                if ($('body').hasClass('box-layout') === true) {
                    $('body').removeClass('fixed');
                } else {
                    $('body').addClass('fixed');
                    $('body').addClass('header-fixed');
                }
                $('.sidebar').css('overflow', 'visible');
                $('.sidebar-menu').css('overflow', 'visible');
                $('.sidebar-menu').css('height', 'auto');
            } else {
                var a = $(window).height() - 70;
                $('#sidebar-scroll').height(a);

                $('body').removeClass('header-fixed');
                if ($('body').hasClass('box-layout') === true) {
                    $('body').removeClass('fixed');
                } else {
                    $('body').addClass('fixed');
                }
                $('#sidebar-scroll').css('width', '100%');
                $('.sidebar').css('overflow', 'inherit');
                $('.sidebar-menu').css('overflow', 'inherit');
            }
        }
    });
    var a = $('.dx-scrollview-content').height() + 100;
    $('.dx-overlay-content').css('height', a);
});
window.addEventListener('load', setMenu, false);
window.addEventListener('resize', setMenu, false);
function setMenu() {
    var $window = $(window);
    if ($window.width() < 1024 && $window.width() >= 767) {
        if ($('body').hasClass('container') === true) {
            $('body').addClass('container');
        }
        $('body').removeClass('fixed');
        $('body').addClass('sidebar-collapse');
        $('.sidebar').css('overflow', 'visible');
        $('.sidebar-menu').css('overflow', 'visible');
        $('.sidebar-menu').css('height', 'auto');
    } else if ($window.width() < 540 && $window.width() < 767) {
        if ($('body').hasClass('box-layout') === true) {
            $('body').removeClass('container');
        }
        $('.main-header').css('margin-top', '50px');
        $('body').removeClass('fixed');
        $('body').addClass('sidebar-collapse');
        $('.sidebar').css('overflow', 'visible');
        $('.sidebar-menu').css('overflow', 'visible');
        $('.sidebar-menu').css('height', 'auto');
    } else if ($window.width() > 540 && $window.width() < 767) {
        if ($('body').hasClass('box-layout') === true) {
            $('body').removeClass('container');
        }
        $('.main-header').css('margin-top', '0px');
        $('body').removeClass('fixed');
        $('body').addClass('sidebar-collapse');
        $('.sidebar').css('overflow', 'visible');
        $('.sidebar-menu').css('overflow', 'visible');
        $('.sidebar-menu').css('height', 'auto');
    } else if ($window.width() >= 1024) {
        var a = $(window).height() - 70;
        $('#sidebar-scroll').height(a);
        $('.main-header').css('margin-top', '0px');
        if ($('body').hasClass('box-layout') === true) {
            $('body').removeClass('fixed');
            $('body').addClass('container');
        } else {
            $('body').addClass('fixed');
        }
        $('body').removeClass('sidebar-collapse');
        $('#sidebar-scroll').css('width', '100%');
        $('.sidebar').css('overflow-y', 'auto');
        $('.sidebar-menu').css('overflow', 'inherit');
    } else {
        $('body').removeClass('sidebar-collapse');
        if ($('body').hasClass('box-layout') === true) {
            $('body').removeClass('fixed');
            $('.sidebar').css('overflow-y', 'auto');
            $('body').addClass('container');
        } else {
            $('body').addClass('fixed');
        }
    }
    if ($window.width() < 1024) {
        $('.nav-item > ul > li').on('click', function () {
            $(this).parent().parent().addClass('hide-sub-menu');
        });
    }
    $('.dropdown-submenu > a').on('click', function (e) {
        var submenu = $(this);
        $('.dropdown-submenu .dropdown-menu').removeClass('show');
        submenu.next('.dropdown-menu').addClass('show');
        e.stopPropagation();
    });

    $('.dropdown').on('hidden.bs.dropdown', function () {
        // hide any open menus when parent closes
        $('.dropdown-menu.show').removeClass('show');
    });
}
