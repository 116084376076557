import * as ActionType from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    suggestion: [],
    item: {
        pageIds: [],
        languages: []
    },
    activeId: null,
    languages: [],
    pageList: []
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionType.TENANT_LIST:
            return list(state, payload);
        case ActionType.TENANT_SUGGESTION:
            return suggestion(state, payload);
        case ActionType.TENANT_DETAIL:
            return detail(state, payload);
        case ActionType.TENANT_INSERT:
            return insert(state, payload);
        case ActionType.TENANT_UPDATE:
            return update(state, payload);
        case ActionType.TENANT_LANGUAGE_INSERT:
            return insertLanguage(state, payload);
        case ActionType.TENANT_LANGUAGE_UPDATE:
            return updateLanguage(state, payload);
        case ActionType.TENANT_LANGUAGE_DELETE:
            return deleteLanguage(state, payload);
        case ActionType.TENANT_LANGUAGE_UPDATE_DEFAULT:
            return updateDefaultLanguage(state, payload);
        case ActionType.TENANT_LANGUAGE_UPDATE_ACTIVE:
            return updateActiveLanguage(state, payload);
        case ActionType.TENANT_ACTIVE_ID:
            return updateActiveId(state, payload);
        case ActionType.TENANT_PAGE_UPDATE:
            return updatePageList(state, payload);
        default:
            return state;
    }
};

const updatePageList = (state, payload) => {
    return Object.assign({}, state, { pageList: payload });
};

function list(state, payload) {
    state = Object.assign({}, state, { data: payload });
    return state;
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function suggestion(state, payload) {
    state = Object.assign({}, state, {
        suggestion: payload
    });
    return state;
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    return Object.assign({}, state, { data });
}

function insertLanguage(state, payload) {
    const languages = [...state.item.languages, payload];
    const item = Object.assign({}, state.item, { languages });

    return Object.assign({}, state, { item });
}

function updateLanguage(state, payload) {
    console.log(payload);
    return Object.assign({}, state, { languages: payload });
}

function updateDefaultLanguage(state, payload) {
    return state;
}

function updateActiveLanguage(state, payload) {
    return state;
}

function deleteLanguage(state, payload) {
    return state;
}

function updateActiveId(state, payload) {
    return { ...state, activeId: payload };
}

export default reducer;
