export const viGeneral = {
    general: {
        login: {
            loginSystem: 'Đăng nhập hệ thống',
            passwordretrieval: 'Lấy lại mật khẩu',
            return: 'Quay lại',
            next: 'Tiếp theo'
        },
        message: {
            rememberpassword: 'Chúng tôi đã gửi yêu cầu lấy lại mật khẩu vào địa chỉ email đã được đăng ký',
            check: 'Bạn vui lòng kiểm tra email để lấy mã xác nhận'
        },
        navbar: {
            logout: 'Đăng xuất',
            changepass: 'Đổi mật khẩu',
            usercustomization: 'Tùy chỉnh người dùng',
            username: 'Tên đăng nhập',
            password: 'Mật khẩu',
            forgotpassword: 'Quên mật khẩu',
            rememberpassword: 'Ghi nhớ mật khẩu',
            login: 'Đăng nhập',
            changepasstitile: 'Thay đổi mật khẩu',
            oldpassword: 'Mật khẩu cũ',
            newpassword: 'Mật khẩu mới',
            Enterpassword: 'Nhập lại mật khẩu',
            Logoutdevices: 'Đăng xuất ra khỏi các thiết bị khác'
        },
        table: {
            promotionCardName: 'Chương trình ưu đãi',
            promotionCardDetailName: 'Ưu đãi của thẻ',
            total: 'Thành tiền',
            totals: 'Tổng',
            sum: 'Cộng',
            dong: 'đồng',
            denominations: 'Mệnh giá',
            productAfterRequest: 'Sản phẩm sau yêu cầu',
            productBeforeRequest: 'Sản phẩm Trước yêu cầu',
            page: 'Trang',
            of: 'của',
            stt: 'STT',
            username: 'Tên nhân viên',
            codeuser: 'Mã nhân viên',
            position: 'Chức vụ',
            sdt: 'Số điện thoại',
            note: 'Ghi chú',
            edit: 'Chỉnh sửa',
            email: 'Email',
            add: 'Số nhà? Số đường',
            branchName: 'Tên chi nhánh',
            branch: 'Chi nhánh',
            address: 'Địa chỉ',
            default: 'Mặc định',
            active: 'Hoạt động',
            action: 'Hành động',
            shiftname: 'Tên ca làm việc',
            time: 'Thời gian',
            des: 'Mô tả',
            status: 'Trạng thái',
            order: 'Thứ tự',
            areaName: 'Tên khu vực',
            areaSelect: 'Chọn khu vực',
            price: 'Giá',
            useOn: 'Ngày sử dụng',
            unitTitle: 'Đơn vị',
            quantity: 'Số lượng',
            quantityShorten: 'SL',
            printInvoice: 'In hóa đơn',
            System: 'Hệ thống',
            domain: 'Domain',
            password: 'Mật khẩu',
            workingDay: 'Ngày làm việc',
            allShifts: 'Ca làm việc',

            promotionname: 'Tên ưu đãi',
            startdate: 'Ngày bắt đầu',
            enddate: 'Ngày kết thúc',
            Typeoffer: 'Loại ưu đãi',
            Discount: 'Mức giảm giá',
            promotion: 'Khuyến mại',
            count: 'Giảm giá',
            intomoney: 'Thành tiền',
            Unitname: 'Tên đơn vị',
            Servicegroup: 'Nhóm dịch vụ',
            photo: 'Ảnh',
            product: 'Sản phẩm',
            productcode: 'Mã sản phẩm',
            productCodeShorten: 'Mã SP',
            productname: 'Tên sản phẩm',
            productgroup: 'Nhóm sản phẩm',
            Unit: 'Đơn vị tính',
            servicecard: 'Thẻ dịch vụ',
            service: 'Dịch vụ',
            del: 'Xóa',
            prepaidCardCode: 'Mã thẻ trả trước',
            prepaidCardName: 'Tên thẻ trả trước',

            cardcode: 'Mã thẻ',
            cardname: 'Tên thẻ',
            actualvalue: 'Giá trị thực',
            promotionalvalue: 'Giá trị khuyến mại',
            cardvalue: 'Giá trị thẻ',
            servicecode: 'Mã dịch vụ',
            servicename: 'Tên dịch vụ',
            serviceprice: 'Đơn giá dịch vụ (VND)',
            servicetime: 'Số phút thực hiện',
            unitprice: 'Đơn giá',
            minutes: 'Số phút',
            conversionunits: 'Đơn vị quy đổi',
            conversionnumbers: 'Số lượng quy đổi',
            mainunit: 'Đơn vị chính',
            postconversionunitprice: 'Đơn giá sau quy đổi',
            timemakingvotes: 'Thời gian lập phiếu',

            fullname: 'Họ và tên',
            department: 'Phòng ban',
            datebirth: 'Ngày sinh',
            dateentry: 'Ngày vào làm',
            subject: 'Đối tượng',
            title: 'Tên chức danh',
            jobtitle: 'Chức danh',
            multiplechoice: 'Chọn nhiều',
            departmentname: 'Tên phòng ban',
            customename: 'Tên khách hàng',
            customercode: 'Mã khách hàng',
            accountname: 'Tên đăng nhập',
            customercodeShort: 'Mã KH',
            lockstatus: 'Trạng thái khóa',
            locktime: 'Thời gian khóa',
            passwordreset: 'Reset mật khẩu',
            recentdays: 'Ngày tới gần đây',
            decentralization: 'Phân quyền',
            votes: 'Số phiếu',
            NumberVotesEntered: 'Số phiếu nhập',
            NumberVotes: 'Số phiếu xuất',
            NumberEntered: 'Số lượng nhập',
            ExportQuantity: 'Số lượng xuất',
            QuantityStock: 'Số lượng tồn',
            import: 'Nhập',
            export: 'Xuất',
            instock: 'Tồn',
            Beginning: 'Đầu kỳ',
            DuringPeriod: 'Nhập trong kỳ',
            ExportedInPeriod: 'Xuất trong kỳ',
            EndingStocks: 'Tồn cuối kỳ',
            Critical: 'Tới hạn',
            bills: 'Số hóa đơn',
            customers: 'Khách hàng',
            customerservicecard: 'Thẻ khách hàng',
            expirationDateCard: 'Ngày hết hạn',
            totalBalanceUsed: 'Số tiền đã sử dụng',
            balanceCard: 'Số tiền còn lại',

            nameInvoices: 'Tên hóa đơn',
            totalbill: 'Tổng hóa đơn',
            totalmoney: 'Tổng tiền',
            moneypaid: 'Tiền đã thanh toán',
            moneyunpaid: 'Tiền chưa thanh toán',
            moneyuse: 'Tiền đã sử dụng',
            moneybalance: 'Tiền còn lại',
            coupontype: 'Loại phiếu',
            cardTotalAmount: 'Tổng giá trị thẻ',
            cardPromotion: 'Chiết khấu',
            cardPrice: 'Thu tiền mua thẻ',
            cardPaid: 'Thu tiền thẻ khách hàng',
            cardUnpaid: 'Số tiền còn phải thu',
            cardUse: 'Số tiền đã dùng',
            prepaidCard: 'Thẻ trả trước',
            paidOn: 'Ngày thu',

            country: 'Tên quốc gia',
            province: 'Tên tỉnh thành',
            district: 'Tên quận/huyện',
            ethnic: 'Tên dân tộc',
            religious: 'Tên tôn giáo',
            paymentmethod: 'Tên phương thức thanh toán',
            guestsource: 'Tên nguồn khách',
            occupationname: 'Tên nghề nghiệp',
            pagename: 'Tên trang',
            path: 'Đường dẫn',
            permissiongroup: 'Nhóm quyền',

            Collectors: 'Người thu',
            Consultants: 'Người tư vấn',
            Sellers: 'Người bán',
            Creator: 'Người lập',
            Pointsearned: 'Loại tích điểm',
            totalPoints: 'Tổng điểm',
            Pointsearn: 'Điểm được tích',
            percentOfAccumulatedPoints: 'Phần trăm tích điểm',
            Accumulator: 'Người tích điểm',
            pointBalance: 'Số dư điểm',
            PurchaseDate: 'Ngày mua',
            ExpiryDate: 'Ngày hết hạn',
            expiryDateShorten: 'HSD',
            Moneyused: 'Tiền đã dùng',
            Moneyremaining: 'Tiền còn lại',
            Paid: 'Đã thanh toán',
            Paymentinvoice: 'Hóa đơn thanh toán',
            Detailed: 'Chi tiết',
            Depositinvoice: 'Hóa đơn nạp tiền',
            statusBefore: 'Tình trạng trước',
            statusAfter: 'Tình trạng sau',
            saveStatus: 'Lưu tình trạng',
            saveStatusBefore: 'Lưu tình trạng trước',
            saveStatusAfter: 'Lưu tình trạng sau',
            serviceInfo: 'Thông tin dịch vụ',
            statusService: 'Tình trạng dịch vụ',
            reminderDate: 'Ngày nhắc hẹn',
            reminderNote: 'Ghi chú nhắc hẹn',
            isRemind: 'Nhắc hẹn',
            type: 'Loại',
            noteCard: 'Ghi chú thẻ',
            noteProduct: 'Ghi chú sản phẩm',
            noteService: 'Ghi chú dịch vụ',
            period: 'Thời hạn (ngày)',
            date: 'Ngày',
            month: 'tháng',
            year: 'năm',
            attitude: 'Mức đánh giá',
            shop: 'Cửa hàng',
            careFullName: 'NV CSKH',
            report: 'Báo cáo',

            bookingServices: 'Dịch vụ đặt hẹn',
            totalPriceBooking: 'Tiền dịch vụ đặt hẹn',
            staff: 'Nhân viên',
            actualServices: 'Dịch vụ thực tế',
            totalPriceActual: 'Tiền dịch vụ thực tế',
            serviceChanges: 'Dịch vụ thay đổi',
            totalPriceChange: 'Tiền gia tăng DV',
            totalPriceReduce: 'Tiền giảm trừ',
            totalBalanceServiceCard: 'Tổng tiền thẻ DV',
            inventory: 'Kiểm kê',
            code: 'Mã phiếu',
            dateCreate: 'Ngày tạo',
            totalAmountDocument: 'Tổng tiền theo số sách',
            totalAmountRealty: 'Tổng tiền thực tế',
            counterUser1: 'Người kiểm kê 1',
            counterUser2: 'Người kiểm kê 2',
            counterUser3: 'Người kiểm kê 3',
            lotId: 'Lô SX',
            content: 'Nội dung',
            moneyAmount: 'Số tiền',
            customerServiceCardCode: 'Mã thẻ dịch vụ KH',

            statusPayment: 'Trạng thái TT',

            titleDocument: 'Sổ sách',
            titleReality: 'Thực tế',
            titleRedundant: 'Thừa',
            titleMissing: 'Thiếu',
            moneyShorten: 'TT',

            joinedDate: 'Ngày đến gần nhất',
            serviceCardname: 'Tên thẻ dịch vụ'
        },
        button: {
            add: 'Thêm mới',
            save: 'Lưu',
            close: 'Đóng',
            copyschedule: 'Sao chép lịch',
            keepadding: 'Tiếp tục thêm',
            copy: 'Sao chép',
            seeall: 'Xem tất cả',
            info: 'Xem Thông tin',
            uploadImg: 'Tải ảnh',
            accept: 'Chấp nhận',
            cancel: 'Hủy bỏ',
            edit: 'Chỉnh sửa',
            export: 'Xuất file',
            return: 'Về danh sách',
            schedule: 'Đặt lịch',
            Createvotes: 'Tạo phiếu',
            saveclose: 'Lưu & đóng',
            saverecharge: 'Lưu & Nạp tiền',
            acceptrecharge: 'Chấp nhận & nạp tiền',
            acceptexport: 'Chấp nhận & In hóa đơn',
            completed: 'Hoàn tất',
            approve: 'Phê duyệt',
            deny: 'Từ chối',
            deleteAll: 'Xóa tất cả',
            decentralization: 'Phân quyền nhanh',
            savedraft: 'Lưu nháp',
            pay: 'Thanh toán',
            download: 'Tải xuống',
            activated: 'Kích hoạt',
            makeappointment: 'Đặt hẹn',
            showAllRights: 'Tất cả các quyền',
            saveprint: 'Lưu & In phiếu',
            viewInfo: 'Xem thông tin',
            printInfo: 'In thông tin',
            saveSend: 'Lưu và gửi',
            cardHistory: 'Lịch sử thẻ',
            print: 'In',
            send: 'Gửi',
            selectBatches: 'Chọn lô sx',
            hideCcheckbox: 'Ẩn ô chọn',
            update: 'Cập nhật'
        },
        info: {
            Smallheaderimage: 'Ảnh header nhỏ',
            SmallFooterimage: 'Ảnh footer nhỏ',
            Largeheaderimage: 'Ảnh header lớn',
            LargeFooterimage: 'Ảnh footer lớn',
            Systemcolor: 'Màu hệ thống',
            Numberofrecords: 'Số bản ghi/trang',
            Country: 'Quốc gia',
            ProvinceCity: 'Tỉnh/Thành phố',
            DistrictDistrict: 'Quận/Huyện',
            Ethnicity: 'Dân tộc',
            Religion: 'Tôn giáo',
            OccupationName: 'Tên Nghề nghiệp',
            Job: 'Nghề Ngiệp',
            Guestsource: 'Nguồn khách',
            Methodpay: 'Phương thức thanh toán',
            area: 'Khu vực',
            CountryName: 'Tên quốc gia',
            DistrictName: 'Tên quận/ Huyện',
            ProvinceName: 'Tên Tỉnh/ Thành',
            gender: 'Giới tính',
            BrandName: 'Tên nhãn hiệu'
        },
        form: {
            declineContent: 'Nội dung từ chối',
            InfoOfCustomer: 'Thông tin khách hàng',
            Gender: 'Giới tính',
            serviceNote: {
                code: 'Mã hóa đơn',
                date: 'Ngày lập hóa đơn',
                customer: 'Khách hàng',
                phone: 'Số điện thoại',
                customerType: 'Đối tượng khách hàng',
                creator: 'Người lập'
            },
            branch: {
                insertTitle: 'Thêm mới chi nhánh',
                updateTitle: 'Chỉnh sửa chi nhánh',
                code: 'Mã chi nhánh',
                website: 'Website',
                hotline: 'Hotline',
                facebook: 'Facebook',
                active: 'Kích hoạt',
                default: 'Mặc định'
            },
            country: {
                insertTitle: 'Thêm mới quốc gia',
                updateTitle: 'Chỉnh sửa quốc gia'
            },
            province: {
                insertTitle: 'Thêm mới tỉnh thành',
                updateTitle: 'Chỉnh sửa tỉnh thành'
            },
            nation: {
                insertTitle: 'Thêm mới dân tộc',
                updateTitle: 'Chỉnh sửa dân tộc'
            },
            occupation: {
                insertTitle: 'Thêm mới nghề nghiệp',
                updateTitle: 'Chỉnh sửa nghề nghiệp'
            },
            job: {
                insertTitle: 'Thêm mới nghề nghiệp',
                updateTitle: 'Chỉnh sửa nghề nghiệp'
            },
            area: {
                insertTitle: 'Thêm mới khu vực',
                updateTitle: 'Chỉnh sửa khu vực'
            },
            servicegroup: {
                insertTitle: 'Thêm mới nhóm dịch vụ',
                updateTitle: 'Chỉnh sửa nhóm dịch vụ'
            },
            religion: {
                insertTitle: 'Thêm mới tôn giáo',
                updateTitle: 'Chỉnh sửa tôn giáo'
            },
            district: {
                insertTitle: 'Thêm mới quận/ huyện',
                updateTitle: 'Chỉnh sửa quận/ huyện'
            },
            unit: {
                insertTitle: 'Thêm mới đơn vị',
                updateTitle: 'Chỉnh sửa đơn vị'
            },
            paymentMethod: {
                insertTitle: 'Thêm mới phương thức thanh toán',
                updateTitle: 'Chỉnh sửa phương thức thanh toán'
            },
            productgroup: {
                insertTitle: 'Thêm mới nhóm sản phẩm',
                updateTitle: 'Chỉnh sửa nhóm sản phẩm'
            },
            service: {
                insertTitle: 'Thêm mới dịch vụ',
                updateTitle: 'Chỉnh sửa dịch vụ'
            },
            facebook: 'Facebook',
            active: 'Kích hoạt',
            customerPrePaid: 'Nạp Tiền',
            Offername: 'Tên ưu đãi',
            Startdate: 'Ngày bắt đầu',
            Enddate: 'Ngày kết thúc',
            Typesoffers: 'Loại ưu đãi',
            Someservices: 'Một vài dịch vụ',
            Allservices: 'Tất cả dịch vụ',
            Details: 'Chi tiết',
            Productlist: 'Danh sách sản phẩm',
            Selectedlist: 'Danh sách được chọn',
            IncentivesCard: 'Ưu đãi của thẻ',
            Incentives: 'Ưu đãi',
            discounts: 'Giảm giá',
            discount: 'Khuyến mại',
            Moneydiscounts: 'Giảm giá bằng tiền',
            Sequencenumber: 'Số thứ tự',
            Programcontent: 'Nội dung chương trình',
            Notes: 'Ghi chú',
            Active: 'Hoạt động',
            UnitName: 'Tên đơn vị',
            Activate: 'Kích hoạt',
            Productgroup: 'Nhóm sản phẩm',
            Productgroupname: 'Tên nhóm sản phẩm',
            Productname: 'Tên sản phẩm',
            Price: 'Giá bán',
            Unit: 'Đơn vị đo',
            ProductSKU: 'Mã sản phẩm',
            Brand: 'Thương hiệu',
            Capacity: 'Dung tích',
            Registrationnumber: 'Số đăng kí',
            Origin: 'Nguồn gốc',
            Order: 'Thứ tự',
            Ingredients: 'Thành phần',
            Uses: 'Công dụng',
            Instructionsuse: 'Hướng dẫn sử dụng',
            Label: 'Nhãn',
            UnitConversion: 'Quy đổi đơn vị',
            CardName: 'Tên thẻ',
            Cardcode: 'Mã thẻ',
            Actualvalue: 'Giá trị thực',
            Promotionalvalue: 'Giá trị khuyến mại',
            Cardvalue: 'Giá trị thẻ',
            Commission: 'Hoa hồng',
            Chooseservice: 'Chọn dịch vụ',
            Branching: 'Chi nhánh',
            Services: 'Dịch vụ',
            Listcardservices: 'Danh sách dịch vụ của thẻ',
            Servicegroupname: 'Tên nhóm dịch vụ',
            Selectparentservicegroup: 'Chọn nhóm dịch vụ cha',
            SelectparentProductgroup: 'Chọn nhóm sản phẩm cha',
            Servicecode: 'Mã dịch vụ',
            Servicename: 'Tên dịch vụ',
            unitprice: 'Đơn giá',
            Timetaken: 'Thời gian thực hiện (phút)',
            CommissionPrice: 'Tiền hoa hồng',
            Des: 'Miêu tả',
            Organizationchart: 'Sơ đồ tổ chức',
            Departmentinfo: 'Thông tin phòng ban',
            Namedepartment: 'Tên phòng ban',
            superiordepartment: 'Phòng ban cấp trên',
            titleposition: 'Chức vụ',
            Usedepartments: 'Sử dụng cho phòng ban',
            Allowedselectmany: 'Được phép chọn nhiều',
            Title: 'Chức danh',
            ServiceList: 'Danh sách dịch vụ',
            EmployeeInformation: 'Thông tin nhân viên',

            Normalstaff: 'Nhân viên thường',
            Technician: 'Kỹ thuật viên',
            Username: 'Tên đăng nhập',
            EmployeeID: 'Mã nhân viên',
            Fullname: 'Họ và tên',
            CustomerCode: 'Mã khách hàng',
            Selectgender: 'Chọn giới tính',
            DateBirth: 'Ngày sinh',
            Department: 'Phòng ban',
            Position: 'Chức vụ',
            HeadUnit: 'Là trưởng đơn vị',
            Subject: 'Đối tượng',
            Issuedate: 'Ngày cấp',
            Placeissue: 'Nơi cấp',
            Businessday: 'Ngày vào công ty',
            Leavedate: 'Ngày nghỉ việc',
            Country: 'Quốc gia',
            ProvinceCity: 'Tỉnh/Thành phố',
            District: 'Quận/Huyện',
            Ethnicity: 'Dân tộc',
            Religion: 'Tôn giáo',
            Bankaccount: 'Tài khoản ngân hàng',
            Bankname: 'Tên ngân hàng',
            BankBranch: 'Chi nhánh ngân hàng',
            Passport: 'Hộ chiếu',
            AttendanceCode: 'Mã chấm công',
            Attendancecardcode: 'Mã thẻ chấm công',
            Maritalstatus: 'Tình trạng hôn nhân',
            Contactinformation: 'Thông tin liên hệ',
            Phonenumber: 'Số điện thoại',
            Ext: 'Số máy lẻ',
            Contacts: 'Địa chỉ liên hệ',
            EmployeeList: 'Danh sách nhân viên',
            CouponCode: 'Mã phiếu',
            DateCreated: 'Ngày tạo phiếu',
            Customer: 'Khách hàng',
            Preparer: 'Người lập',
            Servicedetails: 'Chi tiết dịch vụ',
            Oldservicedetails: 'Chi tiết dịch vụ cũ',
            Newservicedetails: 'Chi tiết dịch vụ mới',
            ProductDetails: 'Chi tiết sản phẩm',
            Oldproductdetails: 'Chi tiết sản phẩm cũ',
            Newproductdetails: 'Chi tiết sản phẩm mới',
            Serviceafterrequest: 'Dịch vụ sau yêu cầu',
            Advanceservice: 'Dịch vụ trước yêu cầu',
            Refundcustomers: 'Hoàn tiền khách hàng',
            Collectcustomers: ' Thu thêm khách hàng',
            Targetcustomers: 'Đối tượng KH',

            Totalamounttopaid: 'Số tiền khách thanh toán',
            Quantity: 'Số lượng',
            Totalamount: 'Tổng tiền',
            Totalamountpaid: 'Tổng tiền đã thanh toán',
            TotalamountUnpaid: 'Tổng tiền chưa thanh toán',
            Totalamounttobepaid: 'Tổng tiền cần thanh toán',
            Formpayment: 'Hình thức thanh toán',
            Payment: 'Tiền thanh toán',
            amountInWords: 'Bằng chữ',
            Employee: 'Nhân viên làm',
            Area: 'Khu vực',
            Promotion: 'Chương trình khuyến mại',
            Money: 'Thành tiền',
            Reasondiscount: 'Lý do giảm giá',
            Serviceregistration: 'Đăng kí dịch vụ',
            Timeappointment: 'Thời gian hẹn',
            Selectappointmentservice: 'Chọn dịch vụ đặt hẹn',
            Sellproducts: 'Bán sản phẩm',
            Sourcesguests: 'Nguồn khách',
            Referrer: 'Người giới thiệu',
            Partner: 'Người dùng thẻ',
            Occupation: 'Nghề nghiệp',
            BeVIP: 'Là khách VIP',
            Asemployee: 'Là nhân viên',
            PersonalInformation: 'Thông tin cá nhân',
            Servicesperformed: 'Dịch vụ đã làm',
            Productspurchased: 'Sản phẩm đã mua',
            Points: 'Tích điểm',
            Invoices: 'Hóa đơn',
            invoiceDetail: 'Chi tiết hóa đơn',
            AccumulatedPoints: 'Điểm đã tích',
            TransferredAmount: 'Số tiền chuyển',
            ReceivedAmount: 'Số tiền nhận',
            PrepaidCardAmount: 'Số tiền trong thẻ trả trước',
            declineReason: 'Lý do từ chối',
            cardBalance: 'Số tiền còn lại trong thẻ ',
            enterWithdrawal: 'Hãy nhập số tiền muốn chuyển về thẻ trả trước:',

            AppointmentTime: 'Giờ hẹn',
            ServiceCardRecharge: 'Nạp tiền thẻ dịch vụ',
            Prepaidcard: 'Tiền trong thẻ trả trước',
            Servicecardname: 'Tên thẻ dịch vụ',
            Actualservicecardvalue: 'Giá trị thực thẻ dịch vụ',
            Salesperson: 'Nhân viên bán',
            Moneyinservicecard: 'Tiền trong thẻ dịch vụ',
            Rightsgroupname: 'Tên nhóm quyền',
            Permissionsdetails: 'Chi tiết quyền',
            Adminaccount: 'Tài khoản admin',
            Pagename: 'Tên trang',
            Pagepath: 'Đường dẫn trang',
            Parentpage: 'Trang cha',
            Backgroundcolor: 'Màu nền',
            Customeruses: 'Khách hàng sử dụng',
            Selectregion: 'Chọn khu vực',
            Regionname: 'Tên khu vực',
            Selectbranch: 'Chọn chi nhánh',
            Countryname: 'Tên quốc gia',
            Selectcountry: 'Chọn quốc gia',
            Nameprovince: 'Tên tỉnh thành',
            Selectcity: 'Chọn tỉnh thành',
            Districtname: 'Tên quận huyện',
            Ethnicname: 'Tên dân tộc',
            Religiousname: 'Tên tôn giáo',
            Namepaymentmethod: 'Tên phương thức thanh toán',
            Customersourcename: 'Tên nguồn khách',
            Occupationname: 'Tên nghề nghiệp',
            Counselor: 'Nhân viên tư vấn',

            Starttime: 'Thời gian bắt đầu',
            Endtime: 'Thời gian kết thúc',
            Folderfilename: 'Tên folder/file',

            receiver: 'Người nhận',
            remitter: 'Người gửi',
            error: {
                nameExisted: 'Tên này đã tồn tại ở ngôn ngữ: ',
                exceedTotalBalance: 'Số tiền nạp vào vượt quá "Giá trị thực thẻ dịch vụ"',
                exceedRemainBalance: 'Số tiền rút vượt quá số tiền còn lại trong thẻ',
                exceedMoreThan0: 'Số tiền rút phải lớn hơn 0',
                serviceCardRequired: 'Chưa chọn thẻ dịch vụ',
                serviceCardNotActive:
                    'Thẻ dịch vụ này không còn hoạt động. Bạn không thể rút tiền về thẻ trả trước của khách hàng này.',
                moneyCannotBeZero: 'Số tiền thanh toán không được để 0 VND. Hãy nhập vào số tiền thanh toán',
                commissionLessThanZero: 'Phần trăm hoa hồng không được nhỏ hơn 0',
                dateRequired: 'Ngày tháng không được bỏ trống',
                commissionLessLessThanCommissioneRate: '% khuyến mãi nhân viên phải nhỏ hơn % khuyến mãi của dịch vụ',
                totalCommissionLessLessThanCommissioneRate:
                    'Tổng % khuyến mãi nhân viên phải nhỏ hơn % khuyến mãi của dịch vụ',
                oldPasswordValidate: 'Mật khẩu mới không được giống mật khẩu cũ',
                confirmPasswordValidate: 'Mật khẩu nhập lại phải giống mật khẩu mới'
            },
            CommissioneRate: 'Hoa hồng tư vấn',
            CommissionSeller: 'Hoa hồng bán hàng',
            Reasonapprove: 'Lý do từ chối',
            Reasonrefund: 'Lý do hoàn tiền',
            expiredDay: 'Ngày hết hạn',
            code: 'Số phiếu',
            date: 'Ngày',
            theCounter1: 'Người kiểm kê 1',
            theCounter2: 'Người kiểm kê 2',
            theCounter3: 'Người kiểm kê 3',
            position: 'Chức vụ',
            note: 'Ghi chú',
            quantityRealty: 'Số lượng thực tế',
            totalAmountRealty: 'Thành tiền theo số lượng thực tế',
            quantityDocument: 'Số lượng sổ sách',
            totalAmountDocument: 'Thành tiền theo số lượng sổ sách',
            signature: 'Chữ ký',
            printed: 'Lần in thứ',
            AmountMoney: 'Số tiền',
            CardBalance: 'Số dư thẻ',
            ReceiverInformation: 'Thông tin người nhận',
            InformationTransferer: 'Thông tin người chuyển',
            signAndFullName: 'Ký và ghi rõ họ tên',
            couponDetails: 'Chi tiết phiếu',
            customerProfile: 'Hồ sơ khách hàng',

            inventoryRecords: 'BIÊN BẢN KIỂM KÊ',
            timeOfInventory: 'Thời điểm kiểm kê',
            inventoryCommittee: 'Ban kiểm kê gồm',
            grandParents: 'Ông/Bà',
            chiefAccountant: 'Kế toán trưởng',
            stocker: 'Thủ kho',
            memberOfInventoryCommittee: 'Thành viên ban kiểm kê',
            cardOwner: 'Chủ sở hữu'
        },
        title: {
            Add: 'Thêm mới',
            Update: 'Thông tin',
            Edit: 'Chỉnh sửa',
            Details: 'Chi tiết',
            account: 'tài khoản',
            region: 'khu vực',
            country: 'quốc gia',
            province: 'tỉnh thành',
            district: 'quận/huyện',
            ethnicity: 'dân tộc',
            religion: 'tôn giáo',
            brand: 'nhãn hiệu',
            paymentmethod: 'Phương thức thanh toán',
            guestsource: 'nguồn khách',
            occupation: 'nghề nghiệp',
            Actionforpage: 'Hành động cho trang',
            job: 'nghề nghiệp',
            pages: 'trang',
            loyaltycards: 'Thẻ khách hàng',
            customers: 'Khách hàng',

            Servicecard: 'Thẻ dịch vụ',
            Paymenthistory: 'Lịch sử thanh toán',
            Tophistory: 'Lịch sử nạp tiền',
            topservicecards: 'Nạp tiền vào thẻ dịch vụ',
            Deposit: 'Nạp tiền',
            Buyservicecards: 'Mua thẻ dịch vụ',
            promotions: 'khuyến mại',
            withdrawToPrepaidCard: 'Rút tiền về thẻ trả trước',

            rightsgroups: 'nhóm quyền',
            permissions: 'phân quyền',
            Createcoupons: 'Tạo phiếu',
            deals: 'chương trình ưu đãi',
            productgroups: 'nhóm sản phẩm',
            products: 'sản phẩm',
            branch: 'chi nhánh',
            units: 'đơn vị',
            servicegroups: 'nhóm dịch vụ',
            group: 'nhóm',
            shift: 'ca làm việc',
            service: 'dịch vụ',
            staff: 'nhân viên',
            title: 'chức danh',
            office: 'chức vụ',
            department: 'phòng ban',
            officedepartment: 'Chức vụ thuộc phòng ban',
            Listservicepersonnel: 'Danh sách nhân viên thực hiện dịch vụ',
            Consumables: 'Vật tư tiêu hao',
            rights: 'quyền',
            customerrequestsheets: 'phiếu yêu cầu khách hàng',
            makepayments: 'thanh toán',
            votes: 'phiếu',
            Accounttype: 'Loại tài khoản',
            chooseposition: 'Chọn chức vụ cha',
            receipt: 'PHIẾU THU',
            receiptoutMoney: 'PHIẾU RÚT TIỀN',
            receiptTransfer: 'PHIẾU CHUYỂN TIỀN',
            receiptReceive: 'PHIẾU NHẬN TIỀN',
            detailProductInvoice: 'Chi tiết hóa đơn sản phẩm',
            detailServiceInvoice: 'Chi tiết thanh toán dịch vụ',
            receiptRefund: 'PHIẾU CHI'
        },
        placeholder: {
            detailPayment: 'Chọn loại chi tiết thanh toán',
            Searchname: 'Tìm kiếm họ và tên',
            employeename: 'Nhập tên nhân viên',
            searchkeyword: 'Nhập từ khóa tìm kiếm',
            selectbranch: 'Chọn chi nhánh',
            Accounttype: 'Chọn loại tài khoản',
            Selectregion: 'Chọn khu vực',
            Selectall: 'Chọn tất cả',
            Selectcountry: 'Chọn quốc gia',
            selectprovince: 'Chọn tỉnh thành',
            Selectstatus: 'Chọn trạng thái',
            Enterdescription: 'Nhập mô tả',
            groupname: 'Nhập tên nhóm quyền',
            Fromdate: 'Từ ngày',
            Todate: 'Đến ngày',
            fromTime: 'Từ giờ',
            toTime: 'Đến giờ',
            Coupontype: 'Loại phiếu',
            language: 'Chọn ngôn ngữ',
            Selectdepartments: 'Chọn phòng ban',
            Searchservices: 'Tìm kiếm dịch vụ',
            Selectproductgroups: 'Chọn nhóm sản phẩm',
            Finddeals: 'Tìm kiếm khuyến mại',
            Cardcodesearch: 'Tìm kiếm mã thẻ',
            Billsearch: 'Tìm kiếm hóa đơn',
            rechargeSearch: 'Tìm kiếm hóa đơn',
            Cardnamesearch: 'Tìm kiếm tên thẻ',
            Pointtype: 'Loại tích điểm',
            Selectservicegroup: 'Chọn nhóm dịch vụ',
            Lookingsupplies: 'Tìm kiếm vật tư',
            Lookingstaff: 'Tìm kiếm nhân viên',
            view: 'Chọn trạng thái xem',
            selectJob: 'Chọn vị trí tuyển dụng',
            chooseMonth: 'Chọn tháng',
            prepaidCardStatus: 'Trạng thái thanh toán',
            selectAllEmployee: 'Chọn tất cả nhân viên',
            serviceType: 'Chọn loại dịch vụ'
        },
        notification: {
            youHave: 'Bạn có',
            notifications: 'thông báo',
            notification: 'Thông báo',
            markAsRead: 'Đánh dấu đã đọc',
            markAsunRead: 'Đánh dấu chưa đọc',
            removeNotifications: 'Gỡ thông báo',
            markAllAsRead: 'Đánh dấu tất cả đã đọc',
            noNotification: 'Bạn chưa có thông báo !'
        }
    }
};
