import {
    ACCOUNT_DELETE,
    ACCOUNT_DETAIL,
    ACCOUNT_INSERT,
    ACCOUNT_LIST,
    ACCOUNT_LOCK,
    ACCOUNT_RESPONSE,
    ACCOUNT_UPDATE,
    ACCOUNT_CHANGE_PASSWORD,
    ACCOUNT_RESET_PASSWORD,
    ACCOUNT_GETALL_EMPLOYEE
} from './action-type';

export function list(payload) {
    return {
        type: ACCOUNT_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: ACCOUNT_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: ACCOUNT_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: ACCOUNT_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: ACCOUNT_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: ACCOUNT_RESPONSE,
        payload
    };
}

export function lockAccount(payload) {
    return {
        type: ACCOUNT_LOCK,
        payload
    };
}

export function changePassword(payload) {
    return {
        type: ACCOUNT_CHANGE_PASSWORD,
        payload
    };
}

export function resetPassword(payload) {
    return {
        type: ACCOUNT_RESET_PASSWORD,
        payload
    };
}

export function getAllEmployee(payload) {
    return {
        type: ACCOUNT_GETALL_EMPLOYEE,
        payload
    };
}
