import { connect } from 'react-redux';
import { warehouseManagerConfigGetAllUserId } from '../service';
import BaseSelectBox from '../../../base/components/BaseSelectBox';

class WarehouseSelectBox extends BaseSelectBox {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            payload: {
                ...this.state.payload,
                userId: this.props.userId
            }
        };
    }
}

const mapStateToProps = (state) => {
    const { currentUser } = state.appSettings.data;

    return {
        languages: state.appSettings.data.languages,
        listData: state.warehouseManagerConfig.suggestion,
        userId: currentUser.id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: (payload) => {
            return dispatch(warehouseManagerConfigGetAllUserId(payload));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(WarehouseSelectBox);
