export const viI = {
    invoice: {
        payment: {
            title: 'Thanh toán',
            code: 'Mã hóa đơn',
            date: 'Ngày lập hóa đơn'
        },
        totalRefund: 'tổng tiền cần hoàn lại',
        type: {
            servicenoteInvoice: 'Hóa đơn dịch vụ',
            goodnoteInvoice: 'Hóa đơn sản phẩm',
            customerCardInvoice: 'Hóa đơn thẻ trả trước',
            serviceRequestInvoice: 'Hóa đơn yêu cầu dịch vụ',
            productRequestInvoice: 'Hóa đơn yêu cầu sản phẩm',
            customerServiceInvoice: 'Hóa đơn thẻ dịch vụ'
        },
        BillServiceCard: {
            code: 'Mã thẻ',
            name: 'Tên thẻ'
        }
    },
    inventory: {
        insertTitle: 'Thêm mới Kiểm kê',
        updateTitle: 'Chỉnh sửa Kiểm kê',
        listTitle: 'Danh sách Kiểm kê',
        unit: 'kiểm kê'
    }
};
