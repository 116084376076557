import {
    WAREHOUSE_MANAGER_CONFIG_DELETE,
    WAREHOUSE_MANAGER_CONFIG_DETAIL,
    WAREHOUSE_MANAGER_CONFIG_INSERT,
    WAREHOUSE_MANAGER_CONFIG_LIST,
    WAREHOUSE_MANAGER_CONFIG_RESPONSE,
    WAREHOUSE_MANAGER_CONFIG_UPDATE,
    WAREHOUSE_MANAGER_CONFIG_GETALL
} from './action-types';

export function list(payload) {
    return {
        type: WAREHOUSE_MANAGER_CONFIG_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: WAREHOUSE_MANAGER_CONFIG_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: WAREHOUSE_MANAGER_CONFIG_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: WAREHOUSE_MANAGER_CONFIG_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: WAREHOUSE_MANAGER_CONFIG_INSERT,
        payload
    };
}

export function responseError(payload) {
    return {
        type: WAREHOUSE_MANAGER_CONFIG_RESPONSE,
        payload
    };
}
export function suggestion(payload) {
    return {
        type: WAREHOUSE_MANAGER_CONFIG_GETALL,
        payload
    };
}
