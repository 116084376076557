export const CORE_LANGUAGE_LIST = 'CORE_LANGUAGE_LIST';
export const CORE_LANGUAGE_UPDATE = 'CORE_LANGUAGE_UPDATE';
export const CORE_LANGUAGE_INSERT = 'CORE_LANGUAGE_INSERT';
export const CORE_LANGUAGE_SUGGESTION = 'CORE_LANGUAGE_SUGGESTION';

export default {
    CORE_LANGUAGE_INSERT,
    CORE_LANGUAGE_LIST,
    CORE_LANGUAGE_UPDATE,
    CORE_LANGUAGE_SUGGESTION
};
