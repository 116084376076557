import { useState, useEffect } from 'react';
import { NAILSPA_URL } from '../constants/Config';

const accessToken = localStorage.getItem('access_token');

export default function useFetchGetAll(url, ViewModel, dependencies, baseUrl = NAILSPA_URL) {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await fetch(`${baseUrl}${url}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const res = await resp.json();
                if (isMounted) {
                    if (res.data) setData(res.data.map((item) => ViewModel(item)));
                    else setData(res.map((item) => ViewModel(item)));
                }
            } catch (e) {
                if (isMounted) setData({});
                if (isMounted) setError(e);
            }
        };

        let isMounted = true;
        fetchData();
        return () => {
            isMounted = false;
        };
    }, dependencies);

    return { data, error };
}
