import { FEEDBACK_RATE } from '../../../constants/Config';
import BaseConstantSelectBox from '../../components/BaseConstantSelectBox';

class FeedBackSelectBox extends BaseConstantSelectBox {
    constructor(props) {
        super(props);

        this.initData = FEEDBACK_RATE;
    }

    onDataChanged(e) {
        if (e.value !== null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: e.value
            });
        }
        if (e.value === null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: ''
            });
        }
    }
}

export default FeedBackSelectBox;
