import React, { Component } from 'react';
import { SelectBox } from 'devextreme-react';
import ArrayStore from 'devextreme/data/array_store';
import './style.css';
import { getThemesList, changeTheme } from './service';
import Field from './Field.js';
import Item from './Item.js';
import { connect } from 'react-redux';
import { DEVEXTREME_BASEURL } from '../../../constants/Config';
import { ToastsStore } from 'react-toasts';
import PropTypes from 'prop-types';

class ThemeColor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colors: null,
            defaultValue: null
        };
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        const { appSetting } = this.props;

        this.props.getAllThemes().then(() => {
            const theme = appSetting.userSettings.find((x) => x.key === 'ThemeId');
            if (theme) {
                this.updateStyleTheme(theme.value);

                this.setState({
                    colors: new ArrayStore({
                        data: this.props.themes,
                        key: 'id'
                    }),
                    defaultValue: theme.value
                });
            } else {
                this.props.changeTheme(this.props.themes[0].themeId);
                this.setState({
                    colors: new ArrayStore({
                        data: this.props.themes,
                        key: 'id'
                    }),
                    defaultValue: this.props.themes[0].themeId
                });
            }
        });
    }

    onValueChanged(e) {
        this.setState({
            defaultValue: e.value
        });
        this.props
            .changeTheme(e.value)
            .then((data) => {
                this.setState({
                    defaultValue: e.value
                });
                this.updateStyleTheme(e.value);
            })
            .catch((error) => {
                ToastsStore.error(error.response.data.message);
            });
    }

    updateStyleTheme(themeId) {
        const style = document.getElementById('style-theme');

        const currentTheme = this.props.themes.find((x) => x.id === themeId);
        if (currentTheme) {
            style.href = DEVEXTREME_BASEURL + currentTheme.url + '.css';
        } else {
            style.href = 'https://cdn3.devexpress.com/jslib/19.2.3/css/dx.light.css';
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { appSetting } = nextProps;
        const theme = appSetting.userSettings.find((x) => x.key === 'ThemeId');

        if (theme && theme.value !== this.state.defaultValue) {
            this.setState({
                defaultValue: theme.value
            });
        }
    }

    render() {
        const { colors, defaultValue } = this.state;
        return (
            <div>
                <SelectBox
                    searchEnabled={true}
                    id={'custom-templates'}
                    dataSource={colors}
                    displayExpr={'name'}
                    width={160}
                    height={'auto'}
                    valueExpr={'id'}
                    fieldRender={Field}
                    value={defaultValue}
                    onValueChanged={this.onValueChanged}
                    itemRender={Item}
                />
            </div>
        );
    }
}

ThemeColor.propTypes = {
    appSetting: PropTypes.object,
    getAllThemes: PropTypes.func,
    changeTheme: PropTypes.func,
    themes: PropTypes.array
};
const mapStateToProps = (state) => {
    return {
        themes: state.themes.data,
        appSetting: state.appSettings.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllThemes: () => {
            return dispatch(getThemesList());
        },
        changeTheme: (themeId) => {
            return dispatch(changeTheme(themeId));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeColor);
