import React from 'react';
import { TextBox } from 'devextreme-react';
import { FILE_URL } from '../../../constants/Config';

export default function Field(data) {
    return (
        <div className={'custom-item'}>
            {data && <img alt="Copyright Of GHMSoft" src={data && FILE_URL + '/' + data.image} />}
            <TextBox
                className={'product-name'}
                placeholder={'Select theme'}
                defaultValue={data && data.name}
                readOnly={true}
            />
        </div>
    );
}
