import React, { Component } from 'react';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import i18n from '../../i18n';
import ReeValidate from 'ree-validate';
import vi from 'ree-validate/dist/locale/vi';
import en from 'ree-validate/dist/locale/en';
import { FILE_URL } from '../../constants/Config';
import FileChoosePopup from '../../modules/filemanagement/FileChoosePopup';

class MultiLangHTMLEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMultiline: true,
            isShowImagePicker: false
        };

        this.validatorTranslation = {};
        this.props.languages.forEach((item) => {
            this.validatorTranslation[item.languageId] = new ReeValidate({
                content: this.props.validatorValue
            });
            if (this.props.currentLanguage === 'vi-VN') {
                this.validatorTranslation[item.languageId].localize('vi', vi);
            } else {
                this.validatorTranslation[item.languageId].localize('en', en);
            }

            this.validatorTranslation[item.languageId]
                .validate('content', this.props.value[item.languageId])
                .then(() => {
                    this.checkValidate();
                });
        });

        this.valueChangedTranslation = this.valueChangedTranslation.bind(this);

        this.sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
        this.fontValues = [
            'Arial',
            'Courier New',
            'Georgia',
            'Impact',
            'Lucida Console',
            'Tahoma',
            'Times New Roman',
            'Verdana'
        ];
        this.headerValues = [false, 1, 2, 3, 4, 5];
        this.enabled = {
            enabled: true
        };

        this.toolbarButtonOptions = {
            icon: 'image',
            text: 'Image',
            stylingMode: 'icon',
            onClick: () => this.setState({ isShowImagePicker: true })
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.props.languages.forEach((item) => {
                this.validatorTranslation[item.languageId]
                    .validate('content', nextProps.value[item.languageId])
                    .then(() => {
                        this.checkValidate();
                    });
            });
        }

        if (nextProps.languages !== this.props.languages) {
            nextProps.languages.forEach((item) => {
                nextProps.languages.forEach((element) => {
                    if (!this.validatorTranslation[element.languageId]) {
                        this.validatorTranslation[element.languageId] = new ReeValidate({
                            content: this.props.validatorValue
                        });

                        this.validatorTranslation[element.languageId]
                            .validate('content', this.props.value[element.languageId])
                            .then(() => {
                                this.checkValidate();
                            });
                    }
                });
            });
        }
    }

    valueChangedTranslation(e) {
        const { currentLanguageActive } = this.props;
        const value = e.value.trim();

        this.props.updateData({
            [this.props.field]: {
                ...this.props.value,
                [currentLanguageActive]: value
            }
        });

        this.validatorTranslation[currentLanguageActive].validate('content', value).then(() => {
            this.checkValidate();
        });
    }

    checkValidate() {
        const { languages, field } = this.props;

        let formValidate = true;
        languages.forEach((element) => {
            if (!this.validatorTranslation[element.languageId]) {
                this.validatorTranslation[element.languageId] = new ReeValidate({
                    content: this.props.validatorValue
                });

                this.validatorTranslation[element.languageId]
                    .validate('content', this.props.value[element.languageId])
                    .then(() => {
                        this.checkValidate();
                    });
            } else {
                if (this.validatorTranslation[element.languageId].errors.has('content')) {
                    formValidate = false;
                }
            }
        });

        this.props.updateData({
            formValidate: { [field]: formValidate }
        });
    }

    onFocusOut = () => {
        this.props.languages.forEach((item) => {
            this.validatorTranslation[item.languageId]
                .validate('content', this.props.value[item.languageId])
                .then(() => {
                    this.checkValidate();
                });
        });
    };

    handleAppendImage = (data) => {
        const { value, currentLanguageActive, field } = this.props;

        const htmlString = `<img src="${FILE_URL}/${data.dataItem.url}" alt="appendImg" />`;

        !value[currentLanguageActive]
            ? (value[currentLanguageActive] = htmlString)
            : (value[currentLanguageActive] += htmlString);

        this.props.updateData({ [field]: value });

        this.props.languages.forEach((item) => {
            this.validatorTranslation[item.languageId]
                .validate('content', this.props.value[item.languageId])
                .then(() => {
                    this.checkValidate();
                });
        });

        this.setState({
            isShowImagePicker: false
        });
    };

    hideFileChoosePopup() {
        this.setState({
            isShowImagePicker: false
        });
    }

    render() {
        const { currentLanguageActive, value, i18nLabel, validatorValue, height, readOnly } = this.props;
        const { isShowImagePicker } = this.state;

        if (!this.validatorTranslation[currentLanguageActive]) {
            return <div>Loading...</div>;
        }

        return (
            <div
                className={`form-group mb-3 cm-mgr-5 ${
                    this.validatorTranslation[currentLanguageActive].errors.has('content') ? ' has-error' : ''
                }`}
            >
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {!readOnly && validatorValue && validatorValue.includes('require') && (
                            <span className="color-red"> *</span>
                        )}
                    </div>

                    <div className={'dx-field-value'}>
                        <div className="form-group mb-3 cm-mgr-5">
                            {/* <div className="d-flex " style={{ width: "300px" }}>
                                <p className=" mr-5 d-flex align-items-end">
                                    Chèn ảnh
                                </p>
                                <ImageBox
                                    field={"appendImage"}
                                    // value={model.appendImage}
                                    updateData={this.handleAppendImage}
                                    editable={true}
                                />
                            </div> */}

                            <div className={'row m-0'}>
                                <div className={'dx-field-value w100pc my-3'}>
                                    <HtmlEditor
                                        height={height || '250px'}
                                        onValueChanged={this.valueChangedTranslation}
                                        value={value[currentLanguageActive]}
                                        readOnly={readOnly || false}
                                    >
                                        <MediaResizing enabled={true} />
                                        <Toolbar multiline={this.state.isMultiline}>
                                            <Item formatName="undo" />
                                            <Item formatName="redo" />
                                            <Item formatName="separator" />
                                            <Item formatName="size" formatValues={this.sizeValues} />
                                            <Item formatName="font" formatValues={this.fontValues} />
                                            <Item formatName="separator" />
                                            <Item formatName="bold" />
                                            <Item formatName="italic" />
                                            <Item formatName="strike" />
                                            <Item formatName="underline" />
                                            <Item formatName="separator" />
                                            <Item formatName="alignLeft" />
                                            <Item formatName="alignCenter" />
                                            <Item formatName="alignRight" />
                                            <Item formatName="alignJustify" />
                                            <Item formatName="separator" />
                                            <Item formatName="orderedList" />
                                            <Item formatName="bulletList" />
                                            <Item formatName="separator" />
                                            <Item formatName="header" formatValues={this.headerValues} />
                                            <Item widget="dxButton" options={this.toolbarButtonOptions} />
                                            <Item formatName="separator" />
                                            <Item formatName="color" />
                                            <Item formatName="background" />
                                            <Item formatName="separator" />
                                            <Item formatName="link" />
                                            {/* <Item formatName="image" /> */}

                                            <Item formatName="separator" />
                                            <Item formatName="clear" />
                                            <Item formatName="codeBlock" />
                                            <Item formatName="blockquote" />

                                            <Item name="separator" />
                                            <Item name="insertTable" />
                                            <Item name="deleteTable" />
                                            <Item name="insertRowAbove" />
                                            <Item name="insertRowBelow" />
                                            <Item name="deleteRow" />
                                            <Item name="insertColumnLeft" />
                                            <Item name="insertColumnRight" />
                                            <Item name="deleteColumn" />
                                        </Toolbar>
                                    </HtmlEditor>
                                    {this.validatorTranslation[currentLanguageActive].errors.has('content') && (
                                        <div className="help-block">
                                            {this.validatorTranslation[currentLanguageActive].errors
                                                .first('content')
                                                .replace('content', i18n.t(i18nLabel))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isShowImagePicker ? (
                    <FileChoosePopup
                        hidePopup={this.hideFileChoosePopup}
                        isShowPopup={isShowImagePicker}
                        allowedExt={['.jpg', '.png']}
                        onFileSelected={this.handleAppendImage}
                    />
                ) : null}
            </div>
        );
    }
}

export default MultiLangHTMLEditor;
