import { SelectBox } from 'devextreme-react';
import React from 'react';
import { connect } from 'react-redux';
import BaseSelectBox from '../../../base/components/BaseSelectBox';
import i18n from '../../../i18n';
import * as _ from 'lodash';
import { customerGetAllRequest } from '../service';
import DataSource from 'devextreme/data/data_source';

class CustomerSelectBox extends BaseSelectBox {
    handleLanguage = () => {
        const { listData } = this.props;
        this.setState({
            datas: listData
        });
    };

    onDataChanged(e) {
        const { field } = this.props;
        if (field === 'receiveCustomerId') {
            const { datas } = this.state;
            const selectedCustomer = _.find(datas, (x) => x.id === e.value);
            this.props.updateData({
                [field]: e.value,
                receiverCode: selectedCustomer ? selectedCustomer.code : ''
            });
        } else {
            this.props.updateData({
                [field]: e.value
            });
        }
    }

    render() {
        const { value, i18nLabel, validatorValue } = this.props;
        // const { datas } = this.state;
        const selectBoxData = new DataSource({
            store: {
                type: 'array',
                key: 'id',
                data: this.state.datas
            },
            paginate: true,
            pageSize: 10
        });
        return (
            <div className={'dx-field'}>
                <div className={'dx-field-label'}>
                    {i18n.t(i18nLabel) + ':'}
                    {validatorValue && validatorValue.includes('require') && <span style={{ color: 'red' }}> *</span>}
                </div>
                <div className={'dx-field-value'}>
                    <SelectBox
                        searchEnabled={true}
                        dataSource={selectBoxData}
                        // items={datas}
                        value={value}
                        valueExpr={'id'}
                        displayExpr={'fullName'}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={true}
                        onValueChanged={this.onDataChanged}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.appSettings.data.languages,
        listData: state.customer.suggestion
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: () => {
            return dispatch(customerGetAllRequest());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelectBox);
