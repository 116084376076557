import React, { Component } from 'react';
import { TextBox } from 'devextreme-react';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

class SearchTextBox extends Component {
    constructor(props) {
        super(props);

        this.valueChangedKeyword = this.valueChangedKeyword.bind(this);
    }

    valueChangedKeyword(e) {
        if (e.event !== undefined && e.event !== null) {
            const { field } = this.props;

            this.props.updateData({
                [field]: e.value
            });
        }
    }

    render() {
        const { handleEventEnter, value, width } = this.props;

        return (
            <div className="form-group cm-mgr-5">
                <div className={'dx-field'}>
                    <div className={'dx-field-value w100pc'}>
                        <TextBox
                            showClearButton={true}
                            value={value}
                            onValueChanged={this.valueChangedKeyword}
                            placeholder={i18n.t('general.placeholder.searchkeyword')}
                            onEnterKey={handleEventEnter}
                            width={width || null}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

SearchTextBox.propTypes = {
    field: PropTypes.any,
    updateData: PropTypes.any,
    handleEventEnter: PropTypes.any,
    value: PropTypes.any,
    width: PropTypes.any
};

export default SearchTextBox;
