import { APP_GET, APP_INIT, APP_UPDATE_USERSETTING, SETTING_CONFIG } from './action-types';

export function coreInitApp(payload) {
    return {
        type: APP_INIT,
        payload
    };
}
export function getApp() {
    return {
        type: APP_GET
    };
}

export function settingConfig(payload) {
    return {
        type: SETTING_CONFIG,
        payload
    };
}

export function updateUserSettings(payload) {
    return {
        type: APP_UPDATE_USERSETTING,
        payload
    };
}
