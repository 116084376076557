import { PAGE_SIZE } from '../../../constants/Config';
import {
    USER_DELETE,
    USER_DETAIL,
    USER_INSERT,
    USER_LIST,
    USER_UPDATE,
    USER_GETCODE,
    USER_GET_ALL,
    USERS_GET_ALL,
    USER_DETAIL_RESET,
    USER_SELECT,
    USER_BRANCH,
    USER_ACTIVE
} from './action-types';

const initialState = {
    data: [],
    totalRows: 0,
    currentPage: 0,
    pageSize: PAGE_SIZE,
    item: null,
    userByBranch: [],
    usersOfBranch: [],
    suggestion: [],
    code: null,
    selectedUserId: null,
    userActive: null
};

const reducer = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case USER_LIST:
            return list(state, payload);
        case USER_DETAIL:
            return detail(state, payload);
        case USER_DETAIL_RESET:
            return resetDetail(state);
        case USER_UPDATE:
            return update(state, payload);
        case USER_GETCODE:
            return getCode(state, payload);
        case USER_INSERT:
            return insert(state, payload);
        case USER_DELETE:
            return deletes(state, payload);
        case USER_GET_ALL:
            return getAll(state, payload);
        case USERS_GET_ALL:
            return suggestion(state, payload);
        case USER_BRANCH:
            return getBranchUsers(state, payload);
        case USER_SELECT:
            return select(state, payload);
        case USER_ACTIVE:
            return getUserActive(state, payload);
        default:
            return state;
    }
};

function select(state, payload) {
    state = Object.assign({}, state, {
        selectedUserId: payload
    });
    return state;
}

function getCode(state, payload) {
    state = Object.assign({}, state, { code: payload });
    return state;
}

function resetDetail(state) {
    state = Object.assign({}, state, {
        item: null
    });
    return state;
}

function list(state, payload) {
    state = Object.assign({}, state, payload);
    return { ...state, ...payload };
}

function detail(state, payload) {
    state = Object.assign({}, state, {
        item: payload
    });
    return state;
}

function deletes(state, payload) {
    const data = state.data.filter((obj) => obj.id !== payload);
    return Object.assign({}, state, { data });
}

function update(state, payload) {
    const data = state.data.map((obj) => {
        if (obj.id === payload.id) {
            return { ...obj, ...payload };
        }
        return obj;
    });
    return Object.assign({}, state, { data });
}

function insert(state, payload) {
    const data = [...state.data, payload];
    return Object.assign({}, state, { data });
}
function getAll(state, payload) {
    state = Object.assign({}, state, { userByBranch: payload });
    return state;
}

function getBranchUsers(state, payload) {
    state = Object.assign({}, state, { usersOfBranch: payload });
    return state;
}

function suggestion(state, payload) {
    state = Object.assign({}, state, { suggestion: payload });
    return state;
}

const getUserActive = (state, payload) => {
    state = Object.assign({}, state, { userActive: payload });
    return state;
};

export const getUsersList = (state) => state.user.data;

export default reducer;
