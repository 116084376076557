import React from 'react';
import { SelectBox } from 'devextreme-react';
import { connect } from 'react-redux';
import { getProductLotInStock } from '../../modules/goodsNoteDetail/service';
import BaseSelectBox from '../components/BaseSelectBox';
import i18n from '../../i18n';
import * as _ from 'lodash';

class LotIdSelectBox extends BaseSelectBox {
    componentDidMount() {
        const { productId, warehouseId, entryDate } = this.props;

        if (productId && warehouseId) {
            this.getLotIdList({
                productId,
                warehouseId,
                entryDate
            });
        }
    }

    handleLanguage = () => {
        const { listData } = this.props;

        this.setState({
            datas: listData
        });
    };

    getAllData() {
        const { productId, warehouseId, entryDate } = this.props;
        const payload = {
            productId,
            warehouseId,
            entryDate
        };
        if (productId && warehouseId && entryDate) {
            this.props.getAllData(payload).then((res) => {
                const datas = _.map(res.data, (item) => {
                    return { id: item, name: item };
                });
                this.setState({
                    datas,
                    renderDone: true
                });
            });
        }
    }

    getLotIdList = (payload) => {
        this.props.getAllData(payload).then((res) => {
            const datas = _.map(res.data, (item) => {
                return { id: item, name: item };
            });
            this.setState({
                datas,
                renderDone: true
            });
        });
    };

    onDataChanged(e) {
        const { field } = this.props;
        this.props.updateData({
            [field]: e.value
        });

        this.props.updateData({
            formValidate: {
                [field]: !!e.value
            }
        });
        this.props.hideSelectbox();
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.productId !== this.props.productId ||
            nextProps.warehouseId !== this.props.warehouseId ||
            nextProps.entryDate !== this.props.entryDate
        ) {
            if (nextProps.productId && nextProps.warehouseId) {
                const payload = {
                    productId: nextProps.productId,
                    warehouseId: nextProps.warehouseId,
                    entryDate: nextProps.entryDate
                };

                this.getLotIdList(payload);
            }
        }
    }

    render() {
        const { i18nLabel, disabled, isShowLabel, readOnly, validatorValue, value } = this.props;
        const { datas } = this.state;
        return (
            <div className={'dx-field'}>
                {isShowLabel && (
                    <div className={'dx-field-label'}>
                        {i18n.t(i18nLabel) + ':'}
                        {validatorValue && validatorValue.includes('require') && (
                            <span style={{ color: 'red' }}> *</span>
                        )}
                    </div>
                )}

                <div className={'dx-field-value'}>
                    <SelectBox
                        searchEnabled={true}
                        items={datas}
                        valueExpr={'id'}
                        displayExpr={'name'}
                        placeholder={i18n.t(i18nLabel)}
                        showClearButton={true}
                        onValueChanged={this.onDataChanged}
                        disabled={disabled}
                        readOnly={readOnly || false}
                        value={value || ''}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { languages } = state.appSettings.data;

    return {
        languages,
        listData: state.warehouse.suggestion
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllData: (payload) => {
            return dispatch(getProductLotInStock(payload));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LotIdSelectBox);
