import BaseViewModel from '../../base/BaseViewModel';
import { GENDER, FORMAT_TIME_BYLANG } from '../../constants/Config';

var moment = require('moment');

export default class CustomerViewModel extends BaseViewModel {
    initialize(props) {
        super.initialize(props);
    }

    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);

        this.birthday = moment(props.birthday).isValid()
            ? moment(props.birthday).format(FORMAT_TIME_BYLANG[languageId])
            : '';

        this.dayAgo = moment(props.dayAgo).isValid() ? moment(props.dayAgo).format(FORMAT_TIME_BYLANG[languageId]) : '';

        this.gender = Number.isInteger(props.gender) ? JSON.parse(GENDER[props.gender].name)[languageId] : props.gender;

        this.expirationDate = moment(props.expirationDate).isValid()
            ? moment(props.expirationDate).format(FORMAT_TIME_BYLANG[languageId])
            : '';

        this.balanceServiceCard = props.balanceServiceCard ? props.balanceServiceCard : 0;
    }
}
