import Loadable from 'react-loadable';
import LoadingComponent from '../../common/loader/index';

export default [
    {
        path: '/customer/custom-card',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer/custom-card/:customerId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('./infoCard/index'),
            loading: LoadingComponent
        })
    },
    {
        path: '/customer/custom-card/:branchId/:cardId',
        exact: true,
        auth: true,
        component: Loadable({
            loader: () => import('../../pages/card/CustomerCardDetailContainer'),
            loading: LoadingComponent
        })
    }
];
