import {
    EMPLOYEE_OFFICE_DELETE,
    EMPLOYEE_OFFICE_DETAIL,
    EMPLOYEE_OFFICE_INSERT,
    EMPLOYEE_OFFICE_LIST,
    EMPLOYEE_OFFICE_UPDATE,
    EMPLOYEE_OFFICE_TREE
} from './action-types';

export function list(payload) {
    return {
        type: EMPLOYEE_OFFICE_LIST,
        payload
    };
}

export function detail(payload) {
    return {
        type: EMPLOYEE_OFFICE_DETAIL,
        payload
    };
}

export function update(payload) {
    return {
        type: EMPLOYEE_OFFICE_UPDATE,
        payload
    };
}

export function deletes(payload) {
    return {
        type: EMPLOYEE_OFFICE_DELETE,
        payload
    };
}

export function insert(payload) {
    return {
        type: EMPLOYEE_OFFICE_INSERT,
        payload
    };
}

export function tree(payload) {
    return {
        type: EMPLOYEE_OFFICE_TREE,
        payload
    };
}
