import { SPINNER_LOADING } from './action-type';

const initialState = {
    isLoading: false
};
const reducer = (state = initialState, { type, payload = false }) => {
    if (type === SPINNER_LOADING) {
        return spinnerLoading(state, payload);
    } else {
        return state;
    }
};

function spinnerLoading(state, payload) {
    return {
        ...state,
        isLoading: payload
    };
}

export default reducer;
