export const SERVICE_CARD_LIST = 'SERVICE_CARD_LIST';
export const SERVICE_CARD_TREE = 'SERVICE_CARD_TREE';
export const SERVICE_CARD_DETAIL = 'SERVICE_CARD_DETAIL';
export const SERVICE_CARD_INSERT = 'SERVICE_CARD_INSERT';
export const SERVICE_CARD_UPDATE = 'SERVICE_CARD_UPDATE';
export const SERVICE_CARD_DELETE = 'SERVICE_CARD_DELETE';
export const SERVICE_CARD_GET_CODE = 'SERVICE_CARD_GET_CODE';

export default {
    SERVICE_CARD_LIST,
    SERVICE_CARD_TREE,
    SERVICE_CARD_DETAIL,
    SERVICE_CARD_INSERT,
    SERVICE_CARD_UPDATE,
    SERVICE_CARD_DELETE,
    SERVICE_CARD_GET_CODE
};
