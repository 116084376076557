import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faBars, faBell, faCaretDown, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Notification from '../../modules/notification/pages';
import { DropDownButton } from 'devextreme-react';
import ThemeColor from '../../modules/core/theme';
import Branch from './branch/index';
import WebsiteSelectHeader from '../../modules/website/website/components/WebsiteSelectHeader';
import { FILE_URL, DEFAULT_AVATAR } from '../../constants/Config';
import i18n from '../../i18n';
import PropTypes from 'prop-types';

const displaName = 'Private header';
library.add(faCogs, faBars, faBell, faCaretDown, faUser, faSignOutAlt);

class PrivateHeader extends Component {
    onItemClick(e) {
        if (e.itemData.event) {
            e.itemData.event(e);
        }
    }

    render() {
        const { currentUser, initNewData, toggleShowForm, logout, currentLanguage } = this.props;
        // const { toggleNavigation} = this.props;
        return (
            <header className="main-header-top dx-toolbar dx-widget ">
                <nav className="navbar navbar-static-top row">
                    {/* <a href="!#" data-toggle="offcanvas" id="toolbar" onClick={toggleNavigation} className="sidebar-toggle dx-theme-accent-as-text-color">
                        <i className="dx-icon dx-icon-menu"></i>
                    </a> */}
                    <div className="logo col-2">
                        <Link to="/">
                            <img src={`${FILE_URL}/${initNewData ? initNewData.logo : ''}`} height={50} alt="" />
                        </Link>
                    </div>
                    <div className="navbar-custom-menu col-10">
                        <ul className="top-nav mr-3">
                            <li className="mr-1 dropdown notification-menu dx-theme-background-color">
                                <WebsiteSelectHeader />
                            </li>

                            <li className="dropdown notification-menu dx-theme-background-color">
                                <Branch />
                            </li>
                            <li className=" mx-1 dropdown notification-menu dx-theme-background-color">
                                <ThemeColor />
                            </li>
                            <li className="dropdown notification-menu">
                                <DropDownButton
                                    className={'btn-acc dx-theme-background-color  '}
                                    maxWidth={200}
                                    width={200}
                                    splitButton={true}
                                    useSelectMode={false}
                                    text={currentUser.fullName}
                                    icon={
                                        currentUser.avatar ? `${FILE_URL}/${currentUser.avatar}` : `${DEFAULT_AVATAR}`
                                    }
                                    items={[
                                        {
                                            id: 1,
                                            name: i18n.t('usercustom.personalinformation', { lng: currentLanguage }),
                                            icon: 'user',
                                            event: (e) => this.props.history.push('/config/usercustom')
                                        },
                                        {
                                            id: 2,
                                            name: i18n.t('general.navbar.changepass', { lng: currentLanguage }),
                                            icon: 'pulldown',
                                            event: (e) => toggleShowForm(e)
                                        },
                                        {
                                            id: 3,
                                            name: i18n.t('general.navbar.logout', { lng: currentLanguage }),
                                            icon: 'runner',
                                            event: (e) => logout(e)
                                        }
                                    ]}
                                    onItemClick={this.onItemClick}
                                    displayExpr={'name'}
                                    keyExpr={'id'}
                                />
                            </li>

                            <Notification />
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        initNewData: state.auth.initNewApp
    };
};

PrivateHeader.displayName = displaName;

PrivateHeader.propTypes = {
    toggleNavigation: PropTypes.func,
    currentUser: PropTypes.object,
    initNewData: PropTypes.object,
    toggleShowForm: PropTypes.func,
    logout: PropTypes.func,
    currentLanguage: PropTypes.string,
    history: PropTypes.object
};

export default connect(mapStateToProps, null)(withRouter(PrivateHeader));
