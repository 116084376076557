import BaseViewModel from '../../base/BaseViewModel';

export default class OfficeTreeUsersViewModel extends BaseViewModel {
    initializeWithLanguage(props, languageId) {
        super.initializeWithLanguage(props, languageId);
        this.items = [];
        this.children = [];
        if (props.data.users.length > 0) {
            props.data.users.forEach((user) => {
                user.text = user.fullName;
            });
            this.items = [...this.items, ...props.data.users];
        }
        if (props.children && props.children.length > 0) {
            this.children = props.children.map((child) => new OfficeTreeUsersViewModel(child, languageId));

            this.items = [...this.items, ...this.children];
        }
    }
}
